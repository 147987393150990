import { GridWithEditor } from "../../../clay/dataGrid/gridWithEditor";
import { ReplacementWidget } from "./widget";

export const ReplacementPage = GridWithEditor({
    prefix: "#/ai/replacements",
    meta: ReplacementWidget,
    makeContext: (context) => context,
    fallbackSorts: ["addedDateTime"],
    title: (record) => {
        return `Replace`;
    },
    postSave: (record) => {},
    locked: (record) => record.details !== "",
});
