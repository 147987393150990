import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { slugCheckValidate } from "../../slugCheck";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { AiCategory, AI_CATEGORY_META } from "./table";

//!RecordWidget
export type AiCategoryWidgetData = AiCategory;

export const AiCategoryWidgetFields = {
    name: FormField(TextWidget),
    slug: FormField(TextWidget),
    description: HtmlEditorWidget,
};

function validateAiCategoryWidget(data: AiCategory, cache: QuickCacheApi) {
    const errors = baseValidateAiCategoryWidget(data, cache);
    slugCheckValidate("AiCategory", data, cache, errors);
    return errors;
}

function AiCategoryWidgetComponent(
    widgets: AiCategoryWidgetWidgets,
    props: AiCategoryWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.slug />
            <widgets.description />
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiCategoryWidgetContext = WidgetContext<
    typeof AiCategoryWidgetFields.name
> &
    WidgetContext<typeof AiCategoryWidgetFields.slug> &
    WidgetContext<typeof AiCategoryWidgetFields.description>;
type AiCategoryWidgetExtraProps = {};
type AiCategoryWidgetBaseState = {
    name: WidgetState<typeof AiCategoryWidgetFields.name>;
    slug: WidgetState<typeof AiCategoryWidgetFields.slug>;
    description: WidgetState<typeof AiCategoryWidgetFields.description>;
};
export type AiCategoryWidgetState = AiCategoryWidgetBaseState;

type BaseAiCategoryWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AiCategoryWidgetFields.name> }
    | { type: "SLUG"; action: WidgetAction<typeof AiCategoryWidgetFields.slug> }
    | {
          type: "DESCRIPTION";
          action: WidgetAction<typeof AiCategoryWidgetFields.description>;
      };

export type AiCategoryWidgetAction = BaseAiCategoryWidgetAction;

export type AiCategoryWidgetProps = WidgetProps<
    AiCategoryWidgetState,
    AiCategoryWidgetData,
    AiCategoryWidgetAction,
    AiCategoryWidgetExtraProps
>;

function baseValidateAiCategoryWidget(
    data: AiCategoryWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiCategoryWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(AiCategoryWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(
        AiCategoryWidgetFields.description,
        data.description,
        cache,
        "description",
        errors
    );
    return errors;
}
function baseAiCategoryWidgetReduce(
    state: AiCategoryWidgetState,
    data: AiCategoryWidgetData,
    action: BaseAiCategoryWidgetAction,
    context: AiCategoryWidgetContext
): WidgetResult<AiCategoryWidgetState, AiCategoryWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiCategoryWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SLUG": {
            const inner = AiCategoryWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = AiCategoryWidgetFields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
    }
}
export type AiCategoryWidgetReactContextType = {
    state: AiCategoryWidgetState;
    data: AiCategoryWidgetData;
    dispatch: (action: AiCategoryWidgetAction) => void;
    status: WidgetStatus;
};
export const AiCategoryWidgetReactContext = React.createContext<
    AiCategoryWidgetReactContextType | undefined
>(undefined);
export const AiCategoryWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiCategoryWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiCategoryWidgetReactContext
        ) as AiCategoryWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiCategoryWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiCategoryWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof AiCategoryWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiCategoryWidgetReactContext
        ) as AiCategoryWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiCategoryWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiCategoryWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof AiCategoryWidgetFields.description> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiCategoryWidgetReactContext
        ) as AiCategoryWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiCategoryWidgetFields.description>) =>
                context.dispatch({ type: "DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiCategoryWidgetFields.description.component
                state={context.state.description}
                data={context.data.description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Description"}
            />
        );
    },
};
export const AiCategoryWidget: RecordWidget<
    AiCategoryWidgetState,
    AiCategoryWidgetData,
    AiCategoryWidgetContext,
    AiCategoryWidgetAction,
    AiCategoryWidgetExtraProps
> = {
    reactContext: AiCategoryWidgetReactContext,
    fieldWidgets: AiCategoryWidgetWidgets,
    dataMeta: AI_CATEGORY_META,
    initialize(
        data: AiCategoryWidgetData,
        context: AiCategoryWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiCategoryWidgetState, AiCategoryWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiCategoryWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerSlug = AiCategoryWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerDescription = AiCategoryWidgetFields.description.initialize(
            data.description,
            subcontext,
            subparameters.description
        );
        let state = {
            name: innerName.state,
            slug: innerSlug.state,
            description: innerDescription.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                slug: innerSlug.data,
                description: innerDescription.data,
            },
        };
    },
    validate: validateAiCategoryWidget,
    component: React.memo((props: AiCategoryWidgetProps) => {
        return (
            <AiCategoryWidgetReactContext.Provider value={props}>
                {AiCategoryWidgetComponent(AiCategoryWidgetWidgets, props)}
            </AiCategoryWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiCategoryWidgetReduce,
};

type AiCategoryWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiCategoryWidgetFields.name
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiCategoryWidgetFields.slug
        >
    >;
    description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiCategoryWidgetFields.description
        >
    >;
};
// END MAGIC -- DO NOT EDIT
