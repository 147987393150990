import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { LinkWidget } from "../../../clay/widgets/link-widget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { TableRow } from "../../../clay/widgets/TableRow";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiPersonDegree, AI_PERSON_DEGREE_META } from "./table";

//!RecordWidget
export type AiPersonDegreeWidgetData = AiPersonDegree;

export const AiPersonDegreeWidgetFields = {
    school: LinkWidget({
        table: "AiSchool",
        nameColumn: "slug",
    }),
    degree: SelectWidget([
        {
            value: "B",
            label: "Bachelors",
        },
        {
            value: "M",
            label: "Masters",
        },
        {
            value: "D",
            label: "Doctorate",
        },
        {
            value: "PhD",
            label: "PhD",
        },
    ]),
    field: TextWidget,
};

export function AiPersonDegreeWidgetComponent(
    widgets: AiPersonDegreeWidgetWidgets,
    props: AiPersonDegreeWidgetProps
) {
    return (
        <TableRow>
            <widgets.school />
            <widgets.degree />
            <widgets.field />
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiPersonDegreeWidgetContext = WidgetContext<
    typeof AiPersonDegreeWidgetFields.school
> &
    WidgetContext<typeof AiPersonDegreeWidgetFields.degree> &
    WidgetContext<typeof AiPersonDegreeWidgetFields.field>;
type AiPersonDegreeWidgetExtraProps = {};
type AiPersonDegreeWidgetBaseState = {
    school: WidgetState<typeof AiPersonDegreeWidgetFields.school>;
    degree: WidgetState<typeof AiPersonDegreeWidgetFields.degree>;
    field: WidgetState<typeof AiPersonDegreeWidgetFields.field>;
};
export type AiPersonDegreeWidgetState = AiPersonDegreeWidgetBaseState;

type BaseAiPersonDegreeWidgetAction =
    | {
          type: "SCHOOL";
          action: WidgetAction<typeof AiPersonDegreeWidgetFields.school>;
      }
    | {
          type: "DEGREE";
          action: WidgetAction<typeof AiPersonDegreeWidgetFields.degree>;
      }
    | {
          type: "FIELD";
          action: WidgetAction<typeof AiPersonDegreeWidgetFields.field>;
      };

export type AiPersonDegreeWidgetAction = BaseAiPersonDegreeWidgetAction;

export type AiPersonDegreeWidgetProps = WidgetProps<
    AiPersonDegreeWidgetState,
    AiPersonDegreeWidgetData,
    AiPersonDegreeWidgetAction,
    AiPersonDegreeWidgetExtraProps
>;

function baseValidateAiPersonDegreeWidget(
    data: AiPersonDegreeWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiPersonDegreeWidgetFields.school,
        data.school,
        cache,
        "school",
        errors
    );
    subvalidate(
        AiPersonDegreeWidgetFields.degree,
        data.degree,
        cache,
        "degree",
        errors
    );
    subvalidate(
        AiPersonDegreeWidgetFields.field,
        data.field,
        cache,
        "field",
        errors
    );
    return errors;
}
function baseAiPersonDegreeWidgetReduce(
    state: AiPersonDegreeWidgetState,
    data: AiPersonDegreeWidgetData,
    action: BaseAiPersonDegreeWidgetAction,
    context: AiPersonDegreeWidgetContext
): WidgetResult<AiPersonDegreeWidgetState, AiPersonDegreeWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "SCHOOL": {
            const inner = AiPersonDegreeWidgetFields.school.reduce(
                state.school,
                data.school,
                action.action,
                subcontext
            );
            return {
                state: { ...state, school: inner.state },
                data: { ...data, school: inner.data },
            };
        }
        case "DEGREE": {
            const inner = AiPersonDegreeWidgetFields.degree.reduce(
                state.degree,
                data.degree,
                action.action,
                subcontext
            );
            return {
                state: { ...state, degree: inner.state },
                data: { ...data, degree: inner.data },
            };
        }
        case "FIELD": {
            const inner = AiPersonDegreeWidgetFields.field.reduce(
                state.field,
                data.field,
                action.action,
                subcontext
            );
            return {
                state: { ...state, field: inner.state },
                data: { ...data, field: inner.data },
            };
        }
    }
}
export type AiPersonDegreeWidgetReactContextType = {
    state: AiPersonDegreeWidgetState;
    data: AiPersonDegreeWidgetData;
    dispatch: (action: AiPersonDegreeWidgetAction) => void;
    status: WidgetStatus;
};
export const AiPersonDegreeWidgetReactContext = React.createContext<
    AiPersonDegreeWidgetReactContextType | undefined
>(undefined);
export const AiPersonDegreeWidgetWidgets = {
    school: function (
        props: WidgetExtraProps<typeof AiPersonDegreeWidgetFields.school> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonDegreeWidgetReactContext
        ) as AiPersonDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonDegreeWidgetFields.school>) =>
                context.dispatch({ type: "SCHOOL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "school", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonDegreeWidgetFields.school.component
                state={context.state.school}
                data={context.data.school}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "School"}
            />
        );
    },
    degree: function (
        props: WidgetExtraProps<typeof AiPersonDegreeWidgetFields.degree> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonDegreeWidgetReactContext
        ) as AiPersonDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonDegreeWidgetFields.degree>) =>
                context.dispatch({ type: "DEGREE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "degree", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonDegreeWidgetFields.degree.component
                state={context.state.degree}
                data={context.data.degree}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Degree"}
            />
        );
    },
    field: function (
        props: WidgetExtraProps<typeof AiPersonDegreeWidgetFields.field> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonDegreeWidgetReactContext
        ) as AiPersonDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonDegreeWidgetFields.field>) =>
                context.dispatch({ type: "FIELD", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "field", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonDegreeWidgetFields.field.component
                state={context.state.field}
                data={context.data.field}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Field"}
            />
        );
    },
};
export const AiPersonDegreeWidget: RecordWidget<
    AiPersonDegreeWidgetState,
    AiPersonDegreeWidgetData,
    AiPersonDegreeWidgetContext,
    AiPersonDegreeWidgetAction,
    AiPersonDegreeWidgetExtraProps
> = {
    reactContext: AiPersonDegreeWidgetReactContext,
    fieldWidgets: AiPersonDegreeWidgetWidgets,
    dataMeta: AI_PERSON_DEGREE_META,
    initialize(
        data: AiPersonDegreeWidgetData,
        context: AiPersonDegreeWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiPersonDegreeWidgetState, AiPersonDegreeWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerSchool = AiPersonDegreeWidgetFields.school.initialize(
            data.school,
            subcontext,
            subparameters.school
        );
        const innerDegree = AiPersonDegreeWidgetFields.degree.initialize(
            data.degree,
            subcontext,
            subparameters.degree
        );
        const innerField = AiPersonDegreeWidgetFields.field.initialize(
            data.field,
            subcontext,
            subparameters.field
        );
        let state = {
            school: innerSchool.state,
            degree: innerDegree.state,
            field: innerField.state,
        };
        return {
            state,
            data: {
                ...data,
                school: innerSchool.data,
                degree: innerDegree.data,
                field: innerField.data,
            },
        };
    },
    validate: baseValidateAiPersonDegreeWidget,
    component: React.memo((props: AiPersonDegreeWidgetProps) => {
        return (
            <AiPersonDegreeWidgetReactContext.Provider value={props}>
                {AiPersonDegreeWidgetComponent(
                    AiPersonDegreeWidgetWidgets,
                    props
                )}
            </AiPersonDegreeWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiPersonDegreeWidgetReduce,
};

type AiPersonDegreeWidgetWidgets = {
    school: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonDegreeWidgetFields.school
        >
    >;
    degree: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonDegreeWidgetFields.degree
        >
    >;
    field: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonDegreeWidgetFields.field
        >
    >;
};
// END MAGIC -- DO NOT EDIT
