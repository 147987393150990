import * as React from "react";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../..//clay/widgets/index";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { SwitchWidget } from "../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ConsultantAffiliation, CONSULTANT_AFFILIATION_META } from "./table";

//!RecordWidget
export type ConsultantAffiliationWidgetData = ConsultantAffiliation;

export const ConsultantAffiliationWidgetFields = {
    active: SwitchWidget,
    acronym: TextWidget,
    name: TextWidget,
    url: TextWidget,
};

function ConsultantAffiliationWidgetComponent(
    widget: ConsultantAffiliationWidgetWidgets,
    props: ConsultantAffiliationWidgetProps
) {
    throw new Error("unreachable");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type ConsultantAffiliationWidgetContext = WidgetContext<
    typeof ConsultantAffiliationWidgetFields.active
> &
    WidgetContext<typeof ConsultantAffiliationWidgetFields.acronym> &
    WidgetContext<typeof ConsultantAffiliationWidgetFields.name> &
    WidgetContext<typeof ConsultantAffiliationWidgetFields.url>;
type ConsultantAffiliationWidgetExtraProps = {};
type ConsultantAffiliationWidgetBaseState = {
    active: WidgetState<typeof ConsultantAffiliationWidgetFields.active>;
    acronym: WidgetState<typeof ConsultantAffiliationWidgetFields.acronym>;
    name: WidgetState<typeof ConsultantAffiliationWidgetFields.name>;
    url: WidgetState<typeof ConsultantAffiliationWidgetFields.url>;
};
export type ConsultantAffiliationWidgetState = ConsultantAffiliationWidgetBaseState;

type BaseConsultantAffiliationWidgetAction =
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof ConsultantAffiliationWidgetFields.active>;
      }
    | {
          type: "ACRONYM";
          action: WidgetAction<
              typeof ConsultantAffiliationWidgetFields.acronym
          >;
      }
    | {
          type: "NAME";
          action: WidgetAction<typeof ConsultantAffiliationWidgetFields.name>;
      }
    | {
          type: "URL";
          action: WidgetAction<typeof ConsultantAffiliationWidgetFields.url>;
      };

export type ConsultantAffiliationWidgetAction = BaseConsultantAffiliationWidgetAction;

export type ConsultantAffiliationWidgetProps = WidgetProps<
    ConsultantAffiliationWidgetState,
    ConsultantAffiliationWidgetData,
    ConsultantAffiliationWidgetAction,
    ConsultantAffiliationWidgetExtraProps
>;

function baseValidateConsultantAffiliationWidget(
    data: ConsultantAffiliationWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ConsultantAffiliationWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(
        ConsultantAffiliationWidgetFields.acronym,
        data.acronym,
        cache,
        "acronym",
        errors
    );
    subvalidate(
        ConsultantAffiliationWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        ConsultantAffiliationWidgetFields.url,
        data.url,
        cache,
        "url",
        errors
    );
    return errors;
}
function baseConsultantAffiliationWidgetReduce(
    state: ConsultantAffiliationWidgetState,
    data: ConsultantAffiliationWidgetData,
    action: BaseConsultantAffiliationWidgetAction,
    context: ConsultantAffiliationWidgetContext
): WidgetResult<
    ConsultantAffiliationWidgetState,
    ConsultantAffiliationWidgetData
> {
    let subcontext = context;
    switch (action.type) {
        case "ACTIVE": {
            const inner = ConsultantAffiliationWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "ACRONYM": {
            const inner = ConsultantAffiliationWidgetFields.acronym.reduce(
                state.acronym,
                data.acronym,
                action.action,
                subcontext
            );
            return {
                state: { ...state, acronym: inner.state },
                data: { ...data, acronym: inner.data },
            };
        }
        case "NAME": {
            const inner = ConsultantAffiliationWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "URL": {
            const inner = ConsultantAffiliationWidgetFields.url.reduce(
                state.url,
                data.url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, url: inner.state },
                data: { ...data, url: inner.data },
            };
        }
    }
}
export type ConsultantAffiliationWidgetReactContextType = {
    state: ConsultantAffiliationWidgetState;
    data: ConsultantAffiliationWidgetData;
    dispatch: (action: ConsultantAffiliationWidgetAction) => void;
    status: WidgetStatus;
};
export const ConsultantAffiliationWidgetReactContext = React.createContext<
    ConsultantAffiliationWidgetReactContextType | undefined
>(undefined);
export const ConsultantAffiliationWidgetWidgets = {
    active: function (
        props: WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.active
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantAffiliationWidgetReactContext
        ) as ConsultantAffiliationWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantAffiliationWidgetFields.active
                >
            ) => context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantAffiliationWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    acronym: function (
        props: WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.acronym
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantAffiliationWidgetReactContext
        ) as ConsultantAffiliationWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantAffiliationWidgetFields.acronym
                >
            ) => context.dispatch({ type: "ACRONYM", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "acronym", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantAffiliationWidgetFields.acronym.component
                state={context.state.acronym}
                data={context.data.acronym}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Acronym"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.name
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantAffiliationWidgetReactContext
        ) as ConsultantAffiliationWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantAffiliationWidgetFields.name
                >
            ) => context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantAffiliationWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    url: function (
        props: WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.url
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantAffiliationWidgetReactContext
        ) as ConsultantAffiliationWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantAffiliationWidgetFields.url
                >
            ) => context.dispatch({ type: "URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantAffiliationWidgetFields.url.component
                state={context.state.url}
                data={context.data.url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Url"}
            />
        );
    },
};
export const ConsultantAffiliationWidget: RecordWidget<
    ConsultantAffiliationWidgetState,
    ConsultantAffiliationWidgetData,
    ConsultantAffiliationWidgetContext,
    ConsultantAffiliationWidgetAction,
    ConsultantAffiliationWidgetExtraProps
> = {
    reactContext: ConsultantAffiliationWidgetReactContext,
    fieldWidgets: ConsultantAffiliationWidgetWidgets,
    dataMeta: CONSULTANT_AFFILIATION_META,
    initialize(
        data: ConsultantAffiliationWidgetData,
        context: ConsultantAffiliationWidgetContext,
        parameters?: string[]
    ): WidgetResult<
        ConsultantAffiliationWidgetState,
        ConsultantAffiliationWidgetData
    > {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerActive = ConsultantAffiliationWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerAcronym = ConsultantAffiliationWidgetFields.acronym.initialize(
            data.acronym,
            subcontext,
            subparameters.acronym
        );
        const innerName = ConsultantAffiliationWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerUrl = ConsultantAffiliationWidgetFields.url.initialize(
            data.url,
            subcontext,
            subparameters.url
        );
        let state = {
            active: innerActive.state,
            acronym: innerAcronym.state,
            name: innerName.state,
            url: innerUrl.state,
        };
        return {
            state,
            data: {
                ...data,
                active: innerActive.data,
                acronym: innerAcronym.data,
                name: innerName.data,
                url: innerUrl.data,
            },
        };
    },
    validate: baseValidateConsultantAffiliationWidget,
    component: React.memo((props: ConsultantAffiliationWidgetProps) => {
        return (
            <ConsultantAffiliationWidgetReactContext.Provider value={props}>
                {ConsultantAffiliationWidgetComponent(
                    ConsultantAffiliationWidgetWidgets,
                    props
                )}
            </ConsultantAffiliationWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseConsultantAffiliationWidgetReduce,
};

type ConsultantAffiliationWidgetWidgets = {
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.active
        >
    >;
    acronym: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.acronym
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.name
        >
    >;
    url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantAffiliationWidgetFields.url
        >
    >;
};
// END MAGIC -- DO NOT EDIT
