import { format } from "date-fns/esm";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { Redirect, REDIRECT_LOG_META, REDIRECT_META } from "./table";

const isBot = require("is-ua-bot");

//!RecordWidget
export type RedirectWidgetData = Redirect;

export const RedirectWidgetFields = {
    source: FormField(TextWidget),
    destination: FormField(TextWidget),
    code: FormField(
        SelectWidget<Redirect["code"]>([
            {
                value: "301",
                label: "Permanent (301)",
            },
            {
                value: "307",
                label: "Temporary (307)",
            },
            {
                value: "404",
                label: "Not Found (404)",
            },
            {
                value: "410",
                label: "Gone (410)",
            },
            {
                value: "451",
                label: "Legal (451)",
            },
        ])
    ),
};

function RedirectWidgetComponent(
    widgets: RedirectWidgetWidgets,
    props: RedirectWidgetProps
) {
    const redirectLogs =
        useRecordQuery(
            REDIRECT_LOG_META,
            {
                filters: [
                    {
                        column: "redirect",
                        filter: {
                            equal: props.data.id.uuid,
                        },
                    },
                ],
            },
            [props.data.id.uuid]
        ) || [];

    const blocks: Dictionary<{
        name: string;
        total: number;
        bots: number;
        notbots: number;
    }> = {};
    for (const log of redirectLogs) {
        const key = format(log.date!, "yyyy MM");
        if (!(key in blocks)) {
            blocks[key] = {
                total: 0,
                bots: 0,
                notbots: 0,
                name: format(log.date!, "MMM yyyy"),
            };
        }
        blocks[key].total += 1;
        if (isBot(log.userAgent)) {
            blocks[key].bots += 1;
        } else {
            blocks[key].notbots += 1;
        }
    }

    const keys = Object.keys(blocks);
    keys.sort();
    keys.reverse();

    return (
        <>
            <widgets.source />
            <widgets.destination />
            <widgets.code />
            <Table>
                <thead>
                    <tr>
                        <td>Month</td>
                        <td>Normal</td>
                        <td>Bots</td>
                        <td>Total</td>
                    </tr>
                </thead>
                <tbody>
                    {keys.map((key) => (
                        <tr key={key}>
                            <td>{blocks[key].name}</td>
                            <td>{blocks[key].notbots.toLocaleString()}</td>
                            <td>{blocks[key].bots.toLocaleString()}</td>
                            <td>{blocks[key].total.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type RedirectWidgetContext = WidgetContext<typeof RedirectWidgetFields.source> &
    WidgetContext<typeof RedirectWidgetFields.destination> &
    WidgetContext<typeof RedirectWidgetFields.code>;
type RedirectWidgetExtraProps = {};
type RedirectWidgetBaseState = {
    source: WidgetState<typeof RedirectWidgetFields.source>;
    destination: WidgetState<typeof RedirectWidgetFields.destination>;
    code: WidgetState<typeof RedirectWidgetFields.code>;
};
export type RedirectWidgetState = RedirectWidgetBaseState;

type BaseRedirectWidgetAction =
    | {
          type: "SOURCE";
          action: WidgetAction<typeof RedirectWidgetFields.source>;
      }
    | {
          type: "DESTINATION";
          action: WidgetAction<typeof RedirectWidgetFields.destination>;
      }
    | { type: "CODE"; action: WidgetAction<typeof RedirectWidgetFields.code> };

export type RedirectWidgetAction = BaseRedirectWidgetAction;

export type RedirectWidgetProps = WidgetProps<
    RedirectWidgetState,
    RedirectWidgetData,
    RedirectWidgetAction,
    RedirectWidgetExtraProps
>;

function baseValidateRedirectWidget(
    data: RedirectWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        RedirectWidgetFields.source,
        data.source,
        cache,
        "source",
        errors
    );
    subvalidate(
        RedirectWidgetFields.destination,
        data.destination,
        cache,
        "destination",
        errors
    );
    subvalidate(RedirectWidgetFields.code, data.code, cache, "code", errors);
    return errors;
}
function baseRedirectWidgetReduce(
    state: RedirectWidgetState,
    data: RedirectWidgetData,
    action: BaseRedirectWidgetAction,
    context: RedirectWidgetContext
): WidgetResult<RedirectWidgetState, RedirectWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "SOURCE": {
            const inner = RedirectWidgetFields.source.reduce(
                state.source,
                data.source,
                action.action,
                subcontext
            );
            return {
                state: { ...state, source: inner.state },
                data: { ...data, source: inner.data },
            };
        }
        case "DESTINATION": {
            const inner = RedirectWidgetFields.destination.reduce(
                state.destination,
                data.destination,
                action.action,
                subcontext
            );
            return {
                state: { ...state, destination: inner.state },
                data: { ...data, destination: inner.data },
            };
        }
        case "CODE": {
            const inner = RedirectWidgetFields.code.reduce(
                state.code,
                data.code,
                action.action,
                subcontext
            );
            return {
                state: { ...state, code: inner.state },
                data: { ...data, code: inner.data },
            };
        }
    }
}
export type RedirectWidgetReactContextType = {
    state: RedirectWidgetState;
    data: RedirectWidgetData;
    dispatch: (action: RedirectWidgetAction) => void;
    status: WidgetStatus;
};
export const RedirectWidgetReactContext = React.createContext<
    RedirectWidgetReactContextType | undefined
>(undefined);
export const RedirectWidgetWidgets = {
    source: function (
        props: WidgetExtraProps<typeof RedirectWidgetFields.source> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            RedirectWidgetReactContext
        ) as RedirectWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof RedirectWidgetFields.source>) =>
                context.dispatch({ type: "SOURCE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "source", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <RedirectWidgetFields.source.component
                state={context.state.source}
                data={context.data.source}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Source"}
            />
        );
    },
    destination: function (
        props: WidgetExtraProps<typeof RedirectWidgetFields.destination> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            RedirectWidgetReactContext
        ) as RedirectWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof RedirectWidgetFields.destination>) =>
                context.dispatch({ type: "DESTINATION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "destination", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <RedirectWidgetFields.destination.component
                state={context.state.destination}
                data={context.data.destination}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Destination"}
            />
        );
    },
    code: function (
        props: WidgetExtraProps<typeof RedirectWidgetFields.code> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            RedirectWidgetReactContext
        ) as RedirectWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof RedirectWidgetFields.code>) =>
                context.dispatch({ type: "CODE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "code", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <RedirectWidgetFields.code.component
                state={context.state.code}
                data={context.data.code}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Code"}
            />
        );
    },
};
export const RedirectWidget: RecordWidget<
    RedirectWidgetState,
    RedirectWidgetData,
    RedirectWidgetContext,
    RedirectWidgetAction,
    RedirectWidgetExtraProps
> = {
    reactContext: RedirectWidgetReactContext,
    fieldWidgets: RedirectWidgetWidgets,
    dataMeta: REDIRECT_META,
    initialize(
        data: RedirectWidgetData,
        context: RedirectWidgetContext,
        parameters?: string[]
    ): WidgetResult<RedirectWidgetState, RedirectWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerSource = RedirectWidgetFields.source.initialize(
            data.source,
            subcontext,
            subparameters.source
        );
        const innerDestination = RedirectWidgetFields.destination.initialize(
            data.destination,
            subcontext,
            subparameters.destination
        );
        const innerCode = RedirectWidgetFields.code.initialize(
            data.code,
            subcontext,
            subparameters.code
        );
        let state = {
            source: innerSource.state,
            destination: innerDestination.state,
            code: innerCode.state,
        };
        return {
            state,
            data: {
                ...data,
                source: innerSource.data,
                destination: innerDestination.data,
                code: innerCode.data,
            },
        };
    },
    validate: baseValidateRedirectWidget,
    component: React.memo((props: RedirectWidgetProps) => {
        return (
            <RedirectWidgetReactContext.Provider value={props}>
                {RedirectWidgetComponent(RedirectWidgetWidgets, props)}
            </RedirectWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseRedirectWidgetReduce,
};

type RedirectWidgetWidgets = {
    source: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof RedirectWidgetFields.source
        >
    >;
    destination: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof RedirectWidgetFields.destination
        >
    >;
    code: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof RedirectWidgetFields.code
        >
    >;
};
// END MAGIC -- DO NOT EDIT
