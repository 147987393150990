import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import {
    AiSchoolDegreeCategory,
    AI_SCHOOL_DEGREE_CATEGORY_META,
} from "./table";

//!RecordWidget
export type CategoryWidgetData = AiSchoolDegreeCategory;

export const CategoryWidgetFields = {
    name: TextWidget,
};

function CategoryWidgetComponent(
    widgets: CategoryWidgetWidgets,
    props: CategoryWidgetProps
) {
    throw new Error("Unreachable");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type CategoryWidgetContext = WidgetContext<typeof CategoryWidgetFields.name>;
type CategoryWidgetExtraProps = {};
type CategoryWidgetBaseState = {
    name: WidgetState<typeof CategoryWidgetFields.name>;
};
export type CategoryWidgetState = CategoryWidgetBaseState;

type BaseCategoryWidgetAction = {
    type: "NAME";
    action: WidgetAction<typeof CategoryWidgetFields.name>;
};

export type CategoryWidgetAction = BaseCategoryWidgetAction;

export type CategoryWidgetProps = WidgetProps<
    CategoryWidgetState,
    CategoryWidgetData,
    CategoryWidgetAction,
    CategoryWidgetExtraProps
>;

function baseValidateCategoryWidget(
    data: CategoryWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(CategoryWidgetFields.name, data.name, cache, "name", errors);
    return errors;
}
function baseCategoryWidgetReduce(
    state: CategoryWidgetState,
    data: CategoryWidgetData,
    action: BaseCategoryWidgetAction,
    context: CategoryWidgetContext
): WidgetResult<CategoryWidgetState, CategoryWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = CategoryWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
    }
}
export type CategoryWidgetReactContextType = {
    state: CategoryWidgetState;
    data: CategoryWidgetData;
    dispatch: (action: CategoryWidgetAction) => void;
    status: WidgetStatus;
};
export const CategoryWidgetReactContext = React.createContext<
    CategoryWidgetReactContextType | undefined
>(undefined);
export const CategoryWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof CategoryWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            CategoryWidgetReactContext
        ) as CategoryWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof CategoryWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <CategoryWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
};
export const CategoryWidget: RecordWidget<
    CategoryWidgetState,
    CategoryWidgetData,
    CategoryWidgetContext,
    CategoryWidgetAction,
    CategoryWidgetExtraProps
> = {
    reactContext: CategoryWidgetReactContext,
    fieldWidgets: CategoryWidgetWidgets,
    dataMeta: AI_SCHOOL_DEGREE_CATEGORY_META,
    initialize(
        data: CategoryWidgetData,
        context: CategoryWidgetContext,
        parameters?: string[]
    ): WidgetResult<CategoryWidgetState, CategoryWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = CategoryWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        let state = {
            name: innerName.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
            },
        };
    },
    validate: baseValidateCategoryWidget,
    component: React.memo((props: CategoryWidgetProps) => {
        return (
            <CategoryWidgetReactContext.Provider value={props}>
                {CategoryWidgetComponent(CategoryWidgetWidgets, props)}
            </CategoryWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseCategoryWidgetReduce,
};

type CategoryWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof CategoryWidgetFields.name
        >
    >;
};
// END MAGIC -- DO NOT EDIT
