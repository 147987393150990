import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { DropdownLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import { AI_CATEGORY_META } from "./table";
import { AiCategoryWidget } from "./widget";

export const AiCategoriesPage = AdminCollectionPage({
    meta: AiCategoryWidget,
    labelColumn: "name",
    urlPrefix: "#/ai/categories",
});

export const AiCategoryLinkWidget = DropdownLinkWidget({
    meta: AI_CATEGORY_META,
    label: (category) => category.name,
});
