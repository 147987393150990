import dateParse from "date-fns/parseISO";
import { Link } from "../../clay/link";
import { RecordMeta } from "../../clay/meta";
import { genUUID, UUID } from "../../clay/uuid";
import { Version } from "../version";

//!Data
export type ConsultantAffiliation = {
    id: UUID;
    recordVersion: Version;
    active: boolean;
    acronym: string;
    name: string;
    url: string;
};

//!Data
export type ConsultantTag = {
    id: UUID;
    url: string;
    name: string;
};

//!Data
export type ConsultantContact = {
    name: string;
    slug: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    email: string;
};

//!Data
export type ConsultantTestimonial = {
    quotation: string;
    name: string;
};

//!Data
export type Consultant = {
    id: UUID;
    recordVersion: Version;

    name: string;

    active: boolean;
    slug: string;
    website: string;
    services: string[];

    national: boolean;
    international: boolean;
    partner: boolean;

    notes: string;

    tags: Link<ConsultantTag>[];
    affiliations: Link<ConsultantAffiliation>[];
    contacts: ConsultantContact[];

    adImageUrl: string;

    summary: string;
    logo: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    clientServices: string[];
    testimonials: ConsultantTestimonial[];
};

//!Data
export type ConsultantConnect = {
    id: UUID;
    recordVersion: Version;

    date: Date | null;
    consultant: Link<Consultant>;
    slug: string;
    firstname: string;
    lastname: string;
    email: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    graduation_year: string;
    services: string[];
    comments: string;
    via: string;
};

//!Data
export type ConsultantOpened = {
    id: UUID;
    recordVersion: Version;

    date: Date | null;
    consultant: Link<Consultant>;
    slug: string;
    via: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type ConsultantAffiliationJSON = {
    id: string;
    recordVersion: number | null;
    active: boolean;
    acronym: string;
    name: string;
    url: string;
};

export function JSONToConsultantAffiliation(
    json: ConsultantAffiliationJSON
): ConsultantAffiliation {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        active: json.active,
        acronym: json.acronym,
        name: json.name,
        url: json.url,
    };
}
export type ConsultantAffiliationBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    active?: boolean;
    acronym?: string;
    name?: string;
    url?: string;
};

export function newConsultantAffiliation(): ConsultantAffiliation {
    return JSONToConsultantAffiliation(
        repairConsultantAffiliationJSON(undefined)
    );
}
export function repairConsultantAffiliationJSON(
    json: ConsultantAffiliationBrokenJSON | undefined
): ConsultantAffiliationJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            active: json.active || false,
            acronym: json.acronym || "",
            name: json.name || "",
            url: json.url || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            active: undefined || false,
            acronym: undefined || "",
            name: undefined || "",
            url: undefined || "",
        };
    }
}

export function ConsultantAffiliationToJSON(
    value: ConsultantAffiliation
): ConsultantAffiliationJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        active: value.active,
        acronym: value.acronym,
        name: value.name,
        url: value.url,
    };
}

export const CONSULTANT_AFFILIATION_META: RecordMeta<
    ConsultantAffiliation,
    ConsultantAffiliationJSON,
    ConsultantAffiliationBrokenJSON
> = {
    name: "ConsultantAffiliation",
    type: "record",
    repair: repairConsultantAffiliationJSON,
    toJSON: ConsultantAffiliationToJSON,
    fromJSON: JSONToConsultantAffiliation,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        active: { type: "boolean" },
        acronym: { type: "string" },
        name: { type: "string" },
        url: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantTagJSON = {
    id: string;
    url: string;
    name: string;
};

export function JSONToConsultantTag(json: ConsultantTagJSON): ConsultantTag {
    return {
        id: { uuid: json.id },
        url: json.url,
        name: json.name,
    };
}
export type ConsultantTagBrokenJSON = {
    id?: string;
    url?: string;
    name?: string;
};

export function newConsultantTag(): ConsultantTag {
    return JSONToConsultantTag(repairConsultantTagJSON(undefined));
}
export function repairConsultantTagJSON(
    json: ConsultantTagBrokenJSON | undefined
): ConsultantTagJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            url: json.url || "",
            name: json.name || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            url: undefined || "",
            name: undefined || "",
        };
    }
}

export function ConsultantTagToJSON(value: ConsultantTag): ConsultantTagJSON {
    return {
        id: value.id.uuid,
        url: value.url,
        name: value.name,
    };
}

export const CONSULTANT_TAG_META: RecordMeta<
    ConsultantTag,
    ConsultantTagJSON,
    ConsultantTagBrokenJSON
> = {
    name: "ConsultantTag",
    type: "record",
    repair: repairConsultantTagJSON,
    toJSON: ConsultantTagToJSON,
    fromJSON: JSONToConsultantTag,
    fields: {
        id: { type: "uuid" },
        url: { type: "string" },
        name: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantContactJSON = {
    name: string;
    slug: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    email: string;
};

export function JSONToConsultantContact(
    json: ConsultantContactJSON
): ConsultantContact {
    return {
        name: json.name,
        slug: json.slug,
        city: json.city,
        state: json.state,
        country: json.country,
        zip: json.zip,
        phone: json.phone,
        email: json.email,
    };
}
export type ConsultantContactBrokenJSON = {
    name?: string;
    slug?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    phone?: string;
    email?: string;
};

export function newConsultantContact(): ConsultantContact {
    return JSONToConsultantContact(repairConsultantContactJSON(undefined));
}
export function repairConsultantContactJSON(
    json: ConsultantContactBrokenJSON | undefined
): ConsultantContactJSON {
    if (json) {
        return {
            name: json.name || "",
            slug: json.slug || "",
            city: json.city || "",
            state: json.state || "",
            country: json.country || "",
            zip: json.zip || "",
            phone: json.phone || "",
            email: json.email || "",
        };
    } else {
        return {
            name: undefined || "",
            slug: undefined || "",
            city: undefined || "",
            state: undefined || "",
            country: undefined || "",
            zip: undefined || "",
            phone: undefined || "",
            email: undefined || "",
        };
    }
}

export function ConsultantContactToJSON(
    value: ConsultantContact
): ConsultantContactJSON {
    return {
        name: value.name,
        slug: value.slug,
        city: value.city,
        state: value.state,
        country: value.country,
        zip: value.zip,
        phone: value.phone,
        email: value.email,
    };
}

export const CONSULTANT_CONTACT_META: RecordMeta<
    ConsultantContact,
    ConsultantContactJSON,
    ConsultantContactBrokenJSON
> = {
    name: "ConsultantContact",
    type: "record",
    repair: repairConsultantContactJSON,
    toJSON: ConsultantContactToJSON,
    fromJSON: JSONToConsultantContact,
    fields: {
        name: { type: "string" },
        slug: { type: "string" },
        city: { type: "string" },
        state: { type: "string" },
        country: { type: "string" },
        zip: { type: "string" },
        phone: { type: "string" },
        email: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantTestimonialJSON = {
    quotation: string;
    name: string;
};

export function JSONToConsultantTestimonial(
    json: ConsultantTestimonialJSON
): ConsultantTestimonial {
    return {
        quotation: json.quotation,
        name: json.name,
    };
}
export type ConsultantTestimonialBrokenJSON = {
    quotation?: string;
    name?: string;
};

export function newConsultantTestimonial(): ConsultantTestimonial {
    return JSONToConsultantTestimonial(
        repairConsultantTestimonialJSON(undefined)
    );
}
export function repairConsultantTestimonialJSON(
    json: ConsultantTestimonialBrokenJSON | undefined
): ConsultantTestimonialJSON {
    if (json) {
        return {
            quotation: json.quotation || "",
            name: json.name || "",
        };
    } else {
        return {
            quotation: undefined || "",
            name: undefined || "",
        };
    }
}

export function ConsultantTestimonialToJSON(
    value: ConsultantTestimonial
): ConsultantTestimonialJSON {
    return {
        quotation: value.quotation,
        name: value.name,
    };
}

export const CONSULTANT_TESTIMONIAL_META: RecordMeta<
    ConsultantTestimonial,
    ConsultantTestimonialJSON,
    ConsultantTestimonialBrokenJSON
> = {
    name: "ConsultantTestimonial",
    type: "record",
    repair: repairConsultantTestimonialJSON,
    toJSON: ConsultantTestimonialToJSON,
    fromJSON: JSONToConsultantTestimonial,
    fields: {
        quotation: { type: "string" },
        name: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
    slug: string;
    website: string;
    services: string[];
    national: boolean;
    international: boolean;
    partner: boolean;
    notes: string;
    tags: (string | null)[];
    affiliations: (string | null)[];
    contacts: ConsultantContactJSON[];
    adImageUrl: string;
    summary: string;
    logo: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    clientServices: string[];
    testimonials: ConsultantTestimonialJSON[];
};

export function JSONToConsultant(json: ConsultantJSON): Consultant {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
        slug: json.slug,
        website: json.website,
        services: json.services.map((inner) => inner),
        national: json.national,
        international: json.international,
        partner: json.partner,
        notes: json.notes,
        tags: json.tags.map((inner) => inner),
        affiliations: json.affiliations.map((inner) => inner),
        contacts: json.contacts.map((inner) => JSONToConsultantContact(inner)),
        adImageUrl: json.adImageUrl,
        summary: json.summary,
        logo: json.logo,
        primaryColor: json.primaryColor,
        secondaryColor: json.secondaryColor,
        tertiaryColor: json.tertiaryColor,
        clientServices: json.clientServices.map((inner) => inner),
        testimonials: json.testimonials.map((inner) =>
            JSONToConsultantTestimonial(inner)
        ),
    };
}
export type ConsultantBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
    slug?: string;
    website?: string;
    services?: string[];
    national?: boolean;
    international?: boolean;
    partner?: boolean;
    notes?: string;
    tags?: (string | null)[];
    affiliations?: (string | null)[];
    contacts?: ConsultantContactJSON[];
    adImageUrl?: string;
    summary?: string;
    logo?: string;
    primaryColor?: string;
    secondaryColor?: string;
    tertiaryColor?: string;
    clientServices?: string[];
    testimonials?: ConsultantTestimonialJSON[];
};

export function newConsultant(): Consultant {
    return JSONToConsultant(repairConsultantJSON(undefined));
}
export function repairConsultantJSON(
    json: ConsultantBrokenJSON | undefined
): ConsultantJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
            slug: json.slug || "",
            website: json.website || "",
            services: (json.services || []).map((inner) => inner || ""),
            national: json.national || false,
            international: json.international || false,
            partner: json.partner || false,
            notes: json.notes || "",
            tags: (json.tags || []).map((inner) => inner || null),
            affiliations: (json.affiliations || []).map(
                (inner) => inner || null
            ),
            contacts: (json.contacts || []).map((inner) =>
                repairConsultantContactJSON(inner)
            ),
            adImageUrl: json.adImageUrl || "",
            summary: json.summary || "",
            logo: json.logo || "",
            primaryColor: json.primaryColor || "",
            secondaryColor: json.secondaryColor || "",
            tertiaryColor: json.tertiaryColor || "",
            clientServices: (json.clientServices || []).map(
                (inner) => inner || ""
            ),
            testimonials: (json.testimonials || []).map((inner) =>
                repairConsultantTestimonialJSON(inner)
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
            slug: undefined || "",
            website: undefined || "",
            services: (undefined || []).map((inner) => inner || ""),
            national: undefined || false,
            international: undefined || false,
            partner: undefined || false,
            notes: undefined || "",
            tags: (undefined || []).map((inner) => inner || null),
            affiliations: (undefined || []).map((inner) => inner || null),
            contacts: (undefined || []).map((inner) =>
                repairConsultantContactJSON(inner)
            ),
            adImageUrl: undefined || "",
            summary: undefined || "",
            logo: undefined || "",
            primaryColor: undefined || "",
            secondaryColor: undefined || "",
            tertiaryColor: undefined || "",
            clientServices: (undefined || []).map((inner) => inner || ""),
            testimonials: (undefined || []).map((inner) =>
                repairConsultantTestimonialJSON(inner)
            ),
        };
    }
}

export function ConsultantToJSON(value: Consultant): ConsultantJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
        slug: value.slug,
        website: value.website,
        services: value.services.map((inner) => inner),
        national: value.national,
        international: value.international,
        partner: value.partner,
        notes: value.notes,
        tags: value.tags.map((inner) => inner),
        affiliations: value.affiliations.map((inner) => inner),
        contacts: value.contacts.map((inner) => ConsultantContactToJSON(inner)),
        adImageUrl: value.adImageUrl,
        summary: value.summary,
        logo: value.logo,
        primaryColor: value.primaryColor,
        secondaryColor: value.secondaryColor,
        tertiaryColor: value.tertiaryColor,
        clientServices: value.clientServices.map((inner) => inner),
        testimonials: value.testimonials.map((inner) =>
            ConsultantTestimonialToJSON(inner)
        ),
    };
}

export const CONSULTANT_META: RecordMeta<
    Consultant,
    ConsultantJSON,
    ConsultantBrokenJSON
> = {
    name: "Consultant",
    type: "record",
    repair: repairConsultantJSON,
    toJSON: ConsultantToJSON,
    fromJSON: JSONToConsultant,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
        slug: { type: "string" },
        website: { type: "string" },
        services: { type: "array", items: { type: "string" } },
        national: { type: "boolean" },
        international: { type: "boolean" },
        partner: { type: "boolean" },
        notes: { type: "string" },
        tags: {
            type: "array",
            items: { type: "uuid", linkTo: "ConsultantTag" },
        },
        affiliations: {
            type: "array",
            items: { type: "uuid", linkTo: "ConsultantAffiliation" },
        },
        contacts: { type: "array", items: CONSULTANT_CONTACT_META },
        adImageUrl: { type: "string" },
        summary: { type: "string" },
        logo: { type: "string" },
        primaryColor: { type: "string" },
        secondaryColor: { type: "string" },
        tertiaryColor: { type: "string" },
        clientServices: { type: "array", items: { type: "string" } },
        testimonials: { type: "array", items: CONSULTANT_TESTIMONIAL_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantConnectJSON = {
    id: string;
    recordVersion: number | null;
    date: string | null;
    consultant: string | null;
    slug: string;
    firstname: string;
    lastname: string;
    email: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    graduation_year: string;
    services: string[];
    comments: string;
    via: string;
};

export function JSONToConsultantConnect(
    json: ConsultantConnectJSON
): ConsultantConnect {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        date: json.date !== null ? dateParse(json.date) : null,
        consultant: json.consultant,
        slug: json.slug,
        firstname: json.firstname,
        lastname: json.lastname,
        email: json.email,
        city: json.city,
        state: json.state,
        zip: json.zip,
        phone: json.phone,
        graduation_year: json.graduation_year,
        services: json.services.map((inner) => inner),
        comments: json.comments,
        via: json.via,
    };
}
export type ConsultantConnectBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    date?: string | null;
    consultant?: string | null;
    slug?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string;
    graduation_year?: string;
    services?: string[];
    comments?: string;
    via?: string;
};

export function newConsultantConnect(): ConsultantConnect {
    return JSONToConsultantConnect(repairConsultantConnectJSON(undefined));
}
export function repairConsultantConnectJSON(
    json: ConsultantConnectBrokenJSON | undefined
): ConsultantConnectJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            date: json.date || null,
            consultant: json.consultant || null,
            slug: json.slug || "",
            firstname: json.firstname || "",
            lastname: json.lastname || "",
            email: json.email || "",
            city: json.city || "",
            state: json.state || "",
            zip: json.zip || "",
            phone: json.phone || "",
            graduation_year: json.graduation_year || "",
            services: (json.services || []).map((inner) => inner || ""),
            comments: json.comments || "",
            via: json.via || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            date: undefined || null,
            consultant: undefined || null,
            slug: undefined || "",
            firstname: undefined || "",
            lastname: undefined || "",
            email: undefined || "",
            city: undefined || "",
            state: undefined || "",
            zip: undefined || "",
            phone: undefined || "",
            graduation_year: undefined || "",
            services: (undefined || []).map((inner) => inner || ""),
            comments: undefined || "",
            via: undefined || "",
        };
    }
}

export function ConsultantConnectToJSON(
    value: ConsultantConnect
): ConsultantConnectJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        date: value.date !== null ? value.date.toISOString() : null,
        consultant: value.consultant,
        slug: value.slug,
        firstname: value.firstname,
        lastname: value.lastname,
        email: value.email,
        city: value.city,
        state: value.state,
        zip: value.zip,
        phone: value.phone,
        graduation_year: value.graduation_year,
        services: value.services.map((inner) => inner),
        comments: value.comments,
        via: value.via,
    };
}

export const CONSULTANT_CONNECT_META: RecordMeta<
    ConsultantConnect,
    ConsultantConnectJSON,
    ConsultantConnectBrokenJSON
> = {
    name: "ConsultantConnect",
    type: "record",
    repair: repairConsultantConnectJSON,
    toJSON: ConsultantConnectToJSON,
    fromJSON: JSONToConsultantConnect,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        date: { type: "datetime" },
        consultant: { type: "uuid", linkTo: "Consultant" },
        slug: { type: "string" },
        firstname: { type: "string" },
        lastname: { type: "string" },
        email: { type: "string" },
        city: { type: "string" },
        state: { type: "string" },
        zip: { type: "string" },
        phone: { type: "string" },
        graduation_year: { type: "string" },
        services: { type: "array", items: { type: "string" } },
        comments: { type: "string" },
        via: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type ConsultantOpenedJSON = {
    id: string;
    recordVersion: number | null;
    date: string | null;
    consultant: string | null;
    slug: string;
    via: string;
};

export function JSONToConsultantOpened(
    json: ConsultantOpenedJSON
): ConsultantOpened {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        date: json.date !== null ? dateParse(json.date) : null,
        consultant: json.consultant,
        slug: json.slug,
        via: json.via,
    };
}
export type ConsultantOpenedBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    date?: string | null;
    consultant?: string | null;
    slug?: string;
    via?: string;
};

export function newConsultantOpened(): ConsultantOpened {
    return JSONToConsultantOpened(repairConsultantOpenedJSON(undefined));
}
export function repairConsultantOpenedJSON(
    json: ConsultantOpenedBrokenJSON | undefined
): ConsultantOpenedJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            date: json.date || null,
            consultant: json.consultant || null,
            slug: json.slug || "",
            via: json.via || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            date: undefined || null,
            consultant: undefined || null,
            slug: undefined || "",
            via: undefined || "",
        };
    }
}

export function ConsultantOpenedToJSON(
    value: ConsultantOpened
): ConsultantOpenedJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        date: value.date !== null ? value.date.toISOString() : null,
        consultant: value.consultant,
        slug: value.slug,
        via: value.via,
    };
}

export const CONSULTANT_OPENED_META: RecordMeta<
    ConsultantOpened,
    ConsultantOpenedJSON,
    ConsultantOpenedBrokenJSON
> = {
    name: "ConsultantOpened",
    type: "record",
    repair: repairConsultantOpenedJSON,
    toJSON: ConsultantOpenedToJSON,
    fromJSON: JSONToConsultantOpened,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        date: { type: "datetime" },
        consultant: { type: "uuid", linkTo: "Consultant" },
        slug: { type: "string" },
        via: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
