import { title } from "change-case";
import { plural } from "pluralize";
import { AdminCollectionPage } from "../../clay/admin-collection-page";
import { ViewWidget } from "./widget";

export const ViewsPage = AdminCollectionPage({
    meta: ViewWidget,
    labelColumn: "name",
    urlPrefix: "#/admin/views",
    categoryColumn: "table",
    defaultColumn: "default",
    categories: [
        "AiArticle",
        "AiSchool",
        "AiPerson",
        "Redirect",
        "Replacement",
        "Consultant",
    ].map((key) => ({
        key,
        label: title(plural(key)),
    })),
    applyCategory: (view, category) => ({
        ...view,
        table: category as any,
    }),
});
