import formatISO9075 from "date-fns/formatISO9075";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import { LocalDate } from "../LocalDate";
import { Widget, WidgetStatus } from "./index";
import { SimpleAtomic } from "./simple-atomic";

export type DateWidgetAction = {};

type StaticDateTimeWidgetProps = {
    state: null;
    data: Date | null;
    dispatch: (action: DateWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
    hideStatus?: boolean;
    todayButton?: boolean;
};

export type StaticDateTimeWidget = {
    state: null;
    data: LocalDate | null;
    action: DateWidgetAction;
    context: {};
    props: {
        style?: React.CSSProperties;
        hideStatus?: boolean;
    };
};

export const StaticDateTimeWidget: Widget<
    null,
    Date | null,
    {},
    DateWidgetAction,
    {
        style?: React.CSSProperties;
        hideStatus?: boolean;
        todayButton?: boolean;
    }
> = {
    ...SimpleAtomic,
    dataMeta: {
        type: "date",
    },
    initialize(data: Date | null) {
        return {
            state: null,
            data,
        };
    },
    component({
        data,
        dispatch,
        status,
        style,
        hideStatus,
    }: StaticDateTimeWidgetProps) {
        return (
            <FormControl
                type="text"
                disabled={true}
                style={{ maxWidth: "2in" }}
                value={data ? formatISO9075(data) : ""}
            />
        );
    },
    reduce(
        state: null,
        data: Date | null,
        action: DateWidgetAction,
        context: {}
    ) {
        return {
            state: null,
            data: data,
        };
    },
    validate(data: Date | null) {
        return [];
    },
};
