import * as jsondiffpatch from "jsondiffpatch";
import React from "react";
import { ListGroup, ListGroupItem, Spinner } from "react-bootstrap";
import { storeRecord, useRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { toPattern } from "../../../clay/dataGrid/patterns";
import { RecordMeta } from "../../../clay/meta";
import { PaginatedWidget } from "../../../clay/paginated-widget";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveButton } from "../../../clay/save-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiArticle, AI_ARTICLE_META } from "../articles/table";
import { AiPage, AI_PAGE_META } from "../pages/table";
import { AiPerson, AI_PERSON_META } from "../person/table";
import { AiSchool, AI_SCHOOL_META } from "../schools/table";
import { Replacement, REPLACEMENT_META } from "./table";

//!RecordWidget
export type MainReplacementWidgetData = Replacement;

export const MainReplacementWidgetFields = {
    original: FormField(TextWidget),
    replacement: FormField(TextWidget),
    articles: FormField(SwitchWidget),
    profiles: FormField(SwitchWidget),
    pages: FormField(SwitchWidget),
    caseSensitive: FormField(SwitchWidget),
};

function MainReplacementWidgetComponent(
    widgets: MainReplacementWidgetWidgets,
    props: MainReplacementWidgetProps
) {
    return (
        <>
            <widgets.original />
            <widgets.replacement />
            <widgets.articles />
            <widgets.profiles />
            <widgets.pages />
            <widgets.caseSensitive />
        </>
    );
}

//!RecordWidget
export type PreviewReplacementWidgetData = Replacement;

export const PreviewReplacementWidgetFields = {
    original: FormField(TextWidget),
};

function escapeRegex(string: string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

type ChangeType<RecordType> = {
    meta: RecordMeta<RecordType, any, any>;
    columns: string[];
    active: (replacement: Replacement) => boolean;
    title: (record: RecordType) => string;
};

const ARTICLE_CHANGE_TYPE: ChangeType<AiArticle> = {
    meta: AI_ARTICLE_META,
    columns: ["content"],
    active: (replacement) => replacement.articles,
    title: (record) => record.title,
};

const PERSON_PROFILE_CHANGE_TYPE: ChangeType<AiPerson> = {
    meta: AI_PERSON_META,
    columns: ["extra_content", "description"],
    active: (replacement) => replacement.profiles,
    title: (record) => record.slug,
};

const SCHOOL_PROFILE_CHANGE_TYPE: ChangeType<AiSchool> = {
    meta: AI_SCHOOL_META,
    columns: ["extra_content", "description"],
    active: (replacement) => replacement.profiles,
    title: (record) => record.slug,
};

const PAGE_CHANGE_TYPE: ChangeType<AiPage> = {
    meta: AI_PAGE_META,
    columns: ["text"],
    active: (replacement) => replacement.profiles,
    title: (record) => record.title,
};

type Change = {
    meta: RecordMeta<any, any, any>;
    title: string;
    record: any;
    patch: jsondiffpatch.Delta;
};

function actionPreviewReplacementWidgetFinalize(
    state: PreviewReplacementWidgetState,
    data: Replacement,
    changes: Change[]
) {
    return {
        state,
        data: {
            ...data,
            details: JSON.stringify(
                changes.map((change) => ({
                    table: change.meta.name,
                    id: change.record.id,
                    patch: change.patch,
                }))
            ),
        },
    };
}

function PreviewReplacementWidgetComponent(
    widgets: PreviewReplacementWidgetWidgets,
    props: PreviewReplacementWidgetProps
) {
    const queryPattern = toPattern(props.data.original);
    const pattern = new RegExp(
        escapeRegex(props.data.original),
        props.data.caseSensitive ? "g" : "gi"
    );
    const changes: Change[] = [];
    let busy = false;

    function applyChangeType<T>(change_type: ChangeType<T>) {
        const data = useRecordQuery(
            change_type.meta,
            {
                filters: change_type.columns.map((column) => ({
                    column,
                    filter: {
                        like: queryPattern,
                    },
                })),
            },
            [queryPattern],
            change_type.active(props.data)
        );

        if (data === undefined) {
            busy = true;
            return;
        }

        for (const record of data) {
            const newRecord = { ...record };
            for (const column of change_type.columns) {
                (newRecord as any)[column] = (record as any)[column].replace(
                    pattern,
                    props.data.replacement
                );
            }

            const patch = jsondiffpatch.diff(record, newRecord);
            if (patch !== undefined) {
                changes.push({
                    meta: change_type.meta,
                    record: newRecord,
                    title: change_type.title(record),
                    patch,
                });
            }
        }
    }

    applyChangeType(ARTICLE_CHANGE_TYPE);
    applyChangeType(PERSON_PROFILE_CHANGE_TYPE);
    applyChangeType(SCHOOL_PROFILE_CHANGE_TYPE);
    applyChangeType(PAGE_CHANGE_TYPE);

    function applyChanges() {
        for (const change of changes) {
            storeRecord(change.meta, "replacement", change.record);
        }
        props.dispatch({
            type: "FINALIZE",
            changes,
        });
    }

    return busy ? (
        <Spinner animation="border" />
    ) : (
        <>
            <ListGroup>
                {changes.map((change) => (
                    <ListGroupItem key={change.record.id}>
                        <h5>{change.title}</h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: jsondiffpatch.formatters.html.format(
                                    change.patch,
                                    undefined
                                ),
                            }}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>

            <SaveButton preSave={applyChanges} />
        </>
    );
}

export const ReplacementWidget = PaginatedWidget({
    pages() {
        return [
            {
                id: "main",
                title: "Details",
                widget: MainReplacementWidget,
            },
            {
                id: "preview",
                title: "Preview",
                widget: PreviewReplacementWidget,
            },
        ];
    },
    dataMeta: REPLACEMENT_META,
});

// BEGIN MAGIC -- DO NOT EDIT
type MainReplacementWidgetContext = WidgetContext<
    typeof MainReplacementWidgetFields.original
> &
    WidgetContext<typeof MainReplacementWidgetFields.replacement> &
    WidgetContext<typeof MainReplacementWidgetFields.articles> &
    WidgetContext<typeof MainReplacementWidgetFields.profiles> &
    WidgetContext<typeof MainReplacementWidgetFields.pages> &
    WidgetContext<typeof MainReplacementWidgetFields.caseSensitive>;
type MainReplacementWidgetExtraProps = {};
type MainReplacementWidgetBaseState = {
    original: WidgetState<typeof MainReplacementWidgetFields.original>;
    replacement: WidgetState<typeof MainReplacementWidgetFields.replacement>;
    articles: WidgetState<typeof MainReplacementWidgetFields.articles>;
    profiles: WidgetState<typeof MainReplacementWidgetFields.profiles>;
    pages: WidgetState<typeof MainReplacementWidgetFields.pages>;
    caseSensitive: WidgetState<
        typeof MainReplacementWidgetFields.caseSensitive
    >;
};
export type MainReplacementWidgetState = MainReplacementWidgetBaseState;

type BaseMainReplacementWidgetAction =
    | {
          type: "ORIGINAL";
          action: WidgetAction<typeof MainReplacementWidgetFields.original>;
      }
    | {
          type: "REPLACEMENT";
          action: WidgetAction<typeof MainReplacementWidgetFields.replacement>;
      }
    | {
          type: "ARTICLES";
          action: WidgetAction<typeof MainReplacementWidgetFields.articles>;
      }
    | {
          type: "PROFILES";
          action: WidgetAction<typeof MainReplacementWidgetFields.profiles>;
      }
    | {
          type: "PAGES";
          action: WidgetAction<typeof MainReplacementWidgetFields.pages>;
      }
    | {
          type: "CASE_SENSITIVE";
          action: WidgetAction<
              typeof MainReplacementWidgetFields.caseSensitive
          >;
      };

export type MainReplacementWidgetAction = BaseMainReplacementWidgetAction;

export type MainReplacementWidgetProps = WidgetProps<
    MainReplacementWidgetState,
    MainReplacementWidgetData,
    MainReplacementWidgetAction,
    MainReplacementWidgetExtraProps
>;

function baseValidateMainReplacementWidget(
    data: MainReplacementWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        MainReplacementWidgetFields.original,
        data.original,
        cache,
        "original",
        errors
    );
    subvalidate(
        MainReplacementWidgetFields.replacement,
        data.replacement,
        cache,
        "replacement",
        errors
    );
    subvalidate(
        MainReplacementWidgetFields.articles,
        data.articles,
        cache,
        "articles",
        errors
    );
    subvalidate(
        MainReplacementWidgetFields.profiles,
        data.profiles,
        cache,
        "profiles",
        errors
    );
    subvalidate(
        MainReplacementWidgetFields.pages,
        data.pages,
        cache,
        "pages",
        errors
    );
    subvalidate(
        MainReplacementWidgetFields.caseSensitive,
        data.caseSensitive,
        cache,
        "caseSensitive",
        errors
    );
    return errors;
}
function baseMainReplacementWidgetReduce(
    state: MainReplacementWidgetState,
    data: MainReplacementWidgetData,
    action: BaseMainReplacementWidgetAction,
    context: MainReplacementWidgetContext
): WidgetResult<MainReplacementWidgetState, MainReplacementWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ORIGINAL": {
            const inner = MainReplacementWidgetFields.original.reduce(
                state.original,
                data.original,
                action.action,
                subcontext
            );
            return {
                state: { ...state, original: inner.state },
                data: { ...data, original: inner.data },
            };
        }
        case "REPLACEMENT": {
            const inner = MainReplacementWidgetFields.replacement.reduce(
                state.replacement,
                data.replacement,
                action.action,
                subcontext
            );
            return {
                state: { ...state, replacement: inner.state },
                data: { ...data, replacement: inner.data },
            };
        }
        case "ARTICLES": {
            const inner = MainReplacementWidgetFields.articles.reduce(
                state.articles,
                data.articles,
                action.action,
                subcontext
            );
            return {
                state: { ...state, articles: inner.state },
                data: { ...data, articles: inner.data },
            };
        }
        case "PROFILES": {
            const inner = MainReplacementWidgetFields.profiles.reduce(
                state.profiles,
                data.profiles,
                action.action,
                subcontext
            );
            return {
                state: { ...state, profiles: inner.state },
                data: { ...data, profiles: inner.data },
            };
        }
        case "PAGES": {
            const inner = MainReplacementWidgetFields.pages.reduce(
                state.pages,
                data.pages,
                action.action,
                subcontext
            );
            return {
                state: { ...state, pages: inner.state },
                data: { ...data, pages: inner.data },
            };
        }
        case "CASE_SENSITIVE": {
            const inner = MainReplacementWidgetFields.caseSensitive.reduce(
                state.caseSensitive,
                data.caseSensitive,
                action.action,
                subcontext
            );
            return {
                state: { ...state, caseSensitive: inner.state },
                data: { ...data, caseSensitive: inner.data },
            };
        }
    }
}
export type MainReplacementWidgetReactContextType = {
    state: MainReplacementWidgetState;
    data: MainReplacementWidgetData;
    dispatch: (action: MainReplacementWidgetAction) => void;
    status: WidgetStatus;
};
export const MainReplacementWidgetReactContext = React.createContext<
    MainReplacementWidgetReactContextType | undefined
>(undefined);
export const MainReplacementWidgetWidgets = {
    original: function (
        props: WidgetExtraProps<typeof MainReplacementWidgetFields.original> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof MainReplacementWidgetFields.original
                >
            ) => context.dispatch({ type: "ORIGINAL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "original", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.original.component
                state={context.state.original}
                data={context.data.original}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Original"}
            />
        );
    },
    replacement: function (
        props: WidgetExtraProps<
            typeof MainReplacementWidgetFields.replacement
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof MainReplacementWidgetFields.replacement
                >
            ) => context.dispatch({ type: "REPLACEMENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "replacement", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.replacement.component
                state={context.state.replacement}
                data={context.data.replacement}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Replacement"}
            />
        );
    },
    articles: function (
        props: WidgetExtraProps<typeof MainReplacementWidgetFields.articles> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof MainReplacementWidgetFields.articles
                >
            ) => context.dispatch({ type: "ARTICLES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "articles", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.articles.component
                state={context.state.articles}
                data={context.data.articles}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Articles"}
            />
        );
    },
    profiles: function (
        props: WidgetExtraProps<typeof MainReplacementWidgetFields.profiles> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof MainReplacementWidgetFields.profiles
                >
            ) => context.dispatch({ type: "PROFILES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "profiles", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.profiles.component
                state={context.state.profiles}
                data={context.data.profiles}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Profiles"}
            />
        );
    },
    pages: function (
        props: WidgetExtraProps<typeof MainReplacementWidgetFields.pages> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof MainReplacementWidgetFields.pages>) =>
                context.dispatch({ type: "PAGES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "pages", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.pages.component
                state={context.state.pages}
                data={context.data.pages}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Pages"}
            />
        );
    },
    caseSensitive: function (
        props: WidgetExtraProps<
            typeof MainReplacementWidgetFields.caseSensitive
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            MainReplacementWidgetReactContext
        ) as MainReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof MainReplacementWidgetFields.caseSensitive
                >
            ) => context.dispatch({ type: "CASE_SENSITIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "caseSensitive", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <MainReplacementWidgetFields.caseSensitive.component
                state={context.state.caseSensitive}
                data={context.data.caseSensitive}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Case Sensitive"}
            />
        );
    },
};
export const MainReplacementWidget: RecordWidget<
    MainReplacementWidgetState,
    MainReplacementWidgetData,
    MainReplacementWidgetContext,
    MainReplacementWidgetAction,
    MainReplacementWidgetExtraProps
> = {
    reactContext: MainReplacementWidgetReactContext,
    fieldWidgets: MainReplacementWidgetWidgets,
    dataMeta: REPLACEMENT_META,
    initialize(
        data: MainReplacementWidgetData,
        context: MainReplacementWidgetContext,
        parameters?: string[]
    ): WidgetResult<MainReplacementWidgetState, MainReplacementWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerOriginal = MainReplacementWidgetFields.original.initialize(
            data.original,
            subcontext,
            subparameters.original
        );
        const innerReplacement = MainReplacementWidgetFields.replacement.initialize(
            data.replacement,
            subcontext,
            subparameters.replacement
        );
        const innerArticles = MainReplacementWidgetFields.articles.initialize(
            data.articles,
            subcontext,
            subparameters.articles
        );
        const innerProfiles = MainReplacementWidgetFields.profiles.initialize(
            data.profiles,
            subcontext,
            subparameters.profiles
        );
        const innerPages = MainReplacementWidgetFields.pages.initialize(
            data.pages,
            subcontext,
            subparameters.pages
        );
        const innerCaseSensitive = MainReplacementWidgetFields.caseSensitive.initialize(
            data.caseSensitive,
            subcontext,
            subparameters.caseSensitive
        );
        let state = {
            original: innerOriginal.state,
            replacement: innerReplacement.state,
            articles: innerArticles.state,
            profiles: innerProfiles.state,
            pages: innerPages.state,
            caseSensitive: innerCaseSensitive.state,
        };
        return {
            state,
            data: {
                ...data,
                original: innerOriginal.data,
                replacement: innerReplacement.data,
                articles: innerArticles.data,
                profiles: innerProfiles.data,
                pages: innerPages.data,
                caseSensitive: innerCaseSensitive.data,
            },
        };
    },
    validate: baseValidateMainReplacementWidget,
    component: React.memo((props: MainReplacementWidgetProps) => {
        return (
            <MainReplacementWidgetReactContext.Provider value={props}>
                {MainReplacementWidgetComponent(
                    MainReplacementWidgetWidgets,
                    props
                )}
            </MainReplacementWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseMainReplacementWidgetReduce,
};

type MainReplacementWidgetWidgets = {
    original: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.original
        >
    >;
    replacement: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.replacement
        >
    >;
    articles: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.articles
        >
    >;
    profiles: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.profiles
        >
    >;
    pages: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.pages
        >
    >;
    caseSensitive: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof MainReplacementWidgetFields.caseSensitive
        >
    >;
};
type PreviewReplacementWidgetContext = WidgetContext<
    typeof PreviewReplacementWidgetFields.original
>;
type PreviewReplacementWidgetExtraProps = {};
type PreviewReplacementWidgetBaseState = {
    original: WidgetState<typeof PreviewReplacementWidgetFields.original>;
};
export type PreviewReplacementWidgetState = PreviewReplacementWidgetBaseState;

type BasePreviewReplacementWidgetAction =
    | {
          type: "ORIGINAL";
          action: WidgetAction<typeof PreviewReplacementWidgetFields.original>;
      }
    | { type: "FINALIZE"; changes: Change[] };

export type PreviewReplacementWidgetAction = BasePreviewReplacementWidgetAction;

export type PreviewReplacementWidgetProps = WidgetProps<
    PreviewReplacementWidgetState,
    PreviewReplacementWidgetData,
    PreviewReplacementWidgetAction,
    PreviewReplacementWidgetExtraProps
>;

function baseValidatePreviewReplacementWidget(
    data: PreviewReplacementWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        PreviewReplacementWidgetFields.original,
        data.original,
        cache,
        "original",
        errors
    );
    return errors;
}
function basePreviewReplacementWidgetReduce(
    state: PreviewReplacementWidgetState,
    data: PreviewReplacementWidgetData,
    action: BasePreviewReplacementWidgetAction,
    context: PreviewReplacementWidgetContext
): WidgetResult<PreviewReplacementWidgetState, PreviewReplacementWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ORIGINAL": {
            const inner = PreviewReplacementWidgetFields.original.reduce(
                state.original,
                data.original,
                action.action,
                subcontext
            );
            return {
                state: { ...state, original: inner.state },
                data: { ...data, original: inner.data },
            };
        }
        case "FINALIZE":
            return actionPreviewReplacementWidgetFinalize(
                state,
                data,
                action.changes
            );
    }
}
export type PreviewReplacementWidgetReactContextType = {
    state: PreviewReplacementWidgetState;
    data: PreviewReplacementWidgetData;
    dispatch: (action: PreviewReplacementWidgetAction) => void;
    status: WidgetStatus;
};
export const PreviewReplacementWidgetReactContext = React.createContext<
    PreviewReplacementWidgetReactContextType | undefined
>(undefined);
export const PreviewReplacementWidgetWidgets = {
    original: function (
        props: WidgetExtraProps<
            typeof PreviewReplacementWidgetFields.original
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            PreviewReplacementWidgetReactContext
        ) as PreviewReplacementWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof PreviewReplacementWidgetFields.original
                >
            ) => context.dispatch({ type: "ORIGINAL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "original", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <PreviewReplacementWidgetFields.original.component
                state={context.state.original}
                data={context.data.original}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Original"}
            />
        );
    },
};
export const PreviewReplacementWidget: RecordWidget<
    PreviewReplacementWidgetState,
    PreviewReplacementWidgetData,
    PreviewReplacementWidgetContext,
    PreviewReplacementWidgetAction,
    PreviewReplacementWidgetExtraProps
> = {
    reactContext: PreviewReplacementWidgetReactContext,
    fieldWidgets: PreviewReplacementWidgetWidgets,
    dataMeta: REPLACEMENT_META,
    initialize(
        data: PreviewReplacementWidgetData,
        context: PreviewReplacementWidgetContext,
        parameters?: string[]
    ): WidgetResult<
        PreviewReplacementWidgetState,
        PreviewReplacementWidgetData
    > {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerOriginal = PreviewReplacementWidgetFields.original.initialize(
            data.original,
            subcontext,
            subparameters.original
        );
        let state = {
            original: innerOriginal.state,
        };
        return {
            state,
            data: {
                ...data,
                original: innerOriginal.data,
            },
        };
    },
    validate: baseValidatePreviewReplacementWidget,
    component: React.memo((props: PreviewReplacementWidgetProps) => {
        return (
            <PreviewReplacementWidgetReactContext.Provider value={props}>
                {PreviewReplacementWidgetComponent(
                    PreviewReplacementWidgetWidgets,
                    props
                )}
            </PreviewReplacementWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: basePreviewReplacementWidgetReduce,
};

type PreviewReplacementWidgetWidgets = {
    original: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof PreviewReplacementWidgetFields.original
        >
    >;
};
// END MAGIC -- DO NOT EDIT
