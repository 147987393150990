import { Decimal } from "decimal.js";
import { Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type AiTimeDescription = {
    start: Quantity;
    end: Quantity;
    content: string;
};

//!Data
export type AiAd = {
    content: string;
};

//!Data
export type AiDiscipline = {
    id: UUID;
    recordVersion: Version;

    active: boolean;
    black_hole: boolean;
    name: string;
    wikidata_ids: string[];
    cipcodes: string[];
    occupation_labels: string[];
    superdiscipline:
        | "Humanities"
        | "Social Sciences"
        | "Natural Sciences"
        | "Formal Sciences"
        | "Applied Sciences";
    description: string;
    factor: Quantity;
    descriptions: AiTimeDescription[];
    ads: AiAd[];
};

//!Data
export type AiSubdiscipline = {
    id: UUID;
    recordVersion: Version;

    active: boolean;
    parent: Link<AiDiscipline>;
    name: string;
    wikidata_ids: string[];
    cipcodes: string[];
    occupation_labels: string[];
    factor: Quantity;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiTimeDescriptionJSON = {
    start: string;
    end: string;
    content: string;
};

export function JSONToAiTimeDescription(
    json: AiTimeDescriptionJSON
): AiTimeDescription {
    return {
        start: new Decimal(json.start),
        end: new Decimal(json.end),
        content: json.content,
    };
}
export type AiTimeDescriptionBrokenJSON = {
    start?: string;
    end?: string;
    content?: string;
};

export function newAiTimeDescription(): AiTimeDescription {
    return JSONToAiTimeDescription(repairAiTimeDescriptionJSON(undefined));
}
export function repairAiTimeDescriptionJSON(
    json: AiTimeDescriptionBrokenJSON | undefined
): AiTimeDescriptionJSON {
    if (json) {
        return {
            start: json.start || "0",
            end: json.end || "0",
            content: json.content || "",
        };
    } else {
        return {
            start: undefined || "0",
            end: undefined || "0",
            content: undefined || "",
        };
    }
}

export function AiTimeDescriptionToJSON(
    value: AiTimeDescription
): AiTimeDescriptionJSON {
    return {
        start: value.start.toString(),
        end: value.end.toString(),
        content: value.content,
    };
}

export const AI_TIME_DESCRIPTION_META: RecordMeta<
    AiTimeDescription,
    AiTimeDescriptionJSON,
    AiTimeDescriptionBrokenJSON
> = {
    name: "AiTimeDescription",
    type: "record",
    repair: repairAiTimeDescriptionJSON,
    toJSON: AiTimeDescriptionToJSON,
    fromJSON: JSONToAiTimeDescription,
    fields: {
        start: { type: "quantity" },
        end: { type: "quantity" },
        content: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiAdJSON = {
    content: string;
};

export function JSONToAiAd(json: AiAdJSON): AiAd {
    return {
        content: json.content,
    };
}
export type AiAdBrokenJSON = {
    content?: string;
};

export function newAiAd(): AiAd {
    return JSONToAiAd(repairAiAdJSON(undefined));
}
export function repairAiAdJSON(json: AiAdBrokenJSON | undefined): AiAdJSON {
    if (json) {
        return {
            content: json.content || "",
        };
    } else {
        return {
            content: undefined || "",
        };
    }
}

export function AiAdToJSON(value: AiAd): AiAdJSON {
    return {
        content: value.content,
    };
}

export const AI_AD_META: RecordMeta<AiAd, AiAdJSON, AiAdBrokenJSON> = {
    name: "AiAd",
    type: "record",
    repair: repairAiAdJSON,
    toJSON: AiAdToJSON,
    fromJSON: JSONToAiAd,
    fields: {
        content: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiDisciplineJSON = {
    id: string;
    recordVersion: number | null;
    active: boolean;
    black_hole: boolean;
    name: string;
    wikidata_ids: string[];
    cipcodes: string[];
    occupation_labels: string[];
    superdiscipline: string;
    description: string;
    factor: string;
    descriptions: AiTimeDescriptionJSON[];
    ads: AiAdJSON[];
};

export function JSONToAiDiscipline(json: AiDisciplineJSON): AiDiscipline {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        active: json.active,
        black_hole: json.black_hole,
        name: json.name,
        wikidata_ids: json.wikidata_ids.map((inner) => inner),
        cipcodes: json.cipcodes.map((inner) => inner),
        occupation_labels: json.occupation_labels.map((inner) => inner),
        superdiscipline: json.superdiscipline as any,
        description: json.description,
        factor: new Decimal(json.factor),
        descriptions: json.descriptions.map((inner) =>
            JSONToAiTimeDescription(inner)
        ),
        ads: json.ads.map((inner) => JSONToAiAd(inner)),
    };
}
export type AiDisciplineBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    active?: boolean;
    black_hole?: boolean;
    name?: string;
    wikidata_ids?: string[];
    cipcodes?: string[];
    occupation_labels?: string[];
    superdiscipline?: string;
    description?: string;
    factor?: string;
    descriptions?: AiTimeDescriptionJSON[];
    ads?: AiAdJSON[];
};

export function newAiDiscipline(): AiDiscipline {
    return JSONToAiDiscipline(repairAiDisciplineJSON(undefined));
}
export function repairAiDisciplineJSON(
    json: AiDisciplineBrokenJSON | undefined
): AiDisciplineJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            active: json.active || false,
            black_hole: json.black_hole || false,
            name: json.name || "",
            wikidata_ids: (json.wikidata_ids || []).map((inner) => inner || ""),
            cipcodes: (json.cipcodes || []).map((inner) => inner || ""),
            occupation_labels: (json.occupation_labels || []).map(
                (inner) => inner || ""
            ),
            superdiscipline: json.superdiscipline || "Humanities",
            description: json.description || "",
            factor: json.factor || "0",
            descriptions: (json.descriptions || []).map((inner) =>
                repairAiTimeDescriptionJSON(inner)
            ),
            ads: (json.ads || []).map((inner) => repairAiAdJSON(inner)),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            active: undefined || false,
            black_hole: undefined || false,
            name: undefined || "",
            wikidata_ids: (undefined || []).map((inner) => inner || ""),
            cipcodes: (undefined || []).map((inner) => inner || ""),
            occupation_labels: (undefined || []).map((inner) => inner || ""),
            superdiscipline: undefined || "Humanities",
            description: undefined || "",
            factor: undefined || "0",
            descriptions: (undefined || []).map((inner) =>
                repairAiTimeDescriptionJSON(inner)
            ),
            ads: (undefined || []).map((inner) => repairAiAdJSON(inner)),
        };
    }
}

export function AiDisciplineToJSON(value: AiDiscipline): AiDisciplineJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        active: value.active,
        black_hole: value.black_hole,
        name: value.name,
        wikidata_ids: value.wikidata_ids.map((inner) => inner),
        cipcodes: value.cipcodes.map((inner) => inner),
        occupation_labels: value.occupation_labels.map((inner) => inner),
        superdiscipline: value.superdiscipline,
        description: value.description,
        factor: value.factor.toString(),
        descriptions: value.descriptions.map((inner) =>
            AiTimeDescriptionToJSON(inner)
        ),
        ads: value.ads.map((inner) => AiAdToJSON(inner)),
    };
}

export const AI_DISCIPLINE_META: RecordMeta<
    AiDiscipline,
    AiDisciplineJSON,
    AiDisciplineBrokenJSON
> = {
    name: "AiDiscipline",
    type: "record",
    repair: repairAiDisciplineJSON,
    toJSON: AiDisciplineToJSON,
    fromJSON: JSONToAiDiscipline,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        active: { type: "boolean" },
        black_hole: { type: "boolean" },
        name: { type: "string" },
        wikidata_ids: { type: "array", items: { type: "string" } },
        cipcodes: { type: "array", items: { type: "string" } },
        occupation_labels: { type: "array", items: { type: "string" } },
        superdiscipline: {
            type: "enum",
            values: [
                "Humanities",
                "Social Sciences",
                "Natural Sciences",
                "Formal Sciences",
                "Applied Sciences",
            ],
        },
        description: { type: "string" },
        factor: { type: "quantity" },
        descriptions: { type: "array", items: AI_TIME_DESCRIPTION_META },
        ads: { type: "array", items: AI_AD_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiSubdisciplineJSON = {
    id: string;
    recordVersion: number | null;
    active: boolean;
    parent: string | null;
    name: string;
    wikidata_ids: string[];
    cipcodes: string[];
    occupation_labels: string[];
    factor: string;
};

export function JSONToAiSubdiscipline(
    json: AiSubdisciplineJSON
): AiSubdiscipline {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        active: json.active,
        parent: json.parent,
        name: json.name,
        wikidata_ids: json.wikidata_ids.map((inner) => inner),
        cipcodes: json.cipcodes.map((inner) => inner),
        occupation_labels: json.occupation_labels.map((inner) => inner),
        factor: new Decimal(json.factor),
    };
}
export type AiSubdisciplineBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    active?: boolean;
    parent?: string | null;
    name?: string;
    wikidata_ids?: string[];
    cipcodes?: string[];
    occupation_labels?: string[];
    factor?: string;
};

export function newAiSubdiscipline(): AiSubdiscipline {
    return JSONToAiSubdiscipline(repairAiSubdisciplineJSON(undefined));
}
export function repairAiSubdisciplineJSON(
    json: AiSubdisciplineBrokenJSON | undefined
): AiSubdisciplineJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            active: json.active || false,
            parent: json.parent || null,
            name: json.name || "",
            wikidata_ids: (json.wikidata_ids || []).map((inner) => inner || ""),
            cipcodes: (json.cipcodes || []).map((inner) => inner || ""),
            occupation_labels: (json.occupation_labels || []).map(
                (inner) => inner || ""
            ),
            factor: json.factor || "0",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            active: undefined || false,
            parent: undefined || null,
            name: undefined || "",
            wikidata_ids: (undefined || []).map((inner) => inner || ""),
            cipcodes: (undefined || []).map((inner) => inner || ""),
            occupation_labels: (undefined || []).map((inner) => inner || ""),
            factor: undefined || "0",
        };
    }
}

export function AiSubdisciplineToJSON(
    value: AiSubdiscipline
): AiSubdisciplineJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        active: value.active,
        parent: value.parent,
        name: value.name,
        wikidata_ids: value.wikidata_ids.map((inner) => inner),
        cipcodes: value.cipcodes.map((inner) => inner),
        occupation_labels: value.occupation_labels.map((inner) => inner),
        factor: value.factor.toString(),
    };
}

export const AI_SUBDISCIPLINE_META: RecordMeta<
    AiSubdiscipline,
    AiSubdisciplineJSON,
    AiSubdisciplineBrokenJSON
> = {
    name: "AiSubdiscipline",
    type: "record",
    repair: repairAiSubdisciplineJSON,
    toJSON: AiSubdisciplineToJSON,
    fromJSON: JSONToAiSubdiscipline,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        active: { type: "boolean" },
        parent: { type: "uuid", linkTo: "AiDiscipline" },
        name: { type: "string" },
        wikidata_ids: { type: "array", items: { type: "string" } },
        cipcodes: { type: "array", items: { type: "string" } },
        occupation_labels: { type: "array", items: { type: "string" } },
        factor: { type: "quantity" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
