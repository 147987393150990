import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type AiMenuItem = {
    name: string;
    link: string;
    emphasized: boolean;
};

//!Data
export type AiMenuSection = {
    name: string;
    headerLink: string;
    items: AiMenuItem[];
};

//!Data
export type AiSiteMenu = {
    name: string;
    sections: AiMenuSection[];
};

//!Data
export type AiMenus = {
    id: UUID;
    recordVersion: Version;

    menus: AiSiteMenu[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiMenuItemJSON = {
    name: string;
    link: string;
    emphasized: boolean;
};

export function JSONToAiMenuItem(json: AiMenuItemJSON): AiMenuItem {
    return {
        name: json.name,
        link: json.link,
        emphasized: json.emphasized,
    };
}
export type AiMenuItemBrokenJSON = {
    name?: string;
    link?: string;
    emphasized?: boolean;
};

export function newAiMenuItem(): AiMenuItem {
    return JSONToAiMenuItem(repairAiMenuItemJSON(undefined));
}
export function repairAiMenuItemJSON(
    json: AiMenuItemBrokenJSON | undefined
): AiMenuItemJSON {
    if (json) {
        return {
            name: json.name || "",
            link: json.link || "",
            emphasized: json.emphasized || false,
        };
    } else {
        return {
            name: undefined || "",
            link: undefined || "",
            emphasized: undefined || false,
        };
    }
}

export function AiMenuItemToJSON(value: AiMenuItem): AiMenuItemJSON {
    return {
        name: value.name,
        link: value.link,
        emphasized: value.emphasized,
    };
}

export const AI_MENU_ITEM_META: RecordMeta<
    AiMenuItem,
    AiMenuItemJSON,
    AiMenuItemBrokenJSON
> = {
    name: "AiMenuItem",
    type: "record",
    repair: repairAiMenuItemJSON,
    toJSON: AiMenuItemToJSON,
    fromJSON: JSONToAiMenuItem,
    fields: {
        name: { type: "string" },
        link: { type: "string" },
        emphasized: { type: "boolean" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiMenuSectionJSON = {
    name: string;
    headerLink: string;
    items: AiMenuItemJSON[];
};

export function JSONToAiMenuSection(json: AiMenuSectionJSON): AiMenuSection {
    return {
        name: json.name,
        headerLink: json.headerLink,
        items: json.items.map((inner) => JSONToAiMenuItem(inner)),
    };
}
export type AiMenuSectionBrokenJSON = {
    name?: string;
    headerLink?: string;
    items?: AiMenuItemJSON[];
};

export function newAiMenuSection(): AiMenuSection {
    return JSONToAiMenuSection(repairAiMenuSectionJSON(undefined));
}
export function repairAiMenuSectionJSON(
    json: AiMenuSectionBrokenJSON | undefined
): AiMenuSectionJSON {
    if (json) {
        return {
            name: json.name || "",
            headerLink: json.headerLink || "",
            items: (json.items || []).map((inner) =>
                repairAiMenuItemJSON(inner)
            ),
        };
    } else {
        return {
            name: undefined || "",
            headerLink: undefined || "",
            items: (undefined || []).map((inner) =>
                repairAiMenuItemJSON(inner)
            ),
        };
    }
}

export function AiMenuSectionToJSON(value: AiMenuSection): AiMenuSectionJSON {
    return {
        name: value.name,
        headerLink: value.headerLink,
        items: value.items.map((inner) => AiMenuItemToJSON(inner)),
    };
}

export const AI_MENU_SECTION_META: RecordMeta<
    AiMenuSection,
    AiMenuSectionJSON,
    AiMenuSectionBrokenJSON
> = {
    name: "AiMenuSection",
    type: "record",
    repair: repairAiMenuSectionJSON,
    toJSON: AiMenuSectionToJSON,
    fromJSON: JSONToAiMenuSection,
    fields: {
        name: { type: "string" },
        headerLink: { type: "string" },
        items: { type: "array", items: AI_MENU_ITEM_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiSiteMenuJSON = {
    name: string;
    sections: AiMenuSectionJSON[];
};

export function JSONToAiSiteMenu(json: AiSiteMenuJSON): AiSiteMenu {
    return {
        name: json.name,
        sections: json.sections.map((inner) => JSONToAiMenuSection(inner)),
    };
}
export type AiSiteMenuBrokenJSON = {
    name?: string;
    sections?: AiMenuSectionJSON[];
};

export function newAiSiteMenu(): AiSiteMenu {
    return JSONToAiSiteMenu(repairAiSiteMenuJSON(undefined));
}
export function repairAiSiteMenuJSON(
    json: AiSiteMenuBrokenJSON | undefined
): AiSiteMenuJSON {
    if (json) {
        return {
            name: json.name || "",
            sections: (json.sections || []).map((inner) =>
                repairAiMenuSectionJSON(inner)
            ),
        };
    } else {
        return {
            name: undefined || "",
            sections: (undefined || []).map((inner) =>
                repairAiMenuSectionJSON(inner)
            ),
        };
    }
}

export function AiSiteMenuToJSON(value: AiSiteMenu): AiSiteMenuJSON {
    return {
        name: value.name,
        sections: value.sections.map((inner) => AiMenuSectionToJSON(inner)),
    };
}

export const AI_SITE_MENU_META: RecordMeta<
    AiSiteMenu,
    AiSiteMenuJSON,
    AiSiteMenuBrokenJSON
> = {
    name: "AiSiteMenu",
    type: "record",
    repair: repairAiSiteMenuJSON,
    toJSON: AiSiteMenuToJSON,
    fromJSON: JSONToAiSiteMenu,
    fields: {
        name: { type: "string" },
        sections: { type: "array", items: AI_MENU_SECTION_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiMenusJSON = {
    id: string;
    recordVersion: number | null;
    menus: AiSiteMenuJSON[];
};

export function JSONToAiMenus(json: AiMenusJSON): AiMenus {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        menus: json.menus.map((inner) => JSONToAiSiteMenu(inner)),
    };
}
export type AiMenusBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    menus?: AiSiteMenuJSON[];
};

export function newAiMenus(): AiMenus {
    return JSONToAiMenus(repairAiMenusJSON(undefined));
}
export function repairAiMenusJSON(
    json: AiMenusBrokenJSON | undefined
): AiMenusJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            menus: (json.menus || []).map((inner) =>
                repairAiSiteMenuJSON(inner)
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            menus: (undefined || []).map((inner) =>
                repairAiSiteMenuJSON(inner)
            ),
        };
    }
}

export function AiMenusToJSON(value: AiMenus): AiMenusJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        menus: value.menus.map((inner) => AiSiteMenuToJSON(inner)),
    };
}

export const AI_MENUS_META: RecordMeta<
    AiMenus,
    AiMenusJSON,
    AiMenusBrokenJSON
> = {
    name: "AiMenus",
    type: "record",
    repair: repairAiMenusJSON,
    toJSON: AiMenusToJSON,
    fromJSON: JSONToAiMenus,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        menus: { type: "array", items: AI_SITE_MENU_META },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
