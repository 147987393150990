import { TableWidgetPage } from "../../../clay/TableWidgetPage";
import { clearCache } from "../cache";
import { AiMenusWidget } from "./widget";

export const AiMenusPage = TableWidgetPage({
    meta: AiMenusWidget,
    makeContext: (context) => context,
    title: () => "AI Menus",
    postSave: (record) => {
        clearCache("basic-context");
        return [];
    },
});
