import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "glamor";
import * as React from "react";
import { BaseAction, Page } from "../clay/Page";
import { hasPermission } from "../permissions";
import { useUser } from "./state";

const CONTAINER_STYLE = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& div": {
        textAlign: "center",
    },
});

const BUTTON_STYLE = css({
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    margin: "10px",
    width: "150px",
    height: "120px",
    justifyContent: "space-between",
    color: "black",
    alignItems: "center",
    "& svg": {
        fontSize: "30pt",
        display: "block",
        alignSelf: "center",
    },
});
type State = {};

type Action = BaseAction;

type ButtonConf = {
    href: string;
    title: string;
    icon: any;
    permission?: {
        table: string;
        permission: string;
    };
};

type ViewData = {
    name: string;
    id: string;
};

function MakeButton(props: { button: ButtonConf }) {
    const button = props.button;

    return (
        <a href={button.href} {...BUTTON_STYLE}>
            <FontAwesomeIcon icon={button.icon} /> <div>{button.title}</div>
        </a>
    );
}

type PagesPageOptions = {
    title: string;
    buttons: ButtonConf[];
};

export function PagesPage(options: PagesPageOptions): Page<State, Action> {
    return {
        initialize() {
            return {
                state: {
                    views: {},
                },
                requests: [],
            };
        },
        reduce(state, action, context) {
            switch (action.type) {
                case "PAGE_ACTIVATED":
                case "HEARTBEAT":
                case "UPDATE_PARAMETERS":
                    return {
                        state,
                        requests: [],
                    };
            }
        },
        headerComponent() {
            return <div style={{ flexGrow: 1 }} />;
        },
        beforeUnload() {
            return false;
        },
        component(props) {
            let user = useUser();

            return (
                <div {...CONTAINER_STYLE}>
                    {options.buttons
                        .filter(
                            (button) =>
                                !button.permission ||
                                hasPermission(
                                    user,
                                    button.permission.table,
                                    button.permission.permission
                                )
                        )
                        .map((button) => (
                            <MakeButton key={button.href} button={button} />
                        ))}
                </div>
            );
        },
        encodeState() {
            return {
                segments: [],
                parameters: {},
            };
        },
        hasUnsavedChanges() {
            return false;
        },
        title() {
            return options.title;
        },
    };
}
