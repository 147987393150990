import {
    autocompletion,
    CompletionContext,
    CompletionResult,
} from "@codemirror/autocomplete";
import { indentWithTab } from "@codemirror/commands";
import { html } from "@codemirror/lang-html";
import { syntaxTree } from "@codemirror/language";
import { Diagnostic, linter } from "@codemirror/lint";
import { EditorView, keymap } from "@codemirror/view";
import {
    faLayerGroup,
    faMagnet,
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/browser";
import { useCodeMirror } from "@uiw/react-codemirror";
import EventEmitter from "events";
import { debounce, groupBy, memoize } from "lodash-es";
import pretty from "pretty";
import * as React from "react";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import useLocalStorage from "react-use-localstorage";
import readingTime from "reading-time";
import { Url } from "url";
import { Dictionary } from "../../clay/common";
import { Widget, WidgetStatus } from "../../clay/widgets";
import { SimpleAtomic } from "../../clay/widgets/simple-atomic";
import { OAUTH_CLIENT_ID } from "../../keys";
import { StateContext } from "../state";
import { CONTENT_AREA } from "../styles";
import { LinkReport } from "./link-report";
const { Parser } = require("htmlparser2/lib/index");
const { DomHandler } = require("domhandler");

const aff = require("raw-loader!../../../en_US.aff").default;
const dic = require("raw-loader!../../../en_US.dic").default;
const Typo = require("typo-js");

const dictionary = new Typo("en_US", aff, dic);

const HtmlHint = require("../../../htmlhint/core").HTMLHint;

function formattedHtml(html: string) {
    return pretty(html);
}

const STATES: Dictionary<string> = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};

const KNOWN_IMAGE_SIZES: Dictionary<
    { width: number; height: number } | undefined | null
> = {};

const LINT_EVENTS = new EventEmitter();

const knownImageSize = memoize(
    (url: string): Promise<{ width: number; height: number } | null> => {
        return new Promise((resolve, reject) => {
            const img = document.createElement("img");
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.onerror = () => {
                resolve(null);
            };
            img.src = url;
        });
    }
);

const TARGET_OK: Dictionary<boolean | undefined> = {};

const targetOk = memoize(async (url: string): Promise<boolean> => {
    try {
        const result = await fetch(
            "/check-link?link=" + encodeURIComponent(url),
            {
                mode: "no-cors",
            }
        );
        return result.ok;
    } catch (error) {
        return false;
    }
});

const RULES = {
    "img-check": true,
    "tagname-lowercase": false,
    "attr-lowercase": true,
    "attr-value-double-quotes": true,
    "attr-value-not-empty": false,
    "attr-no-duplication": true,
    "doctype-first": false,
    "tag-pair": true,
    "tag-self-close": false,
    "spec-char-escape": true,
    "id-unique": true,
    "src-not-empty": true,
    "title-require": true,
    "alt-require": true,
    "doctype-html5": true,
    "id-class-value": "bem",
    "style-disabled": false,
    "inline-style-disabled": false,
    "inline-script-disabled": true,
    "space-tab-mixed-disabled": "disabled",
    "id-class-ad-disabled": true,
    "href-abs-or-rel": false,
    "attr-unsafe-chars": true,
};

export type HtmlEditorWidgetAction = {
    type: "SET";
    value: string;
};

export type HtmlEditorWidgetProps = {
    state: null;
    data: string;
    dispatch: (action: HtmlEditorWidgetAction) => void;
    style?: React.CSSProperties;
    status: WidgetStatus;
    path?: string;
};

type ReplacementContext = {
    pos: any;
    prefix: any;
    token: any;
};

async function autocompleteImage(query: string) {
    const response = await fetch("/apix/image-search/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            expression: query,
            max_results: 10,
            with_field: ["context", "tags"],
        }),
    });
    if (response.status == 400) {
        Sentry.captureException(new Error(), {
            contexts: {
                details: {
                    response: await response.text(),
                    query,
                },
            },
        });
        return [];
    }
    const data = await response.json();
    return data.resources.map((row: any) => ({
        caption: row.filename,
        slug: row.public_id,
        meta: row.filename,
        image_url: row.secure_url,
        alt: row.context?.alt,
        completion:
            '"' + row.secure_url + (data.alt ? '" alt="' + data.alt : "") + '"',
    }));
}

async function autocompleteBadge(query: string) {
    const response = await fetch("/apix/image-search/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            expression:
                query.trim().length > 0
                    ? query + " AND folder=badge"
                    : "folder=badge",
            max_results: 10,
            with_field: ["context", "tags"],
        }),
    });
    if (response.status == 400) {
        Sentry.captureException(new Error(), {
            contexts: {
                details: {
                    response: await response.text(),
                    query,
                },
            },
        });
        return [];
    }

    const data = await response.json();
    return data.resources.map((row: any) => ({
        caption: row.filename,
        slug: row.public_id,
        meta: row.filename,
        image_url: row.secure_url,
        alt: row.context?.alt,
        completion:
            '"' + row.secure_url + (data.alt ? '" alt="' + data.alt : "") + '"',
    }));
}

async function autocompleteSchool(query: string) {
    const response = await fetch(
        "/ai/api/SchoolSearch/" + encodeURIComponent(JSON.stringify(query))
    );
    const content = await response.json();
    return content.entities.map((row: any) => ({
        caption: row.name,
        value: content.prefix + "---" + row.slug + "---",
        slug: row.slug,
        meta: row.short_description,
        completion: '"' + row.slug + '"',
    }));
}

async function autocompletePerson(query: string) {
    const response = await fetch(
        "/ai/api/PersonSearch/" + encodeURIComponent(JSON.stringify(query))
    );
    const content = await response.json();
    return content.entities.map((row: any) => ({
        caption: row.name,
        value: content.prefix + "---" + row.slug + "---",
        slug: row.slug,
        meta: row.shortDescription,
        image_url: row.imageUrl,
        completion: '"' + row.slug + '"',
    }));
}

async function autocompleteED(query: string, data: [number, string][]) {
    query = query.substring(1, query.length - 1);
    const indexComma = query.indexOf(",");
    const pre = indexComma !== -1 ? query.substring(0, indexComma + 1) : "";
    const post = indexComma !== -1 ? query.substring(indexComma + 1) : query;

    return data
        .filter((x) => x[1].toLowerCase().indexOf(post.toLowerCase()) !== -1)
        .map((row: any) => ({
            caption: `${row[0]} - ${row[1]}`,
            value: query + "---" + row[0] + "---",
            slug: row[0],
            meta: row[1],
            completion: '"' + pre + row[0] + '"',
        }));
}

async function autocompletes(tag: string, attribute: string, query: string) {
    switch (tag) {
        case "img":
            switch (attribute) {
                case "src":
                    return autocompleteImage(query);
            }
            break;
        case "Badge":
            switch (attribute) {
                case "url":
                    return autocompleteBadge(query);
            }
            break;
        case "PersonLink":
        case "PersonImage":
        case "PersonButton":
        case "PersonCard":
            switch (attribute) {
                case "slug":
                    return autocompletePerson(query);
                    break;
            }
        case "SchoolLink":
        case "SchoolImage":
        case "SchoolButton":
        case "SchoolCard":
            switch (attribute) {
                case "slug":
                    return autocompleteSchool(query);
            }
            break;
    }
}

export type HtmlEditorWidgetType = Widget<
    null,
    string,
    {},
    HtmlEditorWidgetAction,
    {
        style?: React.CSSProperties;
        path?: string;
    }
>;

function collectData() {
    const url = window.prompt("Enter Url");
    if (!url) {
        return null;
    }
    return new Promise((resolve, reject) => {
        const openedUrl = window.open(url);
        if (openedUrl) {
            const onMessage = (message: MessageEvent) => {
                if (message.source === openedUrl) {
                    console.log("M", message.data);
                    if (message.data === "!") {
                        openedUrl.postMessage("?", "*");
                    } else {
                        openedUrl.close();
                        window.removeEventListener("message", onMessage);
                        resolve(JSON.parse(message.data));
                    }
                }
            };
            window.addEventListener("message", onMessage);
        }
    });
}

async function htmlAutocompleter(
    context: CompletionContext
): Promise<CompletionResult | null> {
    let nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (nodeBefore.name == "AttributeValue") {
        const attributeText: string = (
            context.state.doc.sliceString(nodeBefore.from, nodeBefore.to)
                .trim as any
        )('"');
        const attributeNameNode = nodeBefore.parent!.firstChild!;
        const attributeName = context.state.doc.sliceString(
            attributeNameNode.from,
            attributeNameNode.to
        );

        const tag = nodeBefore.parent!.parent!.firstChild!.nextSibling!;
        const tagName = context.state.doc.sliceString(tag.from, tag.to);
        const completions = await autocompletes(
            tagName,
            attributeName,
            attributeText
        );

        if (completions && completions.length > 0) {
            return {
                from: nodeBefore.from,
                to: nodeBefore.to,
                filter: false,
                options: completions.map((completion: any) => ({
                    label: completion.caption,
                    apply: completion.completion,
                    info: completion.image_url
                        ? () => {
                              const image = document.createElement("img");
                              image.src = completion.image_url;
                              return image;
                          }
                        : undefined,
                })),
            };
        }
    }

    return null;
}

async function htmlLinter(view: EditorView) {
    const content = view.state.doc.sliceString(0);
    const linter = new HtmlHint();
    const promises: Promise<any>[] = [];
    const spelling: Diagnostic[] = [];

    const checkLink = (link: Url, event: any, label: string) => {
        promises.push(
            targetOk(link.toString()).then((result) => {
                if (result) {
                    return null;
                } else {
                    return {
                        message: label,
                        line: event.line,
                        col: event.col,
                        type: "error",
                        raw: event.raw,
                        evidence: event.raw,
                    };
                }
            })
        );
    };
    linter.addRule({
        id: "img-check",
        description: "check for images",
        init: function (parser: any, reporter: any, options: any) {
            const self = this;
            parser.addListener("tagstart", function (event: any) {
                if (event.tagName === "img") {
                    const attrs = parser.getMapAttrs(event.attrs);
                    const src = attrs.src;
                    if (
                        src &&
                        !src.startsWith(
                            "https://res.cloudinary.com/academicinfluence/image/upload/v"
                        )
                    ) {
                        promises.push(
                            knownImageSize(src).then((result) => {
                                if (result === null) {
                                    return {
                                        message: "src element is not valid",
                                        line: event.line,
                                        col: event.col,
                                        type: "error",
                                        raw: event.raw,
                                        evidence: event.raw,
                                    };
                                } else if (
                                    result &&
                                    attrs.width &&
                                    attrs.height
                                ) {
                                    const ratio = result.width / result.height;
                                    const targetRatio =
                                        attrs.width / attrs.height;

                                    if (ratio != targetRatio) {
                                        return {
                                            message:
                                                "img aspect ratio does not match source (${result.width}x${result.height})",
                                            line: event.line,
                                            col: event.col,
                                            type: "error",
                                            raw: event.raw,
                                            evidence: event.raw,
                                        };
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return null;
                                }
                            })
                        );
                    }
                } else if (event.tagName == "a") {
                    const attrs = parser.getMapAttrs(event.attrs);
                    const href = attrs.href;

                    if (href && !href.startsWith("#")) {
                        const url = new URL(
                            href,
                            "https://academicinfluence.com/some/deep/random/page"
                        );
                        checkLink(url, event, "link target is bad");
                    }
                } else if (
                    event.tagName == "SchoolCard" ||
                    event.tagName == "SchoolButton" ||
                    event.tagName == "SchoolLink" ||
                    event.tagName == "SchoolImage" ||
                    event.tagName == "SchoolInfluenceAreas"
                ) {
                    const attrs = parser.getMapAttrs(event.attrs);
                    const slug = attrs.slug;

                    if (slug) {
                        const url = new URL(
                            "/schools/" + slug,
                            "https://academicinfluence.com/some/deep/random/page"
                        );
                        checkLink(
                            url,
                            event,
                            `incorrect school slug: "${slug}"`
                        );
                    }
                } else if (
                    event.tagName == "PersonCard" ||
                    event.tagName == "PersonButton" ||
                    event.tagName == "PersonLink" ||
                    event.tagName == "PersonImage"
                ) {
                    const attrs = parser.getMapAttrs(event.attrs);
                    const slug = attrs.slug;

                    if (slug) {
                        const url = new URL(
                            "/people/" + slug,
                            "https://staging.academicinfluence.com/some/deep/random/page"
                        );
                        checkLink(
                            url,
                            event,
                            `incorrect person slug: "${slug}"`
                        );
                    }
                }
            });

            parser.addListener("text", (event: any) => {
                for (const match of Array.from(
                    event.raw.matchAll(/[A-Za-z']+/g)
                )) {
                    const word = (match as any)[0];
                    if (
                        !dictionary.check(word) &&
                        !localStorage.getItem("ignore:" + word)
                    ) {
                        spelling.push({
                            from: event.pos + (match as any).index,
                            to: event.pos + (match as any).index + word.length,
                            severity: "error",
                            message: word + " is not recongized",
                            actions: [
                                /*...dictionary.suggest(word).map((suggestion: string) => ({
                                name: suggestion,
                                apply(view: EditorView, from: number, to: number) {
                                    view.dispatch(
                                        {
                                            changes: {
                                                from,
                                                to,
                                                insert: suggestion
                                            }
                                        }
                                    )
                                }
                            })),*/ {
                                    name: "Ignore",
                                    apply(
                                        view: EditorView,
                                        from: number,
                                        to: number
                                    ) {
                                        localStorage.setItem(
                                            "ignore:" + word,
                                            "X"
                                        );
                                        view.dispatch({
                                            changes: {
                                                from,
                                                to,
                                                insert: word,
                                            },
                                        });
                                    },
                                },
                            ],
                        });
                    }
                }
            });
        },
    });
    const errors = linter.verify(content, RULES);

    errors.push(...(await Promise.all(promises)).filter((x) => x));

    const updatedErrors = errors.map((error: any) => {
        const from = view.state.doc.line(error.line).from + error.col - 1;
        const matches =
            error.evidence &&
            content.slice(from, from + error.evidence.length) == error.evidence;
        return {
            from,
            to: matches ? from + error.evidence.length : from,
            severity: error.type,
            message: error.message,
        };
    });

    updatedErrors.push(...spelling);

    return updatedErrors;
}

function escapeHtml(unsafe: string) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

const ALLOWED_TAGS = ["B", "I", "P"];

const EXTENSIONS = [
    html({
        autoCloseTags: false,
    }),
    keymap.of([indentWithTab]),
    linter(htmlLinter),
    autocompletion({ override: [htmlAutocompleter] }),
    EditorView.lineWrapping,
    /*    EditorView.domEventHandlers({
        "paste": (event: any, view) => {
            const htmlData = event.clipboardData?.getData("text/html");
            console.log(htmlData)
            if (htmlData) {
                const div = document.createElement("div")
                div.innerHTML = htmlData

                let output = ""

                const process = (node: Node) => {
                    console.log(node.nodeType)
                    if (node.nodeType == Node.TEXT_NODE) {
                        output += escapeHtml((node as Text).data)
                    }
                    if (node.nodeType == Node.ELEMENT_NODE) {
                        const element = node as Element
                        console.log(element.tagName)
                        if (ALLOWED_TAGS.indexOf(element.tagName) !== -1) {
                            output += "<" + element.tagName.toLowerCase() + ">"
                        }

                    }
                    if (node.hasChildNodes()) {
                        for (const child of Array.from(node.childNodes)) {
                            process(child)
                        }
                    }

                    if (node.nodeType == Node.ELEMENT_NODE) {
                        const element = node as Element
                        console.log(element.tagName)
                        if (ALLOWED_TAGS.indexOf(element.tagName) !== -1) {
                            output += "</" + element.tagName.toLowerCase() + ">"
                        }

                    }
                }

                process(div)

                view?.dispatch(view.state.replaceSelection(output));
                event.preventDefault()
            }
        }*/
];

const loadGapi = memoize(() => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.addEventListener("load", () => {
            resolve((window as any).gapi);
        });
        script.addEventListener("error", (error) => {
            reject(error);
        });
        document.body.appendChild(script);
    });
});

const loadPicker = memoize(async () => {
    const gapi: any = await loadGapi();
    return new Promise((resolve, reject) => {
        gapi.load("client:picker", () => {
            gapi.client
                .init({
                    apiKey: "AIzaSyCTDpEwdK1YGvoE2Y_oUC0bB0yXkEebZsQ",
                    discoveryDocs: [
                        "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
                    ],
                    clientId: OAUTH_CLIENT_ID,
                    scope: "profile",
                })
                .then((g: any) => {
                    console.log(g);
                    resolve(42);
                });
        });
    });
});

const GOOD_TAGS = new Set(["P", "H1", "H2", "H3", "H4", "A", "OL", "UL", "LI"]);

function cleanUrl(href: string): string {
    if (href.startsWith("https://www.google.com/url")) {
        const url = new URL(href);
        return cleanUrl(url.searchParams.get("q")!);
    } else if (href.startsWith("https://academicinfluence.com/")) {
        return href.substring("https://academicinfluence.com".length);
    } else {
        return href;
    }
}

function isNotEmpty(element: HTMLElement) {
    if ((element.textContent || "").trim().length != 0) {
        return true;
    }
    return false;
}

function cleanHtml(element: Node, output: string[]) {
    if (element instanceof HTMLElement) {
        if (
            (element.tagName === "P" && element.textContent === "") ||
            element.tagName === "STYLE"
        ) {
            return;
        }
        if (GOOD_TAGS.has(element.tagName) && isNotEmpty(element)) {
            output.push("<" + element.tagName.toLowerCase());

            if (element.tagName === "A" && element.getAttribute("href")) {
                output.push(
                    ` href="${encodeURI(
                        cleanUrl(element.getAttribute("href")!)
                    )}"`
                );
            }

            output.push(">");
        }
        const after = [];
        if (element.tagName === "SPAN") {
            if (parseInt(element.style.fontWeight, 0) > 500) {
                output.push("<b>");
                after.push("</b>");
            }
            if (element.style.fontStyle == "italic") {
                output.push("<i>");
                after.push("</i>");
            }
        }
        for (let index = 0; index < element.childNodes.length; index++) {
            const child = element.childNodes[index];
            cleanHtml(child, output);
        }
        for (const item of after) {
            output.push(item);
        }
        if (GOOD_TAGS.has(element.tagName) && isNotEmpty(element)) {
            output.push("</" + element.tagName.toLowerCase() + ">");
        }
    } else if (element instanceof Text) {
        output.push(element.data);
    }
}

export const HtmlEditorWidget: HtmlEditorWidgetType = {
    ...SimpleAtomic,
    dataMeta: {
        type: "string",
    },
    initialize(data: string) {
        return {
            state: null,
            data,
        };
    },
    component({ data, dispatch, status, style, path }: HtmlEditorWidgetProps) {
        const [text, setText] = React.useState(data);
        const editor = React.useRef<HTMLDivElement | null>(null);
        const onChangeEx = React.useCallback(
            debounce((value: string) => {
                let output = "";
                let inside = false;
                for (let index = 0; index < value.length; index++) {
                    const char = value.charAt(index);
                    switch (char) {
                        case "”":
                        case "“":
                        case "\u201C":
                        case "\u201D":
                            if (inside) {
                                output += '"';
                            } else {
                                output += char;
                            }
                            break;
                        case "<":
                            inside = true;
                            output += "<";
                            break;
                        case ">":
                            inside = true;
                            output += ">";
                            break;
                        default:
                            output += char;
                    }
                }
                /*value = value
                    .replace("”", '"')
                    .replace(:Qa
                        "“", '"')
                    .replace("’", "'")
                    .replace("‘", "'")
                    .replace(/[\u2018\u2019]/g, "'")
                    .replace(/[\u201C\u201D]/g, '"')
                    .replace(/[\u2013\u2014]/g, "-")
                    .replace(/[\u2026]/g, "...");*/
                dispatch({
                    type: "SET",
                    value: output,
                });
            }, 0),
            [dispatch]
        );
        const onChange = React.useCallback(
            (value: string, viewUpdate: any) => {
                onChangeEx(value);
            },
            [setText, onChangeEx]
        );
        const { setContainer, state, view } = useCodeMirror({
            container: editor.current,
            extensions: EXTENSIONS,
            onChange,
            value: text,
            basicSetup: {
                closeBrackets: false,
                searchKeymap: true,
            },
        });

        React.useEffect(() => {
            if (editor.current) {
                setContainer(editor.current);
                /*
                editor.current.addEventListener("paste", (event) => {
                    console.log(
                        "EVENT",
                        event,
                        event.clipboardData?.getData("text/html")
                    );
                    event.preventDefault();
                });*/
            }
        }, [editor.current]);

        const [fontSizeRaw, setFontSize] = useLocalStorage(
            "html-editor-fontsize",
            "12"
        );
        const fontSize = parseInt(fontSizeRaw, 10) || 12;

        const doInsert = React.useCallback(
            (text: string) => {
                view?.dispatch(view.state.replaceSelection(text));
            },
            [view]
        );

        const handleInsert = React.useCallback(
            async (f: (school: any) => string) => {
                const dataPromise = collectData();
                if (dataPromise) {
                    const data: any = await dataPromise;

                    if (data.groupBy === "state") {
                        doInsert(
                            formattedHtml(
                                Object.entries(
                                    groupBy(
                                        data.schools,
                                        (school) => school.state
                                    )
                                )
                                    .map(
                                        ([key, schools]) =>
                                            `<h3>${STATES[key]}</h3>
<SchoolCardList>${schools
                                                .map(
                                                    (school: any) =>
                                                        `
    <SchoolCard slug="${school.slug}">
        ${f(school)}
    </SchoolCard>`
                                                )
                                                .join("\n")}
</SchoolCardList>`
                                    )
                                    .join("\n")
                            )
                        );
                    } else {
                        doInsert(
                            formattedHtml(`<SchoolCardList>${data.schools
                                .map(
                                    (school: any) =>
                                        `
    <SchoolCard slug="${school.slug}">
        ${f(school)}
    </SchoolCard>`
                                )
                                .join("\n")}
</SchoolCardList>`)
                        );
                    }
                }
            },
            [doInsert]
        );

        const onInsertPersonCardList = React.useCallback(async () => {
            const dataPromise = collectData();
            if (dataPromise) {
                const data: any = await dataPromise;

                doInsert(
                    formattedHtml(`<PersonCardList>${data.schools
                        .map(
                            (school: any) =>
                                `
    <PersonCard slug="${school.slug}">
    </PersonCard>`
                        )
                        .join("\n")}
</PersonCardList>`)
                );
            }
        }, [doInsert]);

        const onInsertSchoolCardList = React.useCallback(async () => {
            handleInsert(() => "");
        }, [handleInsert]);

        const onInsertSchoolCardListWithAreas = React.useCallback(async () => {
            handleInsert(
                (school) =>
                    `<SchoolInfluenceAreas slug="${school.slug}" limit="10" status="open"/>`
            );
        }, [handleInsert]);

        const onInsertSchoolCardListWithClosedAreas =
            React.useCallback(async () => {
                handleInsert(
                    (school) =>
                        `<SchoolInfluenceAreas slug="${school.slug}" limit="10" status="closed"/>`
                );
            }, [handleInsert]);

        const onInsertSchoolCardListWithDegrees =
            React.useCallback(async () => {
                handleInsert((school) => {
                    console.log(school);
                    return `<section class="online-degrees">
    <h3 class="online-degrees__heading">Online Degrees</h3>
  ${school.degree_list
      .map(
          (degree: any) => `<details class="online-degrees__degree">
    <summary class="online-degrees__degree__name">${degree.degree_type} in ${
              degree.discipline
          }</summary>
    ${
        degree.concentrations.length > 0
            ? `
    <h4 class="online-degrees__degree__concentrations-header">Concentrations</h4>
    <ul  class="online-degrees__degree__concentrations-list">
${degree.concentrations
    .map(
        (concentration: any) =>
            `      <li class="online-degrees__degree__concentrations-list__item">${concentration}</li>`
    )
    .join("\n")}
    </ul>`
            : ""
    }
    <ul  class="online-degrees__degree__info-list">
      <li  class="online-degrees__degree__info-list__credits"><strong>Required Credits</strong>: ${
          degree.credits_needed
      }</li>
      <li  class="online-degrees__degree__info-list__time"><strong>Completion time</strong>: ${
          degree.completion_time
      }</li>
      <li  class="online-degrees__degree__info-list__time"><strong>Format</strong>: ${
          degree.online_hybrid
      }</li>
    </ul>
  </details>`
      )
      .join("\n")}
</section>`;
                });
            }, [handleInsert]);

        const stats = readingTime(data.replace(/(<([^>]+)>)/gi, ""), {
            wordsPerMinute: 250,
        });

        const onSurround = React.useCallback(
            (before: string, after: string) => {
                const changes = [];
                for (const range of view!.state.selection.ranges) {
                    changes.push({
                        from: range.from,
                        to: range.from,
                        insert: before,
                    });

                    changes.push({
                        from: range.to,
                        to: range.to,
                        insert: after,
                    });
                }
                view?.dispatch({ changes });
            },
            [view]
        );

        const onClickBold = React.useCallback(
            () => onSurround("<b>", "</b>"),
            [onSurround]
        );
        const onClickItalic = React.useCallback(
            () => onSurround("<i>", "</i>"),
            [onSurround]
        );
        const onClickParagraph = React.useCallback(
            () => onSurround("<p>", "</p>"),
            [onSurround]
        );
        const onClickLink = React.useCallback(
            () => onSurround(`<a href="${prompt("Enter Link")}">`, "</a>"),
            [onSurround]
        );

        const onClickH1 = React.useCallback(
            () => onSurround("<h1>", "</h1>"),
            [onSurround]
        );
        const onClickH2 = React.useCallback(
            () => onSurround("<h2>", "</h2>"),
            [onSurround]
        );
        const onClickH3 = React.useCallback(
            () => onSurround("<h3>", "</h3>"),
            [onSurround]
        );

        const rootState = React.useContext(StateContext);

        const clickImport = React.useCallback(async () => {
            await loadPicker();

            const tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: OAUTH_CLIENT_ID,
                prompt: "",
                scope: "https://www.googleapis.com/auth/drive.readonly",
                callback: async (response) => {
                    const picker = new google.picker.PickerBuilder()
                        .addView(google.picker.ViewId.DOCS)
                        .setOAuthToken(response.access_token)
                        .setDeveloperKey(
                            "AIzaSyCTDpEwdK1YGvoE2Y_oUC0bB0yXkEebZsQ"
                        )
                        .setCallback(async (data) => {
                            if (data.action == "picked") {
                                const drive = (window as any).gapi.client.drive
                                    .files;
                                console.log((window as any).gapi);
                                console.log("callback", data);
                                for (const doc of data.docs) {
                                    const { body } = await drive.export({
                                        fileId: doc.id,
                                        mimeType: "text/html",
                                    });

                                    const element =
                                        document.createElement("html");
                                    element.innerHTML = body;
                                    const output: string[] = [];
                                    cleanHtml(element, output);
                                    doInsert(formattedHtml(output.join("")));
                                }
                            }
                        })
                        .build();

                    picker.setVisible(true);
                },
            });

            console.log(tokenClient);

            tokenClient.requestAccessToken();
        }, [rootState, doInsert]);

        const [isDataOpen, setDataOpen] = React.useState(false);

        const openData = React.useCallback(() => {
            setDataOpen(true);
        }, [setDataOpen]);

        const [rewriteData, setRewriteData] = React.useState<{
            schoolCards: any[];
            newOrder: string[];
        } | null>(null);

        const doRearrange = React.useCallback(() => {
            const handler = new DomHandler(
                async (error: any, dom: any) => {
                    const schoolCards: any[] = [];

                    function processSchoolCardList(node: any) {
                        if (node.type === "tag") {
                            if (node.name === "SchoolCard") {
                                schoolCards.push(node);
                            } else {
                                for (const child of node.children) {
                                    processSchoolCardList(child);
                                }
                            }
                        }
                    }

                    function processChildren(nodes: any[]) {
                        for (const node of nodes) {
                            if (node.type == "tag") {
                                if (node.name === "SchoolCardList") {
                                    processSchoolCardList(node);

                                    return false;
                                } else {
                                    if (!processChildren(node.children)) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    }
                    console.log(dom);

                    processChildren(dom);

                    const slugs = schoolCards.map((card) => card.attribs.slug);

                    const response = await fetch(
                        "https://staging.academicinfluence.com/internal/api/rerank",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(slugs),
                        }
                    );

                    const data = await response.json();

                    setRewriteData({ schoolCards, newOrder: data });
                },
                {
                    withStartIndices: true,
                    withEndIndices: true,
                }
            );
            const parser = new Parser(handler, {
                decodeEntities: true,
                lowerCaseTags: false,
                recognizeSelfClosing: true,
            });
            parser.parseComplete(data);
        }, [data]);

        function applyRewrite() {
            if (!rewriteData) {
                return;
            }

            const changes = [];

            const slugs: string[] = rewriteData.schoolCards.map(
                (x) => x.attribs.slug
            );

            const formattedHiddenElement =
                "\n" +
                formattedHtml(`<PreviousOrder prev-order="${slugs}" />`) +
                "\n\n";

            changes.push({
                from: rewriteData.schoolCards[0].startIndex,
                to: rewriteData.schoolCards[0].startIndex,
                insert: formattedHiddenElement,
            });

            for (
                let index = rewriteData.schoolCards.length - 1;
                index >= 0;
                index--
            ) {
                const card = rewriteData.schoolCards[index];
                let source = slugs.indexOf(rewriteData.newOrder[index]);
                if (source === -1) {
                    return;
                }
                const sourceCard = rewriteData.schoolCards[source];

                changes.push({
                    from: card.startIndex,
                    to: card.endIndex + 1,
                    insert: data.substring(
                        sourceCard.startIndex,
                        sourceCard.endIndex + 1
                    ),
                });
            }

            const changeSet = view?.state.changes(changes);
            view?.dispatch(view?.state.update({ changes: changeSet }));
            setRewriteData(null);
        }

        return (
            <>
                {rewriteData && (
                    <Modal show={true} onHide={() => setRewriteData(null)}>
                        <Modal.Header>Reorder</Modal.Header>
                        <Modal.Body>
                            <Table>
                                <thead>
                                    <th>Current</th>
                                    <th>Change</th>
                                    <th>New</th>
                                </thead>
                                <tbody>
                                    {rewriteData.schoolCards.map(
                                        (schoolCard, index) => (
                                            <tr>
                                                <td
                                                    style={{
                                                        color: rewriteData.newOrder.includes(
                                                            schoolCard.attribs
                                                                .slug
                                                        )
                                                            ? "inherit"
                                                            : "red",
                                                    }}
                                                >
                                                    {schoolCard.attribs.slug}
                                                </td>
                                                <td>
                                                    {index -
                                                        rewriteData.newOrder.indexOf(
                                                            schoolCard.attribs
                                                                .slug
                                                        )}
                                                </td>
                                                <td>
                                                    {
                                                        rewriteData.newOrder[
                                                            index
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={applyRewrite}>Apply</Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {isDataOpen && (
                    <Modal
                        show={true}
                        onHide={() => setDataOpen(false)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "initial",
                        }}
                        className="link-report-modal"
                    >
                        <Modal.Header closeButton>Links</Modal.Header>
                        <Modal.Body>
                            <LinkReport text={data} path={path} />
                        </Modal.Body>
                    </Modal>
                )}
                <div>
                    <div style={{ display: "inline-block" }}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-html-editor"
                                variant="success"
                            >
                                Insert
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={onInsertSchoolCardList}>
                                    School Card List
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={onInsertSchoolCardListWithAreas}
                                >
                                    School Card List With Influence Areas
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={
                                        onInsertSchoolCardListWithClosedAreas
                                    }
                                >
                                    School Card List With Closed Influence Areas
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={onInsertSchoolCardListWithDegrees}
                                >
                                    School Card List With Degrees
                                </Dropdown.Item>
                                <Dropdown.Item onClick={onInsertPersonCardList}>
                                    Person Card List
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Button onClick={clickImport}>Import</Button>
                    <Button
                        style={{ display: "inline-block", marginLeft: "1em" }}
                        onClick={() => setFontSize(`${fontSize - 1}`)}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                    <Button
                        style={{ display: "inline-block", marginLeft: "1em" }}
                        onClick={() => setFontSize(`${fontSize + 1}`)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                        style={{
                            display: "inline-block",
                            marginLeft: "1em",
                            fontWeight: "bold",
                        }}
                        onClick={onClickBold}
                    >
                        B
                    </Button>
                    <Button
                        style={{ display: "inline-block", fontStyle: "italic" }}
                        onClick={onClickItalic}
                    >
                        I
                    </Button>
                    <Button
                        style={{ display: "inline-block" }}
                        onClick={onClickParagraph}
                    >
                        P
                    </Button>
                    <Button
                        style={{ display: "inline-block" }}
                        onClick={onClickLink}
                    >
                        A
                    </Button>
                    <Button
                        style={{ display: "inline-block" }}
                        onClick={onClickH1}
                    >
                        H1
                    </Button>
                    <Button
                        style={{ display: "inline-block" }}
                        onClick={onClickH2}
                    >
                        H2
                    </Button>
                    <Button
                        style={{ display: "inline-block" }}
                        onClick={onClickH3}
                    >
                        H3
                    </Button>
                    <Button onClick={openData}>
                        <FontAwesomeIcon icon={faMagnet} />
                    </Button>
                    <Button onClick={doRearrange}>
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </Button>
                    <div style={{ float: "right" }}>
                        Words {stats.words} | {stats.text}
                    </div>
                </div>
                <div {...CONTENT_AREA} style={{ fontSize: fontSize + "pt" }}>
                    <div ref={editor} />
                </div>
            </>
        );
    },
    reduce(
        state: null,
        data: string,
        action: HtmlEditorWidgetAction,
        context: {}
    ) {
        switch (action.type) {
            case "SET":
                return {
                    state: null,
                    data: action.value,
                    requests: [],
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        }
    },
};
