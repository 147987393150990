import dateParse from "date-fns/parseISO";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type AiPage = {
    id: UUID;
    recordVersion: Version;
    sitemap: boolean;

    key: string;
    title: string;
    text: string;
    description: string;
    bannerImageUrl: string;
    bannerImageAltText: string;

    alternateSocialMediaImageUrl: string;
    alternateSocialMediaImageAltText: string;
    modifiedDateTime: Date | null;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiPageJSON = {
    id: string;
    recordVersion: number | null;
    sitemap: boolean;
    key: string;
    title: string;
    text: string;
    description: string;
    bannerImageUrl: string;
    bannerImageAltText: string;
    alternateSocialMediaImageUrl: string;
    alternateSocialMediaImageAltText: string;
    modifiedDateTime: string | null;
};

export function JSONToAiPage(json: AiPageJSON): AiPage {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        sitemap: json.sitemap,
        key: json.key,
        title: json.title,
        text: json.text,
        description: json.description,
        bannerImageUrl: json.bannerImageUrl,
        bannerImageAltText: json.bannerImageAltText,
        alternateSocialMediaImageUrl: json.alternateSocialMediaImageUrl,
        alternateSocialMediaImageAltText: json.alternateSocialMediaImageAltText,
        modifiedDateTime:
            json.modifiedDateTime !== null
                ? dateParse(json.modifiedDateTime)
                : null,
    };
}
export type AiPageBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    sitemap?: boolean;
    key?: string;
    title?: string;
    text?: string;
    description?: string;
    bannerImageUrl?: string;
    bannerImageAltText?: string;
    alternateSocialMediaImageUrl?: string;
    alternateSocialMediaImageAltText?: string;
    modifiedDateTime?: string | null;
};

export function newAiPage(): AiPage {
    return JSONToAiPage(repairAiPageJSON(undefined));
}
export function repairAiPageJSON(
    json: AiPageBrokenJSON | undefined
): AiPageJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            sitemap: json.sitemap || false,
            key: json.key || "",
            title: json.title || "",
            text: json.text || "",
            description: json.description || "",
            bannerImageUrl: json.bannerImageUrl || "",
            bannerImageAltText: json.bannerImageAltText || "",
            alternateSocialMediaImageUrl:
                json.alternateSocialMediaImageUrl || "",
            alternateSocialMediaImageAltText:
                json.alternateSocialMediaImageAltText || "",
            modifiedDateTime: json.modifiedDateTime || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            sitemap: undefined || false,
            key: undefined || "",
            title: undefined || "",
            text: undefined || "",
            description: undefined || "",
            bannerImageUrl: undefined || "",
            bannerImageAltText: undefined || "",
            alternateSocialMediaImageUrl: undefined || "",
            alternateSocialMediaImageAltText: undefined || "",
            modifiedDateTime: undefined || null,
        };
    }
}

export function AiPageToJSON(value: AiPage): AiPageJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        sitemap: value.sitemap,
        key: value.key,
        title: value.title,
        text: value.text,
        description: value.description,
        bannerImageUrl: value.bannerImageUrl,
        bannerImageAltText: value.bannerImageAltText,
        alternateSocialMediaImageUrl: value.alternateSocialMediaImageUrl,
        alternateSocialMediaImageAltText:
            value.alternateSocialMediaImageAltText,
        modifiedDateTime:
            value.modifiedDateTime !== null
                ? value.modifiedDateTime.toISOString()
                : null,
    };
}

export const AI_PAGE_META: RecordMeta<AiPage, AiPageJSON, AiPageBrokenJSON> = {
    name: "AiPage",
    type: "record",
    repair: repairAiPageJSON,
    toJSON: AiPageToJSON,
    fromJSON: JSONToAiPage,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        sitemap: { type: "boolean" },
        key: { type: "string" },
        title: { type: "string" },
        text: { type: "string" },
        description: { type: "string" },
        bannerImageUrl: { type: "string" },
        bannerImageAltText: { type: "string" },
        alternateSocialMediaImageUrl: { type: "string" },
        alternateSocialMediaImageAltText: { type: "string" },
        modifiedDateTime: { type: "datetime" },
    },
    userFacingKey: "title",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
