import { css } from "glamor";
import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useId } from "react-id-generator";
import useSWR from "swr";
import { useUser } from "../../../app/state";
import { TABLES_META } from "../../../app/tables";
import { doQuery } from "../../../clay/api";
import { Link } from "../../../clay/link";
import { useQuickRecord } from "../../../clay/quick-cache";
import { RemoveButton } from "../../../clay/remove-button";
import {
    statusToState,
    WidgetResult,
    WidgetStatus,
} from "../../../clay/widgets";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { AiPerson } from "../person/table";

type PersonLinkWidgetState = {
    text: string | null;
};

type Item = {
    id: string | null;
    name: string;
};

type PersonLinkWidgetAction =
    | {
          type: "TEXT_CHANGE";
          text: string;
      }
    | {
          type: "SELECT";
          id: string | null;
      }
    | { type: "BLUR" }
    | { type: "NEW_RECORD"; id: string };

type PersonLinkWidgetProps = {
    state: PersonLinkWidgetState;
    data: Link<AiPerson>;
    dispatch: (action: PersonLinkWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
};

const WIDGET_STYLE = css({
    "html & input.form-control": {
        border: "none",
        height: "auto",
        padding: "0px",
    },
});

function initialize(
    data: Link<AiPerson>,
    context: {}
): WidgetResult<PersonLinkWidgetState, Link<AiPerson>> {
    return {
        data,
        state: {
            text: null,
        },
    };
}

async function autocompletePerson(query: string) {
    if (query.length < 3) {
        return [];
    }
    const response = await fetch(
        "/ai/api/PersonSearch/" + encodeURIComponent(JSON.stringify(query))
    );
    const content = await response.json();
    return content.entities.map((row: any) => ({
        name: `${row.name} [${row.slug}] (${row.shortDescription})`,
        id: row.slug,
    }));
}

async function personImage(slug: string) {
    const response = await fetch(
        "/ai/api/PersonPage/" + encodeURIComponent(JSON.stringify({ slug }))
    );
    const content = await response.json();
    return content.person?.imageUrl;
}

export const PersonLinkWidget = {
    dataMeta: {
        type: "uuid" as const,
        linkTo: "AiPerson",
    },
    initialize,
    component: (props: PersonLinkWidgetProps) => {
        const user = useUser();
        const text = props.state.text || "";
        const lookupQuery = useSWR(text, autocompletePerson);

        const target = useQuickRecord(TABLES_META.AiPerson, props.data);

        const imageUrl = useSWR(target?.slug, personImage);

        const widgetId = useId()[0];

        async function onChange(selected: any[]) {
            if (selected.length > 0) {
                const item = selected[0];
                const lookup = await doQuery({
                    tableName: "AiPerson",
                    columns: ["id"],
                    filters: [
                        {
                            column: "slug",
                            filter: {
                                equal: item.id,
                            },
                        },
                    ],
                });
                props.dispatch({
                    type: "SELECT",
                    id: lookup.rows[0][0] as string,
                });
            } else {
                if (props.data !== null) {
                    props.dispatch({
                        type: "SELECT",
                        id: null,
                    });
                }
            }
        }

        const items: Item[] = lookupQuery.data || [];
        let selectedText = "";
        if (target) {
            selectedText = target.slug;
        }

        const listItemContext = useListItemContext();

        return (
            <tr {...listItemContext.draggableProps}>
                <td>{listItemContext.dragHandle}</td>
                <td>
                    <div style={{ ...props.style, display: "flex" }}>
                        <div
                            className={
                                "form-control " +
                                statusToState(
                                    props.status.validation,
                                    props.data === null
                                )
                            }
                            {...WIDGET_STYLE}
                        >
                            <Typeahead
                                positionFixed={true}
                                id={widgetId}
                                labelKey="name"
                                selected={[
                                    {
                                        name:
                                            props.state.text === null
                                                ? selectedText
                                                : props.state.text,
                                        id: props.data,
                                    },
                                ]}
                                filterBy={() => true}
                                options={[...items]}
                                onInputChange={(text) =>
                                    props.dispatch({
                                        type: "TEXT_CHANGE",
                                        text,
                                    })
                                }
                                onBlur={() => props.dispatch({ type: "BLUR" })}
                                onChange={onChange}
                                disabled={!props.status.mutable}
                            />
                        </div>
                    </div>
                </td>
                <td>
                    <img
                        src={imageUrl.data}
                        style={{
                            width: "5em",
                            height: "5em",
                        }}
                    />
                </td>
                <td>
                    <RemoveButton />
                </td>
            </tr>
        );
    },
    reduce: (
        state: PersonLinkWidgetState,
        data: Link<AiPerson>,
        action: PersonLinkWidgetAction,
        context: {}
    ): WidgetResult<PersonLinkWidgetState, Link<AiPerson>> => {
        switch (action.type) {
            case "TEXT_CHANGE":
                return {
                    state: {
                        ...state,
                        text: action.text,
                    },
                    data,
                };
            case "SELECT":
                return {
                    state: {
                        ...state,
                        text: null,
                    },
                    data: action.id,
                };
            case "BLUR":
                return {
                    state: {
                        ...state,
                        text: null,
                    },
                    data,
                };
            case "NEW_RECORD":
                return initialize(action.id, context);
        }
    },

    validate(data: Link<AiPerson>) {
        if (data === null) {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        } else {
            return [];
        }
    },
};
