import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Widget, WidgetStatus } from "./index";
import { SimpleAtomic } from "./simple-atomic";

type RawFileWidgetState = null;
export type RawFileWidgetAction = {
    type: "SET";
    value: string;
};

type RawFileWidgetProps = {
    state: RawFileWidgetState;
    data: string;
    dispatch: (action: RawFileWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
    hideStatus?: boolean;
};

export type RawFileWidget = {
    state: RawFileWidgetState;
    data: string;
    action: RawFileWidgetAction;
    context: {};
    props: {
        style?: React.CSSProperties;
        hideStatus?: boolean;
    };
};

export const RawFileWidget: Widget<
    RawFileWidgetState,
    string,
    {},
    RawFileWidgetAction,
    {
        style?: React.CSSProperties;
        hideStatus?: boolean;
    }
> = {
    ...SimpleAtomic,
    dataMeta: {
        type: "date",
    },
    initialize(data: string) {
        return {
            state: null,
            data,
        };
    },
    component({
        data,
        dispatch,
        status,
        style,
        hideStatus,
    }: RawFileWidgetProps) {
        const onDrop = React.useCallback(
            (files: Blob[]) => {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const response = await fetch("/blobs/", {
                        method: "post",
                        headers: {
                            "Content-Type": files[0].type,
                        },
                        body: reader.result,
                    });
                    const text = await response.text();
                    dispatch({
                        type: "SET",
                        value: text,
                    });
                };
                for (const file of files) {
                    reader.readAsArrayBuffer(file);
                }
            },
            [dispatch]
        );

        const onClear = React.useCallback(() => {
            dispatch({
                type: "SET",
                value: "",
            });
        }, [dispatch]);

        const dropZone = useDropzone({ onDrop });
        return data === "" ? (
            <div {...dropZone.getRootProps()}>
                <input {...dropZone.getInputProps()} />
                Drop File Here or Click
            </div>
        ) : (
            <div>
                <Button onClick={onClear} variant="danger">
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </div>
        );
    },
    reduce(
        state: RawFileWidgetState,
        data: string,
        action: RawFileWidgetAction,
        context: {}
    ) {
        switch (action.type) {
            case "SET":
                return {
                    state,
                    data: action.value,
                    requests: [],
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        }
    },
};
