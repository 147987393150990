import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveButton } from "../../../clay/save-button";
import {
    FormField,
    Optional,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import { LinkWidget } from "../../../clay/widgets/link-widget";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { SimpleListWrapper } from "../../../clay/widgets/SimpleListWrapper";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { slugCheckValidate } from "../../slugCheck";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import {
    AI_DISCIPLINE_META,
    AI_SUBDISCIPLINE_META,
} from "../disciplines/table";
import { AiPersonDegreeWidget } from "./degree-widget";
import { AiPerson, AI_PERSON_META } from "./table";
import { AiPersonWorkWidget } from "./work-widget";

//!RecordWidget
export type AiPersonWidgetData = AiPerson;

export const AiPersonWidgetFields = {
    active: FormField(SwitchWidget),
    slug: FormField(TextWidget),
    name: OptionalFormField(TextWidget),
    short_description: OptionalFormField(TextAreaWidget),
    meta_description: OptionalFormField(TextAreaWidget),
    description: Optional(HtmlEditorWidget),
    extra_content: Optional(HtmlEditorWidget),
    image_url: OptionalFormField(TextWidget),
    image_source_url: OptionalFormField(TextWidget),
    works: Optional(ListWidget(AiPersonWorkWidget)),
    hide_wikipedia: FormField(SwitchWidget),
    hide_citations: FormField(SwitchWidget),
    disciplines: OptionalFormField(
        LinkSetWidget({
            meta: AI_DISCIPLINE_META,
            name: (x) => x.name,
        })
    ),
    subdisciplines: OptionalFormField(
        LinkSetWidget({
            meta: AI_SUBDISCIPLINE_META,
            name: (x) => x.name,
        })
    ),
    schools: OptionalFormField(
        ListWidget(
            SimpleListWrapper(
                LinkWidget({
                    table: "AiSchool",
                    nameColumn: "slug",
                })
            )
        )
    ),
    employerSchools: OptionalFormField(
        ListWidget(
            SimpleListWrapper(
                LinkWidget({
                    table: "AiSchool",
                    nameColumn: "slug",
                })
            )
        )
    ),
    degrees: ListWidget(AiPersonDegreeWidget, { emptyOk: true }),
};

export function validateAiPersonWidget(data: AiPerson, cache: QuickCacheApi) {
    const errors = baseValidateAiPersonWidget(data, cache);

    slugCheckValidate("AiPerson", data, cache, errors);

    return errors;
}

export function AiPersonWidgetComponent(
    widgets: AiPersonWidgetWidgets,
    props: AiPersonWidgetProps
) {
    return (
        <>
            <Tabs defaultActivKey="first" id="person-tabs">
                <Tab eventKey="first" title="Main">
                    <div>
                        <a
                            href={
                                "https://wikidata.org/wiki/" +
                                props.data.wikidata_id
                            }
                        >
                            Source
                        </a>
                    </div>
                    <widgets.active />
                    <widgets.slug />
                    <widgets.name />
                    <widgets.image_url />
                    <widgets.image_source_url />
                    <widgets.short_description />
                    <widgets.meta_description />
                    <widgets.hide_wikipedia />
                    <widgets.hide_citations />
                </Tab>{" "}
                <Tab eventKey="description" title="Description">
                    <widgets.description />
                </Tab>
                <Tab eventKey="extra" title="Extra">
                    <widgets.extra_content />
                </Tab>
                <Tab eventKey="works" title="Works">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Active</th>
                                <th>Name</th>
                                <th>Amazon Link</th>
                                <th>Image</th>
                            </tr>
                        </thead>

                        <widgets.works extraItemForAdd containerClass="tbody" />
                    </table>
                </Tab>
                <Tab eventKey="details" title="Details">
                    <widgets.disciplines />
                    <widgets.subdisciplines />
                    <widgets.schools label="Studied at" extraItemForAdd />
                    <widgets.employerSchools
                        label="Worked at"
                        extraItemForAdd
                    />
                    <table>
                        <thead>
                            <th></th>
                            <th>School</th>
                            <th>Degree</th>
                            <th>Field</th>
                        </thead>
                        <widgets.degrees
                            extraItemForAdd
                            containerClass="tbody"
                        />
                    </table>
                    <div style={{ minHeight: "1in", width: "1em" }}></div>
                </Tab>
            </Tabs>
            <SaveButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiPersonWidgetContext = WidgetContext<typeof AiPersonWidgetFields.active> &
    WidgetContext<typeof AiPersonWidgetFields.slug> &
    WidgetContext<typeof AiPersonWidgetFields.name> &
    WidgetContext<typeof AiPersonWidgetFields.short_description> &
    WidgetContext<typeof AiPersonWidgetFields.meta_description> &
    WidgetContext<typeof AiPersonWidgetFields.description> &
    WidgetContext<typeof AiPersonWidgetFields.extra_content> &
    WidgetContext<typeof AiPersonWidgetFields.image_url> &
    WidgetContext<typeof AiPersonWidgetFields.image_source_url> &
    WidgetContext<typeof AiPersonWidgetFields.works> &
    WidgetContext<typeof AiPersonWidgetFields.hide_wikipedia> &
    WidgetContext<typeof AiPersonWidgetFields.hide_citations> &
    WidgetContext<typeof AiPersonWidgetFields.disciplines> &
    WidgetContext<typeof AiPersonWidgetFields.subdisciplines> &
    WidgetContext<typeof AiPersonWidgetFields.schools> &
    WidgetContext<typeof AiPersonWidgetFields.employerSchools> &
    WidgetContext<typeof AiPersonWidgetFields.degrees>;
type AiPersonWidgetExtraProps = {};
type AiPersonWidgetBaseState = {
    active: WidgetState<typeof AiPersonWidgetFields.active>;
    slug: WidgetState<typeof AiPersonWidgetFields.slug>;
    name: WidgetState<typeof AiPersonWidgetFields.name>;
    short_description: WidgetState<
        typeof AiPersonWidgetFields.short_description
    >;
    meta_description: WidgetState<typeof AiPersonWidgetFields.meta_description>;
    description: WidgetState<typeof AiPersonWidgetFields.description>;
    extra_content: WidgetState<typeof AiPersonWidgetFields.extra_content>;
    image_url: WidgetState<typeof AiPersonWidgetFields.image_url>;
    image_source_url: WidgetState<typeof AiPersonWidgetFields.image_source_url>;
    works: WidgetState<typeof AiPersonWidgetFields.works>;
    hide_wikipedia: WidgetState<typeof AiPersonWidgetFields.hide_wikipedia>;
    hide_citations: WidgetState<typeof AiPersonWidgetFields.hide_citations>;
    disciplines: WidgetState<typeof AiPersonWidgetFields.disciplines>;
    subdisciplines: WidgetState<typeof AiPersonWidgetFields.subdisciplines>;
    schools: WidgetState<typeof AiPersonWidgetFields.schools>;
    employerSchools: WidgetState<typeof AiPersonWidgetFields.employerSchools>;
    degrees: WidgetState<typeof AiPersonWidgetFields.degrees>;
};
export type AiPersonWidgetState = AiPersonWidgetBaseState;

type BaseAiPersonWidgetAction =
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof AiPersonWidgetFields.active>;
      }
    | { type: "SLUG"; action: WidgetAction<typeof AiPersonWidgetFields.slug> }
    | { type: "NAME"; action: WidgetAction<typeof AiPersonWidgetFields.name> }
    | {
          type: "SHORT_DESCRIPTION";
          action: WidgetAction<typeof AiPersonWidgetFields.short_description>;
      }
    | {
          type: "META_DESCRIPTION";
          action: WidgetAction<typeof AiPersonWidgetFields.meta_description>;
      }
    | {
          type: "DESCRIPTION";
          action: WidgetAction<typeof AiPersonWidgetFields.description>;
      }
    | {
          type: "EXTRA_CONTENT";
          action: WidgetAction<typeof AiPersonWidgetFields.extra_content>;
      }
    | {
          type: "IMAGE_URL";
          action: WidgetAction<typeof AiPersonWidgetFields.image_url>;
      }
    | {
          type: "IMAGE_SOURCE_URL";
          action: WidgetAction<typeof AiPersonWidgetFields.image_source_url>;
      }
    | { type: "WORKS"; action: WidgetAction<typeof AiPersonWidgetFields.works> }
    | {
          type: "HIDE_WIKIPEDIA";
          action: WidgetAction<typeof AiPersonWidgetFields.hide_wikipedia>;
      }
    | {
          type: "HIDE_CITATIONS";
          action: WidgetAction<typeof AiPersonWidgetFields.hide_citations>;
      }
    | {
          type: "DISCIPLINES";
          action: WidgetAction<typeof AiPersonWidgetFields.disciplines>;
      }
    | {
          type: "SUBDISCIPLINES";
          action: WidgetAction<typeof AiPersonWidgetFields.subdisciplines>;
      }
    | {
          type: "SCHOOLS";
          action: WidgetAction<typeof AiPersonWidgetFields.schools>;
      }
    | {
          type: "EMPLOYER_SCHOOLS";
          action: WidgetAction<typeof AiPersonWidgetFields.employerSchools>;
      }
    | {
          type: "DEGREES";
          action: WidgetAction<typeof AiPersonWidgetFields.degrees>;
      };

export type AiPersonWidgetAction = BaseAiPersonWidgetAction;

export type AiPersonWidgetProps = WidgetProps<
    AiPersonWidgetState,
    AiPersonWidgetData,
    AiPersonWidgetAction,
    AiPersonWidgetExtraProps
>;

function baseValidateAiPersonWidget(
    data: AiPersonWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiPersonWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(AiPersonWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(AiPersonWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        AiPersonWidgetFields.short_description,
        data.short_description,
        cache,
        "short_description",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.meta_description,
        data.meta_description,
        cache,
        "meta_description",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.extra_content,
        data.extra_content,
        cache,
        "extra_content",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.image_url,
        data.image_url,
        cache,
        "image_url",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.image_source_url,
        data.image_source_url,
        cache,
        "image_source_url",
        errors
    );
    subvalidate(AiPersonWidgetFields.works, data.works, cache, "works", errors);
    subvalidate(
        AiPersonWidgetFields.hide_wikipedia,
        data.hide_wikipedia,
        cache,
        "hide_wikipedia",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.hide_citations,
        data.hide_citations,
        cache,
        "hide_citations",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.disciplines,
        data.disciplines,
        cache,
        "disciplines",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.subdisciplines,
        data.subdisciplines,
        cache,
        "subdisciplines",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.schools,
        data.schools,
        cache,
        "schools",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.employerSchools,
        data.employerSchools,
        cache,
        "employerSchools",
        errors
    );
    subvalidate(
        AiPersonWidgetFields.degrees,
        data.degrees,
        cache,
        "degrees",
        errors
    );
    return errors;
}
function baseAiPersonWidgetReduce(
    state: AiPersonWidgetState,
    data: AiPersonWidgetData,
    action: BaseAiPersonWidgetAction,
    context: AiPersonWidgetContext
): WidgetResult<AiPersonWidgetState, AiPersonWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ACTIVE": {
            const inner = AiPersonWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "SLUG": {
            const inner = AiPersonWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "NAME": {
            const inner = AiPersonWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SHORT_DESCRIPTION": {
            const inner = AiPersonWidgetFields.short_description.reduce(
                state.short_description,
                data.short_description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, short_description: inner.state },
                data: { ...data, short_description: inner.data },
            };
        }
        case "META_DESCRIPTION": {
            const inner = AiPersonWidgetFields.meta_description.reduce(
                state.meta_description,
                data.meta_description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, meta_description: inner.state },
                data: { ...data, meta_description: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = AiPersonWidgetFields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "EXTRA_CONTENT": {
            const inner = AiPersonWidgetFields.extra_content.reduce(
                state.extra_content,
                data.extra_content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, extra_content: inner.state },
                data: { ...data, extra_content: inner.data },
            };
        }
        case "IMAGE_URL": {
            const inner = AiPersonWidgetFields.image_url.reduce(
                state.image_url,
                data.image_url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, image_url: inner.state },
                data: { ...data, image_url: inner.data },
            };
        }
        case "IMAGE_SOURCE_URL": {
            const inner = AiPersonWidgetFields.image_source_url.reduce(
                state.image_source_url,
                data.image_source_url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, image_source_url: inner.state },
                data: { ...data, image_source_url: inner.data },
            };
        }
        case "WORKS": {
            const inner = AiPersonWidgetFields.works.reduce(
                state.works,
                data.works,
                action.action,
                subcontext
            );
            return {
                state: { ...state, works: inner.state },
                data: { ...data, works: inner.data },
            };
        }
        case "HIDE_WIKIPEDIA": {
            const inner = AiPersonWidgetFields.hide_wikipedia.reduce(
                state.hide_wikipedia,
                data.hide_wikipedia,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hide_wikipedia: inner.state },
                data: { ...data, hide_wikipedia: inner.data },
            };
        }
        case "HIDE_CITATIONS": {
            const inner = AiPersonWidgetFields.hide_citations.reduce(
                state.hide_citations,
                data.hide_citations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hide_citations: inner.state },
                data: { ...data, hide_citations: inner.data },
            };
        }
        case "DISCIPLINES": {
            const inner = AiPersonWidgetFields.disciplines.reduce(
                state.disciplines,
                data.disciplines,
                action.action,
                subcontext
            );
            return {
                state: { ...state, disciplines: inner.state },
                data: { ...data, disciplines: inner.data },
            };
        }
        case "SUBDISCIPLINES": {
            const inner = AiPersonWidgetFields.subdisciplines.reduce(
                state.subdisciplines,
                data.subdisciplines,
                action.action,
                subcontext
            );
            return {
                state: { ...state, subdisciplines: inner.state },
                data: { ...data, subdisciplines: inner.data },
            };
        }
        case "SCHOOLS": {
            const inner = AiPersonWidgetFields.schools.reduce(
                state.schools,
                data.schools,
                action.action,
                subcontext
            );
            return {
                state: { ...state, schools: inner.state },
                data: { ...data, schools: inner.data },
            };
        }
        case "EMPLOYER_SCHOOLS": {
            const inner = AiPersonWidgetFields.employerSchools.reduce(
                state.employerSchools,
                data.employerSchools,
                action.action,
                subcontext
            );
            return {
                state: { ...state, employerSchools: inner.state },
                data: { ...data, employerSchools: inner.data },
            };
        }
        case "DEGREES": {
            const inner = AiPersonWidgetFields.degrees.reduce(
                state.degrees,
                data.degrees,
                action.action,
                subcontext
            );
            return {
                state: { ...state, degrees: inner.state },
                data: { ...data, degrees: inner.data },
            };
        }
    }
}
export type AiPersonWidgetReactContextType = {
    state: AiPersonWidgetState;
    data: AiPersonWidgetData;
    dispatch: (action: AiPersonWidgetAction) => void;
    status: WidgetStatus;
};
export const AiPersonWidgetReactContext = React.createContext<
    AiPersonWidgetReactContextType | undefined
>(undefined);
export const AiPersonWidgetWidgets = {
    active: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    short_description: function (
        props: WidgetExtraProps<
            typeof AiPersonWidgetFields.short_description
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPersonWidgetFields.short_description
                >
            ) => context.dispatch({ type: "SHORT_DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "short_description",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.short_description.component
                state={context.state.short_description}
                data={context.data.short_description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Short Description"}
            />
        );
    },
    meta_description: function (
        props: WidgetExtraProps<
            typeof AiPersonWidgetFields.meta_description
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPersonWidgetFields.meta_description
                >
            ) => context.dispatch({ type: "META_DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "meta_description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.meta_description.component
                state={context.state.meta_description}
                data={context.data.meta_description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Meta Description"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.description> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.description>) =>
                context.dispatch({ type: "DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.description.component
                state={context.state.description}
                data={context.data.description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Description"}
            />
        );
    },
    extra_content: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.extra_content> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.extra_content>) =>
                context.dispatch({ type: "EXTRA_CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "extra_content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.extra_content.component
                state={context.state.extra_content}
                data={context.data.extra_content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Extra Content"}
            />
        );
    },
    image_url: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.image_url> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.image_url>) =>
                context.dispatch({ type: "IMAGE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "image_url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.image_url.component
                state={context.state.image_url}
                data={context.data.image_url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image Url"}
            />
        );
    },
    image_source_url: function (
        props: WidgetExtraProps<
            typeof AiPersonWidgetFields.image_source_url
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPersonWidgetFields.image_source_url
                >
            ) => context.dispatch({ type: "IMAGE_SOURCE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "image_source_url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.image_source_url.component
                state={context.state.image_source_url}
                data={context.data.image_source_url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image Source Url"}
            />
        );
    },
    works: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.works> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.works>) =>
                context.dispatch({ type: "WORKS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "works", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.works.component
                state={context.state.works}
                data={context.data.works}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Works"}
            />
        );
    },
    hide_wikipedia: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.hide_wikipedia> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiPersonWidgetFields.hide_wikipedia>
            ) => context.dispatch({ type: "HIDE_WIKIPEDIA", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "hide_wikipedia", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.hide_wikipedia.component
                state={context.state.hide_wikipedia}
                data={context.data.hide_wikipedia}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hide Wikipedia"}
            />
        );
    },
    hide_citations: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.hide_citations> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiPersonWidgetFields.hide_citations>
            ) => context.dispatch({ type: "HIDE_CITATIONS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "hide_citations", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.hide_citations.component
                state={context.state.hide_citations}
                data={context.data.hide_citations}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hide Citations"}
            />
        );
    },
    disciplines: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.disciplines> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.disciplines>) =>
                context.dispatch({ type: "DISCIPLINES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "disciplines", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.disciplines.component
                state={context.state.disciplines}
                data={context.data.disciplines}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Disciplines"}
            />
        );
    },
    subdisciplines: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.subdisciplines> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiPersonWidgetFields.subdisciplines>
            ) => context.dispatch({ type: "SUBDISCIPLINES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "subdisciplines", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.subdisciplines.component
                state={context.state.subdisciplines}
                data={context.data.subdisciplines}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Subdisciplines"}
            />
        );
    },
    schools: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.schools> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.schools>) =>
                context.dispatch({ type: "SCHOOLS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "schools", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.schools.component
                state={context.state.schools}
                data={context.data.schools}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Schools"}
            />
        );
    },
    employerSchools: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.employerSchools> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPersonWidgetFields.employerSchools
                >
            ) => context.dispatch({ type: "EMPLOYER_SCHOOLS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "employerSchools", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.employerSchools.component
                state={context.state.employerSchools}
                data={context.data.employerSchools}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Employer Schools"}
            />
        );
    },
    degrees: function (
        props: WidgetExtraProps<typeof AiPersonWidgetFields.degrees> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWidgetReactContext
        ) as AiPersonWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWidgetFields.degrees>) =>
                context.dispatch({ type: "DEGREES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "degrees", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWidgetFields.degrees.component
                state={context.state.degrees}
                data={context.data.degrees}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Degrees"}
            />
        );
    },
};
export const AiPersonWidget: RecordWidget<
    AiPersonWidgetState,
    AiPersonWidgetData,
    AiPersonWidgetContext,
    AiPersonWidgetAction,
    AiPersonWidgetExtraProps
> = {
    reactContext: AiPersonWidgetReactContext,
    fieldWidgets: AiPersonWidgetWidgets,
    dataMeta: AI_PERSON_META,
    initialize(
        data: AiPersonWidgetData,
        context: AiPersonWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiPersonWidgetState, AiPersonWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerActive = AiPersonWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerSlug = AiPersonWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerName = AiPersonWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerShortDescription = AiPersonWidgetFields.short_description.initialize(
            data.short_description,
            subcontext,
            subparameters.short_description
        );
        const innerMetaDescription = AiPersonWidgetFields.meta_description.initialize(
            data.meta_description,
            subcontext,
            subparameters.meta_description
        );
        const innerDescription = AiPersonWidgetFields.description.initialize(
            data.description,
            subcontext,
            subparameters.description
        );
        const innerExtraContent = AiPersonWidgetFields.extra_content.initialize(
            data.extra_content,
            subcontext,
            subparameters.extra_content
        );
        const innerImageUrl = AiPersonWidgetFields.image_url.initialize(
            data.image_url,
            subcontext,
            subparameters.image_url
        );
        const innerImageSourceUrl = AiPersonWidgetFields.image_source_url.initialize(
            data.image_source_url,
            subcontext,
            subparameters.image_source_url
        );
        const innerWorks = AiPersonWidgetFields.works.initialize(
            data.works,
            subcontext,
            subparameters.works
        );
        const innerHideWikipedia = AiPersonWidgetFields.hide_wikipedia.initialize(
            data.hide_wikipedia,
            subcontext,
            subparameters.hide_wikipedia
        );
        const innerHideCitations = AiPersonWidgetFields.hide_citations.initialize(
            data.hide_citations,
            subcontext,
            subparameters.hide_citations
        );
        const innerDisciplines = AiPersonWidgetFields.disciplines.initialize(
            data.disciplines,
            subcontext,
            subparameters.disciplines
        );
        const innerSubdisciplines = AiPersonWidgetFields.subdisciplines.initialize(
            data.subdisciplines,
            subcontext,
            subparameters.subdisciplines
        );
        const innerSchools = AiPersonWidgetFields.schools.initialize(
            data.schools,
            subcontext,
            subparameters.schools
        );
        const innerEmployerSchools = AiPersonWidgetFields.employerSchools.initialize(
            data.employerSchools,
            subcontext,
            subparameters.employerSchools
        );
        const innerDegrees = AiPersonWidgetFields.degrees.initialize(
            data.degrees,
            subcontext,
            subparameters.degrees
        );
        let state = {
            active: innerActive.state,
            slug: innerSlug.state,
            name: innerName.state,
            short_description: innerShortDescription.state,
            meta_description: innerMetaDescription.state,
            description: innerDescription.state,
            extra_content: innerExtraContent.state,
            image_url: innerImageUrl.state,
            image_source_url: innerImageSourceUrl.state,
            works: innerWorks.state,
            hide_wikipedia: innerHideWikipedia.state,
            hide_citations: innerHideCitations.state,
            disciplines: innerDisciplines.state,
            subdisciplines: innerSubdisciplines.state,
            schools: innerSchools.state,
            employerSchools: innerEmployerSchools.state,
            degrees: innerDegrees.state,
        };
        return {
            state,
            data: {
                ...data,
                active: innerActive.data,
                slug: innerSlug.data,
                name: innerName.data,
                short_description: innerShortDescription.data,
                meta_description: innerMetaDescription.data,
                description: innerDescription.data,
                extra_content: innerExtraContent.data,
                image_url: innerImageUrl.data,
                image_source_url: innerImageSourceUrl.data,
                works: innerWorks.data,
                hide_wikipedia: innerHideWikipedia.data,
                hide_citations: innerHideCitations.data,
                disciplines: innerDisciplines.data,
                subdisciplines: innerSubdisciplines.data,
                schools: innerSchools.data,
                employerSchools: innerEmployerSchools.data,
                degrees: innerDegrees.data,
            },
        };
    },
    validate: validateAiPersonWidget,
    component: React.memo((props: AiPersonWidgetProps) => {
        return (
            <AiPersonWidgetReactContext.Provider value={props}>
                {AiPersonWidgetComponent(AiPersonWidgetWidgets, props)}
            </AiPersonWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiPersonWidgetReduce,
};

type AiPersonWidgetWidgets = {
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.active
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.slug
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.name
        >
    >;
    short_description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.short_description
        >
    >;
    meta_description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.meta_description
        >
    >;
    description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.description
        >
    >;
    extra_content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.extra_content
        >
    >;
    image_url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.image_url
        >
    >;
    image_source_url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.image_source_url
        >
    >;
    works: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.works
        >
    >;
    hide_wikipedia: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.hide_wikipedia
        >
    >;
    hide_citations: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.hide_citations
        >
    >;
    disciplines: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.disciplines
        >
    >;
    subdisciplines: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.subdisciplines
        >
    >;
    schools: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.schools
        >
    >;
    employerSchools: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.employerSchools
        >
    >;
    degrees: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWidgetFields.degrees
        >
    >;
};
// END MAGIC -- DO NOT EDIT
