import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiAuthor, AI_AUTHOR_META } from "./table";

//!RecordWidget
export type AiAuthorWidgetData = AiAuthor;

export const AiAuthorWidgetFields = {
    name: FormField(TextWidget),
    aboutPageSlug: OptionalFormField(TextWidget),
    url: OptionalFormField(TextWidget),
    imageUrl: OptionalFormField(TextWidget),
    honorificPrefix: OptionalFormField(TextWidget),
    jobTitle: OptionalFormField(TextWidget),
};

function AiAuthorWidgetComponent(
    widgets: AiAuthorWidgetWidgets,
    props: AiAuthorWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.aboutPageSlug />
            <widgets.url />
            <widgets.imageUrl />
            <widgets.honorificPrefix />
            <widgets.jobTitle />
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiAuthorWidgetContext = WidgetContext<typeof AiAuthorWidgetFields.name> &
    WidgetContext<typeof AiAuthorWidgetFields.aboutPageSlug> &
    WidgetContext<typeof AiAuthorWidgetFields.url> &
    WidgetContext<typeof AiAuthorWidgetFields.imageUrl> &
    WidgetContext<typeof AiAuthorWidgetFields.honorificPrefix> &
    WidgetContext<typeof AiAuthorWidgetFields.jobTitle>;
type AiAuthorWidgetExtraProps = {};
type AiAuthorWidgetBaseState = {
    name: WidgetState<typeof AiAuthorWidgetFields.name>;
    aboutPageSlug: WidgetState<typeof AiAuthorWidgetFields.aboutPageSlug>;
    url: WidgetState<typeof AiAuthorWidgetFields.url>;
    imageUrl: WidgetState<typeof AiAuthorWidgetFields.imageUrl>;
    honorificPrefix: WidgetState<typeof AiAuthorWidgetFields.honorificPrefix>;
    jobTitle: WidgetState<typeof AiAuthorWidgetFields.jobTitle>;
};
export type AiAuthorWidgetState = AiAuthorWidgetBaseState;

type BaseAiAuthorWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AiAuthorWidgetFields.name> }
    | {
          type: "ABOUT_PAGE_SLUG";
          action: WidgetAction<typeof AiAuthorWidgetFields.aboutPageSlug>;
      }
    | { type: "URL"; action: WidgetAction<typeof AiAuthorWidgetFields.url> }
    | {
          type: "IMAGE_URL";
          action: WidgetAction<typeof AiAuthorWidgetFields.imageUrl>;
      }
    | {
          type: "HONORIFIC_PREFIX";
          action: WidgetAction<typeof AiAuthorWidgetFields.honorificPrefix>;
      }
    | {
          type: "JOB_TITLE";
          action: WidgetAction<typeof AiAuthorWidgetFields.jobTitle>;
      };

export type AiAuthorWidgetAction = BaseAiAuthorWidgetAction;

export type AiAuthorWidgetProps = WidgetProps<
    AiAuthorWidgetState,
    AiAuthorWidgetData,
    AiAuthorWidgetAction,
    AiAuthorWidgetExtraProps
>;

function baseValidateAiAuthorWidget(
    data: AiAuthorWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiAuthorWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        AiAuthorWidgetFields.aboutPageSlug,
        data.aboutPageSlug,
        cache,
        "aboutPageSlug",
        errors
    );
    subvalidate(AiAuthorWidgetFields.url, data.url, cache, "url", errors);
    subvalidate(
        AiAuthorWidgetFields.imageUrl,
        data.imageUrl,
        cache,
        "imageUrl",
        errors
    );
    subvalidate(
        AiAuthorWidgetFields.honorificPrefix,
        data.honorificPrefix,
        cache,
        "honorificPrefix",
        errors
    );
    subvalidate(
        AiAuthorWidgetFields.jobTitle,
        data.jobTitle,
        cache,
        "jobTitle",
        errors
    );
    return errors;
}
function baseAiAuthorWidgetReduce(
    state: AiAuthorWidgetState,
    data: AiAuthorWidgetData,
    action: BaseAiAuthorWidgetAction,
    context: AiAuthorWidgetContext
): WidgetResult<AiAuthorWidgetState, AiAuthorWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiAuthorWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "ABOUT_PAGE_SLUG": {
            const inner = AiAuthorWidgetFields.aboutPageSlug.reduce(
                state.aboutPageSlug,
                data.aboutPageSlug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, aboutPageSlug: inner.state },
                data: { ...data, aboutPageSlug: inner.data },
            };
        }
        case "URL": {
            const inner = AiAuthorWidgetFields.url.reduce(
                state.url,
                data.url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, url: inner.state },
                data: { ...data, url: inner.data },
            };
        }
        case "IMAGE_URL": {
            const inner = AiAuthorWidgetFields.imageUrl.reduce(
                state.imageUrl,
                data.imageUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, imageUrl: inner.state },
                data: { ...data, imageUrl: inner.data },
            };
        }
        case "HONORIFIC_PREFIX": {
            const inner = AiAuthorWidgetFields.honorificPrefix.reduce(
                state.honorificPrefix,
                data.honorificPrefix,
                action.action,
                subcontext
            );
            return {
                state: { ...state, honorificPrefix: inner.state },
                data: { ...data, honorificPrefix: inner.data },
            };
        }
        case "JOB_TITLE": {
            const inner = AiAuthorWidgetFields.jobTitle.reduce(
                state.jobTitle,
                data.jobTitle,
                action.action,
                subcontext
            );
            return {
                state: { ...state, jobTitle: inner.state },
                data: { ...data, jobTitle: inner.data },
            };
        }
    }
}
export type AiAuthorWidgetReactContextType = {
    state: AiAuthorWidgetState;
    data: AiAuthorWidgetData;
    dispatch: (action: AiAuthorWidgetAction) => void;
    status: WidgetStatus;
};
export const AiAuthorWidgetReactContext = React.createContext<
    AiAuthorWidgetReactContextType | undefined
>(undefined);
export const AiAuthorWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAuthorWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    aboutPageSlug: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.aboutPageSlug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAuthorWidgetFields.aboutPageSlug>) =>
                context.dispatch({ type: "ABOUT_PAGE_SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "aboutPageSlug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.aboutPageSlug.component
                state={context.state.aboutPageSlug}
                data={context.data.aboutPageSlug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "About Page Slug"}
            />
        );
    },
    url: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.url> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAuthorWidgetFields.url>) =>
                context.dispatch({ type: "URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.url.component
                state={context.state.url}
                data={context.data.url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Url"}
            />
        );
    },
    imageUrl: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.imageUrl> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAuthorWidgetFields.imageUrl>) =>
                context.dispatch({ type: "IMAGE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "imageUrl", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.imageUrl.component
                state={context.state.imageUrl}
                data={context.data.imageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image Url"}
            />
        );
    },
    honorificPrefix: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.honorificPrefix> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiAuthorWidgetFields.honorificPrefix
                >
            ) => context.dispatch({ type: "HONORIFIC_PREFIX", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "honorificPrefix", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.honorificPrefix.component
                state={context.state.honorificPrefix}
                data={context.data.honorificPrefix}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Honorific Prefix"}
            />
        );
    },
    jobTitle: function (
        props: WidgetExtraProps<typeof AiAuthorWidgetFields.jobTitle> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAuthorWidgetReactContext
        ) as AiAuthorWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAuthorWidgetFields.jobTitle>) =>
                context.dispatch({ type: "JOB_TITLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "jobTitle", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAuthorWidgetFields.jobTitle.component
                state={context.state.jobTitle}
                data={context.data.jobTitle}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Job Title"}
            />
        );
    },
};
export const AiAuthorWidget: RecordWidget<
    AiAuthorWidgetState,
    AiAuthorWidgetData,
    AiAuthorWidgetContext,
    AiAuthorWidgetAction,
    AiAuthorWidgetExtraProps
> = {
    reactContext: AiAuthorWidgetReactContext,
    fieldWidgets: AiAuthorWidgetWidgets,
    dataMeta: AI_AUTHOR_META,
    initialize(
        data: AiAuthorWidgetData,
        context: AiAuthorWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiAuthorWidgetState, AiAuthorWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiAuthorWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerAboutPageSlug = AiAuthorWidgetFields.aboutPageSlug.initialize(
            data.aboutPageSlug,
            subcontext,
            subparameters.aboutPageSlug
        );
        const innerUrl = AiAuthorWidgetFields.url.initialize(
            data.url,
            subcontext,
            subparameters.url
        );
        const innerImageUrl = AiAuthorWidgetFields.imageUrl.initialize(
            data.imageUrl,
            subcontext,
            subparameters.imageUrl
        );
        const innerHonorificPrefix = AiAuthorWidgetFields.honorificPrefix.initialize(
            data.honorificPrefix,
            subcontext,
            subparameters.honorificPrefix
        );
        const innerJobTitle = AiAuthorWidgetFields.jobTitle.initialize(
            data.jobTitle,
            subcontext,
            subparameters.jobTitle
        );
        let state = {
            name: innerName.state,
            aboutPageSlug: innerAboutPageSlug.state,
            url: innerUrl.state,
            imageUrl: innerImageUrl.state,
            honorificPrefix: innerHonorificPrefix.state,
            jobTitle: innerJobTitle.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                aboutPageSlug: innerAboutPageSlug.data,
                url: innerUrl.data,
                imageUrl: innerImageUrl.data,
                honorificPrefix: innerHonorificPrefix.data,
                jobTitle: innerJobTitle.data,
            },
        };
    },
    validate: baseValidateAiAuthorWidget,
    component: React.memo((props: AiAuthorWidgetProps) => {
        return (
            <AiAuthorWidgetReactContext.Provider value={props}>
                {AiAuthorWidgetComponent(AiAuthorWidgetWidgets, props)}
            </AiAuthorWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiAuthorWidgetReduce,
};

type AiAuthorWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.name
        >
    >;
    aboutPageSlug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.aboutPageSlug
        >
    >;
    url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.url
        >
    >;
    imageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.imageUrl
        >
    >;
    honorificPrefix: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.honorificPrefix
        >
    >;
    jobTitle: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAuthorWidgetFields.jobTitle
        >
    >;
};
// END MAGIC -- DO NOT EDIT
