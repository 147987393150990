import { css } from "glamor";
import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useId } from "react-id-generator";
import { useUser } from "../../../app/state";
import { useQuery } from "../../../clay/api";
import { Link } from "../../../clay/link";
import { RemoveButton } from "../../../clay/remove-button";
import {
    statusToState,
    WidgetResult,
    WidgetStatus,
} from "../../../clay/widgets";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { AiPerson } from "../person/table";

type ArticleLinkWidgetState = {
    text: string | null;
};

type ArticleLinkWidgetAction =
    | {
          type: "TEXT_CHANGE";
          text: string;
      }
    | {
          type: "SELECT";
          id: string | null;
      }
    | { type: "BLUR" }
    | { type: "NEW_RECORD"; id: string };

type ArticleLinkWidgetProps = {
    state: ArticleLinkWidgetState;
    data: Link<AiPerson>;
    dispatch: (action: ArticleLinkWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
};

const WIDGET_STYLE = css({
    "html & input.form-control": {
        border: "none",
        height: "auto",
        padding: "0px",
    },
});

function initialize(
    data: Link<AiPerson>,
    context: {}
): WidgetResult<ArticleLinkWidgetState, Link<AiPerson>> {
    return {
        data,
        state: {
            text: null,
        },
    };
}

async function autocompletePerson(query: string) {
    if (query.length < 3) {
        return [];
    }
    const response = await fetch(
        "/ai/api/PersonSearch/" + encodeURIComponent(JSON.stringify(query))
    );
    const content = await response.json();
    return content.entities.map((row: any) => ({
        name: `${row.name} [${row.slug}] (${row.shortDescription})`,
        id: row.slug,
    }));
}

async function personImage(slug: string) {
    const response = await fetch(
        "/ai/api/PersonPage/" + encodeURIComponent(JSON.stringify({ slug }))
    );
    const content = await response.json();
    return content.person?.imageUrl;
}

export const ArticleLinkWidget = {
    dataMeta: {
        type: "uuid" as const,
        linkTo: "AiPerson",
    },
    initialize,
    component: (props: ArticleLinkWidgetProps) => {
        const user = useUser();
        const text = props.state.text || "";

        const suggests = useQuery(
            {
                tableName: "AiArticle",
                filters: [
                    {
                        or: [
                            {
                                column: "path",
                                filter: {
                                    like: "%" + text + "%",
                                },
                            },
                            {
                                column: "title",
                                filter: {
                                    like: "%" + text + "%",
                                },
                            },
                        ],
                    },
                ],
                columns: ["id", "title", "path"],
            },
            [text]
        );
        const widgetId = useId()[0];

        async function onChange(selected: any[]) {
            if (selected.length > 0) {
                const item = selected[0];
                props.dispatch({
                    type: "SELECT",
                    id: item[0] as string,
                });
            } else {
                if (props.data !== null) {
                    props.dispatch({
                        type: "SELECT",
                        id: null,
                    });
                }
            }
        }

        const selected =
            useQuery(
                {
                    tableName: "AiArticle",
                    filters: [
                        {
                            column: "id",
                            filter: {
                                equal: props.data,
                            },
                        },
                    ],
                    columns: ["id", "title", "path"],
                },
                [props.data]
            ) || [];

        const listItemContext = useListItemContext();

        return (
            <tr {...listItemContext.draggableProps}>
                <td>{listItemContext.dragHandle}</td>
                <td>
                    <div style={{ ...props.style, display: "flex" }}>
                        <div
                            className={
                                "form-control " +
                                statusToState(
                                    props.status.validation,
                                    props.data === null
                                )
                            }
                            {...WIDGET_STYLE}
                        >
                            <Typeahead
                                positionFixed={true}
                                id={widgetId}
                                labelKey={(x: any) => {
                                    return x[1] + " (" + x[2] + ")";
                                }}
                                selected={selected}
                                filterBy={() => true}
                                options={(suggests as any[]) || []}
                                onInputChange={(text) =>
                                    props.dispatch({
                                        type: "TEXT_CHANGE",
                                        text,
                                    })
                                }
                                onBlur={() => props.dispatch({ type: "BLUR" })}
                                onChange={onChange}
                                disabled={!props.status.mutable}
                            />
                        </div>
                    </div>
                </td>
                <td>
                    <RemoveButton />
                </td>
            </tr>
        );
    },
    reduce: (
        state: ArticleLinkWidgetState,
        data: Link<AiPerson>,
        action: ArticleLinkWidgetAction,
        context: {}
    ): WidgetResult<ArticleLinkWidgetState, Link<AiPerson>> => {
        switch (action.type) {
            case "TEXT_CHANGE":
                return {
                    state: {
                        ...state,
                        text: action.text,
                    },
                    data,
                };
            case "SELECT":
                return {
                    state: {
                        ...state,
                        text: null,
                    },
                    data: action.id,
                };
            case "BLUR":
                return {
                    state: {
                        ...state,
                        text: null,
                    },
                    data,
                };
            case "NEW_RECORD":
                return initialize(action.id, context);
        }
    },

    validate(data: Link<AiPerson>) {
        if (data === null) {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        } else {
            return [];
        }
    },
};
