import React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { DateWidget } from "../../../clay/widgets/DateWidget";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AdGroupItem, AD_GROUP_ITEM_META } from "./table";

//!RecordWidget
export type AdGroupItemWidgetData = AdGroupItem;

export const AdGroupItemWidgetFields = {
    name: FormField(TextWidget),
    image: OptionalFormField(TextWidget),
    mobileImage: OptionalFormField(TextWidget),
    link: FormField(TextWidget),
    alt: FormField(TextWidget),
    width: OptionalFormField(QuantityWidget),
    height: OptionalFormField(QuantityWidget),
    mobileWidth: OptionalFormField(QuantityWidget),
    mobileHeight: OptionalFormField(QuantityWidget),
    trackingPixel: OptionalFormField(TextWidget),
    disabled: FormField(SwitchWidget),
    startDate: OptionalFormField(DateWidget),
    endDate: OptionalFormField(DateWidget),
};

function AdGroupItemWidgetComponent(
    widgets: AdGroupItemWidgetWidgets,
    props: AdGroupItemWidgetProps
) {
    return (
        <>
            <widgets.name />
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <widgets.image />
                </div>
                <div style={{ width: "2em" }} />
                <widgets.width style={{ maxWidth: "6em" }} />
                <div style={{ width: "2em" }} />
                <widgets.height style={{ maxWidth: "6em" }} />
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <widgets.mobileImage />
                </div>
                <div style={{ width: "2em" }} />
                <widgets.mobileWidth style={{ maxWidth: "6em" }} />
                <div style={{ width: "2em" }} />
                <widgets.mobileHeight style={{ maxWidth: "6em" }} />
            </div>
            <widgets.link />
            <widgets.alt />
            <widgets.trackingPixel />
            <widgets.disabled />
            <widgets.startDate />
            <widgets.endDate />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AdGroupItemWidgetContext = WidgetContext<
    typeof AdGroupItemWidgetFields.name
> &
    WidgetContext<typeof AdGroupItemWidgetFields.image> &
    WidgetContext<typeof AdGroupItemWidgetFields.mobileImage> &
    WidgetContext<typeof AdGroupItemWidgetFields.link> &
    WidgetContext<typeof AdGroupItemWidgetFields.alt> &
    WidgetContext<typeof AdGroupItemWidgetFields.width> &
    WidgetContext<typeof AdGroupItemWidgetFields.height> &
    WidgetContext<typeof AdGroupItemWidgetFields.mobileWidth> &
    WidgetContext<typeof AdGroupItemWidgetFields.mobileHeight> &
    WidgetContext<typeof AdGroupItemWidgetFields.trackingPixel> &
    WidgetContext<typeof AdGroupItemWidgetFields.disabled> &
    WidgetContext<typeof AdGroupItemWidgetFields.startDate> &
    WidgetContext<typeof AdGroupItemWidgetFields.endDate>;
type AdGroupItemWidgetExtraProps = {};
type AdGroupItemWidgetBaseState = {
    name: WidgetState<typeof AdGroupItemWidgetFields.name>;
    image: WidgetState<typeof AdGroupItemWidgetFields.image>;
    mobileImage: WidgetState<typeof AdGroupItemWidgetFields.mobileImage>;
    link: WidgetState<typeof AdGroupItemWidgetFields.link>;
    alt: WidgetState<typeof AdGroupItemWidgetFields.alt>;
    width: WidgetState<typeof AdGroupItemWidgetFields.width>;
    height: WidgetState<typeof AdGroupItemWidgetFields.height>;
    mobileWidth: WidgetState<typeof AdGroupItemWidgetFields.mobileWidth>;
    mobileHeight: WidgetState<typeof AdGroupItemWidgetFields.mobileHeight>;
    trackingPixel: WidgetState<typeof AdGroupItemWidgetFields.trackingPixel>;
    disabled: WidgetState<typeof AdGroupItemWidgetFields.disabled>;
    startDate: WidgetState<typeof AdGroupItemWidgetFields.startDate>;
    endDate: WidgetState<typeof AdGroupItemWidgetFields.endDate>;
};
export type AdGroupItemWidgetState = AdGroupItemWidgetBaseState;

type BaseAdGroupItemWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof AdGroupItemWidgetFields.name>;
      }
    | {
          type: "IMAGE";
          action: WidgetAction<typeof AdGroupItemWidgetFields.image>;
      }
    | {
          type: "MOBILE_IMAGE";
          action: WidgetAction<typeof AdGroupItemWidgetFields.mobileImage>;
      }
    | {
          type: "LINK";
          action: WidgetAction<typeof AdGroupItemWidgetFields.link>;
      }
    | { type: "ALT"; action: WidgetAction<typeof AdGroupItemWidgetFields.alt> }
    | {
          type: "WIDTH";
          action: WidgetAction<typeof AdGroupItemWidgetFields.width>;
      }
    | {
          type: "HEIGHT";
          action: WidgetAction<typeof AdGroupItemWidgetFields.height>;
      }
    | {
          type: "MOBILE_WIDTH";
          action: WidgetAction<typeof AdGroupItemWidgetFields.mobileWidth>;
      }
    | {
          type: "MOBILE_HEIGHT";
          action: WidgetAction<typeof AdGroupItemWidgetFields.mobileHeight>;
      }
    | {
          type: "TRACKING_PIXEL";
          action: WidgetAction<typeof AdGroupItemWidgetFields.trackingPixel>;
      }
    | {
          type: "DISABLED";
          action: WidgetAction<typeof AdGroupItemWidgetFields.disabled>;
      }
    | {
          type: "START_DATE";
          action: WidgetAction<typeof AdGroupItemWidgetFields.startDate>;
      }
    | {
          type: "END_DATE";
          action: WidgetAction<typeof AdGroupItemWidgetFields.endDate>;
      };

export type AdGroupItemWidgetAction = BaseAdGroupItemWidgetAction;

export type AdGroupItemWidgetProps = WidgetProps<
    AdGroupItemWidgetState,
    AdGroupItemWidgetData,
    AdGroupItemWidgetAction,
    AdGroupItemWidgetExtraProps
>;

function baseValidateAdGroupItemWidget(
    data: AdGroupItemWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AdGroupItemWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        AdGroupItemWidgetFields.image,
        data.image,
        cache,
        "image",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.mobileImage,
        data.mobileImage,
        cache,
        "mobileImage",
        errors
    );
    subvalidate(AdGroupItemWidgetFields.link, data.link, cache, "link", errors);
    subvalidate(AdGroupItemWidgetFields.alt, data.alt, cache, "alt", errors);
    subvalidate(
        AdGroupItemWidgetFields.width,
        data.width,
        cache,
        "width",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.height,
        data.height,
        cache,
        "height",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.mobileWidth,
        data.mobileWidth,
        cache,
        "mobileWidth",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.mobileHeight,
        data.mobileHeight,
        cache,
        "mobileHeight",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.trackingPixel,
        data.trackingPixel,
        cache,
        "trackingPixel",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.disabled,
        data.disabled,
        cache,
        "disabled",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.startDate,
        data.startDate,
        cache,
        "startDate",
        errors
    );
    subvalidate(
        AdGroupItemWidgetFields.endDate,
        data.endDate,
        cache,
        "endDate",
        errors
    );
    return errors;
}
function baseAdGroupItemWidgetReduce(
    state: AdGroupItemWidgetState,
    data: AdGroupItemWidgetData,
    action: BaseAdGroupItemWidgetAction,
    context: AdGroupItemWidgetContext
): WidgetResult<AdGroupItemWidgetState, AdGroupItemWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AdGroupItemWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "IMAGE": {
            const inner = AdGroupItemWidgetFields.image.reduce(
                state.image,
                data.image,
                action.action,
                subcontext
            );
            return {
                state: { ...state, image: inner.state },
                data: { ...data, image: inner.data },
            };
        }
        case "MOBILE_IMAGE": {
            const inner = AdGroupItemWidgetFields.mobileImage.reduce(
                state.mobileImage,
                data.mobileImage,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mobileImage: inner.state },
                data: { ...data, mobileImage: inner.data },
            };
        }
        case "LINK": {
            const inner = AdGroupItemWidgetFields.link.reduce(
                state.link,
                data.link,
                action.action,
                subcontext
            );
            return {
                state: { ...state, link: inner.state },
                data: { ...data, link: inner.data },
            };
        }
        case "ALT": {
            const inner = AdGroupItemWidgetFields.alt.reduce(
                state.alt,
                data.alt,
                action.action,
                subcontext
            );
            return {
                state: { ...state, alt: inner.state },
                data: { ...data, alt: inner.data },
            };
        }
        case "WIDTH": {
            const inner = AdGroupItemWidgetFields.width.reduce(
                state.width,
                data.width,
                action.action,
                subcontext
            );
            return {
                state: { ...state, width: inner.state },
                data: { ...data, width: inner.data },
            };
        }
        case "HEIGHT": {
            const inner = AdGroupItemWidgetFields.height.reduce(
                state.height,
                data.height,
                action.action,
                subcontext
            );
            return {
                state: { ...state, height: inner.state },
                data: { ...data, height: inner.data },
            };
        }
        case "MOBILE_WIDTH": {
            const inner = AdGroupItemWidgetFields.mobileWidth.reduce(
                state.mobileWidth,
                data.mobileWidth,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mobileWidth: inner.state },
                data: { ...data, mobileWidth: inner.data },
            };
        }
        case "MOBILE_HEIGHT": {
            const inner = AdGroupItemWidgetFields.mobileHeight.reduce(
                state.mobileHeight,
                data.mobileHeight,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mobileHeight: inner.state },
                data: { ...data, mobileHeight: inner.data },
            };
        }
        case "TRACKING_PIXEL": {
            const inner = AdGroupItemWidgetFields.trackingPixel.reduce(
                state.trackingPixel,
                data.trackingPixel,
                action.action,
                subcontext
            );
            return {
                state: { ...state, trackingPixel: inner.state },
                data: { ...data, trackingPixel: inner.data },
            };
        }
        case "DISABLED": {
            const inner = AdGroupItemWidgetFields.disabled.reduce(
                state.disabled,
                data.disabled,
                action.action,
                subcontext
            );
            return {
                state: { ...state, disabled: inner.state },
                data: { ...data, disabled: inner.data },
            };
        }
        case "START_DATE": {
            const inner = AdGroupItemWidgetFields.startDate.reduce(
                state.startDate,
                data.startDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, startDate: inner.state },
                data: { ...data, startDate: inner.data },
            };
        }
        case "END_DATE": {
            const inner = AdGroupItemWidgetFields.endDate.reduce(
                state.endDate,
                data.endDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, endDate: inner.state },
                data: { ...data, endDate: inner.data },
            };
        }
    }
}
export type AdGroupItemWidgetReactContextType = {
    state: AdGroupItemWidgetState;
    data: AdGroupItemWidgetData;
    dispatch: (action: AdGroupItemWidgetAction) => void;
    status: WidgetStatus;
};
export const AdGroupItemWidgetReactContext = React.createContext<
    AdGroupItemWidgetReactContextType | undefined
>(undefined);
export const AdGroupItemWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    image: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.image> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.image>) =>
                context.dispatch({ type: "IMAGE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "image", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.image.component
                state={context.state.image}
                data={context.data.image}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image"}
            />
        );
    },
    mobileImage: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.mobileImage> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AdGroupItemWidgetFields.mobileImage>
            ) => context.dispatch({ type: "MOBILE_IMAGE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "mobileImage", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.mobileImage.component
                state={context.state.mobileImage}
                data={context.data.mobileImage}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Mobile Image"}
            />
        );
    },
    link: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.link> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.link>) =>
                context.dispatch({ type: "LINK", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "link", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.link.component
                state={context.state.link}
                data={context.data.link}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Link"}
            />
        );
    },
    alt: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.alt> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.alt>) =>
                context.dispatch({ type: "ALT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "alt", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.alt.component
                state={context.state.alt}
                data={context.data.alt}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alt"}
            />
        );
    },
    width: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.width> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.width>) =>
                context.dispatch({ type: "WIDTH", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "width", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.width.component
                state={context.state.width}
                data={context.data.width}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Width"}
            />
        );
    },
    height: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.height> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.height>) =>
                context.dispatch({ type: "HEIGHT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "height", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.height.component
                state={context.state.height}
                data={context.data.height}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Height"}
            />
        );
    },
    mobileWidth: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.mobileWidth> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AdGroupItemWidgetFields.mobileWidth>
            ) => context.dispatch({ type: "MOBILE_WIDTH", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "mobileWidth", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.mobileWidth.component
                state={context.state.mobileWidth}
                data={context.data.mobileWidth}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Mobile Width"}
            />
        );
    },
    mobileHeight: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.mobileHeight> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AdGroupItemWidgetFields.mobileHeight
                >
            ) => context.dispatch({ type: "MOBILE_HEIGHT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "mobileHeight", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.mobileHeight.component
                state={context.state.mobileHeight}
                data={context.data.mobileHeight}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Mobile Height"}
            />
        );
    },
    trackingPixel: function (
        props: WidgetExtraProps<
            typeof AdGroupItemWidgetFields.trackingPixel
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AdGroupItemWidgetFields.trackingPixel
                >
            ) => context.dispatch({ type: "TRACKING_PIXEL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "trackingPixel", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.trackingPixel.component
                state={context.state.trackingPixel}
                data={context.data.trackingPixel}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Tracking Pixel"}
            />
        );
    },
    disabled: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.disabled> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.disabled>) =>
                context.dispatch({ type: "DISABLED", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "disabled", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.disabled.component
                state={context.state.disabled}
                data={context.data.disabled}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Disabled"}
            />
        );
    },
    startDate: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.startDate> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.startDate>) =>
                context.dispatch({ type: "START_DATE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "startDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.startDate.component
                state={context.state.startDate}
                data={context.data.startDate}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Start Date"}
            />
        );
    },
    endDate: function (
        props: WidgetExtraProps<typeof AdGroupItemWidgetFields.endDate> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupItemWidgetReactContext
        ) as AdGroupItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupItemWidgetFields.endDate>) =>
                context.dispatch({ type: "END_DATE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "endDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupItemWidgetFields.endDate.component
                state={context.state.endDate}
                data={context.data.endDate}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "End Date"}
            />
        );
    },
};
export const AdGroupItemWidget: RecordWidget<
    AdGroupItemWidgetState,
    AdGroupItemWidgetData,
    AdGroupItemWidgetContext,
    AdGroupItemWidgetAction,
    AdGroupItemWidgetExtraProps
> = {
    reactContext: AdGroupItemWidgetReactContext,
    fieldWidgets: AdGroupItemWidgetWidgets,
    dataMeta: AD_GROUP_ITEM_META,
    initialize(
        data: AdGroupItemWidgetData,
        context: AdGroupItemWidgetContext,
        parameters?: string[]
    ): WidgetResult<AdGroupItemWidgetState, AdGroupItemWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AdGroupItemWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerImage = AdGroupItemWidgetFields.image.initialize(
            data.image,
            subcontext,
            subparameters.image
        );
        const innerMobileImage = AdGroupItemWidgetFields.mobileImage.initialize(
            data.mobileImage,
            subcontext,
            subparameters.mobileImage
        );
        const innerLink = AdGroupItemWidgetFields.link.initialize(
            data.link,
            subcontext,
            subparameters.link
        );
        const innerAlt = AdGroupItemWidgetFields.alt.initialize(
            data.alt,
            subcontext,
            subparameters.alt
        );
        const innerWidth = AdGroupItemWidgetFields.width.initialize(
            data.width,
            subcontext,
            subparameters.width
        );
        const innerHeight = AdGroupItemWidgetFields.height.initialize(
            data.height,
            subcontext,
            subparameters.height
        );
        const innerMobileWidth = AdGroupItemWidgetFields.mobileWidth.initialize(
            data.mobileWidth,
            subcontext,
            subparameters.mobileWidth
        );
        const innerMobileHeight = AdGroupItemWidgetFields.mobileHeight.initialize(
            data.mobileHeight,
            subcontext,
            subparameters.mobileHeight
        );
        const innerTrackingPixel = AdGroupItemWidgetFields.trackingPixel.initialize(
            data.trackingPixel,
            subcontext,
            subparameters.trackingPixel
        );
        const innerDisabled = AdGroupItemWidgetFields.disabled.initialize(
            data.disabled,
            subcontext,
            subparameters.disabled
        );
        const innerStartDate = AdGroupItemWidgetFields.startDate.initialize(
            data.startDate,
            subcontext,
            subparameters.startDate
        );
        const innerEndDate = AdGroupItemWidgetFields.endDate.initialize(
            data.endDate,
            subcontext,
            subparameters.endDate
        );
        let state = {
            name: innerName.state,
            image: innerImage.state,
            mobileImage: innerMobileImage.state,
            link: innerLink.state,
            alt: innerAlt.state,
            width: innerWidth.state,
            height: innerHeight.state,
            mobileWidth: innerMobileWidth.state,
            mobileHeight: innerMobileHeight.state,
            trackingPixel: innerTrackingPixel.state,
            disabled: innerDisabled.state,
            startDate: innerStartDate.state,
            endDate: innerEndDate.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                image: innerImage.data,
                mobileImage: innerMobileImage.data,
                link: innerLink.data,
                alt: innerAlt.data,
                width: innerWidth.data,
                height: innerHeight.data,
                mobileWidth: innerMobileWidth.data,
                mobileHeight: innerMobileHeight.data,
                trackingPixel: innerTrackingPixel.data,
                disabled: innerDisabled.data,
                startDate: innerStartDate.data,
                endDate: innerEndDate.data,
            },
        };
    },
    validate: baseValidateAdGroupItemWidget,
    component: React.memo((props: AdGroupItemWidgetProps) => {
        return (
            <AdGroupItemWidgetReactContext.Provider value={props}>
                {AdGroupItemWidgetComponent(AdGroupItemWidgetWidgets, props)}
            </AdGroupItemWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAdGroupItemWidgetReduce,
};

type AdGroupItemWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.name
        >
    >;
    image: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.image
        >
    >;
    mobileImage: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.mobileImage
        >
    >;
    link: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.link
        >
    >;
    alt: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.alt
        >
    >;
    width: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.width
        >
    >;
    height: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.height
        >
    >;
    mobileWidth: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.mobileWidth
        >
    >;
    mobileHeight: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.mobileHeight
        >
    >;
    trackingPixel: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.trackingPixel
        >
    >;
    disabled: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.disabled
        >
    >;
    startDate: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.startDate
        >
    >;
    endDate: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupItemWidgetFields.endDate
        >
    >;
};
// END MAGIC -- DO NOT EDIT
