import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { Optional } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { AiAd, AI_AD_META } from "./table";

//!RecordWidget
export type AiAdWidgetData = AiAd;

export const AiAdWidgetFields = {
    content: Optional(HtmlEditorWidget),
};

function AiAdWidgetComponent(
    widgets: AiAdWidgetWidgets,
    props: AiAdWidgetProps
) {
    return (
        <>
            <widgets.content />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiAdWidgetContext = WidgetContext<typeof AiAdWidgetFields.content>;
type AiAdWidgetExtraProps = {};
type AiAdWidgetBaseState = {
    content: WidgetState<typeof AiAdWidgetFields.content>;
};
export type AiAdWidgetState = AiAdWidgetBaseState;

type BaseAiAdWidgetAction = {
    type: "CONTENT";
    action: WidgetAction<typeof AiAdWidgetFields.content>;
};

export type AiAdWidgetAction = BaseAiAdWidgetAction;

export type AiAdWidgetProps = WidgetProps<
    AiAdWidgetState,
    AiAdWidgetData,
    AiAdWidgetAction,
    AiAdWidgetExtraProps
>;

function baseValidateAiAdWidget(data: AiAdWidgetData, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiAdWidgetFields.content,
        data.content,
        cache,
        "content",
        errors
    );
    return errors;
}
function baseAiAdWidgetReduce(
    state: AiAdWidgetState,
    data: AiAdWidgetData,
    action: BaseAiAdWidgetAction,
    context: AiAdWidgetContext
): WidgetResult<AiAdWidgetState, AiAdWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "CONTENT": {
            const inner = AiAdWidgetFields.content.reduce(
                state.content,
                data.content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, content: inner.state },
                data: { ...data, content: inner.data },
            };
        }
    }
}
export type AiAdWidgetReactContextType = {
    state: AiAdWidgetState;
    data: AiAdWidgetData;
    dispatch: (action: AiAdWidgetAction) => void;
    status: WidgetStatus;
};
export const AiAdWidgetReactContext = React.createContext<
    AiAdWidgetReactContextType | undefined
>(undefined);
export const AiAdWidgetWidgets = {
    content: function (
        props: WidgetExtraProps<typeof AiAdWidgetFields.content> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiAdWidgetReactContext
        ) as AiAdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiAdWidgetFields.content>) =>
                context.dispatch({ type: "CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiAdWidgetFields.content.component
                state={context.state.content}
                data={context.data.content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Content"}
            />
        );
    },
};
export const AiAdWidget: RecordWidget<
    AiAdWidgetState,
    AiAdWidgetData,
    AiAdWidgetContext,
    AiAdWidgetAction,
    AiAdWidgetExtraProps
> = {
    reactContext: AiAdWidgetReactContext,
    fieldWidgets: AiAdWidgetWidgets,
    dataMeta: AI_AD_META,
    initialize(
        data: AiAdWidgetData,
        context: AiAdWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiAdWidgetState, AiAdWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerContent = AiAdWidgetFields.content.initialize(
            data.content,
            subcontext,
            subparameters.content
        );
        let state = {
            content: innerContent.state,
        };
        return {
            state,
            data: {
                ...data,
                content: innerContent.data,
            },
        };
    },
    validate: baseValidateAiAdWidget,
    component: React.memo((props: AiAdWidgetProps) => {
        return (
            <AiAdWidgetReactContext.Provider value={props}>
                {AiAdWidgetComponent(AiAdWidgetWidgets, props)}
            </AiAdWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiAdWidgetReduce,
};

type AiAdWidgetWidgets = {
    content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiAdWidgetFields.content
        >
    >;
};
// END MAGIC -- DO NOT EDIT
