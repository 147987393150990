import { css } from "glamor";
import { find, some } from "lodash-es";
import * as React from "react";
import { Pagination } from "react-bootstrap";
import { Dictionary } from "./common";
import { RecordMeta } from "./meta";
import { QuickCacheApi } from "./quick-cache";
import {
    RecordWidget,
    subStatus,
    ValidationError,
    Widget,
    WidgetStatus,
} from "./widgets/index";

export const TAB_STYLE = css({
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    borderBottom: "solid 1px black",
    paddingBottom: "20px",
});

export type PaginatedWidgetState<DataType, ContextType> = {
    currentPageId: string;
    currentPageState: any;
};

export type PaginatedWidgetAction =
    | {
          type: "PAGE";
          pageId: string;
          action: any;
      }
    | {
          type: "SELECT_TAB";
          pageId: string;
      };

export type PageConfig<DataType, ContextType> = {
    id: string;
    title: string;
    widget: RecordWidget<any, DataType, ContextType, any, {}>;
};

export type PaginatedWidgetConfig<DataType, ContextType> = {
    pages: (data: DataType) => PageConfig<DataType, ContextType>[];
    dataMeta: RecordMeta<DataType, any, any>;
    validate?: (
        data: DataType,
        cache: QuickCacheApi,
        errors: ValidationError[]
    ) => ValidationError[];
};

type Props<DataType, ContextType> = {
    state: PaginatedWidgetState<DataType, ContextType>;
    data: DataType;
    dispatch: (action: PaginatedWidgetAction) => void;
    status: WidgetStatus;
};

export type PaginatedWidgetType<DataType, ContextType> = Widget<
    PaginatedWidgetState<DataType, ContextType>,
    DataType,
    ContextType,
    PaginatedWidgetAction,
    {}
> & {
    dataMeta: RecordMeta<DataType, any, any>;
    config: PaginatedWidgetConfig<DataType, ContextType>;
};

export function PaginatedWidget<DataType, ContextType>(
    config: PaginatedWidgetConfig<DataType, ContextType>
): PaginatedWidgetType<DataType, ContextType> {
    return {
        dataMeta: config.dataMeta,
        config,
        initialize(data: DataType, context: ContextType, encoded?: string[]) {
            const pages = config.pages(data);

            const currentPageId =
                encoded && encoded[0] ? encoded[0] : pages[0].id;

            const page = find(pages, (page) => page.id === currentPageId)!;

            const inner = page.widget.initialize(data, context);

            return {
                state: {
                    currentPageState: inner.state,
                    currentPageId,
                },
                data: inner.data,
            };
        },
        reduce(
            state: PaginatedWidgetState<DataType, ContextType>,
            data: DataType,
            action: PaginatedWidgetAction,
            context: ContextType
        ) {
            switch (action.type) {
                case "SELECT_TAB":
                    const page = find(
                        config.pages(data),
                        (page) => page.id === action.pageId
                    )!;
                    const inner = page.widget.initialize(data, context);
                    return {
                        state: {
                            ...state,
                            currentPageId: action.pageId,
                            currentPageState: inner.state,
                        },
                        data: inner.data,
                    };
                case "PAGE":
                    if (state.currentPageId === action.pageId) {
                        const page = find(
                            config.pages(data),
                            (page) => page.id === action.pageId
                        );
                        if (!page) {
                            throw new Error("Current tab is missing");
                        }
                        const inner = page.widget.reduce(
                            state.currentPageState,
                            data,
                            action.action,
                            context
                        );
                        return {
                            state: {
                                ...state,
                                currentPageState: inner.state,
                            },
                            data: inner.data,
                        };
                    } else {
                        return {
                            state,
                            data,
                        };
                    }
            }
        },
        validate(data: DataType, cache: QuickCacheApi) {
            const errors = [];
            for (const page of config.pages(data)) {
                const inner = page.widget.validate(data, cache);
                if (inner.length > 0) {
                    errors.push({
                        field: page.id,
                        invalid: some(inner, "invalid"),
                        empty: some(inner, "empty"),
                        detail: inner,
                    });
                }
            }
            if (config.validate) {
                return config.validate(data, cache, errors);
            } else {
                return errors;
            }
        },
        encodeState(state: PaginatedWidgetState<DataType, ContextType>) {
            return [state.currentPageId];
        },
        component(props: Props<DataType, ContextType>) {
            const pages = config.pages(props.data);
            const page = find(
                pages,
                (page) => page.id === props.state.currentPageId
            );
            if (!page) {
                throw new Error("Current tab is missing");
            }

            const accessible: Dictionary<boolean> = {};
            let finished = true;
            for (const page of pages) {
                accessible[page.id] = finished;
                if (
                    finished &&
                    props.status.mutable &&
                    subStatus(props.status, page.id).validation.length > 0
                ) {
                    finished = false;
                }
            }

            return (
                <>
                    <div {...TAB_STYLE}>
                        <page.widget.component
                            state={props.state.currentPageState}
                            data={props.data}
                            dispatch={(action) =>
                                props.dispatch({
                                    type: "PAGE",
                                    pageId: props.state.currentPageId,
                                    action,
                                })
                            }
                            status={subStatus(
                                props.status,
                                props.state.currentPageId
                            )}
                        />
                    </div>
                    <Pagination style={{ marginBottom: "0px" }}>
                        {config.pages(props.data).map((page) => (
                            <Pagination.Item
                                key={page.id}
                                disabled={!accessible[page.id]}
                                active={page.id == props.state.currentPageId}
                                onClick={() =>
                                    props.dispatch({
                                        type: "SELECT_TAB",
                                        pageId: page.id,
                                    })
                                }
                            >
                                {page.title}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </>
            );
        },
    };
}
