import * as React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { TableRow } from "../../clay/widgets/TableRow";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ConsultantTestimonial, CONSULTANT_TESTIMONIAL_META } from "./table";
import { ConsultantWidgetReactContext } from "./widget";

//!RecordWidget
export type ConsultantTestimonialWidgetData = ConsultantTestimonial;

export const ConsultantTestimonialWidgetFields = {
    quotation: TextWidget,
    name: TextWidget,
};

function ConsultantTestimonialWidgetComponent(
    widgets: ConsultantTestimonialWidgetWidgets,
    props: ConsultantTestimonialWidgetProps
) {
    const parentContext = React.useContext(ConsultantWidgetReactContext)!;

    return (
        <TableRow>
            <widgets.quotation />
            <widgets.name />
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type ConsultantTestimonialWidgetContext = WidgetContext<
    typeof ConsultantTestimonialWidgetFields.quotation
> &
    WidgetContext<typeof ConsultantTestimonialWidgetFields.name>;
type ConsultantTestimonialWidgetExtraProps = {};
type ConsultantTestimonialWidgetBaseState = {
    quotation: WidgetState<typeof ConsultantTestimonialWidgetFields.quotation>;
    name: WidgetState<typeof ConsultantTestimonialWidgetFields.name>;
};
export type ConsultantTestimonialWidgetState = ConsultantTestimonialWidgetBaseState;

type BaseConsultantTestimonialWidgetAction =
    | {
          type: "QUOTATION";
          action: WidgetAction<
              typeof ConsultantTestimonialWidgetFields.quotation
          >;
      }
    | {
          type: "NAME";
          action: WidgetAction<typeof ConsultantTestimonialWidgetFields.name>;
      };

export type ConsultantTestimonialWidgetAction = BaseConsultantTestimonialWidgetAction;

export type ConsultantTestimonialWidgetProps = WidgetProps<
    ConsultantTestimonialWidgetState,
    ConsultantTestimonialWidgetData,
    ConsultantTestimonialWidgetAction,
    ConsultantTestimonialWidgetExtraProps
>;

function baseValidateConsultantTestimonialWidget(
    data: ConsultantTestimonialWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ConsultantTestimonialWidgetFields.quotation,
        data.quotation,
        cache,
        "quotation",
        errors
    );
    subvalidate(
        ConsultantTestimonialWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    return errors;
}
function baseConsultantTestimonialWidgetReduce(
    state: ConsultantTestimonialWidgetState,
    data: ConsultantTestimonialWidgetData,
    action: BaseConsultantTestimonialWidgetAction,
    context: ConsultantTestimonialWidgetContext
): WidgetResult<
    ConsultantTestimonialWidgetState,
    ConsultantTestimonialWidgetData
> {
    let subcontext = context;
    switch (action.type) {
        case "QUOTATION": {
            const inner = ConsultantTestimonialWidgetFields.quotation.reduce(
                state.quotation,
                data.quotation,
                action.action,
                subcontext
            );
            return {
                state: { ...state, quotation: inner.state },
                data: { ...data, quotation: inner.data },
            };
        }
        case "NAME": {
            const inner = ConsultantTestimonialWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
    }
}
export type ConsultantTestimonialWidgetReactContextType = {
    state: ConsultantTestimonialWidgetState;
    data: ConsultantTestimonialWidgetData;
    dispatch: (action: ConsultantTestimonialWidgetAction) => void;
    status: WidgetStatus;
};
export const ConsultantTestimonialWidgetReactContext = React.createContext<
    ConsultantTestimonialWidgetReactContextType | undefined
>(undefined);
export const ConsultantTestimonialWidgetWidgets = {
    quotation: function (
        props: WidgetExtraProps<
            typeof ConsultantTestimonialWidgetFields.quotation
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantTestimonialWidgetReactContext
        ) as ConsultantTestimonialWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantTestimonialWidgetFields.quotation
                >
            ) => context.dispatch({ type: "QUOTATION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "quotation", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantTestimonialWidgetFields.quotation.component
                state={context.state.quotation}
                data={context.data.quotation}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Quotation"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<
            typeof ConsultantTestimonialWidgetFields.name
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantTestimonialWidgetReactContext
        ) as ConsultantTestimonialWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantTestimonialWidgetFields.name
                >
            ) => context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantTestimonialWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
};
export const ConsultantTestimonialWidget: RecordWidget<
    ConsultantTestimonialWidgetState,
    ConsultantTestimonialWidgetData,
    ConsultantTestimonialWidgetContext,
    ConsultantTestimonialWidgetAction,
    ConsultantTestimonialWidgetExtraProps
> = {
    reactContext: ConsultantTestimonialWidgetReactContext,
    fieldWidgets: ConsultantTestimonialWidgetWidgets,
    dataMeta: CONSULTANT_TESTIMONIAL_META,
    initialize(
        data: ConsultantTestimonialWidgetData,
        context: ConsultantTestimonialWidgetContext,
        parameters?: string[]
    ): WidgetResult<
        ConsultantTestimonialWidgetState,
        ConsultantTestimonialWidgetData
    > {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerQuotation = ConsultantTestimonialWidgetFields.quotation.initialize(
            data.quotation,
            subcontext,
            subparameters.quotation
        );
        const innerName = ConsultantTestimonialWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        let state = {
            quotation: innerQuotation.state,
            name: innerName.state,
        };
        return {
            state,
            data: {
                ...data,
                quotation: innerQuotation.data,
                name: innerName.data,
            },
        };
    },
    validate: baseValidateConsultantTestimonialWidget,
    component: React.memo((props: ConsultantTestimonialWidgetProps) => {
        return (
            <ConsultantTestimonialWidgetReactContext.Provider value={props}>
                {ConsultantTestimonialWidgetComponent(
                    ConsultantTestimonialWidgetWidgets,
                    props
                )}
            </ConsultantTestimonialWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseConsultantTestimonialWidgetReduce,
};

type ConsultantTestimonialWidgetWidgets = {
    quotation: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantTestimonialWidgetFields.quotation
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantTestimonialWidgetFields.name
        >
    >;
};
// END MAGIC -- DO NOT EDIT
