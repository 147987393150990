import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import { AiArticle } from "../articles/table";
import { AiPerson } from "../person/table";

//!Data
export type AiHomePage = {
    id: UUID;
    recordVersion: Version;

    currentFeature: Link<AiArticle>;
    blurb: string;
    featuredPeople: Link<AiPerson>[];
    promotionalArticles: Link<AiArticle>[];
    footerArticles: Link<AiArticle>[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiHomePageJSON = {
    id: string;
    recordVersion: number | null;
    currentFeature: string | null;
    blurb: string;
    featuredPeople: (string | null)[];
    promotionalArticles: (string | null)[];
    footerArticles: (string | null)[];
};

export function JSONToAiHomePage(json: AiHomePageJSON): AiHomePage {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        currentFeature: json.currentFeature,
        blurb: json.blurb,
        featuredPeople: json.featuredPeople.map((inner) => inner),
        promotionalArticles: json.promotionalArticles.map((inner) => inner),
        footerArticles: json.footerArticles.map((inner) => inner),
    };
}
export type AiHomePageBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    currentFeature?: string | null;
    blurb?: string;
    featuredPeople?: (string | null)[];
    promotionalArticles?: (string | null)[];
    footerArticles?: (string | null)[];
};

export function newAiHomePage(): AiHomePage {
    return JSONToAiHomePage(repairAiHomePageJSON(undefined));
}
export function repairAiHomePageJSON(
    json: AiHomePageBrokenJSON | undefined
): AiHomePageJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            currentFeature: json.currentFeature || null,
            blurb: json.blurb || "",
            featuredPeople: (json.featuredPeople || []).map(
                (inner) => inner || null
            ),
            promotionalArticles: (json.promotionalArticles || []).map(
                (inner) => inner || null
            ),
            footerArticles: (json.footerArticles || []).map(
                (inner) => inner || null
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            currentFeature: undefined || null,
            blurb: undefined || "",
            featuredPeople: (undefined || []).map((inner) => inner || null),
            promotionalArticles: (undefined || []).map(
                (inner) => inner || null
            ),
            footerArticles: (undefined || []).map((inner) => inner || null),
        };
    }
}

export function AiHomePageToJSON(value: AiHomePage): AiHomePageJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        currentFeature: value.currentFeature,
        blurb: value.blurb,
        featuredPeople: value.featuredPeople.map((inner) => inner),
        promotionalArticles: value.promotionalArticles.map((inner) => inner),
        footerArticles: value.footerArticles.map((inner) => inner),
    };
}

export const AI_HOME_PAGE_META: RecordMeta<
    AiHomePage,
    AiHomePageJSON,
    AiHomePageBrokenJSON
> = {
    name: "AiHomePage",
    type: "record",
    repair: repairAiHomePageJSON,
    toJSON: AiHomePageToJSON,
    fromJSON: JSONToAiHomePage,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        currentFeature: { type: "uuid", linkTo: "AiArticle" },
        blurb: { type: "string" },
        featuredPeople: {
            type: "array",
            items: { type: "uuid", linkTo: "AiPerson" },
        },
        promotionalArticles: {
            type: "array",
            items: { type: "uuid", linkTo: "AiArticle" },
        },
        footerArticles: {
            type: "array",
            items: { type: "uuid", linkTo: "AiArticle" },
        },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
