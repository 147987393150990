import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { SelectLinkWidget } from "../../../clay/widgets/SelectLinkWidget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AI_DISCIPLINE_META } from "../disciplines/table";
import { AccreditationAgency, ACCREDITATION_AGENCY_META } from "./table";

//!RecordWidget
export type AccreditationAgencyWidgetData = AccreditationAgency;

export const AccreditationAgencyWidgetFields = {
    agencyId: TextWidget,
    name: TextWidget,
    category: SelectWidget([
        {
            value: "national",
            label: "National",
        },
        {
            value: "regional",
            label: "Regional",
        },
        {
            value: "programmatic",
            label: "Programmatic",
        },
    ]),
    discipline: SelectLinkWidget({
        meta: AI_DISCIPLINE_META,
        label: (discipline) => discipline.name,
    }),
};

function AccreditationAgencyWidgetComponent(
    widget: AccreditationAgencyWidgetWidgets,
    props: AccreditationAgencyWidgetProps
) {
    throw new Error("unreachable");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type AccreditationAgencyWidgetContext = WidgetContext<
    typeof AccreditationAgencyWidgetFields.agencyId
> &
    WidgetContext<typeof AccreditationAgencyWidgetFields.name> &
    WidgetContext<typeof AccreditationAgencyWidgetFields.category> &
    WidgetContext<typeof AccreditationAgencyWidgetFields.discipline>;
type AccreditationAgencyWidgetExtraProps = {};
type AccreditationAgencyWidgetBaseState = {
    agencyId: WidgetState<typeof AccreditationAgencyWidgetFields.agencyId>;
    name: WidgetState<typeof AccreditationAgencyWidgetFields.name>;
    category: WidgetState<typeof AccreditationAgencyWidgetFields.category>;
    discipline: WidgetState<typeof AccreditationAgencyWidgetFields.discipline>;
};
export type AccreditationAgencyWidgetState = AccreditationAgencyWidgetBaseState;

type BaseAccreditationAgencyWidgetAction =
    | {
          type: "AGENCY_ID";
          action: WidgetAction<typeof AccreditationAgencyWidgetFields.agencyId>;
      }
    | {
          type: "NAME";
          action: WidgetAction<typeof AccreditationAgencyWidgetFields.name>;
      }
    | {
          type: "CATEGORY";
          action: WidgetAction<typeof AccreditationAgencyWidgetFields.category>;
      }
    | {
          type: "DISCIPLINE";
          action: WidgetAction<
              typeof AccreditationAgencyWidgetFields.discipline
          >;
      };

export type AccreditationAgencyWidgetAction = BaseAccreditationAgencyWidgetAction;

export type AccreditationAgencyWidgetProps = WidgetProps<
    AccreditationAgencyWidgetState,
    AccreditationAgencyWidgetData,
    AccreditationAgencyWidgetAction,
    AccreditationAgencyWidgetExtraProps
>;

function baseValidateAccreditationAgencyWidget(
    data: AccreditationAgencyWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AccreditationAgencyWidgetFields.agencyId,
        data.agencyId,
        cache,
        "agencyId",
        errors
    );
    subvalidate(
        AccreditationAgencyWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        AccreditationAgencyWidgetFields.category,
        data.category,
        cache,
        "category",
        errors
    );
    subvalidate(
        AccreditationAgencyWidgetFields.discipline,
        data.discipline,
        cache,
        "discipline",
        errors
    );
    return errors;
}
function baseAccreditationAgencyWidgetReduce(
    state: AccreditationAgencyWidgetState,
    data: AccreditationAgencyWidgetData,
    action: BaseAccreditationAgencyWidgetAction,
    context: AccreditationAgencyWidgetContext
): WidgetResult<AccreditationAgencyWidgetState, AccreditationAgencyWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "AGENCY_ID": {
            const inner = AccreditationAgencyWidgetFields.agencyId.reduce(
                state.agencyId,
                data.agencyId,
                action.action,
                subcontext
            );
            return {
                state: { ...state, agencyId: inner.state },
                data: { ...data, agencyId: inner.data },
            };
        }
        case "NAME": {
            const inner = AccreditationAgencyWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "CATEGORY": {
            const inner = AccreditationAgencyWidgetFields.category.reduce(
                state.category,
                data.category,
                action.action,
                subcontext
            );
            return {
                state: { ...state, category: inner.state },
                data: { ...data, category: inner.data },
            };
        }
        case "DISCIPLINE": {
            const inner = AccreditationAgencyWidgetFields.discipline.reduce(
                state.discipline,
                data.discipline,
                action.action,
                subcontext
            );
            return {
                state: { ...state, discipline: inner.state },
                data: { ...data, discipline: inner.data },
            };
        }
    }
}
export type AccreditationAgencyWidgetReactContextType = {
    state: AccreditationAgencyWidgetState;
    data: AccreditationAgencyWidgetData;
    dispatch: (action: AccreditationAgencyWidgetAction) => void;
    status: WidgetStatus;
};
export const AccreditationAgencyWidgetReactContext = React.createContext<
    AccreditationAgencyWidgetReactContextType | undefined
>(undefined);
export const AccreditationAgencyWidgetWidgets = {
    agencyId: function (
        props: WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.agencyId
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AccreditationAgencyWidgetReactContext
        ) as AccreditationAgencyWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AccreditationAgencyWidgetFields.agencyId
                >
            ) => context.dispatch({ type: "AGENCY_ID", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "agencyId", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AccreditationAgencyWidgetFields.agencyId.component
                state={context.state.agencyId}
                data={context.data.agencyId}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Agency Id"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<typeof AccreditationAgencyWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AccreditationAgencyWidgetReactContext
        ) as AccreditationAgencyWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AccreditationAgencyWidgetFields.name
                >
            ) => context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AccreditationAgencyWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    category: function (
        props: WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.category
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AccreditationAgencyWidgetReactContext
        ) as AccreditationAgencyWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AccreditationAgencyWidgetFields.category
                >
            ) => context.dispatch({ type: "CATEGORY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "category", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AccreditationAgencyWidgetFields.category.component
                state={context.state.category}
                data={context.data.category}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Category"}
            />
        );
    },
    discipline: function (
        props: WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.discipline
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AccreditationAgencyWidgetReactContext
        ) as AccreditationAgencyWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AccreditationAgencyWidgetFields.discipline
                >
            ) => context.dispatch({ type: "DISCIPLINE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "discipline", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AccreditationAgencyWidgetFields.discipline.component
                state={context.state.discipline}
                data={context.data.discipline}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Discipline"}
            />
        );
    },
};
export const AccreditationAgencyWidget: RecordWidget<
    AccreditationAgencyWidgetState,
    AccreditationAgencyWidgetData,
    AccreditationAgencyWidgetContext,
    AccreditationAgencyWidgetAction,
    AccreditationAgencyWidgetExtraProps
> = {
    reactContext: AccreditationAgencyWidgetReactContext,
    fieldWidgets: AccreditationAgencyWidgetWidgets,
    dataMeta: ACCREDITATION_AGENCY_META,
    initialize(
        data: AccreditationAgencyWidgetData,
        context: AccreditationAgencyWidgetContext,
        parameters?: string[]
    ): WidgetResult<
        AccreditationAgencyWidgetState,
        AccreditationAgencyWidgetData
    > {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerAgencyId = AccreditationAgencyWidgetFields.agencyId.initialize(
            data.agencyId,
            subcontext,
            subparameters.agencyId
        );
        const innerName = AccreditationAgencyWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerCategory = AccreditationAgencyWidgetFields.category.initialize(
            data.category,
            subcontext,
            subparameters.category
        );
        const innerDiscipline = AccreditationAgencyWidgetFields.discipline.initialize(
            data.discipline,
            subcontext,
            subparameters.discipline
        );
        let state = {
            agencyId: innerAgencyId.state,
            name: innerName.state,
            category: innerCategory.state,
            discipline: innerDiscipline.state,
        };
        return {
            state,
            data: {
                ...data,
                agencyId: innerAgencyId.data,
                name: innerName.data,
                category: innerCategory.data,
                discipline: innerDiscipline.data,
            },
        };
    },
    validate: baseValidateAccreditationAgencyWidget,
    component: React.memo((props: AccreditationAgencyWidgetProps) => {
        return (
            <AccreditationAgencyWidgetReactContext.Provider value={props}>
                {AccreditationAgencyWidgetComponent(
                    AccreditationAgencyWidgetWidgets,
                    props
                )}
            </AccreditationAgencyWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAccreditationAgencyWidgetReduce,
};

type AccreditationAgencyWidgetWidgets = {
    agencyId: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.agencyId
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.name
        >
    >;
    category: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.category
        >
    >;
    discipline: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AccreditationAgencyWidgetFields.discipline
        >
    >;
};
// END MAGIC -- DO NOT EDIT
