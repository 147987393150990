import { RecordHistoryPage } from "../clay/record-history-page";
import { RouterPage } from "../clay/router-page";
import { AccreditationAgenciesPages } from "./ai/accreditation-agency";
import { AdPage } from "./ai/ad";
import { AdGroupsPage } from "./ai/adgroup";
import { AiArticlePage } from "./ai/articles";
import { AiAuthorPage } from "./ai/authors";
import { AiCategoriesPage } from "./ai/categories";
import { AiDisciplinesPage, AiSubdisciplinesPage } from "./ai/disciplines";
import { DocumentsPage } from "./ai/documents";
import { AiHomePagePage } from "./ai/home";
import { AiMenusPage } from "./ai/menu";
import { AiPagesPage } from "./ai/pages";
import { AiPeoplePage } from "./ai/person";
import { RedirectPage } from "./ai/redirect";
import { ReplacementPage } from "./ai/replace";
import {
    AiSchoolDegreeCategoryPage,
    AiSchoolDegreeLevelPage,
    AiSchoolsPage,
} from "./ai/schools";
import { ShortcodesPage } from "./ai/shortcodes";
import {
    ConsultantAffilitationPages,
    ConsultantPage,
    ConsultantTagsPages,
} from "./consultants";
import { HomePage } from "./home-page";
import { RolesPage } from "./roles";
import { SettingsPage } from "./settings";
import { UsersPage } from "./user/browse";
import { ViewsPage } from "./views";

const ADMIN_PAGE = RouterPage({
    users: UsersPage,
    views: ViewsPage,
    roles: RolesPage,
    "record-history": RecordHistoryPage,
    "": SettingsPage,
    "degree-categories": AiSchoolDegreeCategoryPage,
    "degree-levels": AiSchoolDegreeLevelPage,
});

const AI_PAGE = RouterPage({
    categories: AiCategoriesPage,
    disciplines: AiDisciplinesPage,
    subdisciplines: AiSubdisciplinesPage,
    shortcodes: ShortcodesPage,
    articles: AiArticlePage,
    home: AiHomePagePage,
    schools: AiSchoolsPage,
    people: AiPeoplePage,
    pages: AiPagesPage,
    authors: AiAuthorPage,
    menus: AiMenusPage,
    documents: DocumentsPage,
    "accreditation-agencies": AccreditationAgenciesPages,
    ads: AdPage,
    "ad-groups": AdGroupsPage,
    redirects: RedirectPage,
    replacements: ReplacementPage,
    "consultant-affiliations": ConsultantAffilitationPages,
    consultants: ConsultantPage,
    "consultant-tags": ConsultantTagsPages,
});

export const ROOT_PAGE = RouterPage({
    admin: ADMIN_PAGE,
    ai: AI_PAGE,
    "": HomePage,
});
