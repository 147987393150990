import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { AiShortcode, AI_SHORTCODE_META } from "./table";

//!RecordWidget
export type AiShortcodeWidgetData = AiShortcode;

export const AiShortcodeWidgetFields = {
    name: FormField(TextWidget),
    content: HtmlEditorWidget,
};

function AiShortcodeWidgetComponent(
    widgets: AiShortcodeWidgetWidgets,
    props: AiShortcodeWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.content />
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiShortcodeWidgetContext = WidgetContext<
    typeof AiShortcodeWidgetFields.name
> &
    WidgetContext<typeof AiShortcodeWidgetFields.content>;
type AiShortcodeWidgetExtraProps = {};
type AiShortcodeWidgetBaseState = {
    name: WidgetState<typeof AiShortcodeWidgetFields.name>;
    content: WidgetState<typeof AiShortcodeWidgetFields.content>;
};
export type AiShortcodeWidgetState = AiShortcodeWidgetBaseState;

type BaseAiShortcodeWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof AiShortcodeWidgetFields.name>;
      }
    | {
          type: "CONTENT";
          action: WidgetAction<typeof AiShortcodeWidgetFields.content>;
      };

export type AiShortcodeWidgetAction = BaseAiShortcodeWidgetAction;

export type AiShortcodeWidgetProps = WidgetProps<
    AiShortcodeWidgetState,
    AiShortcodeWidgetData,
    AiShortcodeWidgetAction,
    AiShortcodeWidgetExtraProps
>;

function baseValidateAiShortcodeWidget(
    data: AiShortcodeWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiShortcodeWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        AiShortcodeWidgetFields.content,
        data.content,
        cache,
        "content",
        errors
    );
    return errors;
}
function baseAiShortcodeWidgetReduce(
    state: AiShortcodeWidgetState,
    data: AiShortcodeWidgetData,
    action: BaseAiShortcodeWidgetAction,
    context: AiShortcodeWidgetContext
): WidgetResult<AiShortcodeWidgetState, AiShortcodeWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiShortcodeWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "CONTENT": {
            const inner = AiShortcodeWidgetFields.content.reduce(
                state.content,
                data.content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, content: inner.state },
                data: { ...data, content: inner.data },
            };
        }
    }
}
export type AiShortcodeWidgetReactContextType = {
    state: AiShortcodeWidgetState;
    data: AiShortcodeWidgetData;
    dispatch: (action: AiShortcodeWidgetAction) => void;
    status: WidgetStatus;
};
export const AiShortcodeWidgetReactContext = React.createContext<
    AiShortcodeWidgetReactContextType | undefined
>(undefined);
export const AiShortcodeWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiShortcodeWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiShortcodeWidgetReactContext
        ) as AiShortcodeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiShortcodeWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiShortcodeWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    content: function (
        props: WidgetExtraProps<typeof AiShortcodeWidgetFields.content> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiShortcodeWidgetReactContext
        ) as AiShortcodeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiShortcodeWidgetFields.content>) =>
                context.dispatch({ type: "CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiShortcodeWidgetFields.content.component
                state={context.state.content}
                data={context.data.content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Content"}
            />
        );
    },
};
export const AiShortcodeWidget: RecordWidget<
    AiShortcodeWidgetState,
    AiShortcodeWidgetData,
    AiShortcodeWidgetContext,
    AiShortcodeWidgetAction,
    AiShortcodeWidgetExtraProps
> = {
    reactContext: AiShortcodeWidgetReactContext,
    fieldWidgets: AiShortcodeWidgetWidgets,
    dataMeta: AI_SHORTCODE_META,
    initialize(
        data: AiShortcodeWidgetData,
        context: AiShortcodeWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiShortcodeWidgetState, AiShortcodeWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiShortcodeWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerContent = AiShortcodeWidgetFields.content.initialize(
            data.content,
            subcontext,
            subparameters.content
        );
        let state = {
            name: innerName.state,
            content: innerContent.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                content: innerContent.data,
            },
        };
    },
    validate: baseValidateAiShortcodeWidget,
    component: React.memo((props: AiShortcodeWidgetProps) => {
        return (
            <AiShortcodeWidgetReactContext.Provider value={props}>
                {AiShortcodeWidgetComponent(AiShortcodeWidgetWidgets, props)}
            </AiShortcodeWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiShortcodeWidgetReduce,
};

type AiShortcodeWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiShortcodeWidgetFields.name
        >
    >;
    content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiShortcodeWidgetFields.content
        >
    >;
};
// END MAGIC -- DO NOT EDIT
