import { faCogs } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { PagesPage } from "./pages-page";

const BUTTONS = [
    {
        href: "/#/admin/",
        title: "Settings",
        icon: faCogs,
    },
];

export const HomePage = {
    ...PagesPage({
        title: "Dashboard",
        buttons: BUTTONS,
    }),
    headerComponent() {
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ flexGrow: 1 }} />
            </>
        );
    },
};
