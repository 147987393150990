import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import { AiDiscipline, AiSubdiscipline } from "../disciplines/table";
import { AiSchool } from "../schools/table";

//!Data
export type AiPersonWork = {
    label: string;
    isbn: string;
    active: boolean;
    amazonLink: string;
    imageUrl: string;
    source: string;
};

//!Data
export type AiPersonDegree = {
    school: Link<AiSchool>;
    degree: "B" | "M" | "D" | "PhD";
    field: string;
};

//!Data
export type AiPerson = {
    id: UUID;
    recordVersion: Version;
    name: string;
    active: boolean;
    wikidata_id: string;
    slug: string;
    description: string;
    short_description: string;
    meta_description: string;
    extra_content: string;
    image_url: string;
    image_source_url: string;
    works: AiPersonWork[];
    hide_wikipedia: boolean;
    hide_citations: boolean;

    disciplines: Link<AiDiscipline>[];
    subdisciplines: Link<AiSubdiscipline>[];
    schools: Link<AiSchool>[];
    employerSchools: Link<AiSchool>[];
    degrees: AiPersonDegree[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiPersonWorkJSON = {
    label: string;
    isbn: string;
    active: boolean;
    amazonLink: string;
    imageUrl: string;
    source: string;
};

export function JSONToAiPersonWork(json: AiPersonWorkJSON): AiPersonWork {
    return {
        label: json.label,
        isbn: json.isbn,
        active: json.active,
        amazonLink: json.amazonLink,
        imageUrl: json.imageUrl,
        source: json.source,
    };
}
export type AiPersonWorkBrokenJSON = {
    label?: string;
    isbn?: string;
    active?: boolean;
    amazonLink?: string;
    imageUrl?: string;
    source?: string;
};

export function newAiPersonWork(): AiPersonWork {
    return JSONToAiPersonWork(repairAiPersonWorkJSON(undefined));
}
export function repairAiPersonWorkJSON(
    json: AiPersonWorkBrokenJSON | undefined
): AiPersonWorkJSON {
    if (json) {
        return {
            label: json.label || "",
            isbn: json.isbn || "",
            active: json.active || false,
            amazonLink: json.amazonLink || "",
            imageUrl: json.imageUrl || "",
            source: json.source || "",
        };
    } else {
        return {
            label: undefined || "",
            isbn: undefined || "",
            active: undefined || false,
            amazonLink: undefined || "",
            imageUrl: undefined || "",
            source: undefined || "",
        };
    }
}

export function AiPersonWorkToJSON(value: AiPersonWork): AiPersonWorkJSON {
    return {
        label: value.label,
        isbn: value.isbn,
        active: value.active,
        amazonLink: value.amazonLink,
        imageUrl: value.imageUrl,
        source: value.source,
    };
}

export const AI_PERSON_WORK_META: RecordMeta<
    AiPersonWork,
    AiPersonWorkJSON,
    AiPersonWorkBrokenJSON
> = {
    name: "AiPersonWork",
    type: "record",
    repair: repairAiPersonWorkJSON,
    toJSON: AiPersonWorkToJSON,
    fromJSON: JSONToAiPersonWork,
    fields: {
        label: { type: "string" },
        isbn: { type: "string" },
        active: { type: "boolean" },
        amazonLink: { type: "string" },
        imageUrl: { type: "string" },
        source: { type: "string" },
    },
    userFacingKey: "source",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiPersonDegreeJSON = {
    school: string | null;
    degree: string;
    field: string;
};

export function JSONToAiPersonDegree(json: AiPersonDegreeJSON): AiPersonDegree {
    return {
        school: json.school,
        degree: json.degree as any,
        field: json.field,
    };
}
export type AiPersonDegreeBrokenJSON = {
    school?: string | null;
    degree?: string;
    field?: string;
};

export function newAiPersonDegree(): AiPersonDegree {
    return JSONToAiPersonDegree(repairAiPersonDegreeJSON(undefined));
}
export function repairAiPersonDegreeJSON(
    json: AiPersonDegreeBrokenJSON | undefined
): AiPersonDegreeJSON {
    if (json) {
        return {
            school: json.school || null,
            degree: json.degree || "B",
            field: json.field || "",
        };
    } else {
        return {
            school: undefined || null,
            degree: undefined || "B",
            field: undefined || "",
        };
    }
}

export function AiPersonDegreeToJSON(
    value: AiPersonDegree
): AiPersonDegreeJSON {
    return {
        school: value.school,
        degree: value.degree,
        field: value.field,
    };
}

export const AI_PERSON_DEGREE_META: RecordMeta<
    AiPersonDegree,
    AiPersonDegreeJSON,
    AiPersonDegreeBrokenJSON
> = {
    name: "AiPersonDegree",
    type: "record",
    repair: repairAiPersonDegreeJSON,
    toJSON: AiPersonDegreeToJSON,
    fromJSON: JSONToAiPersonDegree,
    fields: {
        school: { type: "uuid", linkTo: "AiSchool" },
        degree: {
            type: "enum",
            values: ["B", "M", "D", "PhD"],
        },
        field: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiPersonJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    active: boolean;
    wikidata_id: string;
    slug: string;
    description: string;
    short_description: string;
    meta_description: string;
    extra_content: string;
    image_url: string;
    image_source_url: string;
    works: AiPersonWorkJSON[];
    hide_wikipedia: boolean;
    hide_citations: boolean;
    disciplines: (string | null)[];
    subdisciplines: (string | null)[];
    schools: (string | null)[];
    employerSchools: (string | null)[];
    degrees: AiPersonDegreeJSON[];
};

export function JSONToAiPerson(json: AiPersonJSON): AiPerson {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        active: json.active,
        wikidata_id: json.wikidata_id,
        slug: json.slug,
        description: json.description,
        short_description: json.short_description,
        meta_description: json.meta_description,
        extra_content: json.extra_content,
        image_url: json.image_url,
        image_source_url: json.image_source_url,
        works: json.works.map((inner) => JSONToAiPersonWork(inner)),
        hide_wikipedia: json.hide_wikipedia,
        hide_citations: json.hide_citations,
        disciplines: json.disciplines.map((inner) => inner),
        subdisciplines: json.subdisciplines.map((inner) => inner),
        schools: json.schools.map((inner) => inner),
        employerSchools: json.employerSchools.map((inner) => inner),
        degrees: json.degrees.map((inner) => JSONToAiPersonDegree(inner)),
    };
}
export type AiPersonBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    active?: boolean;
    wikidata_id?: string;
    slug?: string;
    description?: string;
    short_description?: string;
    meta_description?: string;
    extra_content?: string;
    image_url?: string;
    image_source_url?: string;
    works?: AiPersonWorkJSON[];
    hide_wikipedia?: boolean;
    hide_citations?: boolean;
    disciplines?: (string | null)[];
    subdisciplines?: (string | null)[];
    schools?: (string | null)[];
    employerSchools?: (string | null)[];
    degrees?: AiPersonDegreeJSON[];
};

export function newAiPerson(): AiPerson {
    return JSONToAiPerson(repairAiPersonJSON(undefined));
}
export function repairAiPersonJSON(
    json: AiPersonBrokenJSON | undefined
): AiPersonJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            active: json.active || false,
            wikidata_id: json.wikidata_id || "",
            slug: json.slug || "",
            description: json.description || "",
            short_description: json.short_description || "",
            meta_description: json.meta_description || "",
            extra_content: json.extra_content || "",
            image_url: json.image_url || "",
            image_source_url: json.image_source_url || "",
            works: (json.works || []).map((inner) =>
                repairAiPersonWorkJSON(inner)
            ),
            hide_wikipedia: json.hide_wikipedia || false,
            hide_citations: json.hide_citations || false,
            disciplines: (json.disciplines || []).map((inner) => inner || null),
            subdisciplines: (json.subdisciplines || []).map(
                (inner) => inner || null
            ),
            schools: (json.schools || []).map((inner) => inner || null),
            employerSchools: (json.employerSchools || []).map(
                (inner) => inner || null
            ),
            degrees: (json.degrees || []).map((inner) =>
                repairAiPersonDegreeJSON(inner)
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            active: undefined || false,
            wikidata_id: undefined || "",
            slug: undefined || "",
            description: undefined || "",
            short_description: undefined || "",
            meta_description: undefined || "",
            extra_content: undefined || "",
            image_url: undefined || "",
            image_source_url: undefined || "",
            works: (undefined || []).map((inner) =>
                repairAiPersonWorkJSON(inner)
            ),
            hide_wikipedia: undefined || false,
            hide_citations: undefined || false,
            disciplines: (undefined || []).map((inner) => inner || null),
            subdisciplines: (undefined || []).map((inner) => inner || null),
            schools: (undefined || []).map((inner) => inner || null),
            employerSchools: (undefined || []).map((inner) => inner || null),
            degrees: (undefined || []).map((inner) =>
                repairAiPersonDegreeJSON(inner)
            ),
        };
    }
}

export function AiPersonToJSON(value: AiPerson): AiPersonJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        active: value.active,
        wikidata_id: value.wikidata_id,
        slug: value.slug,
        description: value.description,
        short_description: value.short_description,
        meta_description: value.meta_description,
        extra_content: value.extra_content,
        image_url: value.image_url,
        image_source_url: value.image_source_url,
        works: value.works.map((inner) => AiPersonWorkToJSON(inner)),
        hide_wikipedia: value.hide_wikipedia,
        hide_citations: value.hide_citations,
        disciplines: value.disciplines.map((inner) => inner),
        subdisciplines: value.subdisciplines.map((inner) => inner),
        schools: value.schools.map((inner) => inner),
        employerSchools: value.employerSchools.map((inner) => inner),
        degrees: value.degrees.map((inner) => AiPersonDegreeToJSON(inner)),
    };
}

export const AI_PERSON_META: RecordMeta<
    AiPerson,
    AiPersonJSON,
    AiPersonBrokenJSON
> = {
    name: "AiPerson",
    type: "record",
    repair: repairAiPersonJSON,
    toJSON: AiPersonToJSON,
    fromJSON: JSONToAiPerson,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        active: { type: "boolean" },
        wikidata_id: { type: "string" },
        slug: { type: "string" },
        description: { type: "string" },
        short_description: { type: "string" },
        meta_description: { type: "string" },
        extra_content: { type: "string" },
        image_url: { type: "string" },
        image_source_url: { type: "string" },
        works: { type: "array", items: AI_PERSON_WORK_META },
        hide_wikipedia: { type: "boolean" },
        hide_citations: { type: "boolean" },
        disciplines: {
            type: "array",
            items: { type: "uuid", linkTo: "AiDiscipline" },
        },
        subdisciplines: {
            type: "array",
            items: { type: "uuid", linkTo: "AiSubdiscipline" },
        },
        schools: { type: "array", items: { type: "uuid", linkTo: "AiSchool" } },
        employerSchools: {
            type: "array",
            items: { type: "uuid", linkTo: "AiSchool" },
        },
        degrees: { type: "array", items: AI_PERSON_DEGREE_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
