import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type AiShortcode = {
    id: UUID;
    recordVersion: Version;

    name: string;
    content: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiShortcodeJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    content: string;
};

export function JSONToAiShortcode(json: AiShortcodeJSON): AiShortcode {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        content: json.content,
    };
}
export type AiShortcodeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    content?: string;
};

export function newAiShortcode(): AiShortcode {
    return JSONToAiShortcode(repairAiShortcodeJSON(undefined));
}
export function repairAiShortcodeJSON(
    json: AiShortcodeBrokenJSON | undefined
): AiShortcodeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            content: json.content || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            content: undefined || "",
        };
    }
}

export function AiShortcodeToJSON(value: AiShortcode): AiShortcodeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        content: value.content,
    };
}

export const AI_SHORTCODE_META: RecordMeta<
    AiShortcode,
    AiShortcodeJSON,
    AiShortcodeBrokenJSON
> = {
    name: "AiShortcode",
    type: "record",
    repair: repairAiShortcodeJSON,
    toJSON: AiShortcodeToJSON,
    fromJSON: JSONToAiShortcode,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        content: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
