import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { titleCase } from "change-case";
import { css } from "glamor";
import { some, uniq } from "lodash-es";
import * as React from "react";
import {
    Accordion,
    Button,
    Card,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";
import ReactSwitch from "react-switch";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { SaveDeleteButton } from "../../clay/save-delete-button";
import { FormField } from "../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { TABLES_META } from "../tables";
import { Role, ROLE_META } from "./table";

//!RecordWidget
export type RoleWidgetData = Role;

const RoleWidgetFields = {
    name: FormField(TextWidget),
};

type PermissionKey = {
    tableName: string;
    permission: string;
};

function parsePermissionKey(key: string): PermissionKey {
    const parts = key.split("-");
    return {
        tableName: parts[0],
        permission: parts[1],
    };
}

export type RoleWidgetExtraActions = {
    type: "TOGGLE_PERMISSON";
    tableName: string;
    permission: string;
};
function roleWidgetReduce(
    state: RoleWidgetState,
    data: RoleWidgetData,
    action: RoleWidgetAction,
    context: RoleWidgetContext
): WidgetResult<RoleWidgetState, RoleWidgetData> {
    switch (action.type) {
        case "TOGGLE_PERMISSON":
            const key = action.tableName + "-" + action.permission;
            if (data.permissions.indexOf(key) === -1) {
                const permissions = [...data.permissions];
                permissions.push(key);
                return {
                    state,
                    data: {
                        ...data,
                        permissions: uniq(permissions),
                    },
                };
            } else {
                return {
                    state,
                    data: {
                        ...data,
                        permissions: data.permissions.filter(
                            (permission) => permission !== key
                        ),
                    },
                };
            }
        default:
            return baseRoleWidgetReduce(state, data, action, context);
    }
}

function permissionsForTable(table: string) {
    const permissions = [
        {
            key: "read",
            label: "Read",
        },
        {
            key: "write",
            label: "Write",
        },
        {
            key: "delete",
            label: "Delete",
        },
        {
            key: "new",
            label: "New",
        },
        {
            key: "unlock",
            label: "Unlock",
        },
        {
            key: "export",
            label: "Export to Excel",
        },
    ];

    return permissions;
}

const PERMISSON_LABEL_STYLE = css({
    paddingLeft: ".25in",
    lineHeight: "28px",
    verticalAlign: "text-bottom",
});

function RoleWidgetComponent(
    widgets: RoleWidgetWidgets,
    props: RoleWidgetProps
) {
    let tables = Object.keys(TABLES_META).slice();
    tables.push("RecordHistory");
    tables.sort();
    return (
        <>
            <widgets.name />

            <Accordion>
                {tables.map((tableName) => (
                    <Card key={tableName}>
                        <Card.Header>
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={tableName}
                            >
                                {titleCase(tableName)}{" "}
                                {some(
                                    permissionsForTable(tableName),
                                    (p) =>
                                        props.data.permissions.indexOf(
                                            tableName + "-" + p.key
                                        ) !== -1
                                ) ? (
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                ) : (
                                    <FontAwesomeIcon icon={faSquare} />
                                )}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={tableName}>
                            <ListGroup>
                                {permissionsForTable(tableName).map(
                                    (permission) => (
                                        <ListGroupItem key={permission.key}>
                                            <ReactSwitch
                                                checked={
                                                    props.data.permissions.indexOf(
                                                        tableName +
                                                            "-" +
                                                            permission.key
                                                    ) !== -1
                                                }
                                                onChange={() =>
                                                    props.dispatch({
                                                        type:
                                                            "TOGGLE_PERMISSON",
                                                        tableName,
                                                        permission:
                                                            permission.key,
                                                    })
                                                }
                                            />
                                            <span {...PERMISSON_LABEL_STYLE}>
                                                {permission.label}
                                            </span>
                                        </ListGroupItem>
                                    )
                                )}
                            </ListGroup>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
            <SaveDeleteButton duplicate />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type RoleWidgetContext = WidgetContext<typeof RoleWidgetFields.name>;
type RoleWidgetExtraProps = {};
type RoleWidgetBaseState = {
    name: WidgetState<typeof RoleWidgetFields.name>;
};
export type RoleWidgetState = RoleWidgetBaseState;

type BaseRoleWidgetAction = {
    type: "NAME";
    action: WidgetAction<typeof RoleWidgetFields.name>;
};

export type RoleWidgetAction = RoleWidgetExtraActions | BaseRoleWidgetAction;

export type RoleWidgetProps = WidgetProps<
    RoleWidgetState,
    RoleWidgetData,
    RoleWidgetAction,
    RoleWidgetExtraProps
>;

function baseValidateRoleWidget(data: RoleWidgetData, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(RoleWidgetFields.name, data.name, cache, "name", errors);
    return errors;
}
function baseRoleWidgetReduce(
    state: RoleWidgetState,
    data: RoleWidgetData,
    action: BaseRoleWidgetAction,
    context: RoleWidgetContext
): WidgetResult<RoleWidgetState, RoleWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = RoleWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
    }
}
export type RoleWidgetReactContextType = {
    state: RoleWidgetState;
    data: RoleWidgetData;
    dispatch: (action: RoleWidgetAction) => void;
    status: WidgetStatus;
};
export const RoleWidgetReactContext = React.createContext<
    RoleWidgetReactContextType | undefined
>(undefined);
export const RoleWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof RoleWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            RoleWidgetReactContext
        ) as RoleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof RoleWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <RoleWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
};
export const RoleWidget: RecordWidget<
    RoleWidgetState,
    RoleWidgetData,
    RoleWidgetContext,
    RoleWidgetAction,
    RoleWidgetExtraProps
> = {
    reactContext: RoleWidgetReactContext,
    fieldWidgets: RoleWidgetWidgets,
    dataMeta: ROLE_META,
    initialize(
        data: RoleWidgetData,
        context: RoleWidgetContext,
        parameters?: string[]
    ): WidgetResult<RoleWidgetState, RoleWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = RoleWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        let state = {
            name: innerName.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
            },
        };
    },
    validate: baseValidateRoleWidget,
    component: React.memo((props: RoleWidgetProps) => {
        return (
            <RoleWidgetReactContext.Provider value={props}>
                {RoleWidgetComponent(RoleWidgetWidgets, props)}
            </RoleWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: roleWidgetReduce,
};

type RoleWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof RoleWidgetFields.name
        >
    >;
};
// END MAGIC -- DO NOT EDIT
