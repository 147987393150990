import { GridWithEditor } from "../../../clay/dataGrid/gridWithEditor";
import { clearCache } from "../cache";
import { RedirectWidget } from "./widget";

export const RedirectPage = GridWithEditor({
    prefix: "#/ai/redirects",
    meta: RedirectWidget,
    makeContext: (context) => context,
    fallbackSorts: ["source"],
    title: (record) => {
        return `Redirect: ${record.source}`;
    },
    postSave: (record) => {
        clearCache("redirects");
    },
});
