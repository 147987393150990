export function clearCache(key: string) {
    fetch(
        "https://staging.academicinfluence.com/api/clearcache?key=" +
            encodeURIComponent(key),
        {
            mode: "no-cors",
            method: "POST",
        }
    );

    fetch(
        "https://academicinfluence.com/api/clearcache?key=" +
            encodeURIComponent(key),
        {
            mode: "no-cors",
            method: "POST",
        }
    );
}

export function clearUrlCache(key: string) {
    clearCache(key);
}
