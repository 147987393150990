import * as React from "react";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../..//clay/widgets/index";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { Optional } from "../../clay/widgets/FormField";
import { TableRow } from "../../clay/widgets/TableRow";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ConsultantContact, CONSULTANT_CONTACT_META } from "./table";
import { ConsultantWidgetReactContext } from "./widget";

//!RecordWidget
export type ConsultantContactWidgetData = ConsultantContact;

export const ConsultantContactWidgetFields = {
    name: TextWidget,
    slug: TextWidget,
    email: TextWidget,
    phone: Optional(TextWidget),
    city: TextWidget,
    state: TextWidget,
    zip: TextWidget,
};

function ConsultantContactWidgetComponent(
    widgets: ConsultantContactWidgetWidgets,
    props: ConsultantContactWidgetProps
) {
    const parentContext = React.useContext(ConsultantWidgetReactContext)!;

    return (
        <TableRow>
            <widgets.name />
            <widgets.city />
            <widgets.state />
            <widgets.zip />
            <widgets.slug />
            <widgets.email />
            <widgets.phone />
            <a
                href={
                    "https://academicinfluence.com/counselors/" +
                    parentContext.data.slug +
                    "/" +
                    props.data.slug +
                    "/connect"
                }
            >
                {"/counselors/" +
                    parentContext.data.slug +
                    "/" +
                    props.data.slug +
                    "/connect"}
            </a>
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type ConsultantContactWidgetContext = WidgetContext<
    typeof ConsultantContactWidgetFields.name
> &
    WidgetContext<typeof ConsultantContactWidgetFields.slug> &
    WidgetContext<typeof ConsultantContactWidgetFields.email> &
    WidgetContext<typeof ConsultantContactWidgetFields.phone> &
    WidgetContext<typeof ConsultantContactWidgetFields.city> &
    WidgetContext<typeof ConsultantContactWidgetFields.state> &
    WidgetContext<typeof ConsultantContactWidgetFields.zip>;
type ConsultantContactWidgetExtraProps = {};
type ConsultantContactWidgetBaseState = {
    name: WidgetState<typeof ConsultantContactWidgetFields.name>;
    slug: WidgetState<typeof ConsultantContactWidgetFields.slug>;
    email: WidgetState<typeof ConsultantContactWidgetFields.email>;
    phone: WidgetState<typeof ConsultantContactWidgetFields.phone>;
    city: WidgetState<typeof ConsultantContactWidgetFields.city>;
    state: WidgetState<typeof ConsultantContactWidgetFields.state>;
    zip: WidgetState<typeof ConsultantContactWidgetFields.zip>;
};
export type ConsultantContactWidgetState = ConsultantContactWidgetBaseState;

type BaseConsultantContactWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof ConsultantContactWidgetFields.name>;
      }
    | {
          type: "SLUG";
          action: WidgetAction<typeof ConsultantContactWidgetFields.slug>;
      }
    | {
          type: "EMAIL";
          action: WidgetAction<typeof ConsultantContactWidgetFields.email>;
      }
    | {
          type: "PHONE";
          action: WidgetAction<typeof ConsultantContactWidgetFields.phone>;
      }
    | {
          type: "CITY";
          action: WidgetAction<typeof ConsultantContactWidgetFields.city>;
      }
    | {
          type: "STATE";
          action: WidgetAction<typeof ConsultantContactWidgetFields.state>;
      }
    | {
          type: "ZIP";
          action: WidgetAction<typeof ConsultantContactWidgetFields.zip>;
      };

export type ConsultantContactWidgetAction = BaseConsultantContactWidgetAction;

export type ConsultantContactWidgetProps = WidgetProps<
    ConsultantContactWidgetState,
    ConsultantContactWidgetData,
    ConsultantContactWidgetAction,
    ConsultantContactWidgetExtraProps
>;

function baseValidateConsultantContactWidget(
    data: ConsultantContactWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ConsultantContactWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.slug,
        data.slug,
        cache,
        "slug",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.email,
        data.email,
        cache,
        "email",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.phone,
        data.phone,
        cache,
        "phone",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.city,
        data.city,
        cache,
        "city",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.state,
        data.state,
        cache,
        "state",
        errors
    );
    subvalidate(
        ConsultantContactWidgetFields.zip,
        data.zip,
        cache,
        "zip",
        errors
    );
    return errors;
}
function baseConsultantContactWidgetReduce(
    state: ConsultantContactWidgetState,
    data: ConsultantContactWidgetData,
    action: BaseConsultantContactWidgetAction,
    context: ConsultantContactWidgetContext
): WidgetResult<ConsultantContactWidgetState, ConsultantContactWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = ConsultantContactWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SLUG": {
            const inner = ConsultantContactWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "EMAIL": {
            const inner = ConsultantContactWidgetFields.email.reduce(
                state.email,
                data.email,
                action.action,
                subcontext
            );
            return {
                state: { ...state, email: inner.state },
                data: { ...data, email: inner.data },
            };
        }
        case "PHONE": {
            const inner = ConsultantContactWidgetFields.phone.reduce(
                state.phone,
                data.phone,
                action.action,
                subcontext
            );
            return {
                state: { ...state, phone: inner.state },
                data: { ...data, phone: inner.data },
            };
        }
        case "CITY": {
            const inner = ConsultantContactWidgetFields.city.reduce(
                state.city,
                data.city,
                action.action,
                subcontext
            );
            return {
                state: { ...state, city: inner.state },
                data: { ...data, city: inner.data },
            };
        }
        case "STATE": {
            const inner = ConsultantContactWidgetFields.state.reduce(
                state.state,
                data.state,
                action.action,
                subcontext
            );
            return {
                state: { ...state, state: inner.state },
                data: { ...data, state: inner.data },
            };
        }
        case "ZIP": {
            const inner = ConsultantContactWidgetFields.zip.reduce(
                state.zip,
                data.zip,
                action.action,
                subcontext
            );
            return {
                state: { ...state, zip: inner.state },
                data: { ...data, zip: inner.data },
            };
        }
    }
}
export type ConsultantContactWidgetReactContextType = {
    state: ConsultantContactWidgetState;
    data: ConsultantContactWidgetData;
    dispatch: (action: ConsultantContactWidgetAction) => void;
    status: WidgetStatus;
};
export const ConsultantContactWidgetReactContext = React.createContext<
    ConsultantContactWidgetReactContextType | undefined
>(undefined);
export const ConsultantContactWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantContactWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantContactWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    email: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.email> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantContactWidgetFields.email>
            ) => context.dispatch({ type: "EMAIL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "email", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.email.component
                state={context.state.email}
                data={context.data.email}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Email"}
            />
        );
    },
    phone: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.phone> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantContactWidgetFields.phone>
            ) => context.dispatch({ type: "PHONE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "phone", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.phone.component
                state={context.state.phone}
                data={context.data.phone}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Phone"}
            />
        );
    },
    city: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.city> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantContactWidgetFields.city>) =>
                context.dispatch({ type: "CITY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "city", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.city.component
                state={context.state.city}
                data={context.data.city}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "City"}
            />
        );
    },
    state: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.state> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantContactWidgetFields.state>
            ) => context.dispatch({ type: "STATE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "state", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.state.component
                state={context.state.state}
                data={context.data.state}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "State"}
            />
        );
    },
    zip: function (
        props: WidgetExtraProps<typeof ConsultantContactWidgetFields.zip> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantContactWidgetReactContext
        ) as ConsultantContactWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantContactWidgetFields.zip>) =>
                context.dispatch({ type: "ZIP", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "zip", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantContactWidgetFields.zip.component
                state={context.state.zip}
                data={context.data.zip}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Zip"}
            />
        );
    },
};
export const ConsultantContactWidget: RecordWidget<
    ConsultantContactWidgetState,
    ConsultantContactWidgetData,
    ConsultantContactWidgetContext,
    ConsultantContactWidgetAction,
    ConsultantContactWidgetExtraProps
> = {
    reactContext: ConsultantContactWidgetReactContext,
    fieldWidgets: ConsultantContactWidgetWidgets,
    dataMeta: CONSULTANT_CONTACT_META,
    initialize(
        data: ConsultantContactWidgetData,
        context: ConsultantContactWidgetContext,
        parameters?: string[]
    ): WidgetResult<ConsultantContactWidgetState, ConsultantContactWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = ConsultantContactWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerSlug = ConsultantContactWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerEmail = ConsultantContactWidgetFields.email.initialize(
            data.email,
            subcontext,
            subparameters.email
        );
        const innerPhone = ConsultantContactWidgetFields.phone.initialize(
            data.phone,
            subcontext,
            subparameters.phone
        );
        const innerCity = ConsultantContactWidgetFields.city.initialize(
            data.city,
            subcontext,
            subparameters.city
        );
        const innerState = ConsultantContactWidgetFields.state.initialize(
            data.state,
            subcontext,
            subparameters.state
        );
        const innerZip = ConsultantContactWidgetFields.zip.initialize(
            data.zip,
            subcontext,
            subparameters.zip
        );
        let state = {
            name: innerName.state,
            slug: innerSlug.state,
            email: innerEmail.state,
            phone: innerPhone.state,
            city: innerCity.state,
            state: innerState.state,
            zip: innerZip.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                slug: innerSlug.data,
                email: innerEmail.data,
                phone: innerPhone.data,
                city: innerCity.data,
                state: innerState.data,
                zip: innerZip.data,
            },
        };
    },
    validate: baseValidateConsultantContactWidget,
    component: React.memo((props: ConsultantContactWidgetProps) => {
        return (
            <ConsultantContactWidgetReactContext.Provider value={props}>
                {ConsultantContactWidgetComponent(
                    ConsultantContactWidgetWidgets,
                    props
                )}
            </ConsultantContactWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseConsultantContactWidgetReduce,
};

type ConsultantContactWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.name
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.slug
        >
    >;
    email: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.email
        >
    >;
    phone: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.phone
        >
    >;
    city: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.city
        >
    >;
    state: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.state
        >
    >;
    zip: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantContactWidgetFields.zip
        >
    >;
};
// END MAGIC -- DO NOT EDIT
