import { AdminTablePage } from "../../clay/admin-table";
import { GridWithEditor } from "../../clay/dataGrid/gridWithEditor";
import { ConsultantAffiliationWidget } from "./affiliation-widget";
import { ConsultantTagWidget } from "./tag-widget";
import { ConsultantWidget } from "./widget";

export const ConsultantAffilitationPages = AdminTablePage({
    rowWidget: ConsultantAffiliationWidget,
    compare: (lhs, rhs) => lhs.acronym.localeCompare(rhs.acronym),
    extraRequests: {
        disciplines: {
            type: "QUERY" as "QUERY",
            request: {
                tableName: "AiDiscipline",
                columns: ["id", "name"],
                sorts: ["name"],
            },
        },
    },
    columns: [
        {
            id: "active",
            label: "Active",
            width: 100,
        },
        {
            id: "acronym",
            label: "Acronym",
            width: 100,
        },
        {
            id: "name",
            label: "Name",
            width: 500,
        },
        {
            id: "url",
            label: "Url",
            width: 500,
        },
    ],
});

export const ConsultantTagsPages = AdminTablePage({
    rowWidget: ConsultantTagWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 500,
        },
    ],
});

export const ConsultantPage = GridWithEditor({
    prefix: "#/ai/consultants",
    meta: ConsultantWidget,
    makeContext: (context) => context,
    fallbackSorts: ["name"],
    title: (record) => {
        return `Consultant: ${record.name}`;
    },
    locked: (record) => {
        return false;
    },
    applyName: (record, name) => ({
        ...record,
        name,
    }),
    postSave: (record) => {
        return [];
    },
});
