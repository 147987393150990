import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TabsWidget } from "../../../clay/widgets/TabsWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiMenuSectionWidget } from "./section-widget";
import { AiSiteMenu, AI_SITE_MENU_META } from "./table";

//!RecordWidget
export type AiSiteMenuWidgetData = AiSiteMenu;

export const AiSiteMenuWidgetFields = {
    name: FormField(TextWidget),
    sections: TabsWidget(AiMenuSectionWidget, {}),
};

function AiSiteMenuWidgetComponent(
    widgets: AiSiteMenuWidgetWidgets,
    props: AiSiteMenuWidgetProps
) {
    const actions = React.useCallback(
        (item: {}, index: number) => {
            return [
                {
                    label: "Remove",
                    action: () => {
                        props.dispatch({
                            type: "SECTIONS",
                            action: {
                                type: "REMOVE",
                                index,
                            },
                        });
                    },
                },
            ];
        },
        [props.dispatch]
    );
    return (
        <>
            <widgets.name />
            <widgets.sections
                actions={actions}
                labelForItem={(item) => item.name}
                newLabel="New Section"
            />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiSiteMenuWidgetContext = WidgetContext<
    typeof AiSiteMenuWidgetFields.name
> &
    WidgetContext<typeof AiSiteMenuWidgetFields.sections>;
type AiSiteMenuWidgetExtraProps = {};
type AiSiteMenuWidgetBaseState = {
    name: WidgetState<typeof AiSiteMenuWidgetFields.name>;
    sections: WidgetState<typeof AiSiteMenuWidgetFields.sections>;
};
export type AiSiteMenuWidgetState = AiSiteMenuWidgetBaseState;

type BaseAiSiteMenuWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AiSiteMenuWidgetFields.name> }
    | {
          type: "SECTIONS";
          action: WidgetAction<typeof AiSiteMenuWidgetFields.sections>;
      };

export type AiSiteMenuWidgetAction = BaseAiSiteMenuWidgetAction;

export type AiSiteMenuWidgetProps = WidgetProps<
    AiSiteMenuWidgetState,
    AiSiteMenuWidgetData,
    AiSiteMenuWidgetAction,
    AiSiteMenuWidgetExtraProps
>;

function baseValidateAiSiteMenuWidget(
    data: AiSiteMenuWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiSiteMenuWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        AiSiteMenuWidgetFields.sections,
        data.sections,
        cache,
        "sections",
        errors
    );
    return errors;
}
function baseAiSiteMenuWidgetReduce(
    state: AiSiteMenuWidgetState,
    data: AiSiteMenuWidgetData,
    action: BaseAiSiteMenuWidgetAction,
    context: AiSiteMenuWidgetContext
): WidgetResult<AiSiteMenuWidgetState, AiSiteMenuWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiSiteMenuWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SECTIONS": {
            const inner = AiSiteMenuWidgetFields.sections.reduce(
                state.sections,
                data.sections,
                action.action,
                subcontext
            );
            return {
                state: { ...state, sections: inner.state },
                data: { ...data, sections: inner.data },
            };
        }
    }
}
export type AiSiteMenuWidgetReactContextType = {
    state: AiSiteMenuWidgetState;
    data: AiSiteMenuWidgetData;
    dispatch: (action: AiSiteMenuWidgetAction) => void;
    status: WidgetStatus;
};
export const AiSiteMenuWidgetReactContext = React.createContext<
    AiSiteMenuWidgetReactContextType | undefined
>(undefined);
export const AiSiteMenuWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiSiteMenuWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSiteMenuWidgetReactContext
        ) as AiSiteMenuWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSiteMenuWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSiteMenuWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    sections: function (
        props: WidgetExtraProps<typeof AiSiteMenuWidgetFields.sections> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSiteMenuWidgetReactContext
        ) as AiSiteMenuWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSiteMenuWidgetFields.sections>) =>
                context.dispatch({ type: "SECTIONS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "sections", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSiteMenuWidgetFields.sections.component
                state={context.state.sections}
                data={context.data.sections}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Sections"}
            />
        );
    },
};
export const AiSiteMenuWidget: RecordWidget<
    AiSiteMenuWidgetState,
    AiSiteMenuWidgetData,
    AiSiteMenuWidgetContext,
    AiSiteMenuWidgetAction,
    AiSiteMenuWidgetExtraProps
> = {
    reactContext: AiSiteMenuWidgetReactContext,
    fieldWidgets: AiSiteMenuWidgetWidgets,
    dataMeta: AI_SITE_MENU_META,
    initialize(
        data: AiSiteMenuWidgetData,
        context: AiSiteMenuWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiSiteMenuWidgetState, AiSiteMenuWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiSiteMenuWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerSections = AiSiteMenuWidgetFields.sections.initialize(
            data.sections,
            subcontext,
            subparameters.sections
        );
        let state = {
            name: innerName.state,
            sections: innerSections.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                sections: innerSections.data,
            },
        };
    },
    validate: baseValidateAiSiteMenuWidget,
    component: React.memo((props: AiSiteMenuWidgetProps) => {
        return (
            <AiSiteMenuWidgetReactContext.Provider value={props}>
                {AiSiteMenuWidgetComponent(AiSiteMenuWidgetWidgets, props)}
            </AiSiteMenuWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiSiteMenuWidgetReduce,
};

type AiSiteMenuWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSiteMenuWidgetFields.name
        >
    >;
    sections: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSiteMenuWidgetFields.sections
        >
    >;
};
// END MAGIC -- DO NOT EDIT
