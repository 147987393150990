import { Decimal } from "decimal.js";
import { Quantity } from "../../../clay/common";
import { LocalDate } from "../../../clay/LocalDate";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../version";

//!Data
export type AdGroupItem = {
    name: string;
    image: string;
    text: string;
    link: string;
    alt: string;
    trackingPixel: string;
    mobileImage: string;
    mobileWidth: Quantity;
    mobileHeight: Quantity;
    width: Quantity;
    height: Quantity;
    disabled: boolean;
    startDate: LocalDate | null;
    endDate: LocalDate | null;
};

//!Data
export type AdGroup = {
    id: UUID;
    recordVersion: Version;
    name: string;
    items: AdGroupItem[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type AdGroupItemJSON = {
    name: string;
    image: string;
    text: string;
    link: string;
    alt: string;
    trackingPixel: string;
    mobileImage: string;
    mobileWidth: string;
    mobileHeight: string;
    width: string;
    height: string;
    disabled: boolean;
    startDate: string | null;
    endDate: string | null;
};

export function JSONToAdGroupItem(json: AdGroupItemJSON): AdGroupItem {
    return {
        name: json.name,
        image: json.image,
        text: json.text,
        link: json.link,
        alt: json.alt,
        trackingPixel: json.trackingPixel,
        mobileImage: json.mobileImage,
        mobileWidth: new Decimal(json.mobileWidth),
        mobileHeight: new Decimal(json.mobileHeight),
        width: new Decimal(json.width),
        height: new Decimal(json.height),
        disabled: json.disabled,
        startDate:
            json.startDate !== null ? LocalDate.parse(json.startDate) : null,
        endDate: json.endDate !== null ? LocalDate.parse(json.endDate) : null,
    };
}
export type AdGroupItemBrokenJSON = {
    name?: string;
    image?: string;
    text?: string;
    link?: string;
    alt?: string;
    trackingPixel?: string;
    mobileImage?: string;
    mobileWidth?: string;
    mobileHeight?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    startDate?: string | null;
    endDate?: string | null;
};

export function newAdGroupItem(): AdGroupItem {
    return JSONToAdGroupItem(repairAdGroupItemJSON(undefined));
}
export function repairAdGroupItemJSON(
    json: AdGroupItemBrokenJSON | undefined
): AdGroupItemJSON {
    if (json) {
        return {
            name: json.name || "",
            image: json.image || "",
            text: json.text || "",
            link: json.link || "",
            alt: json.alt || "",
            trackingPixel: json.trackingPixel || "",
            mobileImage: json.mobileImage || "",
            mobileWidth: json.mobileWidth || "0",
            mobileHeight: json.mobileHeight || "0",
            width: json.width || "0",
            height: json.height || "0",
            disabled: json.disabled || false,
            startDate: json.startDate || null,
            endDate: json.endDate || null,
        };
    } else {
        return {
            name: undefined || "",
            image: undefined || "",
            text: undefined || "",
            link: undefined || "",
            alt: undefined || "",
            trackingPixel: undefined || "",
            mobileImage: undefined || "",
            mobileWidth: undefined || "0",
            mobileHeight: undefined || "0",
            width: undefined || "0",
            height: undefined || "0",
            disabled: undefined || false,
            startDate: undefined || null,
            endDate: undefined || null,
        };
    }
}

export function AdGroupItemToJSON(value: AdGroupItem): AdGroupItemJSON {
    return {
        name: value.name,
        image: value.image,
        text: value.text,
        link: value.link,
        alt: value.alt,
        trackingPixel: value.trackingPixel,
        mobileImage: value.mobileImage,
        mobileWidth: value.mobileWidth.toString(),
        mobileHeight: value.mobileHeight.toString(),
        width: value.width.toString(),
        height: value.height.toString(),
        disabled: value.disabled,
        startDate: value.startDate !== null ? value.startDate.toString() : null,
        endDate: value.endDate !== null ? value.endDate.toString() : null,
    };
}

export const AD_GROUP_ITEM_META: RecordMeta<
    AdGroupItem,
    AdGroupItemJSON,
    AdGroupItemBrokenJSON
> = {
    name: "AdGroupItem",
    type: "record",
    repair: repairAdGroupItemJSON,
    toJSON: AdGroupItemToJSON,
    fromJSON: JSONToAdGroupItem,
    fields: {
        name: { type: "string" },
        image: { type: "string" },
        text: { type: "string" },
        link: { type: "string" },
        alt: { type: "string" },
        trackingPixel: { type: "string" },
        mobileImage: { type: "string" },
        mobileWidth: { type: "quantity" },
        mobileHeight: { type: "quantity" },
        width: { type: "quantity" },
        height: { type: "quantity" },
        disabled: { type: "boolean" },
        startDate: { type: "date" },
        endDate: { type: "date" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AdGroupJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    items: AdGroupItemJSON[];
};

export function JSONToAdGroup(json: AdGroupJSON): AdGroup {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        items: json.items.map((inner) => JSONToAdGroupItem(inner)),
    };
}
export type AdGroupBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    items?: AdGroupItemJSON[];
};

export function newAdGroup(): AdGroup {
    return JSONToAdGroup(repairAdGroupJSON(undefined));
}
export function repairAdGroupJSON(
    json: AdGroupBrokenJSON | undefined
): AdGroupJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            items: (json.items || []).map((inner) =>
                repairAdGroupItemJSON(inner)
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            items: (undefined || []).map((inner) =>
                repairAdGroupItemJSON(inner)
            ),
        };
    }
}

export function AdGroupToJSON(value: AdGroup): AdGroupJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        items: value.items.map((inner) => AdGroupItemToJSON(inner)),
    };
}

export const AD_GROUP_META: RecordMeta<
    AdGroup,
    AdGroupJSON,
    AdGroupBrokenJSON
> = {
    name: "AdGroup",
    type: "record",
    repair: repairAdGroupJSON,
    toJSON: AdGroupToJSON,
    fromJSON: JSONToAdGroup,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        items: { type: "array", items: AD_GROUP_ITEM_META },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
