import { css } from "glamor";

export const CONTENT_AREA = css({
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
    flexGrow: 1,
    ".active": {
        display: "flex",
    },
});

export const TABLE_STYLE = css({
    borderSpacing: "100px 0",
    "& td": {
        padding: "2px 5px",
    },
    "& th": {
        padding: "2px 5px",
    },
    "& thead th": {
        textAlign: "center",
    },
});
