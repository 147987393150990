import { OAUTH_CLIENT_ID } from "../keys";
import { Authentication, AuthenticationUser } from "./service";

interface GoogleUser {
    getBasicProfile(): {
        getEmail(): string;
        getImageUrl(): string;
    };
    getAuthResponse(): {
        id_token: string;
    };
}

let currentToken: string | null = null;

export const GOOGLE_AUTH: Authentication = {
    initialize(userChange: (user: AuthenticationUser | null) => void) {
        const onUserChange = (user: any) => {
            console.log("HI", user);
            if (!user || !user.credential) {
                userChange(null);
                document.getElementById("sign-in-button")!.style.display =
                    "block";
            } else {
                document.getElementById("sign-in-button")!.style.display =
                    "none";
                userChange({
                    id_token: user.credential,
                    image: "",
                    email: "",
                });
            }
        };
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://accounts.google.com/gsi/client";
        scriptTag.onload = () => {
            const gapi = (global as any).google.accounts.id;
            gapi.initialize({
                client_id: OAUTH_CLIENT_ID,
                callback: onUserChange,
                auto_select: true,
            });
            const signInButton = document.createElement("div");
            signInButton.id = "sign-in-button";
            document.body.appendChild(signInButton);
            console.log(
                gapi.renderButton(signInButton, {
                    type: "standard",
                })
            );

            gapi.prompt();
        };
        document.body.appendChild(scriptTag);
    },
    signIn() {
        const gapi = (global as any).google.accounts.id;
        console.log("TRY");
        gapi.prompt((hello: any) => console.log(hello));
    },
    signOut() {},
    getToken() {
        return currentToken;
    },
};
