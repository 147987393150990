import { Decimal } from "decimal.js";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { BareRequestHandle, QueryRequest } from "../../clay/requests";
import { SaveDeleteButton } from "../../clay/save-delete-button";
import { FormField } from "../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { LabelledBoolWidget } from "../../clay/widgets/labelled-bool-widget";
import { ListWidget } from "../../clay/widgets/ListWidget";
import { SwitchWidget } from "../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { CONTENT_AREA, TABLE_STYLE } from "../styles";
import { ColumnPickerWidget } from "./column-picker-widget";
import { ColumnWidget } from "./column-widget";
import { View, VIEW_META } from "./table";

//!RecordWidget
export type ViewWidgetData = View;

export const ViewWidgetFields = {
    name: FormField(TextWidget),
    default: FormField(SwitchWidget),
    columns: ListWidget(ColumnWidget, {
        adaptEmpty: (column) => ({
            ...column,
            width: new Decimal("200"),
        }),
    }),
    defaultSortColumn: FormField(ColumnPickerWidget),
    defaultSortDirection: FormField(
        LabelledBoolWidget("Descending", "Ascending")
    ),
};

function ViewWidgetRequests(
    state: ViewWidgetState,
    data: View,
    context: ViewWidgetContext
): Dictionary<BareRequestHandle<QueryRequest>> {
    return {};
}

function ViewWidgetComponent(
    widgets: ViewWidgetWidgets,
    props: ViewWidgetProps
) {
    return (
        <>
            <Row>
                <Col>
                    <widgets.name />
                </Col>
                <Col>
                    <widgets.defaultSortColumn table={props.data.table} />
                </Col>
                <Col>
                    <widgets.defaultSortDirection />
                </Col>
                <Col>
                    <widgets.default label="Default View" />
                </Col>
            </Row>
            <div {...CONTENT_AREA}>
                <table {...TABLE_STYLE}>
                    <thead>
                        <tr>
                            <th />
                            <th>Column</th>
                            <th>Name</th>
                            <th>Width</th>
                            <th>Filter</th>
                        </tr>
                    </thead>

                    <widgets.columns
                        extraItemForAdd
                        containerClass="tbody"
                        itemProps={{ table: props.data.table }}
                    />
                </table>
            </div>

            <SaveDeleteButton noun="View" duplicate />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type ViewWidgetContext = WidgetContext<typeof ViewWidgetFields.name> &
    WidgetContext<typeof ViewWidgetFields.default> &
    WidgetContext<typeof ViewWidgetFields.columns> &
    WidgetContext<typeof ViewWidgetFields.defaultSortColumn> &
    WidgetContext<typeof ViewWidgetFields.defaultSortDirection>;
type ViewWidgetExtraProps = {};
type ViewWidgetBaseState = {
    name: WidgetState<typeof ViewWidgetFields.name>;
    default: WidgetState<typeof ViewWidgetFields.default>;
    columns: WidgetState<typeof ViewWidgetFields.columns>;
    defaultSortColumn: WidgetState<typeof ViewWidgetFields.defaultSortColumn>;
    defaultSortDirection: WidgetState<
        typeof ViewWidgetFields.defaultSortDirection
    >;
};
export type ViewWidgetState = ViewWidgetBaseState;

type BaseViewWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof ViewWidgetFields.name> }
    | { type: "DEFAULT"; action: WidgetAction<typeof ViewWidgetFields.default> }
    | { type: "COLUMNS"; action: WidgetAction<typeof ViewWidgetFields.columns> }
    | {
          type: "DEFAULT_SORT_COLUMN";
          action: WidgetAction<typeof ViewWidgetFields.defaultSortColumn>;
      }
    | {
          type: "DEFAULT_SORT_DIRECTION";
          action: WidgetAction<typeof ViewWidgetFields.defaultSortDirection>;
      };

export type ViewWidgetAction = BaseViewWidgetAction;

export type ViewWidgetProps = WidgetProps<
    ViewWidgetState,
    ViewWidgetData,
    ViewWidgetAction,
    ViewWidgetExtraProps
>;

function baseValidateViewWidget(data: ViewWidgetData, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(ViewWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        ViewWidgetFields.default,
        data.default,
        cache,
        "default",
        errors
    );
    subvalidate(
        ViewWidgetFields.columns,
        data.columns,
        cache,
        "columns",
        errors
    );
    subvalidate(
        ViewWidgetFields.defaultSortColumn,
        data.defaultSortColumn,
        cache,
        "defaultSortColumn",
        errors
    );
    subvalidate(
        ViewWidgetFields.defaultSortDirection,
        data.defaultSortDirection,
        cache,
        "defaultSortDirection",
        errors
    );
    return errors;
}
function baseViewWidgetReduce(
    state: ViewWidgetState,
    data: ViewWidgetData,
    action: BaseViewWidgetAction,
    context: ViewWidgetContext
): WidgetResult<ViewWidgetState, ViewWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = ViewWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "DEFAULT": {
            const inner = ViewWidgetFields.default.reduce(
                state.default,
                data.default,
                action.action,
                subcontext
            );
            return {
                state: { ...state, default: inner.state },
                data: { ...data, default: inner.data },
            };
        }
        case "COLUMNS": {
            const inner = ViewWidgetFields.columns.reduce(
                state.columns,
                data.columns,
                action.action,
                subcontext
            );
            return {
                state: { ...state, columns: inner.state },
                data: { ...data, columns: inner.data },
            };
        }
        case "DEFAULT_SORT_COLUMN": {
            const inner = ViewWidgetFields.defaultSortColumn.reduce(
                state.defaultSortColumn,
                data.defaultSortColumn,
                action.action,
                subcontext
            );
            return {
                state: { ...state, defaultSortColumn: inner.state },
                data: { ...data, defaultSortColumn: inner.data },
            };
        }
        case "DEFAULT_SORT_DIRECTION": {
            const inner = ViewWidgetFields.defaultSortDirection.reduce(
                state.defaultSortDirection,
                data.defaultSortDirection,
                action.action,
                subcontext
            );
            return {
                state: { ...state, defaultSortDirection: inner.state },
                data: { ...data, defaultSortDirection: inner.data },
            };
        }
    }
}
export type ViewWidgetReactContextType = {
    state: ViewWidgetState;
    data: ViewWidgetData;
    dispatch: (action: ViewWidgetAction) => void;
    status: WidgetStatus;
};
export const ViewWidgetReactContext = React.createContext<
    ViewWidgetReactContextType | undefined
>(undefined);
export const ViewWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof ViewWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ViewWidgetReactContext
        ) as ViewWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ViewWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ViewWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    default: function (
        props: WidgetExtraProps<typeof ViewWidgetFields.default> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ViewWidgetReactContext
        ) as ViewWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ViewWidgetFields.default>) =>
                context.dispatch({ type: "DEFAULT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "default", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ViewWidgetFields.default.component
                state={context.state.default}
                data={context.data.default}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Default"}
            />
        );
    },
    columns: function (
        props: WidgetExtraProps<typeof ViewWidgetFields.columns> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ViewWidgetReactContext
        ) as ViewWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ViewWidgetFields.columns>) =>
                context.dispatch({ type: "COLUMNS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "columns", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ViewWidgetFields.columns.component
                state={context.state.columns}
                data={context.data.columns}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Columns"}
            />
        );
    },
    defaultSortColumn: function (
        props: WidgetExtraProps<typeof ViewWidgetFields.defaultSortColumn> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ViewWidgetReactContext
        ) as ViewWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ViewWidgetFields.defaultSortColumn>) =>
                context.dispatch({ type: "DEFAULT_SORT_COLUMN", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "defaultSortColumn",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <ViewWidgetFields.defaultSortColumn.component
                state={context.state.defaultSortColumn}
                data={context.data.defaultSortColumn}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Default Sort Column"}
            />
        );
    },
    defaultSortDirection: function (
        props: WidgetExtraProps<
            typeof ViewWidgetFields.defaultSortDirection
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ViewWidgetReactContext
        ) as ViewWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ViewWidgetFields.defaultSortDirection
                >
            ) => context.dispatch({ type: "DEFAULT_SORT_DIRECTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "defaultSortDirection",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <ViewWidgetFields.defaultSortDirection.component
                state={context.state.defaultSortDirection}
                data={context.data.defaultSortDirection}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Default Sort Direction"}
            />
        );
    },
};
export const ViewWidget: RecordWidget<
    ViewWidgetState,
    ViewWidgetData,
    ViewWidgetContext,
    ViewWidgetAction,
    ViewWidgetExtraProps
> = {
    reactContext: ViewWidgetReactContext,
    fieldWidgets: ViewWidgetWidgets,
    dataMeta: VIEW_META,
    initialize(
        data: ViewWidgetData,
        context: ViewWidgetContext,
        parameters?: string[]
    ): WidgetResult<ViewWidgetState, ViewWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = ViewWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerDefault = ViewWidgetFields.default.initialize(
            data.default,
            subcontext,
            subparameters.default
        );
        const innerColumns = ViewWidgetFields.columns.initialize(
            data.columns,
            subcontext,
            subparameters.columns
        );
        const innerDefaultSortColumn = ViewWidgetFields.defaultSortColumn.initialize(
            data.defaultSortColumn,
            subcontext,
            subparameters.defaultSortColumn
        );
        const innerDefaultSortDirection = ViewWidgetFields.defaultSortDirection.initialize(
            data.defaultSortDirection,
            subcontext,
            subparameters.defaultSortDirection
        );
        let state = {
            name: innerName.state,
            default: innerDefault.state,
            columns: innerColumns.state,
            defaultSortColumn: innerDefaultSortColumn.state,
            defaultSortDirection: innerDefaultSortDirection.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                default: innerDefault.data,
                columns: innerColumns.data,
                defaultSortColumn: innerDefaultSortColumn.data,
                defaultSortDirection: innerDefaultSortDirection.data,
            },
        };
    },
    validate: baseValidateViewWidget,
    component: React.memo((props: ViewWidgetProps) => {
        return (
            <ViewWidgetReactContext.Provider value={props}>
                {ViewWidgetComponent(ViewWidgetWidgets, props)}
            </ViewWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseViewWidgetReduce,
};

type ViewWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ViewWidgetFields.name
        >
    >;
    default: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ViewWidgetFields.default
        >
    >;
    columns: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ViewWidgetFields.columns
        >
    >;
    defaultSortColumn: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ViewWidgetFields.defaultSortColumn
        >
    >;
    defaultSortDirection: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ViewWidgetFields.defaultSortDirection
        >
    >;
};
// END MAGIC -- DO NOT EDIT
