import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextArrayWidget } from "../../../clay/widgets/TextArrayWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiDisciplineLinkWidget } from "./discipline-link";
import { AiSubdiscipline, AI_SUBDISCIPLINE_META } from "./table";

//!RecordWidget
export type AiSubdisciplineWidgetData = AiSubdiscipline;

export const AiSubdisciplineWidgetFields = {
    name: FormField(TextWidget),
    parent: FormField(AiDisciplineLinkWidget),
    active: FormField(SwitchWidget),
    occupation_labels: FormField(TextArrayWidget),
    wikidata_ids: FormField(TextArrayWidget),
    cipcodes: FormField(TextArrayWidget),
    factor: OptionalFormField(QuantityWidget),
};

function AiSubdisciplineWidgetComponent(
    widgets: AiSubdisciplineWidgetWidgets,
    props: AiSubdisciplineWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.parent />
            <widgets.active />
            <widgets.factor />
            <widgets.occupation_labels />
            <widgets.wikidata_ids />
            <widgets.cipcodes />

            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiSubdisciplineWidgetContext = WidgetContext<
    typeof AiSubdisciplineWidgetFields.name
> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.parent> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.active> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.occupation_labels> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.wikidata_ids> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.cipcodes> &
    WidgetContext<typeof AiSubdisciplineWidgetFields.factor>;
type AiSubdisciplineWidgetExtraProps = {};
type AiSubdisciplineWidgetBaseState = {
    name: WidgetState<typeof AiSubdisciplineWidgetFields.name>;
    parent: WidgetState<typeof AiSubdisciplineWidgetFields.parent>;
    active: WidgetState<typeof AiSubdisciplineWidgetFields.active>;
    occupation_labels: WidgetState<
        typeof AiSubdisciplineWidgetFields.occupation_labels
    >;
    wikidata_ids: WidgetState<typeof AiSubdisciplineWidgetFields.wikidata_ids>;
    cipcodes: WidgetState<typeof AiSubdisciplineWidgetFields.cipcodes>;
    factor: WidgetState<typeof AiSubdisciplineWidgetFields.factor>;
};
export type AiSubdisciplineWidgetState = AiSubdisciplineWidgetBaseState;

type BaseAiSubdisciplineWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.name>;
      }
    | {
          type: "PARENT";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.parent>;
      }
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.active>;
      }
    | {
          type: "OCCUPATION_LABELS";
          action: WidgetAction<
              typeof AiSubdisciplineWidgetFields.occupation_labels
          >;
      }
    | {
          type: "WIKIDATA_IDS";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.wikidata_ids>;
      }
    | {
          type: "CIPCODES";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.cipcodes>;
      }
    | {
          type: "FACTOR";
          action: WidgetAction<typeof AiSubdisciplineWidgetFields.factor>;
      };

export type AiSubdisciplineWidgetAction = BaseAiSubdisciplineWidgetAction;

export type AiSubdisciplineWidgetProps = WidgetProps<
    AiSubdisciplineWidgetState,
    AiSubdisciplineWidgetData,
    AiSubdisciplineWidgetAction,
    AiSubdisciplineWidgetExtraProps
>;

function baseValidateAiSubdisciplineWidget(
    data: AiSubdisciplineWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiSubdisciplineWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.parent,
        data.parent,
        cache,
        "parent",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.occupation_labels,
        data.occupation_labels,
        cache,
        "occupation_labels",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.wikidata_ids,
        data.wikidata_ids,
        cache,
        "wikidata_ids",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.cipcodes,
        data.cipcodes,
        cache,
        "cipcodes",
        errors
    );
    subvalidate(
        AiSubdisciplineWidgetFields.factor,
        data.factor,
        cache,
        "factor",
        errors
    );
    return errors;
}
function baseAiSubdisciplineWidgetReduce(
    state: AiSubdisciplineWidgetState,
    data: AiSubdisciplineWidgetData,
    action: BaseAiSubdisciplineWidgetAction,
    context: AiSubdisciplineWidgetContext
): WidgetResult<AiSubdisciplineWidgetState, AiSubdisciplineWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiSubdisciplineWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "PARENT": {
            const inner = AiSubdisciplineWidgetFields.parent.reduce(
                state.parent,
                data.parent,
                action.action,
                subcontext
            );
            return {
                state: { ...state, parent: inner.state },
                data: { ...data, parent: inner.data },
            };
        }
        case "ACTIVE": {
            const inner = AiSubdisciplineWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "OCCUPATION_LABELS": {
            const inner = AiSubdisciplineWidgetFields.occupation_labels.reduce(
                state.occupation_labels,
                data.occupation_labels,
                action.action,
                subcontext
            );
            return {
                state: { ...state, occupation_labels: inner.state },
                data: { ...data, occupation_labels: inner.data },
            };
        }
        case "WIKIDATA_IDS": {
            const inner = AiSubdisciplineWidgetFields.wikidata_ids.reduce(
                state.wikidata_ids,
                data.wikidata_ids,
                action.action,
                subcontext
            );
            return {
                state: { ...state, wikidata_ids: inner.state },
                data: { ...data, wikidata_ids: inner.data },
            };
        }
        case "CIPCODES": {
            const inner = AiSubdisciplineWidgetFields.cipcodes.reduce(
                state.cipcodes,
                data.cipcodes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, cipcodes: inner.state },
                data: { ...data, cipcodes: inner.data },
            };
        }
        case "FACTOR": {
            const inner = AiSubdisciplineWidgetFields.factor.reduce(
                state.factor,
                data.factor,
                action.action,
                subcontext
            );
            return {
                state: { ...state, factor: inner.state },
                data: { ...data, factor: inner.data },
            };
        }
    }
}
export type AiSubdisciplineWidgetReactContextType = {
    state: AiSubdisciplineWidgetState;
    data: AiSubdisciplineWidgetData;
    dispatch: (action: AiSubdisciplineWidgetAction) => void;
    status: WidgetStatus;
};
export const AiSubdisciplineWidgetReactContext = React.createContext<
    AiSubdisciplineWidgetReactContextType | undefined
>(undefined);
export const AiSubdisciplineWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiSubdisciplineWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSubdisciplineWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    parent: function (
        props: WidgetExtraProps<typeof AiSubdisciplineWidgetFields.parent> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSubdisciplineWidgetFields.parent>) =>
                context.dispatch({ type: "PARENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "parent", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.parent.component
                state={context.state.parent}
                data={context.data.parent}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Parent"}
            />
        );
    },
    active: function (
        props: WidgetExtraProps<typeof AiSubdisciplineWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSubdisciplineWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    occupation_labels: function (
        props: WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.occupation_labels
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSubdisciplineWidgetFields.occupation_labels
                >
            ) => context.dispatch({ type: "OCCUPATION_LABELS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "occupation_labels",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.occupation_labels.component
                state={context.state.occupation_labels}
                data={context.data.occupation_labels}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Occupation Labels"}
            />
        );
    },
    wikidata_ids: function (
        props: WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.wikidata_ids
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSubdisciplineWidgetFields.wikidata_ids
                >
            ) => context.dispatch({ type: "WIKIDATA_IDS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "wikidata_ids", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.wikidata_ids.component
                state={context.state.wikidata_ids}
                data={context.data.wikidata_ids}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Wikidata Ids"}
            />
        );
    },
    cipcodes: function (
        props: WidgetExtraProps<typeof AiSubdisciplineWidgetFields.cipcodes> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSubdisciplineWidgetFields.cipcodes
                >
            ) => context.dispatch({ type: "CIPCODES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "cipcodes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.cipcodes.component
                state={context.state.cipcodes}
                data={context.data.cipcodes}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Cipcodes"}
            />
        );
    },
    factor: function (
        props: WidgetExtraProps<typeof AiSubdisciplineWidgetFields.factor> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSubdisciplineWidgetReactContext
        ) as AiSubdisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSubdisciplineWidgetFields.factor>) =>
                context.dispatch({ type: "FACTOR", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "factor", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSubdisciplineWidgetFields.factor.component
                state={context.state.factor}
                data={context.data.factor}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Factor"}
            />
        );
    },
};
export const AiSubdisciplineWidget: RecordWidget<
    AiSubdisciplineWidgetState,
    AiSubdisciplineWidgetData,
    AiSubdisciplineWidgetContext,
    AiSubdisciplineWidgetAction,
    AiSubdisciplineWidgetExtraProps
> = {
    reactContext: AiSubdisciplineWidgetReactContext,
    fieldWidgets: AiSubdisciplineWidgetWidgets,
    dataMeta: AI_SUBDISCIPLINE_META,
    initialize(
        data: AiSubdisciplineWidgetData,
        context: AiSubdisciplineWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiSubdisciplineWidgetState, AiSubdisciplineWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiSubdisciplineWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerParent = AiSubdisciplineWidgetFields.parent.initialize(
            data.parent,
            subcontext,
            subparameters.parent
        );
        const innerActive = AiSubdisciplineWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerOccupationLabels = AiSubdisciplineWidgetFields.occupation_labels.initialize(
            data.occupation_labels,
            subcontext,
            subparameters.occupation_labels
        );
        const innerWikidataIds = AiSubdisciplineWidgetFields.wikidata_ids.initialize(
            data.wikidata_ids,
            subcontext,
            subparameters.wikidata_ids
        );
        const innerCipcodes = AiSubdisciplineWidgetFields.cipcodes.initialize(
            data.cipcodes,
            subcontext,
            subparameters.cipcodes
        );
        const innerFactor = AiSubdisciplineWidgetFields.factor.initialize(
            data.factor,
            subcontext,
            subparameters.factor
        );
        let state = {
            name: innerName.state,
            parent: innerParent.state,
            active: innerActive.state,
            occupation_labels: innerOccupationLabels.state,
            wikidata_ids: innerWikidataIds.state,
            cipcodes: innerCipcodes.state,
            factor: innerFactor.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                parent: innerParent.data,
                active: innerActive.data,
                occupation_labels: innerOccupationLabels.data,
                wikidata_ids: innerWikidataIds.data,
                cipcodes: innerCipcodes.data,
                factor: innerFactor.data,
            },
        };
    },
    validate: baseValidateAiSubdisciplineWidget,
    component: React.memo((props: AiSubdisciplineWidgetProps) => {
        return (
            <AiSubdisciplineWidgetReactContext.Provider value={props}>
                {AiSubdisciplineWidgetComponent(
                    AiSubdisciplineWidgetWidgets,
                    props
                )}
            </AiSubdisciplineWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiSubdisciplineWidgetReduce,
};

type AiSubdisciplineWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.name
        >
    >;
    parent: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.parent
        >
    >;
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.active
        >
    >;
    occupation_labels: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.occupation_labels
        >
    >;
    wikidata_ids: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.wikidata_ids
        >
    >;
    cipcodes: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.cipcodes
        >
    >;
    factor: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSubdisciplineWidgetFields.factor
        >
    >;
};
// END MAGIC -- DO NOT EDIT
