import { AdminTablePage } from "../../../clay/admin-table";
import { GridWithEditor } from "../../../clay/dataGrid/gridWithEditor";
import { clearCache, clearUrlCache } from "../cache";
import { CategoryWidget } from "./category-widget";
import { DegreeLevelWidget } from "./level-widget";
import { AiSchoolWidget } from "./widget";

export const AiSchoolDegreeCategoryPage = AdminTablePage({
    rowWidget: CategoryWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
        },
    ],
});

export const AiSchoolDegreeLevelPage = AdminTablePage({
    rowWidget: DegreeLevelWidget,
    compare: (lhs, rhs) => lhs.number.comparedTo(rhs.number),
    columns: [
        {
            id: "name",
            label: "Name",
        },
        {
            id: "number",
            label: "Number",
        },
    ],
});

export const AiSchoolsPage = GridWithEditor({
    prefix: "#/ai/schools",
    meta: AiSchoolWidget,
    makeContext: (context) => context,
    fallbackSorts: ["slug"],
    title: (record) => {
        return `School : ${record.slug}`;
    },
    postSave: (record) => {
        clearCache("school-page-" + record.slug);
        clearUrlCache("/schools/" + record.slug);
    },
});
