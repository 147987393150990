import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { AiDisciplineWidget } from "./discipline_widget";
import { AiSubdisciplineWidget } from "./subdiscipline_widget";

export const AiDisciplinesPage = AdminCollectionPage({
    meta: AiDisciplineWidget,
    labelColumn: "name",
    urlPrefix: "#/ai/disciplines",
});

export const AiSubdisciplinesPage = AdminCollectionPage({
    meta: AiSubdisciplineWidget,
    labelColumn: "name",
    urlPrefix: "#/ai/subdisciplines",
});
