import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { clearCache } from "../cache";
import { AiShortcodeWidget } from "./widget";

export const ShortcodesPage = AdminCollectionPage({
    meta: AiShortcodeWidget,
    labelColumn: "name",
    urlPrefix: "/#/ai/shortcodes",
    postSave: () => {
        clearCache("shortcodes");
    },
});
