import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "glamor";
import * as React from "react";
import { Nav, NavItem } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { connect } from "react-redux";
import { RouterPageState } from "../clay/router-page";
import { ServerMessage, Status } from "../clay/service";
import { titleCase } from "../clay/title-case";
import { hasPermission } from "../permissions";
import { ROOT_PAGE } from "./pages";
import { Action, State, StateContext } from "./state";

type Props<S, A> = {
    loggedIn: boolean;
    imageUrl: string | null;
    pageState: RouterPageState;
    dispatch: (action: Action) => void;
    status: Status;
    state: State;
    errors: ServerMessage[];
};

const PROFILE_IMAGE = css({
    height: "40px",
    paddingLeft: "10px",
    paddingRight: "10px",
});

const LOGIN_STYLE = css({
    textAlign: "center",
    marginTop: "50px",
});

const APP_STYLE = css({
    height: "100vh",
    display: "flex",
});

const NAV_STYLE = css({
    marginBottom: 0,
    minHeight: ".75in",
    background: "#ddd",
    width: "2in",
    padding: "18px",
    overflowY: "auto",
    flexShrink: 0,
});

const TITLE_STYLE = css({
    textAlign: "left",
    flexGrow: 1,
    verticalAlign: "middle",
});

const ERROR_CONTAINER = css({
    position: "absolute",
    bottom: 0,
    right: 0,
    "& .toast-header": {
        color: "red",
    },
    "& pre": {
        overflow: "auto",
        display: "block",
        maxHeight: "50vh",
    },
});

type ErrorProps = {
    error: ServerMessage;
    dispatch: (action: Action) => void;
};

function Error({ error, dispatch }: ErrorProps) {
    const [showDetail, setDetail] = React.useState(false);

    return (
        <Toast
            show={true}
            delay={1000}
            onClose={() =>
                dispatch({
                    type: "CLOSE_ERROR",
                    error,
                })
            }
        >
            <Toast.Header>
                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                {titleCase((error as any).status)}
            </Toast.Header>
            <Toast.Body>
                {showDetail ? (
                    <pre>{JSON.stringify(error, null, 4)}</pre>
                ) : (
                    <a onClick={() => setDetail(true)}>detail</a>
                )}
            </Toast.Body>
        </Toast>
    );
}

function SidebarHeader(props: { children: React.ReactNode }) {
    return (
        <div
            style={{
                fontSize: "22px",
                textTransform: "uppercase",
                color: "#979797",
            }}
        >
            {props.children}
        </div>
    );
}

function AppForm<S, A>({
    loggedIn,
    imageUrl,
    pageState,
    dispatch,
    status,
    state,
    errors,
}: Props<S, A>) {
    const subdispatch = React.useCallback(
        (action: Action) => dispatch({ type: "PAGE", action }),
        [dispatch]
    );
    if (loggedIn && state.user) {
        return (
            <StateContext.Provider value={state}>
                <div {...APP_STYLE}>
                    <div {...NAV_STYLE}>
                        <a href="#">
                            <div
                                style={{
                                    color: "#1e998a",
                                    fontWeight: "bold",
                                    display: "inline-block",
                                    fontSize: "36px",
                                }}
                            >
                                Editor
                            </div>
                        </a>
                        <SidebarHeader>Pages</SidebarHeader>
                        {hasPermission(state.user, "AiArticle", "read") && (
                            <Nav.Link href="#/ai/articles/browse">
                                Articles
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "Document", "read") && (
                            <Nav.Link href="#/ai/documents">Documents</Nav.Link>
                        )}
                        {hasPermission(state.user, "AiAuthor", "read") && (
                            <Nav.Link href="#/ai/authors">Authors</Nav.Link>
                        )}
                        {hasPermission(state.user, "Redirect", "read") && (
                            <Nav.Link href="#/ai/redirects/browse">
                                Redirects
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiShortcode", "read") && (
                            <Nav.Link href="#/ai/shortcodes">
                                Shortcodes
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiCategory", "write") && (
                            <Nav.Link href="#/ai/categories">
                                Categories
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiDiscipline", "write") && (
                            <Nav.Link href="#/ai/disciplines">
                                Disciplines
                            </Nav.Link>
                        )}
                        {hasPermission(
                            state.user,
                            "AiSubdiscipline",
                            "write"
                        ) && (
                            <Nav.Link href="#/ai/subdisciplines">
                                Subdisciplines
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiHomePage", "write") && (
                            <Nav.Link href="#/ai/home/5f6455fa-ce87-4118-b165-52090c76caad">
                                Site
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiMenus", "write") && (
                            <Nav.Link href="#/ai/menus/5f6455fa-ce87-4118-b165-52090c76caad">
                                Menus
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiSchool", "write") && (
                            <Nav.Link href="#/ai/schools/browse">
                                Schools
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiPerson", "write") && (
                            <Nav.Link href="#/ai/people/browse">
                                People
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "AiPage", "write") && (
                            <Nav.Link href="#/ai/pages">Pages</Nav.Link>
                        )}
                        {hasPermission(
                            state.user,
                            "AccreditationAgency",
                            "write"
                        ) && (
                            <Nav.Link href="#/ai/accreditation-agencies/">
                                Accreditation Agencies
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "Ad", "write") && (
                            <Nav.Link href="#/ai/ads/">Ads</Nav.Link>
                        )}
                        {hasPermission(state.user, "AdGroup", "write") && (
                            <Nav.Link href="#/ai/ad-groups/">
                                Ad Groups
                            </Nav.Link>
                        )}
                        {hasPermission(
                            state.user,
                            "ConsultantAffiliation",
                            "write"
                        ) && (
                            <Nav.Link href="#/ai/consultant-affiliations/">
                                Consultant Affiliations
                            </Nav.Link>
                        )}
                        {hasPermission(
                            state.user,
                            "ConsultantTag",
                            "write"
                        ) && (
                            <Nav.Link href="#/ai/consultant-tags/">
                                Consultant Tags
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "Consultant", "write") && (
                            <Nav.Link href="#/ai/consultants/browse">
                                Consultant
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "Replacement", "write") && (
                            <Nav.Link href="#/ai/replacements/browse">
                                Replacements
                            </Nav.Link>
                        )}

                        <SidebarHeader>Settings</SidebarHeader>
                        {hasPermission(state.user, "RecordHistory", "read") && (
                            <Nav.Link href="#/admin/record-history">
                                Record History
                            </Nav.Link>
                        )}
                        {hasPermission(state.user, "User", "write") && (
                            <Nav.Link href="#/admin/users">Users</Nav.Link>
                        )}
                        {hasPermission(state.user, "Role", "write") && (
                            <Nav.Link href="#/admin/roles">Roles</Nav.Link>
                        )}
                        {hasPermission(state.user, "View", "write") && (
                            <Nav.Link href="#/admin/views">Views</Nav.Link>
                        )}
                        <div style={{ flexGrow: 1 }} />
                        <NavItem style={{ display: "flex" }}>
                            {imageUrl && (
                                <img {...PROFILE_IMAGE} src={imageUrl} />
                            )}
                        </NavItem>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            overflowY: "auto",
                        }}
                    >
                        <div className="navbar navbar-default navbar-light">
                            <ROOT_PAGE.headerComponent
                                state={pageState}
                                dispatch={subdispatch}
                            />
                        </div>
                        <ROOT_PAGE.component
                            state={pageState}
                            dispatch={subdispatch}
                        />
                    </div>
                    <div {...ERROR_CONTAINER}>
                        {errors.map((error, index) => (
                            <Error
                                key={index}
                                error={error}
                                dispatch={dispatch}
                            />
                        ))}
                    </div>
                </div>
            </StateContext.Provider>
        );
    } else {
        return (
            <div {...LOGIN_STYLE}>
                <h1>Please Login to use Editor</h1>
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        loggedIn: state.user !== null,
        imageUrl: state.profile_image_url,
        pageState: state.pageState,
        status: state.status,
        state: state,
        errors: state.errors,
    };
}
export default connect(mapStateToProps)(AppForm);
