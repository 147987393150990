import { Decimal } from "decimal.js";
import { Quantity } from "../../../clay/common";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../version";

//!Data
export type Ad = {
    id: UUID;
    recordVersion: Version;
    name: string;
    image: string;
    link: string;
    alt: string;
    width: Quantity;
    height: Quantity;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AdJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    image: string;
    link: string;
    alt: string;
    width: string;
    height: string;
};

export function JSONToAd(json: AdJSON): Ad {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        image: json.image,
        link: json.link,
        alt: json.alt,
        width: new Decimal(json.width),
        height: new Decimal(json.height),
    };
}
export type AdBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    image?: string;
    link?: string;
    alt?: string;
    width?: string;
    height?: string;
};

export function newAd(): Ad {
    return JSONToAd(repairAdJSON(undefined));
}
export function repairAdJSON(json: AdBrokenJSON | undefined): AdJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            image: json.image || "",
            link: json.link || "",
            alt: json.alt || "",
            width: json.width || "0",
            height: json.height || "0",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            image: undefined || "",
            link: undefined || "",
            alt: undefined || "",
            width: undefined || "0",
            height: undefined || "0",
        };
    }
}

export function AdToJSON(value: Ad): AdJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        image: value.image,
        link: value.link,
        alt: value.alt,
        width: value.width.toString(),
        height: value.height.toString(),
    };
}

export const AD_META: RecordMeta<Ad, AdJSON, AdBrokenJSON> = {
    name: "Ad",
    type: "record",
    repair: repairAdJSON,
    toJSON: AdToJSON,
    fromJSON: JSONToAd,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        image: { type: "string" },
        link: { type: "string" },
        alt: { type: "string" },
        width: { type: "quantity" },
        height: { type: "quantity" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
