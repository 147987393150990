import * as React from "react";
import { Table } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiMenuItemWidget } from "./item-widget";
import { AiMenuSection, AI_MENU_SECTION_META } from "./table";

//!RecordWidget
export type AiMenuSectionWidgetData = AiMenuSection;

export const AiMenuSectionWidgetFields = {
    name: FormField(TextWidget),
    headerLink: OptionalFormField(TextWidget),
    items: ListWidget(AiMenuItemWidget, { emptyOk: true }),
};

function AiMenuSectionWidgetComponent(
    widgets: AiMenuSectionWidgetWidgets,
    props: AiMenuSectionWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.headerLink />
            <Table>
                <thead>
                    <tr>
                        <th />
                        <th>Name</th>
                        <th>Link</th>
                        <th>Emphasized</th>
                    </tr>
                </thead>
                <widgets.items extraItemForAdd containerClass="tbody" />
            </Table>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiMenuSectionWidgetContext = WidgetContext<
    typeof AiMenuSectionWidgetFields.name
> &
    WidgetContext<typeof AiMenuSectionWidgetFields.headerLink> &
    WidgetContext<typeof AiMenuSectionWidgetFields.items>;
type AiMenuSectionWidgetExtraProps = {};
type AiMenuSectionWidgetBaseState = {
    name: WidgetState<typeof AiMenuSectionWidgetFields.name>;
    headerLink: WidgetState<typeof AiMenuSectionWidgetFields.headerLink>;
    items: WidgetState<typeof AiMenuSectionWidgetFields.items>;
};
export type AiMenuSectionWidgetState = AiMenuSectionWidgetBaseState;

type BaseAiMenuSectionWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof AiMenuSectionWidgetFields.name>;
      }
    | {
          type: "HEADER_LINK";
          action: WidgetAction<typeof AiMenuSectionWidgetFields.headerLink>;
      }
    | {
          type: "ITEMS";
          action: WidgetAction<typeof AiMenuSectionWidgetFields.items>;
      };

export type AiMenuSectionWidgetAction = BaseAiMenuSectionWidgetAction;

export type AiMenuSectionWidgetProps = WidgetProps<
    AiMenuSectionWidgetState,
    AiMenuSectionWidgetData,
    AiMenuSectionWidgetAction,
    AiMenuSectionWidgetExtraProps
>;

function baseValidateAiMenuSectionWidget(
    data: AiMenuSectionWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiMenuSectionWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        AiMenuSectionWidgetFields.headerLink,
        data.headerLink,
        cache,
        "headerLink",
        errors
    );
    subvalidate(
        AiMenuSectionWidgetFields.items,
        data.items,
        cache,
        "items",
        errors
    );
    return errors;
}
function baseAiMenuSectionWidgetReduce(
    state: AiMenuSectionWidgetState,
    data: AiMenuSectionWidgetData,
    action: BaseAiMenuSectionWidgetAction,
    context: AiMenuSectionWidgetContext
): WidgetResult<AiMenuSectionWidgetState, AiMenuSectionWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiMenuSectionWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "HEADER_LINK": {
            const inner = AiMenuSectionWidgetFields.headerLink.reduce(
                state.headerLink,
                data.headerLink,
                action.action,
                subcontext
            );
            return {
                state: { ...state, headerLink: inner.state },
                data: { ...data, headerLink: inner.data },
            };
        }
        case "ITEMS": {
            const inner = AiMenuSectionWidgetFields.items.reduce(
                state.items,
                data.items,
                action.action,
                subcontext
            );
            return {
                state: { ...state, items: inner.state },
                data: { ...data, items: inner.data },
            };
        }
    }
}
export type AiMenuSectionWidgetReactContextType = {
    state: AiMenuSectionWidgetState;
    data: AiMenuSectionWidgetData;
    dispatch: (action: AiMenuSectionWidgetAction) => void;
    status: WidgetStatus;
};
export const AiMenuSectionWidgetReactContext = React.createContext<
    AiMenuSectionWidgetReactContextType | undefined
>(undefined);
export const AiMenuSectionWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiMenuSectionWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuSectionWidgetReactContext
        ) as AiMenuSectionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenuSectionWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuSectionWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    headerLink: function (
        props: WidgetExtraProps<typeof AiMenuSectionWidgetFields.headerLink> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuSectionWidgetReactContext
        ) as AiMenuSectionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiMenuSectionWidgetFields.headerLink
                >
            ) => context.dispatch({ type: "HEADER_LINK", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "headerLink", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuSectionWidgetFields.headerLink.component
                state={context.state.headerLink}
                data={context.data.headerLink}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Header Link"}
            />
        );
    },
    items: function (
        props: WidgetExtraProps<typeof AiMenuSectionWidgetFields.items> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuSectionWidgetReactContext
        ) as AiMenuSectionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenuSectionWidgetFields.items>) =>
                context.dispatch({ type: "ITEMS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "items", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuSectionWidgetFields.items.component
                state={context.state.items}
                data={context.data.items}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Items"}
            />
        );
    },
};
export const AiMenuSectionWidget: RecordWidget<
    AiMenuSectionWidgetState,
    AiMenuSectionWidgetData,
    AiMenuSectionWidgetContext,
    AiMenuSectionWidgetAction,
    AiMenuSectionWidgetExtraProps
> = {
    reactContext: AiMenuSectionWidgetReactContext,
    fieldWidgets: AiMenuSectionWidgetWidgets,
    dataMeta: AI_MENU_SECTION_META,
    initialize(
        data: AiMenuSectionWidgetData,
        context: AiMenuSectionWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiMenuSectionWidgetState, AiMenuSectionWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiMenuSectionWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerHeaderLink = AiMenuSectionWidgetFields.headerLink.initialize(
            data.headerLink,
            subcontext,
            subparameters.headerLink
        );
        const innerItems = AiMenuSectionWidgetFields.items.initialize(
            data.items,
            subcontext,
            subparameters.items
        );
        let state = {
            name: innerName.state,
            headerLink: innerHeaderLink.state,
            items: innerItems.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                headerLink: innerHeaderLink.data,
                items: innerItems.data,
            },
        };
    },
    validate: baseValidateAiMenuSectionWidget,
    component: React.memo((props: AiMenuSectionWidgetProps) => {
        return (
            <AiMenuSectionWidgetReactContext.Provider value={props}>
                {AiMenuSectionWidgetComponent(
                    AiMenuSectionWidgetWidgets,
                    props
                )}
            </AiMenuSectionWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiMenuSectionWidgetReduce,
};

type AiMenuSectionWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuSectionWidgetFields.name
        >
    >;
    headerLink: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuSectionWidgetFields.headerLink
        >
    >;
    items: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuSectionWidgetFields.items
        >
    >;
};
// END MAGIC -- DO NOT EDIT
