import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiSchoolDegreeLevel, AI_SCHOOL_DEGREE_LEVEL_META } from "./table";

//!RecordWidget
export type DegreeLevelWidgetData = AiSchoolDegreeLevel;

export const DegreeLevelWidgetFields = {
    name: TextWidget,
    number: QuantityWidget,
};

function DegreeLevelWidgetComponent(
    widgets: DegreeLevelWidgetWidgets,
    props: DegreeLevelWidgetProps
) {
    throw new Error("unused");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type DegreeLevelWidgetContext = WidgetContext<
    typeof DegreeLevelWidgetFields.name
> &
    WidgetContext<typeof DegreeLevelWidgetFields.number>;
type DegreeLevelWidgetExtraProps = {};
type DegreeLevelWidgetBaseState = {
    name: WidgetState<typeof DegreeLevelWidgetFields.name>;
    number: WidgetState<typeof DegreeLevelWidgetFields.number>;
};
export type DegreeLevelWidgetState = DegreeLevelWidgetBaseState;

type BaseDegreeLevelWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof DegreeLevelWidgetFields.name>;
      }
    | {
          type: "NUMBER";
          action: WidgetAction<typeof DegreeLevelWidgetFields.number>;
      };

export type DegreeLevelWidgetAction = BaseDegreeLevelWidgetAction;

export type DegreeLevelWidgetProps = WidgetProps<
    DegreeLevelWidgetState,
    DegreeLevelWidgetData,
    DegreeLevelWidgetAction,
    DegreeLevelWidgetExtraProps
>;

function baseValidateDegreeLevelWidget(
    data: DegreeLevelWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(DegreeLevelWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        DegreeLevelWidgetFields.number,
        data.number,
        cache,
        "number",
        errors
    );
    return errors;
}
function baseDegreeLevelWidgetReduce(
    state: DegreeLevelWidgetState,
    data: DegreeLevelWidgetData,
    action: BaseDegreeLevelWidgetAction,
    context: DegreeLevelWidgetContext
): WidgetResult<DegreeLevelWidgetState, DegreeLevelWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = DegreeLevelWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "NUMBER": {
            const inner = DegreeLevelWidgetFields.number.reduce(
                state.number,
                data.number,
                action.action,
                subcontext
            );
            return {
                state: { ...state, number: inner.state },
                data: { ...data, number: inner.data },
            };
        }
    }
}
export type DegreeLevelWidgetReactContextType = {
    state: DegreeLevelWidgetState;
    data: DegreeLevelWidgetData;
    dispatch: (action: DegreeLevelWidgetAction) => void;
    status: WidgetStatus;
};
export const DegreeLevelWidgetReactContext = React.createContext<
    DegreeLevelWidgetReactContextType | undefined
>(undefined);
export const DegreeLevelWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof DegreeLevelWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            DegreeLevelWidgetReactContext
        ) as DegreeLevelWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof DegreeLevelWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <DegreeLevelWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    number: function (
        props: WidgetExtraProps<typeof DegreeLevelWidgetFields.number> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            DegreeLevelWidgetReactContext
        ) as DegreeLevelWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof DegreeLevelWidgetFields.number>) =>
                context.dispatch({ type: "NUMBER", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "number", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <DegreeLevelWidgetFields.number.component
                state={context.state.number}
                data={context.data.number}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Number"}
            />
        );
    },
};
export const DegreeLevelWidget: RecordWidget<
    DegreeLevelWidgetState,
    DegreeLevelWidgetData,
    DegreeLevelWidgetContext,
    DegreeLevelWidgetAction,
    DegreeLevelWidgetExtraProps
> = {
    reactContext: DegreeLevelWidgetReactContext,
    fieldWidgets: DegreeLevelWidgetWidgets,
    dataMeta: AI_SCHOOL_DEGREE_LEVEL_META,
    initialize(
        data: DegreeLevelWidgetData,
        context: DegreeLevelWidgetContext,
        parameters?: string[]
    ): WidgetResult<DegreeLevelWidgetState, DegreeLevelWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = DegreeLevelWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerNumber = DegreeLevelWidgetFields.number.initialize(
            data.number,
            subcontext,
            subparameters.number
        );
        let state = {
            name: innerName.state,
            number: innerNumber.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                number: innerNumber.data,
            },
        };
    },
    validate: baseValidateDegreeLevelWidget,
    component: React.memo((props: DegreeLevelWidgetProps) => {
        return (
            <DegreeLevelWidgetReactContext.Provider value={props}>
                {DegreeLevelWidgetComponent(DegreeLevelWidgetWidgets, props)}
            </DegreeLevelWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseDegreeLevelWidgetReduce,
};

type DegreeLevelWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof DegreeLevelWidgetFields.name
        >
    >;
    number: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof DegreeLevelWidgetFields.number
        >
    >;
};
// END MAGIC -- DO NOT EDIT
