import * as React from "react";

export function FieldRow(props: {
    children: React.ReactNode;
    noExpand?: boolean;
}) {
    return (
        <div className={props.noExpand ? "field-row no-expand" : "field-row"}>
            {props.children}
        </div>
    );
}
