import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import { AiDiscipline } from "../disciplines/table";

//!Data
export type AccreditationAgency = {
    id: UUID;
    recordVersion: Version;

    agencyId: string;
    name: string;
    category: "national" | "regional" | "programmatic";
    discipline: Link<AiDiscipline>;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AccreditationAgencyJSON = {
    id: string;
    recordVersion: number | null;
    agencyId: string;
    name: string;
    category: string;
    discipline: string | null;
};

export function JSONToAccreditationAgency(
    json: AccreditationAgencyJSON
): AccreditationAgency {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        agencyId: json.agencyId,
        name: json.name,
        category: json.category as any,
        discipline: json.discipline,
    };
}
export type AccreditationAgencyBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    agencyId?: string;
    name?: string;
    category?: string;
    discipline?: string | null;
};

export function newAccreditationAgency(): AccreditationAgency {
    return JSONToAccreditationAgency(repairAccreditationAgencyJSON(undefined));
}
export function repairAccreditationAgencyJSON(
    json: AccreditationAgencyBrokenJSON | undefined
): AccreditationAgencyJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            agencyId: json.agencyId || "",
            name: json.name || "",
            category: json.category || "national",
            discipline: json.discipline || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            agencyId: undefined || "",
            name: undefined || "",
            category: undefined || "national",
            discipline: undefined || null,
        };
    }
}

export function AccreditationAgencyToJSON(
    value: AccreditationAgency
): AccreditationAgencyJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        agencyId: value.agencyId,
        name: value.name,
        category: value.category,
        discipline: value.discipline,
    };
}

export const ACCREDITATION_AGENCY_META: RecordMeta<
    AccreditationAgency,
    AccreditationAgencyJSON,
    AccreditationAgencyBrokenJSON
> = {
    name: "AccreditationAgency",
    type: "record",
    repair: repairAccreditationAgencyJSON,
    toJSON: AccreditationAgencyToJSON,
    fromJSON: JSONToAccreditationAgency,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        agencyId: { type: "string" },
        name: { type: "string" },
        category: {
            type: "enum",
            values: ["national", "regional", "programmatic"],
        },
        discipline: { type: "uuid", linkTo: "AiDiscipline" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
