import { GridWithEditor } from "../../../clay/dataGrid/gridWithEditor";
import { clearCache, clearUrlCache } from "../cache";
import { AiPersonWidget } from "./widget";

export const AiPeoplePage = GridWithEditor({
    prefix: "#/ai/people",
    meta: AiPersonWidget,
    makeContext: (context) => context,
    fallbackSorts: ["slug"],
    title: (record) => {
        return `Person : ${record.slug}`;
    },
    postSave: (record) => {
        clearCache("person-page-" + record.slug);
        clearUrlCache("/people/" + record.slug);
    },
});
