import { LinkWidget } from "../../clay/widgets/link-widget";

export const UserLinkWidget = LinkWidget({
    table: "User",
});

function RoleLinkWidget(role: string) {
    return LinkWidget({
        table: "User",
        extraFilter: {
            column: "roles",
            filter: {
                intersects: [role],
            },
        },
    });
}

const ROLE_PROJECT_MANAGER = "22f619e3-10a5-410a-9bfd-fdbfa42c2ed1";
const ROLE_SERVICE_REPRESENTATIVE = "14d9dd92-7098-4709-973f-0d7383e2cf11";
const ROLE_CERTIFIED_FOREMAN = "b0f6ddd1-36cc-436c-835e-08359b719eea";

export const ProjectManagerLinkWidget = RoleLinkWidget(ROLE_PROJECT_MANAGER);
export const ServiceRepresentativeLinkWidget = RoleLinkWidget(
    ROLE_SERVICE_REPRESENTATIVE
);
export const CertifiedForemanLinkWidget = RoleLinkWidget(
    ROLE_CERTIFIED_FOREMAN
);
