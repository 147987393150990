import { AdminTablePage } from "../../../clay/admin-table";
import { AdWidget } from "./widget";

export const AdPage = AdminTablePage({
    rowWidget: AdWidget,
    compare: (lhs, rhs) => lhs.name.localeCompare(rhs.name),
    columns: [
        {
            id: "name",
            label: "Name",
            width: 500,
        },
        {
            id: "image",
            label: "Image",
            width: 150,
        },
        {
            id: "link",
            label: "Link",
            width: 200,
        },
        {
            id: "alt",
            label: "Alt Text",
        },
        {
            id: "width",
            label: "Width",
        },
        {
            id: "height",
            label: "Height",
        },
    ],
});
