import * as Sentry from "@sentry/browser";
import "fixed-data-table-2/dist/fixed-data-table.css";
import "jsondiffpatch/dist/formatters-styles/html.css";
import { isEmpty } from "lodash-es";
import * as React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import * as ReactDOMClient from "react-dom/client";
import ReactModal from "react-modal";
import { Provider, useSelector } from "react-redux";
import "react-resizable/css/styles.css";
import "react-tagsinput/react-tagsinput.css";
import { createStore, Store } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import { DndWrapper } from "../clay/dnd";
import { PageRequest } from "../clay/Page";
import { QuickCache, useQuickCache } from "../clay/quick-cache";
import { RequestHandle, RequestType } from "../clay/requests";
import { ServerMessage } from "../clay/service";
import App from "./App";
import "./client.css";
import { ROOT_PAGE } from "./pages";
import { SERVICE } from "./service";
import {
    Action,
    createInitialState,
    encodeState,
    reducer,
    State,
} from "./state";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://b73692ee50aa4b00b1cf99aa81fd70ae@sentry.io/1802583",
        release: process.env.VERSION,
    });
}

const store: Store<State, Action> = createStore(
    reducer as any,
    createInitialState() as any,
    devToolsEnhancer({ name: "Dropsheet Main" })
);

window.addEventListener("hashchange", () => {
    const state = store.getState();
    if (state.waitingHash !== null) {
        return;
    }
    const correctHash = encodeState(state);
    if (correctHash !== null && window.location.hash !== correctHash) {
        store.dispatch({
            type: "HASHCHANGE",
            hash: window.location.hash,
        });
    }
});

SERVICE.on("message", (message: ServerMessage) =>
    store.dispatch({
        type: "SERVER_MESSAGE",
        message,
    })
);

type Extended<T extends RequestType<{}, {}, {}>, A> = T extends any
    ? RequestHandle<T, A>
    : never;

const subwindowRequestIds: { subWindow: Window; requestId: string }[] = [];

window.addEventListener("message", (event) => {
    for (const request of subwindowRequestIds) {
        if (request.subWindow === event.source) {
            store.dispatch({
                type: "SERVER_MESSAGE",
                message: {
                    type: "RESPONSE",
                    id: request.requestId,
                    response: event.data,
                },
            });
        }
    }
});

function dispatchRequest<A>(
    requestId: string,
    request: Extended<PageRequest, A>
): boolean {
    switch (request.type) {
        case "RECORDS":
        case "RECORD":
        case "QUERY":
        case "STORE":
        case "DELETE":
        case "FETCH_HISTORY":
        case "REVERT":
        case "EDIT":
            SERVICE.send({
                request: {
                    type: request.type,
                    ...request.request,
                },
                id: requestId,
            });
            return true;
        case "REDIRECT_HASH":
            window.location.hash = request.request;
            return true;
        case "OPEN_HASH":
            const subWindow = window.open(request.request);
            if (subWindow) {
                subwindowRequestIds.push({ subWindow, requestId });
            }
            return true;
        case "PRINT":
            window.open(
                "/print/" +
                    request.request.template +
                    "/" +
                    request.request.id +
                    "/" +
                    request.request.parameters.join("/") +
                    "?token=" +
                    SERVICE.getToken()
            );
            return true;
        case "FINISHED":
            if (window.opener) {
                window.opener.postMessage(
                    request.request,
                    location.protocol + "//" + location.host
                );
            }
            return true;
        case "TIMEOUT":
            setTimeout(() => {
                store.dispatch({
                    type: "SERVER_MESSAGE",
                    message: {
                        type: "RESPONSE",
                        id: requestId,
                        response: {},
                    },
                });
            }, request.request);
            return true;
        case "NULL":
        case "RESET_REQUESTS":
        case "LOCAL_STORE":
            throw new Error("Should not be created");
    }
}

store.subscribe(() => {
    const state = store.getState();

    if (state.user !== null && !isEmpty(state.pendingRequests)) {
        for (const [requestId, request] of Object.entries(
            state.pendingRequests
        )) {
            dispatchRequest(requestId, request as any);
        }
        store.dispatch({
            type: "REQUESTS_DISPATCHED",
            requests: Object.keys(state.pendingRequests),
        });
    }
    if (state.waitingHash !== null) {
        return;
    }
    const correctHash = encodeState(state);
    if (correctHash !== null && window.location.hash !== correctHash) {
        window.location.hash = correctHash;
    }

    Sentry.configureScope((scope) => {
        scope.setUser({
            id: (state.user && state.user.id) || undefined,
            email: state.email || undefined,
        });
    });
});

(window as any).cloudinaryKeys = () => {
    const state = store.getState();
    if (state.user) {
        console.log("STATE", state.user);
        return (state.user as any).cloudinary_keys;
    } else {
        return null;
    }
};

function SetPageTitle() {
    const pageState = useSelector<State, State["pageState"]>(
        (state) => state.pageState
    );
    const cache = useQuickCache();
    const baseTitle = ROOT_PAGE.title(pageState, cache);
    const title =
        (ROOT_PAGE.hasUnsavedChanges(pageState) ? "* " : "") + baseTitle;
    React.useEffect(() => {
        document.title = title;
    }, [title]);
    return <></>;
}

store.dispatch({
    type: "HASHCHANGE",
    hash: window.location.hash,
});

const element = document.createElement("div");
document.body.appendChild(element);
ReactModal.setAppElement(element);

const root = ReactDOMClient.createRoot(element);

root.render(
    <DndWrapper>
        <QuickCache>
            <Provider store={store}>
                <SetPageTitle />
                <App />
            </Provider>
        </QuickCache>
    </DndWrapper>
);

document.addEventListener("visibilitychange", () => {
    store.dispatch({
        type: "VISIBILITY_CHANGE",
        value: document.visibilityState,
    });
});

const UNSAVED_MESSAGE = "There are unsaved changes";

window.addEventListener("beforeunload", (event) => {
    const state = store.getState();

    if (ROOT_PAGE.beforeUnload(state.pageState)) {
        return null;
    }

    if (ROOT_PAGE.hasUnsavedChanges(state.pageState)) {
        event.returnValue = UNSAVED_MESSAGE;
        return UNSAVED_MESSAGE;
    } else {
        return null;
    }
});

setInterval(() => {
    store.dispatch({
        type: "HEARTBEAT",
    });
}, 60000);
