import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { Optional } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiPersonWork, AI_PERSON_WORK_META } from "./table";

//!RecordWidget
export type AiPersonWorkWidgetData = AiPersonWork;

export const AiPersonWorkWidgetFields = {
    active: SwitchWidget,
    label: TextWidget,
    amazonLink: Optional(TextWidget),
    imageUrl: Optional(TextWidget),
};

export function AiPersonWorkWidgetComponent(
    widgets: AiPersonWorkWidgetWidgets,
    props: AiPersonWorkWidgetProps
) {
    const listItemContext = useListItemContext();
    return (
        <tr {...listItemContext.draggableProps}>
            <td>{listItemContext.dragHandle}</td>
            <td>
                <widgets.active />
            </td>
            <td>
                <widgets.label />
            </td>
            <td>
                <widgets.amazonLink />
            </td>
            <td>
                <widgets.imageUrl />
            </td>
        </tr>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiPersonWorkWidgetContext = WidgetContext<
    typeof AiPersonWorkWidgetFields.active
> &
    WidgetContext<typeof AiPersonWorkWidgetFields.label> &
    WidgetContext<typeof AiPersonWorkWidgetFields.amazonLink> &
    WidgetContext<typeof AiPersonWorkWidgetFields.imageUrl>;
type AiPersonWorkWidgetExtraProps = {};
type AiPersonWorkWidgetBaseState = {
    active: WidgetState<typeof AiPersonWorkWidgetFields.active>;
    label: WidgetState<typeof AiPersonWorkWidgetFields.label>;
    amazonLink: WidgetState<typeof AiPersonWorkWidgetFields.amazonLink>;
    imageUrl: WidgetState<typeof AiPersonWorkWidgetFields.imageUrl>;
};
export type AiPersonWorkWidgetState = AiPersonWorkWidgetBaseState;

type BaseAiPersonWorkWidgetAction =
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof AiPersonWorkWidgetFields.active>;
      }
    | {
          type: "LABEL";
          action: WidgetAction<typeof AiPersonWorkWidgetFields.label>;
      }
    | {
          type: "AMAZON_LINK";
          action: WidgetAction<typeof AiPersonWorkWidgetFields.amazonLink>;
      }
    | {
          type: "IMAGE_URL";
          action: WidgetAction<typeof AiPersonWorkWidgetFields.imageUrl>;
      };

export type AiPersonWorkWidgetAction = BaseAiPersonWorkWidgetAction;

export type AiPersonWorkWidgetProps = WidgetProps<
    AiPersonWorkWidgetState,
    AiPersonWorkWidgetData,
    AiPersonWorkWidgetAction,
    AiPersonWorkWidgetExtraProps
>;

function baseValidateAiPersonWorkWidget(
    data: AiPersonWorkWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiPersonWorkWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(
        AiPersonWorkWidgetFields.label,
        data.label,
        cache,
        "label",
        errors
    );
    subvalidate(
        AiPersonWorkWidgetFields.amazonLink,
        data.amazonLink,
        cache,
        "amazonLink",
        errors
    );
    subvalidate(
        AiPersonWorkWidgetFields.imageUrl,
        data.imageUrl,
        cache,
        "imageUrl",
        errors
    );
    return errors;
}
function baseAiPersonWorkWidgetReduce(
    state: AiPersonWorkWidgetState,
    data: AiPersonWorkWidgetData,
    action: BaseAiPersonWorkWidgetAction,
    context: AiPersonWorkWidgetContext
): WidgetResult<AiPersonWorkWidgetState, AiPersonWorkWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ACTIVE": {
            const inner = AiPersonWorkWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "LABEL": {
            const inner = AiPersonWorkWidgetFields.label.reduce(
                state.label,
                data.label,
                action.action,
                subcontext
            );
            return {
                state: { ...state, label: inner.state },
                data: { ...data, label: inner.data },
            };
        }
        case "AMAZON_LINK": {
            const inner = AiPersonWorkWidgetFields.amazonLink.reduce(
                state.amazonLink,
                data.amazonLink,
                action.action,
                subcontext
            );
            return {
                state: { ...state, amazonLink: inner.state },
                data: { ...data, amazonLink: inner.data },
            };
        }
        case "IMAGE_URL": {
            const inner = AiPersonWorkWidgetFields.imageUrl.reduce(
                state.imageUrl,
                data.imageUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, imageUrl: inner.state },
                data: { ...data, imageUrl: inner.data },
            };
        }
    }
}
export type AiPersonWorkWidgetReactContextType = {
    state: AiPersonWorkWidgetState;
    data: AiPersonWorkWidgetData;
    dispatch: (action: AiPersonWorkWidgetAction) => void;
    status: WidgetStatus;
};
export const AiPersonWorkWidgetReactContext = React.createContext<
    AiPersonWorkWidgetReactContextType | undefined
>(undefined);
export const AiPersonWorkWidgetWidgets = {
    active: function (
        props: WidgetExtraProps<typeof AiPersonWorkWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWorkWidgetReactContext
        ) as AiPersonWorkWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWorkWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWorkWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    label: function (
        props: WidgetExtraProps<typeof AiPersonWorkWidgetFields.label> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWorkWidgetReactContext
        ) as AiPersonWorkWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWorkWidgetFields.label>) =>
                context.dispatch({ type: "LABEL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "label", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWorkWidgetFields.label.component
                state={context.state.label}
                data={context.data.label}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Label"}
            />
        );
    },
    amazonLink: function (
        props: WidgetExtraProps<typeof AiPersonWorkWidgetFields.amazonLink> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWorkWidgetReactContext
        ) as AiPersonWorkWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiPersonWorkWidgetFields.amazonLink>
            ) => context.dispatch({ type: "AMAZON_LINK", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "amazonLink", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWorkWidgetFields.amazonLink.component
                state={context.state.amazonLink}
                data={context.data.amazonLink}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Amazon Link"}
            />
        );
    },
    imageUrl: function (
        props: WidgetExtraProps<typeof AiPersonWorkWidgetFields.imageUrl> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPersonWorkWidgetReactContext
        ) as AiPersonWorkWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPersonWorkWidgetFields.imageUrl>) =>
                context.dispatch({ type: "IMAGE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "imageUrl", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPersonWorkWidgetFields.imageUrl.component
                state={context.state.imageUrl}
                data={context.data.imageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image Url"}
            />
        );
    },
};
export const AiPersonWorkWidget: RecordWidget<
    AiPersonWorkWidgetState,
    AiPersonWorkWidgetData,
    AiPersonWorkWidgetContext,
    AiPersonWorkWidgetAction,
    AiPersonWorkWidgetExtraProps
> = {
    reactContext: AiPersonWorkWidgetReactContext,
    fieldWidgets: AiPersonWorkWidgetWidgets,
    dataMeta: AI_PERSON_WORK_META,
    initialize(
        data: AiPersonWorkWidgetData,
        context: AiPersonWorkWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiPersonWorkWidgetState, AiPersonWorkWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerActive = AiPersonWorkWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerLabel = AiPersonWorkWidgetFields.label.initialize(
            data.label,
            subcontext,
            subparameters.label
        );
        const innerAmazonLink = AiPersonWorkWidgetFields.amazonLink.initialize(
            data.amazonLink,
            subcontext,
            subparameters.amazonLink
        );
        const innerImageUrl = AiPersonWorkWidgetFields.imageUrl.initialize(
            data.imageUrl,
            subcontext,
            subparameters.imageUrl
        );
        let state = {
            active: innerActive.state,
            label: innerLabel.state,
            amazonLink: innerAmazonLink.state,
            imageUrl: innerImageUrl.state,
        };
        return {
            state,
            data: {
                ...data,
                active: innerActive.data,
                label: innerLabel.data,
                amazonLink: innerAmazonLink.data,
                imageUrl: innerImageUrl.data,
            },
        };
    },
    validate: baseValidateAiPersonWorkWidget,
    component: React.memo((props: AiPersonWorkWidgetProps) => {
        return (
            <AiPersonWorkWidgetReactContext.Provider value={props}>
                {AiPersonWorkWidgetComponent(AiPersonWorkWidgetWidgets, props)}
            </AiPersonWorkWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiPersonWorkWidgetReduce,
};

type AiPersonWorkWidgetWidgets = {
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWorkWidgetFields.active
        >
    >;
    label: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWorkWidgetFields.label
        >
    >;
    amazonLink: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWorkWidgetFields.amazonLink
        >
    >;
    imageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPersonWorkWidgetFields.imageUrl
        >
    >;
};
// END MAGIC -- DO NOT EDIT
