import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveButton } from "../../../clay/save-button";
import {
    FormField,
    Optional,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { slugCheckValidate } from "../../slugCheck";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { SchoolDegreeWidget } from "./degree-widget";
import { AiSchool, AI_SCHOOL_META } from "./table";

//!RecordWidget
export type AiSchoolWidgetData = AiSchool;

export const AiSchoolWidgetFields = {
    active: FormField(SwitchWidget),
    name: OptionalFormField(TextWidget),
    slug: FormField(TextWidget),
    logo_url: OptionalFormField(TextWidget),
    logo_source_url: OptionalFormField(TextWidget),
    short_description: OptionalFormField(TextWidget),
    description: Optional(HtmlEditorWidget),
    disciplines_text: Optional(HtmlEditorWidget),
    influential_alumni_text: Optional(HtmlEditorWidget),
    extra_content: Optional(HtmlEditorWidget),
    admissions_website: OptionalFormField(TextWidget),
    admissions_email: OptionalFormField(TextWidget),
    degrees: ListWidget(SchoolDegreeWidget, { emptyOk: true }),
};

export function validateAiSchoolWidget(data: AiSchool, cache: QuickCacheApi) {
    const errors = baseValidateAiSchoolWidget(data, cache);

    slugCheckValidate("AiSchool", data, cache, errors);

    return errors;
}

export function AiSchoolWidgetComponent(
    widgets: AiSchoolWidgetWidgets,
    props: AiSchoolWidgetProps
) {
    return (
        <>
            <Tabs defaultActivKey="first" id="school-tabs">
                <Tab eventKey="first" title="Main">
                    <div>
                        <a
                            href={
                                "https://wikidata.org/wiki/" +
                                props.data.wikidata_id
                            }
                        >
                            Source
                        </a>
                    </div>
                    <widgets.active />
                    <widgets.slug />
                    <widgets.name />
                    <widgets.admissions_website />
                    <widgets.admissions_email />
                    <widgets.logo_url />
                    <widgets.logo_source_url />
                    <widgets.short_description />
                </Tab>
                <Tab eventKey="description" title="Description">
                    <widgets.description />
                </Tab>
                <Tab eventKey="disciplines" title="Disciplines">
                    <widgets.disciplines_text />
                </Tab>
                <Tab eventKey="alumni" title="Influential Alumni">
                    <widgets.influential_alumni_text />
                </Tab>
                <Tab eventKey="extra" title="Extra">
                    <widgets.extra_content />
                </Tab>
                <Tab eventKey="degrees" title="Degrees">
                    <widgets.degrees extraItemForAdd containerClass="tbody" />
                </Tab>
            </Tabs>

            <SaveButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiSchoolWidgetContext = WidgetContext<typeof AiSchoolWidgetFields.active> &
    WidgetContext<typeof AiSchoolWidgetFields.name> &
    WidgetContext<typeof AiSchoolWidgetFields.slug> &
    WidgetContext<typeof AiSchoolWidgetFields.logo_url> &
    WidgetContext<typeof AiSchoolWidgetFields.logo_source_url> &
    WidgetContext<typeof AiSchoolWidgetFields.short_description> &
    WidgetContext<typeof AiSchoolWidgetFields.description> &
    WidgetContext<typeof AiSchoolWidgetFields.disciplines_text> &
    WidgetContext<typeof AiSchoolWidgetFields.influential_alumni_text> &
    WidgetContext<typeof AiSchoolWidgetFields.extra_content> &
    WidgetContext<typeof AiSchoolWidgetFields.admissions_website> &
    WidgetContext<typeof AiSchoolWidgetFields.admissions_email> &
    WidgetContext<typeof AiSchoolWidgetFields.degrees>;
type AiSchoolWidgetExtraProps = {};
type AiSchoolWidgetBaseState = {
    active: WidgetState<typeof AiSchoolWidgetFields.active>;
    name: WidgetState<typeof AiSchoolWidgetFields.name>;
    slug: WidgetState<typeof AiSchoolWidgetFields.slug>;
    logo_url: WidgetState<typeof AiSchoolWidgetFields.logo_url>;
    logo_source_url: WidgetState<typeof AiSchoolWidgetFields.logo_source_url>;
    short_description: WidgetState<
        typeof AiSchoolWidgetFields.short_description
    >;
    description: WidgetState<typeof AiSchoolWidgetFields.description>;
    disciplines_text: WidgetState<typeof AiSchoolWidgetFields.disciplines_text>;
    influential_alumni_text: WidgetState<
        typeof AiSchoolWidgetFields.influential_alumni_text
    >;
    extra_content: WidgetState<typeof AiSchoolWidgetFields.extra_content>;
    admissions_website: WidgetState<
        typeof AiSchoolWidgetFields.admissions_website
    >;
    admissions_email: WidgetState<typeof AiSchoolWidgetFields.admissions_email>;
    degrees: WidgetState<typeof AiSchoolWidgetFields.degrees>;
};
export type AiSchoolWidgetState = AiSchoolWidgetBaseState;

type BaseAiSchoolWidgetAction =
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof AiSchoolWidgetFields.active>;
      }
    | { type: "NAME"; action: WidgetAction<typeof AiSchoolWidgetFields.name> }
    | { type: "SLUG"; action: WidgetAction<typeof AiSchoolWidgetFields.slug> }
    | {
          type: "LOGO_URL";
          action: WidgetAction<typeof AiSchoolWidgetFields.logo_url>;
      }
    | {
          type: "LOGO_SOURCE_URL";
          action: WidgetAction<typeof AiSchoolWidgetFields.logo_source_url>;
      }
    | {
          type: "SHORT_DESCRIPTION";
          action: WidgetAction<typeof AiSchoolWidgetFields.short_description>;
      }
    | {
          type: "DESCRIPTION";
          action: WidgetAction<typeof AiSchoolWidgetFields.description>;
      }
    | {
          type: "DISCIPLINES_TEXT";
          action: WidgetAction<typeof AiSchoolWidgetFields.disciplines_text>;
      }
    | {
          type: "INFLUENTIAL_ALUMNI_TEXT";
          action: WidgetAction<
              typeof AiSchoolWidgetFields.influential_alumni_text
          >;
      }
    | {
          type: "EXTRA_CONTENT";
          action: WidgetAction<typeof AiSchoolWidgetFields.extra_content>;
      }
    | {
          type: "ADMISSIONS_WEBSITE";
          action: WidgetAction<typeof AiSchoolWidgetFields.admissions_website>;
      }
    | {
          type: "ADMISSIONS_EMAIL";
          action: WidgetAction<typeof AiSchoolWidgetFields.admissions_email>;
      }
    | {
          type: "DEGREES";
          action: WidgetAction<typeof AiSchoolWidgetFields.degrees>;
      };

export type AiSchoolWidgetAction = BaseAiSchoolWidgetAction;

export type AiSchoolWidgetProps = WidgetProps<
    AiSchoolWidgetState,
    AiSchoolWidgetData,
    AiSchoolWidgetAction,
    AiSchoolWidgetExtraProps
>;

function baseValidateAiSchoolWidget(
    data: AiSchoolWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiSchoolWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(AiSchoolWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(AiSchoolWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(
        AiSchoolWidgetFields.logo_url,
        data.logo_url,
        cache,
        "logo_url",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.logo_source_url,
        data.logo_source_url,
        cache,
        "logo_source_url",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.short_description,
        data.short_description,
        cache,
        "short_description",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.disciplines_text,
        data.disciplines_text,
        cache,
        "disciplines_text",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.influential_alumni_text,
        data.influential_alumni_text,
        cache,
        "influential_alumni_text",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.extra_content,
        data.extra_content,
        cache,
        "extra_content",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.admissions_website,
        data.admissions_website,
        cache,
        "admissions_website",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.admissions_email,
        data.admissions_email,
        cache,
        "admissions_email",
        errors
    );
    subvalidate(
        AiSchoolWidgetFields.degrees,
        data.degrees,
        cache,
        "degrees",
        errors
    );
    return errors;
}
function baseAiSchoolWidgetReduce(
    state: AiSchoolWidgetState,
    data: AiSchoolWidgetData,
    action: BaseAiSchoolWidgetAction,
    context: AiSchoolWidgetContext
): WidgetResult<AiSchoolWidgetState, AiSchoolWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ACTIVE": {
            const inner = AiSchoolWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "NAME": {
            const inner = AiSchoolWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SLUG": {
            const inner = AiSchoolWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "LOGO_URL": {
            const inner = AiSchoolWidgetFields.logo_url.reduce(
                state.logo_url,
                data.logo_url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, logo_url: inner.state },
                data: { ...data, logo_url: inner.data },
            };
        }
        case "LOGO_SOURCE_URL": {
            const inner = AiSchoolWidgetFields.logo_source_url.reduce(
                state.logo_source_url,
                data.logo_source_url,
                action.action,
                subcontext
            );
            return {
                state: { ...state, logo_source_url: inner.state },
                data: { ...data, logo_source_url: inner.data },
            };
        }
        case "SHORT_DESCRIPTION": {
            const inner = AiSchoolWidgetFields.short_description.reduce(
                state.short_description,
                data.short_description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, short_description: inner.state },
                data: { ...data, short_description: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = AiSchoolWidgetFields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "DISCIPLINES_TEXT": {
            const inner = AiSchoolWidgetFields.disciplines_text.reduce(
                state.disciplines_text,
                data.disciplines_text,
                action.action,
                subcontext
            );
            return {
                state: { ...state, disciplines_text: inner.state },
                data: { ...data, disciplines_text: inner.data },
            };
        }
        case "INFLUENTIAL_ALUMNI_TEXT": {
            const inner = AiSchoolWidgetFields.influential_alumni_text.reduce(
                state.influential_alumni_text,
                data.influential_alumni_text,
                action.action,
                subcontext
            );
            return {
                state: { ...state, influential_alumni_text: inner.state },
                data: { ...data, influential_alumni_text: inner.data },
            };
        }
        case "EXTRA_CONTENT": {
            const inner = AiSchoolWidgetFields.extra_content.reduce(
                state.extra_content,
                data.extra_content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, extra_content: inner.state },
                data: { ...data, extra_content: inner.data },
            };
        }
        case "ADMISSIONS_WEBSITE": {
            const inner = AiSchoolWidgetFields.admissions_website.reduce(
                state.admissions_website,
                data.admissions_website,
                action.action,
                subcontext
            );
            return {
                state: { ...state, admissions_website: inner.state },
                data: { ...data, admissions_website: inner.data },
            };
        }
        case "ADMISSIONS_EMAIL": {
            const inner = AiSchoolWidgetFields.admissions_email.reduce(
                state.admissions_email,
                data.admissions_email,
                action.action,
                subcontext
            );
            return {
                state: { ...state, admissions_email: inner.state },
                data: { ...data, admissions_email: inner.data },
            };
        }
        case "DEGREES": {
            const inner = AiSchoolWidgetFields.degrees.reduce(
                state.degrees,
                data.degrees,
                action.action,
                subcontext
            );
            return {
                state: { ...state, degrees: inner.state },
                data: { ...data, degrees: inner.data },
            };
        }
    }
}
export type AiSchoolWidgetReactContextType = {
    state: AiSchoolWidgetState;
    data: AiSchoolWidgetData;
    dispatch: (action: AiSchoolWidgetAction) => void;
    status: WidgetStatus;
};
export const AiSchoolWidgetReactContext = React.createContext<
    AiSchoolWidgetReactContextType | undefined
>(undefined);
export const AiSchoolWidgetWidgets = {
    active: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    logo_url: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.logo_url> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.logo_url>) =>
                context.dispatch({ type: "LOGO_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "logo_url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.logo_url.component
                state={context.state.logo_url}
                data={context.data.logo_url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Logo Url"}
            />
        );
    },
    logo_source_url: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.logo_source_url> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.logo_source_url
                >
            ) => context.dispatch({ type: "LOGO_SOURCE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "logo_source_url", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.logo_source_url.component
                state={context.state.logo_source_url}
                data={context.data.logo_source_url}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Logo Source Url"}
            />
        );
    },
    short_description: function (
        props: WidgetExtraProps<
            typeof AiSchoolWidgetFields.short_description
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.short_description
                >
            ) => context.dispatch({ type: "SHORT_DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "short_description",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.short_description.component
                state={context.state.short_description}
                data={context.data.short_description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Short Description"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.description> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.description>) =>
                context.dispatch({ type: "DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.description.component
                state={context.state.description}
                data={context.data.description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Description"}
            />
        );
    },
    disciplines_text: function (
        props: WidgetExtraProps<
            typeof AiSchoolWidgetFields.disciplines_text
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.disciplines_text
                >
            ) => context.dispatch({ type: "DISCIPLINES_TEXT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "disciplines_text", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.disciplines_text.component
                state={context.state.disciplines_text}
                data={context.data.disciplines_text}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Disciplines Text"}
            />
        );
    },
    influential_alumni_text: function (
        props: WidgetExtraProps<
            typeof AiSchoolWidgetFields.influential_alumni_text
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.influential_alumni_text
                >
            ) => context.dispatch({ type: "INFLUENTIAL_ALUMNI_TEXT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "influential_alumni_text",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.influential_alumni_text.component
                state={context.state.influential_alumni_text}
                data={context.data.influential_alumni_text}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Influential Alumni Text"}
            />
        );
    },
    extra_content: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.extra_content> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.extra_content>) =>
                context.dispatch({ type: "EXTRA_CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "extra_content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.extra_content.component
                state={context.state.extra_content}
                data={context.data.extra_content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Extra Content"}
            />
        );
    },
    admissions_website: function (
        props: WidgetExtraProps<
            typeof AiSchoolWidgetFields.admissions_website
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.admissions_website
                >
            ) => context.dispatch({ type: "ADMISSIONS_WEBSITE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "admissions_website",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.admissions_website.component
                state={context.state.admissions_website}
                data={context.data.admissions_website}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Admissions Website"}
            />
        );
    },
    admissions_email: function (
        props: WidgetExtraProps<
            typeof AiSchoolWidgetFields.admissions_email
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiSchoolWidgetFields.admissions_email
                >
            ) => context.dispatch({ type: "ADMISSIONS_EMAIL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "admissions_email", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.admissions_email.component
                state={context.state.admissions_email}
                data={context.data.admissions_email}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Admissions Email"}
            />
        );
    },
    degrees: function (
        props: WidgetExtraProps<typeof AiSchoolWidgetFields.degrees> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiSchoolWidgetReactContext
        ) as AiSchoolWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiSchoolWidgetFields.degrees>) =>
                context.dispatch({ type: "DEGREES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "degrees", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiSchoolWidgetFields.degrees.component
                state={context.state.degrees}
                data={context.data.degrees}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Degrees"}
            />
        );
    },
};
export const AiSchoolWidget: RecordWidget<
    AiSchoolWidgetState,
    AiSchoolWidgetData,
    AiSchoolWidgetContext,
    AiSchoolWidgetAction,
    AiSchoolWidgetExtraProps
> = {
    reactContext: AiSchoolWidgetReactContext,
    fieldWidgets: AiSchoolWidgetWidgets,
    dataMeta: AI_SCHOOL_META,
    initialize(
        data: AiSchoolWidgetData,
        context: AiSchoolWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiSchoolWidgetState, AiSchoolWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerActive = AiSchoolWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerName = AiSchoolWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerSlug = AiSchoolWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerLogoUrl = AiSchoolWidgetFields.logo_url.initialize(
            data.logo_url,
            subcontext,
            subparameters.logo_url
        );
        const innerLogoSourceUrl = AiSchoolWidgetFields.logo_source_url.initialize(
            data.logo_source_url,
            subcontext,
            subparameters.logo_source_url
        );
        const innerShortDescription = AiSchoolWidgetFields.short_description.initialize(
            data.short_description,
            subcontext,
            subparameters.short_description
        );
        const innerDescription = AiSchoolWidgetFields.description.initialize(
            data.description,
            subcontext,
            subparameters.description
        );
        const innerDisciplinesText = AiSchoolWidgetFields.disciplines_text.initialize(
            data.disciplines_text,
            subcontext,
            subparameters.disciplines_text
        );
        const innerInfluentialAlumniText = AiSchoolWidgetFields.influential_alumni_text.initialize(
            data.influential_alumni_text,
            subcontext,
            subparameters.influential_alumni_text
        );
        const innerExtraContent = AiSchoolWidgetFields.extra_content.initialize(
            data.extra_content,
            subcontext,
            subparameters.extra_content
        );
        const innerAdmissionsWebsite = AiSchoolWidgetFields.admissions_website.initialize(
            data.admissions_website,
            subcontext,
            subparameters.admissions_website
        );
        const innerAdmissionsEmail = AiSchoolWidgetFields.admissions_email.initialize(
            data.admissions_email,
            subcontext,
            subparameters.admissions_email
        );
        const innerDegrees = AiSchoolWidgetFields.degrees.initialize(
            data.degrees,
            subcontext,
            subparameters.degrees
        );
        let state = {
            active: innerActive.state,
            name: innerName.state,
            slug: innerSlug.state,
            logo_url: innerLogoUrl.state,
            logo_source_url: innerLogoSourceUrl.state,
            short_description: innerShortDescription.state,
            description: innerDescription.state,
            disciplines_text: innerDisciplinesText.state,
            influential_alumni_text: innerInfluentialAlumniText.state,
            extra_content: innerExtraContent.state,
            admissions_website: innerAdmissionsWebsite.state,
            admissions_email: innerAdmissionsEmail.state,
            degrees: innerDegrees.state,
        };
        return {
            state,
            data: {
                ...data,
                active: innerActive.data,
                name: innerName.data,
                slug: innerSlug.data,
                logo_url: innerLogoUrl.data,
                logo_source_url: innerLogoSourceUrl.data,
                short_description: innerShortDescription.data,
                description: innerDescription.data,
                disciplines_text: innerDisciplinesText.data,
                influential_alumni_text: innerInfluentialAlumniText.data,
                extra_content: innerExtraContent.data,
                admissions_website: innerAdmissionsWebsite.data,
                admissions_email: innerAdmissionsEmail.data,
                degrees: innerDegrees.data,
            },
        };
    },
    validate: validateAiSchoolWidget,
    component: React.memo((props: AiSchoolWidgetProps) => {
        return (
            <AiSchoolWidgetReactContext.Provider value={props}>
                {AiSchoolWidgetComponent(AiSchoolWidgetWidgets, props)}
            </AiSchoolWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiSchoolWidgetReduce,
};

type AiSchoolWidgetWidgets = {
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.active
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.name
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.slug
        >
    >;
    logo_url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.logo_url
        >
    >;
    logo_source_url: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.logo_source_url
        >
    >;
    short_description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.short_description
        >
    >;
    description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.description
        >
    >;
    disciplines_text: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.disciplines_text
        >
    >;
    influential_alumni_text: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.influential_alumni_text
        >
    >;
    extra_content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.extra_content
        >
    >;
    admissions_website: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.admissions_website
        >
    >;
    admissions_email: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.admissions_email
        >
    >;
    degrees: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiSchoolWidgetFields.degrees
        >
    >;
};
// END MAGIC -- DO NOT EDIT
