import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type Document = {
    id: UUID;
    recordVersion: Version;
    slug: string;
    file: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type DocumentJSON = {
    id: string;
    recordVersion: number | null;
    slug: string;
    file: string;
};

export function JSONToDocument(json: DocumentJSON): Document {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        slug: json.slug,
        file: json.file,
    };
}
export type DocumentBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    slug?: string;
    file?: string;
};

export function newDocument(): Document {
    return JSONToDocument(repairDocumentJSON(undefined));
}
export function repairDocumentJSON(
    json: DocumentBrokenJSON | undefined
): DocumentJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            slug: json.slug || "",
            file: json.file || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            slug: undefined || "",
            file: undefined || "",
        };
    }
}

export function DocumentToJSON(value: Document): DocumentJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        slug: value.slug,
        file: value.file,
    };
}

export const DOCUMENT_META: RecordMeta<
    Document,
    DocumentJSON,
    DocumentBrokenJSON
> = {
    name: "Document",
    type: "record",
    repair: repairDocumentJSON,
    toJSON: DocumentToJSON,
    fromJSON: JSONToDocument,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        slug: { type: "string" },
        file: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
