import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TableRow } from "../../../clay/widgets/TableRow";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AiMenuItem, AI_MENU_ITEM_META } from "./table";

//!RecordWidget
export type AiMenuItemWidgetData = AiMenuItem;

export const AiMenuItemWidgetFields = {
    name: TextWidget,
    link: TextWidget,
    emphasized: SwitchWidget,
};

function AiMenuItemWidgetComponent(
    widgets: AiMenuItemWidgetWidgets,
    props: AiMenuItemWidgetProps
) {
    return (
        <TableRow>
            <widgets.name />
            <widgets.link />
            <widgets.emphasized />
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiMenuItemWidgetContext = WidgetContext<
    typeof AiMenuItemWidgetFields.name
> &
    WidgetContext<typeof AiMenuItemWidgetFields.link> &
    WidgetContext<typeof AiMenuItemWidgetFields.emphasized>;
type AiMenuItemWidgetExtraProps = {};
type AiMenuItemWidgetBaseState = {
    name: WidgetState<typeof AiMenuItemWidgetFields.name>;
    link: WidgetState<typeof AiMenuItemWidgetFields.link>;
    emphasized: WidgetState<typeof AiMenuItemWidgetFields.emphasized>;
};
export type AiMenuItemWidgetState = AiMenuItemWidgetBaseState;

type BaseAiMenuItemWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AiMenuItemWidgetFields.name> }
    | { type: "LINK"; action: WidgetAction<typeof AiMenuItemWidgetFields.link> }
    | {
          type: "EMPHASIZED";
          action: WidgetAction<typeof AiMenuItemWidgetFields.emphasized>;
      };

export type AiMenuItemWidgetAction = BaseAiMenuItemWidgetAction;

export type AiMenuItemWidgetProps = WidgetProps<
    AiMenuItemWidgetState,
    AiMenuItemWidgetData,
    AiMenuItemWidgetAction,
    AiMenuItemWidgetExtraProps
>;

function baseValidateAiMenuItemWidget(
    data: AiMenuItemWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiMenuItemWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(AiMenuItemWidgetFields.link, data.link, cache, "link", errors);
    subvalidate(
        AiMenuItemWidgetFields.emphasized,
        data.emphasized,
        cache,
        "emphasized",
        errors
    );
    return errors;
}
function baseAiMenuItemWidgetReduce(
    state: AiMenuItemWidgetState,
    data: AiMenuItemWidgetData,
    action: BaseAiMenuItemWidgetAction,
    context: AiMenuItemWidgetContext
): WidgetResult<AiMenuItemWidgetState, AiMenuItemWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiMenuItemWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "LINK": {
            const inner = AiMenuItemWidgetFields.link.reduce(
                state.link,
                data.link,
                action.action,
                subcontext
            );
            return {
                state: { ...state, link: inner.state },
                data: { ...data, link: inner.data },
            };
        }
        case "EMPHASIZED": {
            const inner = AiMenuItemWidgetFields.emphasized.reduce(
                state.emphasized,
                data.emphasized,
                action.action,
                subcontext
            );
            return {
                state: { ...state, emphasized: inner.state },
                data: { ...data, emphasized: inner.data },
            };
        }
    }
}
export type AiMenuItemWidgetReactContextType = {
    state: AiMenuItemWidgetState;
    data: AiMenuItemWidgetData;
    dispatch: (action: AiMenuItemWidgetAction) => void;
    status: WidgetStatus;
};
export const AiMenuItemWidgetReactContext = React.createContext<
    AiMenuItemWidgetReactContextType | undefined
>(undefined);
export const AiMenuItemWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiMenuItemWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuItemWidgetReactContext
        ) as AiMenuItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenuItemWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuItemWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    link: function (
        props: WidgetExtraProps<typeof AiMenuItemWidgetFields.link> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuItemWidgetReactContext
        ) as AiMenuItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenuItemWidgetFields.link>) =>
                context.dispatch({ type: "LINK", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "link", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuItemWidgetFields.link.component
                state={context.state.link}
                data={context.data.link}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Link"}
            />
        );
    },
    emphasized: function (
        props: WidgetExtraProps<typeof AiMenuItemWidgetFields.emphasized> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenuItemWidgetReactContext
        ) as AiMenuItemWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenuItemWidgetFields.emphasized>) =>
                context.dispatch({ type: "EMPHASIZED", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "emphasized", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenuItemWidgetFields.emphasized.component
                state={context.state.emphasized}
                data={context.data.emphasized}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Emphasized"}
            />
        );
    },
};
export const AiMenuItemWidget: RecordWidget<
    AiMenuItemWidgetState,
    AiMenuItemWidgetData,
    AiMenuItemWidgetContext,
    AiMenuItemWidgetAction,
    AiMenuItemWidgetExtraProps
> = {
    reactContext: AiMenuItemWidgetReactContext,
    fieldWidgets: AiMenuItemWidgetWidgets,
    dataMeta: AI_MENU_ITEM_META,
    initialize(
        data: AiMenuItemWidgetData,
        context: AiMenuItemWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiMenuItemWidgetState, AiMenuItemWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiMenuItemWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerLink = AiMenuItemWidgetFields.link.initialize(
            data.link,
            subcontext,
            subparameters.link
        );
        const innerEmphasized = AiMenuItemWidgetFields.emphasized.initialize(
            data.emphasized,
            subcontext,
            subparameters.emphasized
        );
        let state = {
            name: innerName.state,
            link: innerLink.state,
            emphasized: innerEmphasized.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                link: innerLink.data,
                emphasized: innerEmphasized.data,
            },
        };
    },
    validate: baseValidateAiMenuItemWidget,
    component: React.memo((props: AiMenuItemWidgetProps) => {
        return (
            <AiMenuItemWidgetReactContext.Provider value={props}>
                {AiMenuItemWidgetComponent(AiMenuItemWidgetWidgets, props)}
            </AiMenuItemWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiMenuItemWidgetReduce,
};

type AiMenuItemWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuItemWidgetFields.name
        >
    >;
    link: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuItemWidgetFields.link
        >
    >;
    emphasized: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenuItemWidgetFields.emphasized
        >
    >;
};
// END MAGIC -- DO NOT EDIT
