import { User } from "@sentry/node";
import dateParse from "date-fns/parseISO";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type RedirectLog = {
    id: UUID;
    recordVersion: Version;
    redirect: Link<Redirect>;
    date: Date | null;
    referrer: string;
    userAgent: string;
};

//!Data
export type Redirect = {
    id: UUID;
    recordVersion: Version;
    source: string;
    destination: string;
    code: "301" | "307" | "404" | "410" | "451";
    addedUser: Link<User>;
    addedBy: Link<User>;
    addedDateTime: Date | null;
};

// BEGIN MAGIC -- DO NOT EDIT
export type RedirectLogJSON = {
    id: string;
    recordVersion: number | null;
    redirect: string | null;
    date: string | null;
    referrer: string;
    userAgent: string;
};

export function JSONToRedirectLog(json: RedirectLogJSON): RedirectLog {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        redirect: json.redirect,
        date: json.date !== null ? dateParse(json.date) : null,
        referrer: json.referrer,
        userAgent: json.userAgent,
    };
}
export type RedirectLogBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    redirect?: string | null;
    date?: string | null;
    referrer?: string;
    userAgent?: string;
};

export function newRedirectLog(): RedirectLog {
    return JSONToRedirectLog(repairRedirectLogJSON(undefined));
}
export function repairRedirectLogJSON(
    json: RedirectLogBrokenJSON | undefined
): RedirectLogJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            redirect: json.redirect || null,
            date: json.date || null,
            referrer: json.referrer || "",
            userAgent: json.userAgent || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            redirect: undefined || null,
            date: undefined || null,
            referrer: undefined || "",
            userAgent: undefined || "",
        };
    }
}

export function RedirectLogToJSON(value: RedirectLog): RedirectLogJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        redirect: value.redirect,
        date: value.date !== null ? value.date.toISOString() : null,
        referrer: value.referrer,
        userAgent: value.userAgent,
    };
}

export const REDIRECT_LOG_META: RecordMeta<
    RedirectLog,
    RedirectLogJSON,
    RedirectLogBrokenJSON
> = {
    name: "RedirectLog",
    type: "record",
    repair: repairRedirectLogJSON,
    toJSON: RedirectLogToJSON,
    fromJSON: JSONToRedirectLog,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        redirect: { type: "uuid", linkTo: "Redirect" },
        date: { type: "datetime" },
        referrer: { type: "string" },
        userAgent: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type RedirectJSON = {
    id: string;
    recordVersion: number | null;
    source: string;
    destination: string;
    code: string;
    addedUser: string | null;
    addedBy: string | null;
    addedDateTime: string | null;
};

export function JSONToRedirect(json: RedirectJSON): Redirect {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        source: json.source,
        destination: json.destination,
        code: json.code as any,
        addedUser: json.addedUser,
        addedBy: json.addedBy,
        addedDateTime:
            json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
    };
}
export type RedirectBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    source?: string;
    destination?: string;
    code?: string;
    addedUser?: string | null;
    addedBy?: string | null;
    addedDateTime?: string | null;
};

export function newRedirect(): Redirect {
    return JSONToRedirect(repairRedirectJSON(undefined));
}
export function repairRedirectJSON(
    json: RedirectBrokenJSON | undefined
): RedirectJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            source: json.source || "",
            destination: json.destination || "",
            code: json.code || "301",
            addedUser: json.addedUser || null,
            addedBy: json.addedBy || null,
            addedDateTime: json.addedDateTime || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            source: undefined || "",
            destination: undefined || "",
            code: undefined || "301",
            addedUser: undefined || null,
            addedBy: undefined || null,
            addedDateTime: undefined || null,
        };
    }
}

export function RedirectToJSON(value: Redirect): RedirectJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        source: value.source,
        destination: value.destination,
        code: value.code,
        addedUser: value.addedUser,
        addedBy: value.addedBy,
        addedDateTime:
            value.addedDateTime !== null
                ? value.addedDateTime.toISOString()
                : null,
    };
}

export const REDIRECT_META: RecordMeta<
    Redirect,
    RedirectJSON,
    RedirectBrokenJSON
> = {
    name: "Redirect",
    type: "record",
    repair: repairRedirectJSON,
    toJSON: RedirectToJSON,
    fromJSON: JSONToRedirect,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        source: { type: "string" },
        destination: { type: "string" },
        code: {
            type: "enum",
            values: ["301", "307", "404", "410", "451"],
        },
        addedUser: { type: "uuid", linkTo: "User" },
        addedBy: { type: "uuid", linkTo: "User" },
        addedDateTime: { type: "datetime" },
    },
    userFacingKey: "source",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
