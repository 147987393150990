import * as React from "react";
import { Form } from "react-bootstrap";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { SaveDeleteButton } from "../../clay/save-delete-button";
import { EmailWidget } from "../../clay/widgets/EmailWidget";
import { FormField } from "../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { SwitchWidget } from "../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { CONTENT_AREA } from "../styles";
import { RolesWidget } from "./role-widget";
import { User, USER_META } from "./table";

//!RecordWidget
export type UserWidgetData = User;

const UserWidgetFields = {
    name: FormField(TextWidget),
    accountEmail: FormField(EmailWidget),
    active: FormField(SwitchWidget),
    roles: RolesWidget,
};

function UserWidgetComponent(
    widgets: UserWidgetWidgets,
    props: UserWidgetProps
) {
    return (
        <>
            <widgets.name />
            <widgets.accountEmail label="Account Email (for Login)" />
            <widgets.active />
            <Form.Group {...CONTENT_AREA}>
                <Form.Label>Roles</Form.Label>
                <widgets.roles />
            </Form.Group>
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type UserWidgetContext = WidgetContext<typeof UserWidgetFields.name> &
    WidgetContext<typeof UserWidgetFields.accountEmail> &
    WidgetContext<typeof UserWidgetFields.active> &
    WidgetContext<typeof UserWidgetFields.roles>;
type UserWidgetExtraProps = {};
type UserWidgetBaseState = {
    name: WidgetState<typeof UserWidgetFields.name>;
    accountEmail: WidgetState<typeof UserWidgetFields.accountEmail>;
    active: WidgetState<typeof UserWidgetFields.active>;
    roles: WidgetState<typeof UserWidgetFields.roles>;
};
export type UserWidgetState = UserWidgetBaseState;

type BaseUserWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof UserWidgetFields.name> }
    | {
          type: "ACCOUNT_EMAIL";
          action: WidgetAction<typeof UserWidgetFields.accountEmail>;
      }
    | { type: "ACTIVE"; action: WidgetAction<typeof UserWidgetFields.active> }
    | { type: "ROLES"; action: WidgetAction<typeof UserWidgetFields.roles> };

export type UserWidgetAction = BaseUserWidgetAction;

export type UserWidgetProps = WidgetProps<
    UserWidgetState,
    UserWidgetData,
    UserWidgetAction,
    UserWidgetExtraProps
>;

function baseValidateUserWidget(data: UserWidgetData, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(UserWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        UserWidgetFields.accountEmail,
        data.accountEmail,
        cache,
        "accountEmail",
        errors
    );
    subvalidate(UserWidgetFields.active, data.active, cache, "active", errors);
    subvalidate(UserWidgetFields.roles, data.roles, cache, "roles", errors);
    return errors;
}
function baseUserWidgetReduce(
    state: UserWidgetState,
    data: UserWidgetData,
    action: BaseUserWidgetAction,
    context: UserWidgetContext
): WidgetResult<UserWidgetState, UserWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = UserWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "ACCOUNT_EMAIL": {
            const inner = UserWidgetFields.accountEmail.reduce(
                state.accountEmail,
                data.accountEmail,
                action.action,
                subcontext
            );
            return {
                state: { ...state, accountEmail: inner.state },
                data: { ...data, accountEmail: inner.data },
            };
        }
        case "ACTIVE": {
            const inner = UserWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "ROLES": {
            const inner = UserWidgetFields.roles.reduce(
                state.roles,
                data.roles,
                action.action,
                subcontext
            );
            return {
                state: { ...state, roles: inner.state },
                data: { ...data, roles: inner.data },
            };
        }
    }
}
export type UserWidgetReactContextType = {
    state: UserWidgetState;
    data: UserWidgetData;
    dispatch: (action: UserWidgetAction) => void;
    status: WidgetStatus;
};
export const UserWidgetReactContext = React.createContext<
    UserWidgetReactContextType | undefined
>(undefined);
export const UserWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof UserWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            UserWidgetReactContext
        ) as UserWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof UserWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <UserWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    accountEmail: function (
        props: WidgetExtraProps<typeof UserWidgetFields.accountEmail> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            UserWidgetReactContext
        ) as UserWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof UserWidgetFields.accountEmail>) =>
                context.dispatch({ type: "ACCOUNT_EMAIL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "accountEmail", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <UserWidgetFields.accountEmail.component
                state={context.state.accountEmail}
                data={context.data.accountEmail}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Account Email"}
            />
        );
    },
    active: function (
        props: WidgetExtraProps<typeof UserWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            UserWidgetReactContext
        ) as UserWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof UserWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <UserWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    roles: function (
        props: WidgetExtraProps<typeof UserWidgetFields.roles> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            UserWidgetReactContext
        ) as UserWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof UserWidgetFields.roles>) =>
                context.dispatch({ type: "ROLES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "roles", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <UserWidgetFields.roles.component
                state={context.state.roles}
                data={context.data.roles}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Roles"}
            />
        );
    },
};
export const UserWidget: RecordWidget<
    UserWidgetState,
    UserWidgetData,
    UserWidgetContext,
    UserWidgetAction,
    UserWidgetExtraProps
> = {
    reactContext: UserWidgetReactContext,
    fieldWidgets: UserWidgetWidgets,
    dataMeta: USER_META,
    initialize(
        data: UserWidgetData,
        context: UserWidgetContext,
        parameters?: string[]
    ): WidgetResult<UserWidgetState, UserWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = UserWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerAccountEmail = UserWidgetFields.accountEmail.initialize(
            data.accountEmail,
            subcontext,
            subparameters.accountEmail
        );
        const innerActive = UserWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerRoles = UserWidgetFields.roles.initialize(
            data.roles,
            subcontext,
            subparameters.roles
        );
        let state = {
            name: innerName.state,
            accountEmail: innerAccountEmail.state,
            active: innerActive.state,
            roles: innerRoles.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                accountEmail: innerAccountEmail.data,
                active: innerActive.data,
                roles: innerRoles.data,
            },
        };
    },
    validate: baseValidateUserWidget,
    component: React.memo((props: UserWidgetProps) => {
        return (
            <UserWidgetReactContext.Provider value={props}>
                {UserWidgetComponent(UserWidgetWidgets, props)}
            </UserWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseUserWidgetReduce,
};

type UserWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof UserWidgetFields.name
        >
    >;
    accountEmail: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof UserWidgetFields.accountEmail
        >
    >;
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof UserWidgetFields.active
        >
    >;
    roles: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof UserWidgetFields.roles
        >
    >;
};
// END MAGIC -- DO NOT EDIT
