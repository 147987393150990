import { GOOGLE_AUTH } from "../clay/google-auth";
import { openDirectConnection, StandardService } from "../clay/service";

export const SERVICE = new StandardService(openDirectConnection(), GOOGLE_AUTH);

window.addEventListener("storage", (event) => {
    if (event.key === "dropsheet-auth") {
        if (event.newValue === null) {
            SERVICE.emit("message", {
                type: "SET_USER" as "SET_USER",
                token: null,
                profile_image_url: null,
            });
        } else {
            SERVICE.emit("message", {
                type: "SET_USER",
                ...JSON.parse(event.newValue),
            });
        }
    }
});
