import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../version";

//!Data
export type AiAuthor = {
    id: UUID;
    recordVersion: Version;

    name: string;
    aboutPageSlug: string;
    url: string;
    imageUrl: string;
    honorificPrefix: string;
    jobTitle: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiAuthorJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    aboutPageSlug: string;
    url: string;
    imageUrl: string;
    honorificPrefix: string;
    jobTitle: string;
};

export function JSONToAiAuthor(json: AiAuthorJSON): AiAuthor {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        aboutPageSlug: json.aboutPageSlug,
        url: json.url,
        imageUrl: json.imageUrl,
        honorificPrefix: json.honorificPrefix,
        jobTitle: json.jobTitle,
    };
}
export type AiAuthorBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    aboutPageSlug?: string;
    url?: string;
    imageUrl?: string;
    honorificPrefix?: string;
    jobTitle?: string;
};

export function newAiAuthor(): AiAuthor {
    return JSONToAiAuthor(repairAiAuthorJSON(undefined));
}
export function repairAiAuthorJSON(
    json: AiAuthorBrokenJSON | undefined
): AiAuthorJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            aboutPageSlug: json.aboutPageSlug || "",
            url: json.url || "",
            imageUrl: json.imageUrl || "",
            honorificPrefix: json.honorificPrefix || "",
            jobTitle: json.jobTitle || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            aboutPageSlug: undefined || "",
            url: undefined || "",
            imageUrl: undefined || "",
            honorificPrefix: undefined || "",
            jobTitle: undefined || "",
        };
    }
}

export function AiAuthorToJSON(value: AiAuthor): AiAuthorJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        aboutPageSlug: value.aboutPageSlug,
        url: value.url,
        imageUrl: value.imageUrl,
        honorificPrefix: value.honorificPrefix,
        jobTitle: value.jobTitle,
    };
}

export const AI_AUTHOR_META: RecordMeta<
    AiAuthor,
    AiAuthorJSON,
    AiAuthorBrokenJSON
> = {
    name: "AiAuthor",
    type: "record",
    repair: repairAiAuthorJSON,
    toJSON: AiAuthorToJSON,
    fromJSON: JSONToAiAuthor,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        aboutPageSlug: { type: "string" },
        url: { type: "string" },
        imageUrl: { type: "string" },
        honorificPrefix: { type: "string" },
        jobTitle: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
