import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { clearCache } from "../cache";
import { AdGroupWidget } from "./widget";

export const AdGroupsPage = AdminCollectionPage({
    meta: AdGroupWidget,
    labelColumn: "name",
    urlPrefix: "/#/ai/ad-groups",
    postSave: () => {
        clearCache("basic-context");
    },
});
