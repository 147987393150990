import * as React from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../..//clay/widgets/index";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { SaveDeleteButton } from "../../clay/save-delete-button";
import { FormField, OptionalFormField } from "../../clay/widgets/FormField";
import { FieldRow } from "../../clay/widgets/layout";
import { LinkSetWidget } from "../../clay/widgets/link-set-widget";
import { ListWidget } from "../../clay/widgets/ListWidget";
import { SimpleListWrapper } from "../../clay/widgets/SimpleListWrapper";
import { SwitchWidget } from "../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ImageUrlWidget } from "../widgets/image-url-editor";
import { ConsultantContactWidget } from "./contact-widget";
import {
    Consultant,
    CONSULTANT_AFFILIATION_META,
    CONSULTANT_META,
    CONSULTANT_TAG_META,
} from "./table";
import { ConsultantTestimonialWidget } from "./testimonal-widget";

//!RecordWidget
export type ConsultantWidgetData = Consultant;

export const ConsultantWidgetFields = {
    active: FormField(SwitchWidget),
    slug: FormField(TextWidget),
    name: FormField(TextWidget),
    website: OptionalFormField(TextWidget),
    national: FormField(SwitchWidget),
    international: FormField(SwitchWidget),
    partner: FormField(SwitchWidget),
    services: OptionalFormField(ListWidget(SimpleListWrapper(TextWidget))),
    tags: OptionalFormField(
        LinkSetWidget({
            meta: CONSULTANT_TAG_META,
            name: (affiliation) => affiliation.name,
        })
    ),
    notes: OptionalFormField(TextAreaWidget),
    contacts: ListWidget(ConsultantContactWidget),
    testimonials: ListWidget(ConsultantTestimonialWidget, { emptyOk: true }),
    affiliations: OptionalFormField(
        LinkSetWidget({
            meta: CONSULTANT_AFFILIATION_META,
            name: (affiliation) => affiliation.acronym,
        })
    ),
    adImageUrl: OptionalFormField(ImageUrlWidget),
    summary: OptionalFormField(TextAreaWidget),
    logo: OptionalFormField(TextWidget),
    primaryColor: OptionalFormField(TextWidget),
    secondaryColor: OptionalFormField(TextWidget),
    tertiaryColor: OptionalFormField(TextWidget),
    clientServices: OptionalFormField(
        ListWidget(SimpleListWrapper(TextWidget))
    ),
};

function ConsultantWidgetComponent(
    widgets: ConsultantWidgetWidgets,
    props: ConsultantWidgetProps
) {
    return (
        <>
            <Tabs defaultActiveKey="main">
                <Tab eventKey="main" title="Main">
                    <FieldRow>
                        <widgets.slug />
                        <widgets.name />
                    </FieldRow>
                    <widgets.website />
                    <widgets.adImageUrl />
                    <FieldRow noExpand>
                        <widgets.active />
                        <widgets.national />
                        <widgets.international />
                        <widgets.partner />
                    </FieldRow>
                    <widgets.services extraItemForAdd />
                    <FieldRow>
                        <widgets.tags />
                        <widgets.affiliations />
                    </FieldRow>
                    <widgets.notes />
                    <Table>
                        <thead>
                            <th></th>
                            <th>Name</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Slug</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Link</th>
                        </thead>
                        <widgets.contacts
                            extraItemForAdd
                            containerClass="tbody"
                        />
                    </Table>
                </Tab>
                <Tab eventKey="connect-page" title="Connect Page">
                    <widgets.summary />
                    <widgets.logo />
                    <widgets.primaryColor label="Header Color" />
                    <widgets.secondaryColor label="Background Color" />
                    <widgets.tertiaryColor label="Form Field Border Color" />
                    <widgets.clientServices extraItemForAdd />
                    <Table>
                        <thead>
                            <th />
                            <th>Quote</th>
                            <th>Name</th>
                        </thead>
                        <widgets.testimonials
                            extraItemForAdd
                            containerClass="tbody"
                        />
                    </Table>
                </Tab>
            </Tabs>

            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type ConsultantWidgetContext = WidgetContext<
    typeof ConsultantWidgetFields.active
> &
    WidgetContext<typeof ConsultantWidgetFields.slug> &
    WidgetContext<typeof ConsultantWidgetFields.name> &
    WidgetContext<typeof ConsultantWidgetFields.website> &
    WidgetContext<typeof ConsultantWidgetFields.national> &
    WidgetContext<typeof ConsultantWidgetFields.international> &
    WidgetContext<typeof ConsultantWidgetFields.partner> &
    WidgetContext<typeof ConsultantWidgetFields.services> &
    WidgetContext<typeof ConsultantWidgetFields.tags> &
    WidgetContext<typeof ConsultantWidgetFields.notes> &
    WidgetContext<typeof ConsultantWidgetFields.contacts> &
    WidgetContext<typeof ConsultantWidgetFields.testimonials> &
    WidgetContext<typeof ConsultantWidgetFields.affiliations> &
    WidgetContext<typeof ConsultantWidgetFields.adImageUrl> &
    WidgetContext<typeof ConsultantWidgetFields.summary> &
    WidgetContext<typeof ConsultantWidgetFields.logo> &
    WidgetContext<typeof ConsultantWidgetFields.primaryColor> &
    WidgetContext<typeof ConsultantWidgetFields.secondaryColor> &
    WidgetContext<typeof ConsultantWidgetFields.tertiaryColor> &
    WidgetContext<typeof ConsultantWidgetFields.clientServices>;
type ConsultantWidgetExtraProps = {};
type ConsultantWidgetBaseState = {
    active: WidgetState<typeof ConsultantWidgetFields.active>;
    slug: WidgetState<typeof ConsultantWidgetFields.slug>;
    name: WidgetState<typeof ConsultantWidgetFields.name>;
    website: WidgetState<typeof ConsultantWidgetFields.website>;
    national: WidgetState<typeof ConsultantWidgetFields.national>;
    international: WidgetState<typeof ConsultantWidgetFields.international>;
    partner: WidgetState<typeof ConsultantWidgetFields.partner>;
    services: WidgetState<typeof ConsultantWidgetFields.services>;
    tags: WidgetState<typeof ConsultantWidgetFields.tags>;
    notes: WidgetState<typeof ConsultantWidgetFields.notes>;
    contacts: WidgetState<typeof ConsultantWidgetFields.contacts>;
    testimonials: WidgetState<typeof ConsultantWidgetFields.testimonials>;
    affiliations: WidgetState<typeof ConsultantWidgetFields.affiliations>;
    adImageUrl: WidgetState<typeof ConsultantWidgetFields.adImageUrl>;
    summary: WidgetState<typeof ConsultantWidgetFields.summary>;
    logo: WidgetState<typeof ConsultantWidgetFields.logo>;
    primaryColor: WidgetState<typeof ConsultantWidgetFields.primaryColor>;
    secondaryColor: WidgetState<typeof ConsultantWidgetFields.secondaryColor>;
    tertiaryColor: WidgetState<typeof ConsultantWidgetFields.tertiaryColor>;
    clientServices: WidgetState<typeof ConsultantWidgetFields.clientServices>;
};
export type ConsultantWidgetState = ConsultantWidgetBaseState;

type BaseConsultantWidgetAction =
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof ConsultantWidgetFields.active>;
      }
    | { type: "SLUG"; action: WidgetAction<typeof ConsultantWidgetFields.slug> }
    | { type: "NAME"; action: WidgetAction<typeof ConsultantWidgetFields.name> }
    | {
          type: "WEBSITE";
          action: WidgetAction<typeof ConsultantWidgetFields.website>;
      }
    | {
          type: "NATIONAL";
          action: WidgetAction<typeof ConsultantWidgetFields.national>;
      }
    | {
          type: "INTERNATIONAL";
          action: WidgetAction<typeof ConsultantWidgetFields.international>;
      }
    | {
          type: "PARTNER";
          action: WidgetAction<typeof ConsultantWidgetFields.partner>;
      }
    | {
          type: "SERVICES";
          action: WidgetAction<typeof ConsultantWidgetFields.services>;
      }
    | { type: "TAGS"; action: WidgetAction<typeof ConsultantWidgetFields.tags> }
    | {
          type: "NOTES";
          action: WidgetAction<typeof ConsultantWidgetFields.notes>;
      }
    | {
          type: "CONTACTS";
          action: WidgetAction<typeof ConsultantWidgetFields.contacts>;
      }
    | {
          type: "TESTIMONIALS";
          action: WidgetAction<typeof ConsultantWidgetFields.testimonials>;
      }
    | {
          type: "AFFILIATIONS";
          action: WidgetAction<typeof ConsultantWidgetFields.affiliations>;
      }
    | {
          type: "AD_IMAGE_URL";
          action: WidgetAction<typeof ConsultantWidgetFields.adImageUrl>;
      }
    | {
          type: "SUMMARY";
          action: WidgetAction<typeof ConsultantWidgetFields.summary>;
      }
    | { type: "LOGO"; action: WidgetAction<typeof ConsultantWidgetFields.logo> }
    | {
          type: "PRIMARY_COLOR";
          action: WidgetAction<typeof ConsultantWidgetFields.primaryColor>;
      }
    | {
          type: "SECONDARY_COLOR";
          action: WidgetAction<typeof ConsultantWidgetFields.secondaryColor>;
      }
    | {
          type: "TERTIARY_COLOR";
          action: WidgetAction<typeof ConsultantWidgetFields.tertiaryColor>;
      }
    | {
          type: "CLIENT_SERVICES";
          action: WidgetAction<typeof ConsultantWidgetFields.clientServices>;
      };

export type ConsultantWidgetAction = BaseConsultantWidgetAction;

export type ConsultantWidgetProps = WidgetProps<
    ConsultantWidgetState,
    ConsultantWidgetData,
    ConsultantWidgetAction,
    ConsultantWidgetExtraProps
>;

function baseValidateConsultantWidget(
    data: ConsultantWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ConsultantWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(ConsultantWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(ConsultantWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(
        ConsultantWidgetFields.website,
        data.website,
        cache,
        "website",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.national,
        data.national,
        cache,
        "national",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.international,
        data.international,
        cache,
        "international",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.partner,
        data.partner,
        cache,
        "partner",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.services,
        data.services,
        cache,
        "services",
        errors
    );
    subvalidate(ConsultantWidgetFields.tags, data.tags, cache, "tags", errors);
    subvalidate(
        ConsultantWidgetFields.notes,
        data.notes,
        cache,
        "notes",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.contacts,
        data.contacts,
        cache,
        "contacts",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.testimonials,
        data.testimonials,
        cache,
        "testimonials",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.affiliations,
        data.affiliations,
        cache,
        "affiliations",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.adImageUrl,
        data.adImageUrl,
        cache,
        "adImageUrl",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.summary,
        data.summary,
        cache,
        "summary",
        errors
    );
    subvalidate(ConsultantWidgetFields.logo, data.logo, cache, "logo", errors);
    subvalidate(
        ConsultantWidgetFields.primaryColor,
        data.primaryColor,
        cache,
        "primaryColor",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.secondaryColor,
        data.secondaryColor,
        cache,
        "secondaryColor",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.tertiaryColor,
        data.tertiaryColor,
        cache,
        "tertiaryColor",
        errors
    );
    subvalidate(
        ConsultantWidgetFields.clientServices,
        data.clientServices,
        cache,
        "clientServices",
        errors
    );
    return errors;
}
function baseConsultantWidgetReduce(
    state: ConsultantWidgetState,
    data: ConsultantWidgetData,
    action: BaseConsultantWidgetAction,
    context: ConsultantWidgetContext
): WidgetResult<ConsultantWidgetState, ConsultantWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "ACTIVE": {
            const inner = ConsultantWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "SLUG": {
            const inner = ConsultantWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "NAME": {
            const inner = ConsultantWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "WEBSITE": {
            const inner = ConsultantWidgetFields.website.reduce(
                state.website,
                data.website,
                action.action,
                subcontext
            );
            return {
                state: { ...state, website: inner.state },
                data: { ...data, website: inner.data },
            };
        }
        case "NATIONAL": {
            const inner = ConsultantWidgetFields.national.reduce(
                state.national,
                data.national,
                action.action,
                subcontext
            );
            return {
                state: { ...state, national: inner.state },
                data: { ...data, national: inner.data },
            };
        }
        case "INTERNATIONAL": {
            const inner = ConsultantWidgetFields.international.reduce(
                state.international,
                data.international,
                action.action,
                subcontext
            );
            return {
                state: { ...state, international: inner.state },
                data: { ...data, international: inner.data },
            };
        }
        case "PARTNER": {
            const inner = ConsultantWidgetFields.partner.reduce(
                state.partner,
                data.partner,
                action.action,
                subcontext
            );
            return {
                state: { ...state, partner: inner.state },
                data: { ...data, partner: inner.data },
            };
        }
        case "SERVICES": {
            const inner = ConsultantWidgetFields.services.reduce(
                state.services,
                data.services,
                action.action,
                subcontext
            );
            return {
                state: { ...state, services: inner.state },
                data: { ...data, services: inner.data },
            };
        }
        case "TAGS": {
            const inner = ConsultantWidgetFields.tags.reduce(
                state.tags,
                data.tags,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tags: inner.state },
                data: { ...data, tags: inner.data },
            };
        }
        case "NOTES": {
            const inner = ConsultantWidgetFields.notes.reduce(
                state.notes,
                data.notes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, notes: inner.state },
                data: { ...data, notes: inner.data },
            };
        }
        case "CONTACTS": {
            const inner = ConsultantWidgetFields.contacts.reduce(
                state.contacts,
                data.contacts,
                action.action,
                subcontext
            );
            return {
                state: { ...state, contacts: inner.state },
                data: { ...data, contacts: inner.data },
            };
        }
        case "TESTIMONIALS": {
            const inner = ConsultantWidgetFields.testimonials.reduce(
                state.testimonials,
                data.testimonials,
                action.action,
                subcontext
            );
            return {
                state: { ...state, testimonials: inner.state },
                data: { ...data, testimonials: inner.data },
            };
        }
        case "AFFILIATIONS": {
            const inner = ConsultantWidgetFields.affiliations.reduce(
                state.affiliations,
                data.affiliations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, affiliations: inner.state },
                data: { ...data, affiliations: inner.data },
            };
        }
        case "AD_IMAGE_URL": {
            const inner = ConsultantWidgetFields.adImageUrl.reduce(
                state.adImageUrl,
                data.adImageUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, adImageUrl: inner.state },
                data: { ...data, adImageUrl: inner.data },
            };
        }
        case "SUMMARY": {
            const inner = ConsultantWidgetFields.summary.reduce(
                state.summary,
                data.summary,
                action.action,
                subcontext
            );
            return {
                state: { ...state, summary: inner.state },
                data: { ...data, summary: inner.data },
            };
        }
        case "LOGO": {
            const inner = ConsultantWidgetFields.logo.reduce(
                state.logo,
                data.logo,
                action.action,
                subcontext
            );
            return {
                state: { ...state, logo: inner.state },
                data: { ...data, logo: inner.data },
            };
        }
        case "PRIMARY_COLOR": {
            const inner = ConsultantWidgetFields.primaryColor.reduce(
                state.primaryColor,
                data.primaryColor,
                action.action,
                subcontext
            );
            return {
                state: { ...state, primaryColor: inner.state },
                data: { ...data, primaryColor: inner.data },
            };
        }
        case "SECONDARY_COLOR": {
            const inner = ConsultantWidgetFields.secondaryColor.reduce(
                state.secondaryColor,
                data.secondaryColor,
                action.action,
                subcontext
            );
            return {
                state: { ...state, secondaryColor: inner.state },
                data: { ...data, secondaryColor: inner.data },
            };
        }
        case "TERTIARY_COLOR": {
            const inner = ConsultantWidgetFields.tertiaryColor.reduce(
                state.tertiaryColor,
                data.tertiaryColor,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tertiaryColor: inner.state },
                data: { ...data, tertiaryColor: inner.data },
            };
        }
        case "CLIENT_SERVICES": {
            const inner = ConsultantWidgetFields.clientServices.reduce(
                state.clientServices,
                data.clientServices,
                action.action,
                subcontext
            );
            return {
                state: { ...state, clientServices: inner.state },
                data: { ...data, clientServices: inner.data },
            };
        }
    }
}
export type ConsultantWidgetReactContextType = {
    state: ConsultantWidgetState;
    data: ConsultantWidgetData;
    dispatch: (action: ConsultantWidgetAction) => void;
    status: WidgetStatus;
};
export const ConsultantWidgetReactContext = React.createContext<
    ConsultantWidgetReactContextType | undefined
>(undefined);
export const ConsultantWidgetWidgets = {
    active: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    website: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.website> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.website>) =>
                context.dispatch({ type: "WEBSITE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "website", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.website.component
                state={context.state.website}
                data={context.data.website}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Website"}
            />
        );
    },
    national: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.national> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.national>) =>
                context.dispatch({ type: "NATIONAL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "national", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.national.component
                state={context.state.national}
                data={context.data.national}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "National"}
            />
        );
    },
    international: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.international> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantWidgetFields.international
                >
            ) => context.dispatch({ type: "INTERNATIONAL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "international", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.international.component
                state={context.state.international}
                data={context.data.international}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "International"}
            />
        );
    },
    partner: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.partner> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.partner>) =>
                context.dispatch({ type: "PARTNER", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "partner", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.partner.component
                state={context.state.partner}
                data={context.data.partner}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Partner"}
            />
        );
    },
    services: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.services> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.services>) =>
                context.dispatch({ type: "SERVICES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "services", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.services.component
                state={context.state.services}
                data={context.data.services}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Services"}
            />
        );
    },
    tags: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.tags> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.tags>) =>
                context.dispatch({ type: "TAGS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "tags", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.tags.component
                state={context.state.tags}
                data={context.data.tags}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Tags"}
            />
        );
    },
    notes: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.notes> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.notes>) =>
                context.dispatch({ type: "NOTES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "notes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.notes.component
                state={context.state.notes}
                data={context.data.notes}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Notes"}
            />
        );
    },
    contacts: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.contacts> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.contacts>) =>
                context.dispatch({ type: "CONTACTS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "contacts", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.contacts.component
                state={context.state.contacts}
                data={context.data.contacts}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Contacts"}
            />
        );
    },
    testimonials: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.testimonials> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantWidgetFields.testimonials>
            ) => context.dispatch({ type: "TESTIMONIALS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "testimonials", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.testimonials.component
                state={context.state.testimonials}
                data={context.data.testimonials}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Testimonials"}
            />
        );
    },
    affiliations: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.affiliations> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantWidgetFields.affiliations>
            ) => context.dispatch({ type: "AFFILIATIONS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "affiliations", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.affiliations.component
                state={context.state.affiliations}
                data={context.data.affiliations}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Affiliations"}
            />
        );
    },
    adImageUrl: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.adImageUrl> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.adImageUrl>) =>
                context.dispatch({ type: "AD_IMAGE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "adImageUrl", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.adImageUrl.component
                state={context.state.adImageUrl}
                data={context.data.adImageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ad Image Url"}
            />
        );
    },
    summary: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.summary> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.summary>) =>
                context.dispatch({ type: "SUMMARY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "summary", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.summary.component
                state={context.state.summary}
                data={context.data.summary}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Summary"}
            />
        );
    },
    logo: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.logo> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantWidgetFields.logo>) =>
                context.dispatch({ type: "LOGO", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "logo", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.logo.component
                state={context.state.logo}
                data={context.data.logo}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Logo"}
            />
        );
    },
    primaryColor: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.primaryColor> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof ConsultantWidgetFields.primaryColor>
            ) => context.dispatch({ type: "PRIMARY_COLOR", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "primaryColor", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.primaryColor.component
                state={context.state.primaryColor}
                data={context.data.primaryColor}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Primary Color"}
            />
        );
    },
    secondaryColor: function (
        props: WidgetExtraProps<
            typeof ConsultantWidgetFields.secondaryColor
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantWidgetFields.secondaryColor
                >
            ) => context.dispatch({ type: "SECONDARY_COLOR", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "secondaryColor", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.secondaryColor.component
                state={context.state.secondaryColor}
                data={context.data.secondaryColor}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Secondary Color"}
            />
        );
    },
    tertiaryColor: function (
        props: WidgetExtraProps<typeof ConsultantWidgetFields.tertiaryColor> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantWidgetFields.tertiaryColor
                >
            ) => context.dispatch({ type: "TERTIARY_COLOR", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "tertiaryColor", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.tertiaryColor.component
                state={context.state.tertiaryColor}
                data={context.data.tertiaryColor}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Tertiary Color"}
            />
        );
    },
    clientServices: function (
        props: WidgetExtraProps<
            typeof ConsultantWidgetFields.clientServices
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            ConsultantWidgetReactContext
        ) as ConsultantWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof ConsultantWidgetFields.clientServices
                >
            ) => context.dispatch({ type: "CLIENT_SERVICES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "clientServices", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantWidgetFields.clientServices.component
                state={context.state.clientServices}
                data={context.data.clientServices}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Client Services"}
            />
        );
    },
};
export const ConsultantWidget: RecordWidget<
    ConsultantWidgetState,
    ConsultantWidgetData,
    ConsultantWidgetContext,
    ConsultantWidgetAction,
    ConsultantWidgetExtraProps
> = {
    reactContext: ConsultantWidgetReactContext,
    fieldWidgets: ConsultantWidgetWidgets,
    dataMeta: CONSULTANT_META,
    initialize(
        data: ConsultantWidgetData,
        context: ConsultantWidgetContext,
        parameters?: string[]
    ): WidgetResult<ConsultantWidgetState, ConsultantWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerActive = ConsultantWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerSlug = ConsultantWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerName = ConsultantWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerWebsite = ConsultantWidgetFields.website.initialize(
            data.website,
            subcontext,
            subparameters.website
        );
        const innerNational = ConsultantWidgetFields.national.initialize(
            data.national,
            subcontext,
            subparameters.national
        );
        const innerInternational = ConsultantWidgetFields.international.initialize(
            data.international,
            subcontext,
            subparameters.international
        );
        const innerPartner = ConsultantWidgetFields.partner.initialize(
            data.partner,
            subcontext,
            subparameters.partner
        );
        const innerServices = ConsultantWidgetFields.services.initialize(
            data.services,
            subcontext,
            subparameters.services
        );
        const innerTags = ConsultantWidgetFields.tags.initialize(
            data.tags,
            subcontext,
            subparameters.tags
        );
        const innerNotes = ConsultantWidgetFields.notes.initialize(
            data.notes,
            subcontext,
            subparameters.notes
        );
        const innerContacts = ConsultantWidgetFields.contacts.initialize(
            data.contacts,
            subcontext,
            subparameters.contacts
        );
        const innerTestimonials = ConsultantWidgetFields.testimonials.initialize(
            data.testimonials,
            subcontext,
            subparameters.testimonials
        );
        const innerAffiliations = ConsultantWidgetFields.affiliations.initialize(
            data.affiliations,
            subcontext,
            subparameters.affiliations
        );
        const innerAdImageUrl = ConsultantWidgetFields.adImageUrl.initialize(
            data.adImageUrl,
            subcontext,
            subparameters.adImageUrl
        );
        const innerSummary = ConsultantWidgetFields.summary.initialize(
            data.summary,
            subcontext,
            subparameters.summary
        );
        const innerLogo = ConsultantWidgetFields.logo.initialize(
            data.logo,
            subcontext,
            subparameters.logo
        );
        const innerPrimaryColor = ConsultantWidgetFields.primaryColor.initialize(
            data.primaryColor,
            subcontext,
            subparameters.primaryColor
        );
        const innerSecondaryColor = ConsultantWidgetFields.secondaryColor.initialize(
            data.secondaryColor,
            subcontext,
            subparameters.secondaryColor
        );
        const innerTertiaryColor = ConsultantWidgetFields.tertiaryColor.initialize(
            data.tertiaryColor,
            subcontext,
            subparameters.tertiaryColor
        );
        const innerClientServices = ConsultantWidgetFields.clientServices.initialize(
            data.clientServices,
            subcontext,
            subparameters.clientServices
        );
        let state = {
            active: innerActive.state,
            slug: innerSlug.state,
            name: innerName.state,
            website: innerWebsite.state,
            national: innerNational.state,
            international: innerInternational.state,
            partner: innerPartner.state,
            services: innerServices.state,
            tags: innerTags.state,
            notes: innerNotes.state,
            contacts: innerContacts.state,
            testimonials: innerTestimonials.state,
            affiliations: innerAffiliations.state,
            adImageUrl: innerAdImageUrl.state,
            summary: innerSummary.state,
            logo: innerLogo.state,
            primaryColor: innerPrimaryColor.state,
            secondaryColor: innerSecondaryColor.state,
            tertiaryColor: innerTertiaryColor.state,
            clientServices: innerClientServices.state,
        };
        return {
            state,
            data: {
                ...data,
                active: innerActive.data,
                slug: innerSlug.data,
                name: innerName.data,
                website: innerWebsite.data,
                national: innerNational.data,
                international: innerInternational.data,
                partner: innerPartner.data,
                services: innerServices.data,
                tags: innerTags.data,
                notes: innerNotes.data,
                contacts: innerContacts.data,
                testimonials: innerTestimonials.data,
                affiliations: innerAffiliations.data,
                adImageUrl: innerAdImageUrl.data,
                summary: innerSummary.data,
                logo: innerLogo.data,
                primaryColor: innerPrimaryColor.data,
                secondaryColor: innerSecondaryColor.data,
                tertiaryColor: innerTertiaryColor.data,
                clientServices: innerClientServices.data,
            },
        };
    },
    validate: baseValidateConsultantWidget,
    component: React.memo((props: ConsultantWidgetProps) => {
        return (
            <ConsultantWidgetReactContext.Provider value={props}>
                {ConsultantWidgetComponent(ConsultantWidgetWidgets, props)}
            </ConsultantWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseConsultantWidgetReduce,
};

type ConsultantWidgetWidgets = {
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.active
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.slug
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.name
        >
    >;
    website: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.website
        >
    >;
    national: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.national
        >
    >;
    international: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.international
        >
    >;
    partner: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.partner
        >
    >;
    services: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.services
        >
    >;
    tags: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.tags
        >
    >;
    notes: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.notes
        >
    >;
    contacts: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.contacts
        >
    >;
    testimonials: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.testimonials
        >
    >;
    affiliations: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.affiliations
        >
    >;
    adImageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.adImageUrl
        >
    >;
    summary: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.summary
        >
    >;
    logo: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.logo
        >
    >;
    primaryColor: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.primaryColor
        >
    >;
    secondaryColor: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.secondaryColor
        >
    >;
    tertiaryColor: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.tertiaryColor
        >
    >;
    clientServices: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantWidgetFields.clientServices
        >
    >;
};
// END MAGIC -- DO NOT EDIT
