import { AdminTablePage } from "../../../clay/admin-table";
import { DocumentWidget } from "./widget";

export const DocumentsPage = AdminTablePage({
    rowWidget: DocumentWidget,
    columns: [
        {
            id: "slug",
            label: "Slug",
        },
        {
            id: "file",
            label: "File",
        },
    ],
    compare(x, y) {
        return x.slug.localeCompare(y.slug);
    },
});
