import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../version";

//!Data
export type AiCategory = {
    id: UUID;
    recordVersion: Version;

    name: string;
    slug: string;
    description: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiCategoryJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    slug: string;
    description: string;
};

export function JSONToAiCategory(json: AiCategoryJSON): AiCategory {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        slug: json.slug,
        description: json.description,
    };
}
export type AiCategoryBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    slug?: string;
    description?: string;
};

export function newAiCategory(): AiCategory {
    return JSONToAiCategory(repairAiCategoryJSON(undefined));
}
export function repairAiCategoryJSON(
    json: AiCategoryBrokenJSON | undefined
): AiCategoryJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            slug: json.slug || "",
            description: json.description || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            slug: undefined || "",
            description: undefined || "",
        };
    }
}

export function AiCategoryToJSON(value: AiCategory): AiCategoryJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        slug: value.slug,
        description: value.description,
    };
}

export const AI_CATEGORY_META: RecordMeta<
    AiCategory,
    AiCategoryJSON,
    AiCategoryBrokenJSON
> = {
    name: "AiCategory",
    type: "record",
    repair: repairAiCategoryJSON,
    toJSON: AiCategoryToJSON,
    fromJSON: JSONToAiCategory,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        slug: { type: "string" },
        description: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
