import PromiseWindow from "promise-window";

class AdaptedPromiseWindow extends PromiseWindow {
    constructor(uri: string) {
        super(uri);
    }
    _getFeatures() {
        return undefined;
    }
}

export function openWindow(url: string): Promise<any> {
    return new AdaptedPromiseWindow(url).open();
}
