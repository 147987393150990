import * as React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ConsultantTag, CONSULTANT_TAG_META } from "./table";

//!RecordWidget
export type ConsultantTagWidgetData = ConsultantTag;

export const ConsultantTagWidgetFields = {
    name: TextWidget,
};

function ConsultantTagWidgetComponent(
    widget: ConsultantTagWidgetWidgets,
    props: ConsultantTagWidgetProps
) {
    throw new Error("unreachable");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type ConsultantTagWidgetContext = WidgetContext<
    typeof ConsultantTagWidgetFields.name
>;
type ConsultantTagWidgetExtraProps = {};
type ConsultantTagWidgetBaseState = {
    name: WidgetState<typeof ConsultantTagWidgetFields.name>;
};
export type ConsultantTagWidgetState = ConsultantTagWidgetBaseState;

type BaseConsultantTagWidgetAction = {
    type: "NAME";
    action: WidgetAction<typeof ConsultantTagWidgetFields.name>;
};

export type ConsultantTagWidgetAction = BaseConsultantTagWidgetAction;

export type ConsultantTagWidgetProps = WidgetProps<
    ConsultantTagWidgetState,
    ConsultantTagWidgetData,
    ConsultantTagWidgetAction,
    ConsultantTagWidgetExtraProps
>;

function baseValidateConsultantTagWidget(
    data: ConsultantTagWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ConsultantTagWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    return errors;
}
function baseConsultantTagWidgetReduce(
    state: ConsultantTagWidgetState,
    data: ConsultantTagWidgetData,
    action: BaseConsultantTagWidgetAction,
    context: ConsultantTagWidgetContext
): WidgetResult<ConsultantTagWidgetState, ConsultantTagWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = ConsultantTagWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
    }
}
export type ConsultantTagWidgetReactContextType = {
    state: ConsultantTagWidgetState;
    data: ConsultantTagWidgetData;
    dispatch: (action: ConsultantTagWidgetAction) => void;
    status: WidgetStatus;
};
export const ConsultantTagWidgetReactContext = React.createContext<
    ConsultantTagWidgetReactContextType | undefined
>(undefined);
export const ConsultantTagWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof ConsultantTagWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ConsultantTagWidgetReactContext
        ) as ConsultantTagWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ConsultantTagWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ConsultantTagWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
};
export const ConsultantTagWidget: RecordWidget<
    ConsultantTagWidgetState,
    ConsultantTagWidgetData,
    ConsultantTagWidgetContext,
    ConsultantTagWidgetAction,
    ConsultantTagWidgetExtraProps
> = {
    reactContext: ConsultantTagWidgetReactContext,
    fieldWidgets: ConsultantTagWidgetWidgets,
    dataMeta: CONSULTANT_TAG_META,
    initialize(
        data: ConsultantTagWidgetData,
        context: ConsultantTagWidgetContext,
        parameters?: string[]
    ): WidgetResult<ConsultantTagWidgetState, ConsultantTagWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = ConsultantTagWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        let state = {
            name: innerName.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
            },
        };
    },
    validate: baseValidateConsultantTagWidget,
    component: React.memo((props: ConsultantTagWidgetProps) => {
        return (
            <ConsultantTagWidgetReactContext.Provider value={props}>
                {ConsultantTagWidgetComponent(
                    ConsultantTagWidgetWidgets,
                    props
                )}
            </ConsultantTagWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseConsultantTagWidgetReduce,
};

type ConsultantTagWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ConsultantTagWidgetFields.name
        >
    >;
};
// END MAGIC -- DO NOT EDIT
