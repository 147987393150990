import { AdminTablePage } from "../../../clay/admin-table";
import { AccreditationAgencyWidget } from "./widget";

export const AccreditationAgenciesPages = AdminTablePage({
    rowWidget: AccreditationAgencyWidget,
    compare: (lhs, rhs) => lhs.agencyId.localeCompare(rhs.agencyId),
    extraRequests: {
        disciplines: {
            type: "QUERY" as "QUERY",
            request: {
                tableName: "AiDiscipline",
                columns: ["id", "name"],
                sorts: ["name"],
            },
        },
    },
    columns: [
        {
            id: "agencyId",
            label: "Agency Id",
            width: 100,
        },
        {
            id: "name",
            label: "Name",
            width: 500,
        },
        {
            id: "category",
            label: "Category",
            width: 150,
        },
        {
            id: "discipline",
            label: "Discipline",
            width: 200,
        },
    ],
});
