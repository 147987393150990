import * as React from "react";
import AsyncSelect from "react-select/async";
import { compareTwoStrings } from "string-similarity";
import { Dictionary } from "../../clay/common";
import { Widget, WidgetStatus } from "../../clay/widgets/index";
import { SimpleAtomic } from "../../clay/widgets/simple-atomic";

export type ImageUrlWidgetAction =
    | {
          type: "SET";
          value: string;
      }
    | {
          type: "BLUR";
      };

type ImageUrlWidgetProps = {
    state: boolean;
    data: string;
    dispatch: (action: ImageUrlWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
    hideStatus?: boolean;
};

export type ImageUrlWidgetType = Widget<
    boolean,
    string,
    {},
    ImageUrlWidgetAction,
    {
        style?: React.CSSProperties;
        hideStatus?: boolean;
    }
>;

export const ImageUrlWidget: ImageUrlWidgetType = {
    ...SimpleAtomic,
    dataMeta: {
        type: "string",
    },
    initialize(data: string) {
        return {
            state: false,
            data,
        };
    },
    component({
        data,
        dispatch,
        status,
        state,
        style,
        hideStatus,
    }: ImageUrlWidgetProps) {
        const loadOptions = React.useCallback(async (query: string) => {
            const response = await fetch("/apix/image-search/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    expression: query,
                    max_results: 500,
                    with_field: ["context", "tags"],
                }),
            });
            const data = await response.json();
            console.log("GOT", data);
            const results = data.resources.map((row: any) => ({
                filename: row.secure_url,
                value: row.public_id,
            }));

            const distances: Dictionary<number> = {};
            for (const row of results) {
                distances[row.value] = compareTwoStrings(query, row.value);
            }

            results.sort(
                (a: any, b: any) => distances[b.value] - distances[a.value]
            );

            console.log(results);
            return results;
        }, []);

        const onInputChange = React.useCallback(
            (event: string) => {
                dispatch({
                    type: "SET",
                    value: event,
                });
            },
            [dispatch]
        );

        const onChange = React.useCallback(
            (event: any) => {
                console.log("OC");
                dispatch({
                    type: "SET",
                    value: event ? event.value : "",
                });
            },
            [dispatch]
        );

        const current = {
            filename: data,
            value: data,
        };

        return (
            <AsyncSelect
                onChange={onChange}
                value={current}
                cacheOptions
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.filename}
                isClearable={true}
            />
        );
    },
    reduce(
        state: boolean,
        data: string,
        action: ImageUrlWidgetAction,
        context: {}
    ) {
        switch (action.type) {
            case "SET":
                return {
                    state: true,
                    data: action.value,
                };
            case "BLUR":
                return {
                    state: false,
                    data: data.trim().replace(/ +/, " "),
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        }
    },
};
