import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { PagesPage } from "./pages-page";

export const SettingsPage = {
    ...PagesPage({
        title: "Settings",
        buttons: [],
    }),
    headerComponent: () => {
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item href="#/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ flexGrow: 1 }} />
            </>
        );
    },
};
