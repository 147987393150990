import { EventEmitter } from "events";
import ReconnectingWebsocket from "reconnecting-websocket";
import { Dictionary } from "../clay/common";
import { UserPermissions } from "../clay/server/api";

export type Status = {
    connected: boolean;
    pendingCount: number;
    cache: {
        syncTime: string;
    } | null;
    currentToken: string;
};

export type ServerMessage =
    | {
          type: "UPDATE_PROFILE";
          profile_image_url: string | null;
          email: string | null;
      }
    | {
          type: "UPDATE_USER";
          user: UserPermissions | null;
      }
    | {
          type: "RESPONSE";
          id: string;
          response: {};
      }
    | {
          type: "ERROR";
          status: string;
          id: string;
      }
    | {
          type: "UPDATE_STATUS";
          status: Status;
      };

export type Connection = {
    send: (message: any) => void;
    listen: (callback: (message: any) => void) => void;
    setUserToken: (token: string) => void;
};

export type AuthenticationUser = {
    id_token: string;
    email: string;
    image: string;
};

export type Authentication = {
    initialize: (userChange: (user: AuthenticationUser | null) => void) => void;
    signIn: () => void;
    signOut: () => void;
    getToken: () => string | null;
};

export class StandardService extends EventEmitter {
    _connection: Connection;
    authentication: Authentication;
    _handlers: Dictionary<{ resolve: (response: any) => void; message: any }>;
    tokens: Dictionary<number>;

    constructor(connection: Connection, authentication: Authentication) {
        super();
        this._handlers = {};
        this.authentication = authentication;
        this._connection = connection;
        connection.listen(this._handleMessage.bind(this));
        this.tokens = {};
        authentication.initialize(this.userChange.bind(this));
    }

    userChange(user: AuthenticationUser | null) {
        if (!user) {
            this.send({
                type: "LOGOUT",
            });
        } else {
            this._connection.setUserToken(user.id_token);

            this.emit("message", {
                type: "UPDATE_PROFILE",
                profile_image_url: user.image,
                email: user.email,
            });
        }
    }

    _handleMessage(message: any) {
        if (message.type === "RESPONSE") {
            const handler = this._handlers[message.id];
            if (handler !== undefined) {
                delete this._handlers[message.id];
                handler.resolve(message.response);
            }
            this.emit("message", message);
        } else if (message.type === "ERROR") {
            const handler = this._handlers[message.id];
            if (handler !== undefined) {
                delete this._handlers[message.id];
                this.emit("message", { ...message, request: handler.message });
            } else {
                this.emit("message", message);
            }
        } else if (message.type === "INVALIDATE_CACHE") {
            this.emit("cache-change", message);
        } else {
            this.emit("message", message);
        }
    }

    async send(message: any): Promise<any> {
        (await this._connection).send(message);
        if (message.id !== undefined) {
            return new Promise((resolve, reject) => {
                this._handlers[message.id] = { resolve, message };
            });
        }
    }

    sync() {
        this.send({
            type: "OFFLINE",
        });
    }

    login() {
        this.authentication.signIn();
    }

    getToken() {
        return this.authentication.getToken();
    }

    logout() {
        return this.authentication.signOut();
    }
}

export function openDirectConnection(): Connection {
    const socket = new ReconnectingWebsocket(
        (window.location.protocol === "http:" ? "ws:" : "wss:") +
            "//" +
            window.location.host +
            "/api/"
    );

    let currentToken: string | null = null;

    socket.addEventListener("open", function () {
        if (currentToken) {
            socket.send(
                JSON.stringify({
                    type: "SET_USER",
                    token: currentToken,
                })
            );
        }
    });

    return {
        send: (message: any) => socket.send(JSON.stringify(message)),
        listen: (callback: any) =>
            socket.addEventListener("message", (event) =>
                callback(JSON.parse(event.data))
            ),
        setUserToken: (token: string) => {
            socket.send(
                JSON.stringify({
                    type: "SET_USER",
                    token: token,
                })
            );
            currentToken = token;
        },
    };
}

let refreshed = false;
