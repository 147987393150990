export const STATES: { [key: string]: string } = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
};

export const US_STATES: { abbr: string; name: string; slug: string }[] = [
    { abbr: "AL", name: "Alabama", slug: "alabama" },
    { abbr: "AK", name: "Alaska", slug: "alaska" },
    { abbr: "AZ", name: "Arizona", slug: "arizona" },
    { abbr: "AR", name: "Arkansas", slug: "arkansas" },
    { abbr: "CA", name: "California", slug: "california" },
    { abbr: "CO", name: "Colorado", slug: "colorado" },
    { abbr: "CT", name: "Connecticut", slug: "connecticut" },
    { abbr: "DE", name: "Delaware", slug: "delaware" },
    { abbr: "DC", name: "District Of Columbia", slug: "dc" },
    { abbr: "FL", name: "Florida", slug: "florida" },
    { abbr: "GA", name: "Georgia", slug: "georgia" },
    { abbr: "HI", name: "Hawaii", slug: "hawaii" },
    { abbr: "ID", name: "Idaho", slug: "idaho" },
    { abbr: "IL", name: "Illinois", slug: "illinois" },
    { abbr: "IN", name: "Indiana", slug: "indiana" },
    { abbr: "IA", name: "Iowa", slug: "iowa" },
    { abbr: "KS", name: "Kansas", slug: "kansas" },
    { abbr: "KY", name: "Kentucky", slug: "kentucky" },
    { abbr: "LA", name: "Louisiana", slug: "louisiana" },
    { abbr: "ME", name: "Maine", slug: "maine" },
    { abbr: "MD", name: "Maryland", slug: "maryland" },
    { abbr: "MA", name: "Massachusetts", slug: "massachusetts" },
    { abbr: "MI", name: "Michigan", slug: "michigan" },
    { abbr: "MN", name: "Minnesota", slug: "minnesota" },
    { abbr: "MS", name: "Mississippi", slug: "mississippi" },
    { abbr: "MO", name: "Missouri", slug: "missouri" },
    { abbr: "MT", name: "Montana", slug: "montana" },
    { abbr: "NE", name: "Nebraska", slug: "nebraska" },
    { abbr: "NV", name: "Nevada", slug: "nevada" },
    { abbr: "NH", name: "New Hampshire", slug: "new-hampshire" },
    { abbr: "NJ", name: "New Jersey", slug: "new-jersey" },
    { abbr: "NM", name: "New Mexico", slug: "new-mexico" },
    { abbr: "NY", name: "New York", slug: "new-york" },
    { abbr: "NC", name: "North Carolina", slug: "north-carolina" },
    { abbr: "ND", name: "North Dakota", slug: "north-dakota" },
    { abbr: "OH", name: "Ohio", slug: "ohio" },
    { abbr: "OK", name: "Oklahoma", slug: "oklahoma" },
    { abbr: "OR", name: "Oregon", slug: "oregon" },
    { abbr: "PA", name: "Pennsylvania", slug: "pennsylvania" },
    { abbr: "RI", name: "Rhode Island", slug: "rhode-island" },
    { abbr: "SC", name: "South Carolina", slug: "south-carolina" },
    { abbr: "SD", name: "South Dakota", slug: "south-dakota" },
    { abbr: "TN", name: "Tennessee", slug: "tennessee" },
    { abbr: "TX", name: "Texas", slug: "texas" },
    { abbr: "UT", name: "Utah", slug: "utah" },
    { abbr: "VT", name: "Vermont", slug: "vermont" },
    { abbr: "VA", name: "Virginia", slug: "virginia" },
    { abbr: "WA", name: "Washington", slug: "washington" },
    { abbr: "WV", name: "West Virginia", slug: "west-virginia" },
    { abbr: "WI", name: "Wisconsin", slug: "wisconsin" },
    { abbr: "WY", name: "Wyoming", slug: "wyoming" },
];

export default STATES;
