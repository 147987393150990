import { Dictionary } from "../clay/common";
import { Filter, FILTER_META } from "../clay/filters/table";
import { RecordMeta } from "../clay/meta";
import {
    AccreditationAgency,
    ACCREDITATION_AGENCY_META,
} from "./ai/accreditation-agency/table";
import { Ad, AD_META } from "./ai/ad/table";
import { AdGroup, AD_GROUP_META } from "./ai/adgroup/table";
import { AiArticle, AI_ARTICLE_META } from "./ai/articles/table";
import { AiAuthor, AI_AUTHOR_META } from "./ai/authors/table";
import { AiCategory, AI_CATEGORY_META } from "./ai/categories/table";
import {
    AiDiscipline,
    AiSubdiscipline,
    AI_DISCIPLINE_META,
    AI_SUBDISCIPLINE_META,
} from "./ai/disciplines/table";
import { Document, DOCUMENT_META } from "./ai/documents/table";
import { AiHomePage, AI_HOME_PAGE_META } from "./ai/home/table";
import { AiMenus, AI_MENUS_META } from "./ai/menu/table";
import { AiPage, AI_PAGE_META } from "./ai/pages/table";
import { AiPerson, AI_PERSON_META } from "./ai/person/table";
import {
    Redirect,
    RedirectLog,
    REDIRECT_LOG_META,
    REDIRECT_META,
} from "./ai/redirect/table";
import { Replacement, REPLACEMENT_META } from "./ai/replace/table";
import {
    AiSchool,
    AiSchoolDegreeCategory,
    AiSchoolDegreeLevel,
    AI_SCHOOL_DEGREE_CATEGORY_META,
    AI_SCHOOL_DEGREE_LEVEL_META,
    AI_SCHOOL_META,
} from "./ai/schools/table";
import { AiShortcode, AI_SHORTCODE_META } from "./ai/shortcodes/table";
import {
    Consultant,
    ConsultantAffiliation,
    ConsultantConnect,
    ConsultantOpened,
    ConsultantTag,
    CONSULTANT_AFFILIATION_META,
    CONSULTANT_CONNECT_META,
    CONSULTANT_META,
    CONSULTANT_OPENED_META,
    CONSULTANT_TAG_META,
} from "./consultants/table";
import { Role, ROLE_META } from "./roles/table";
import { User, USER_META } from "./user/table";
import { View, VIEW_META } from "./views/table";

//!Tables
export type Tables = [
    AiSchoolDegreeLevel,
    AiSchoolDegreeCategory,
    AiMenus,
    ConsultantOpened,
    ConsultantConnect,
    ConsultantTag,
    ConsultantAffiliation,
    Consultant,
    AiAuthor,
    Redirect,
    Document,
    RedirectLog,
    User,
    View,
    Role,
    Filter,
    AiArticle,
    AiCategory,
    AiHomePage,
    AiPerson,

    AiDiscipline,
    AiSubdiscipline,

    AiSchool,

    Replacement,
    AccreditationAgency,
    AiPage,
    Ad,
    AdGroup,
    AiShortcode
];

// BEGIN MAGIC -- DO NOT EDIT
export const TABLES_META: Dictionary<RecordMeta<any, any, any>> = {
    AiSchoolDegreeLevel: AI_SCHOOL_DEGREE_LEVEL_META,
    AiSchoolDegreeCategory: AI_SCHOOL_DEGREE_CATEGORY_META,
    AiMenus: AI_MENUS_META,
    ConsultantOpened: CONSULTANT_OPENED_META,
    ConsultantConnect: CONSULTANT_CONNECT_META,
    ConsultantTag: CONSULTANT_TAG_META,
    ConsultantAffiliation: CONSULTANT_AFFILIATION_META,
    Consultant: CONSULTANT_META,
    AiAuthor: AI_AUTHOR_META,
    Redirect: REDIRECT_META,
    Document: DOCUMENT_META,
    RedirectLog: REDIRECT_LOG_META,
    User: USER_META,
    View: VIEW_META,
    Role: ROLE_META,
    Filter: FILTER_META,
    AiArticle: AI_ARTICLE_META,
    AiCategory: AI_CATEGORY_META,
    AiHomePage: AI_HOME_PAGE_META,
    AiPerson: AI_PERSON_META,
    AiDiscipline: AI_DISCIPLINE_META,
    AiSubdiscipline: AI_SUBDISCIPLINE_META,
    AiSchool: AI_SCHOOL_META,
    Replacement: REPLACEMENT_META,
    AccreditationAgency: ACCREDITATION_AGENCY_META,
    AiPage: AI_PAGE_META,
    Ad: AD_META,
    AdGroup: AD_GROUP_META,
    AiShortcode: AI_SHORTCODE_META,
};

// END MAGIC -- DO NOT EDIT
