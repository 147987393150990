import * as Sentry from "@sentry/browser";
import { pickBy } from "lodash-es";
import {
    parse as parseQueryString,
    stringify as stringifyQueryString,
} from "querystring";
import Random from "random-js";
import * as React from "react";
import { Dictionary } from "../clay/common";
import { Link } from "../clay/link";
import { PageRequest, ReduceResult } from "../clay/Page";
import { RequestHandle } from "../clay/requests";
import { RouterPageState } from "../clay/router-page";
import { UserPermissions } from "../clay/server/api";
import { ServerMessage, Status } from "../clay/service";
import { ROOT_PAGE } from "./pages";
import { User } from "./user/table";

type RandomConfig = {
    seed: number[];
    useCount: number;
};

export type State = {
    profile_image_url: string | null;
    email: string | null;
    user: null | UserPermissions;
    status: Status;
    pageState: RouterPageState;
    pendingRequests: Dictionary<RequestHandle<PageRequest, Action>>;
    dispatchedRequests: Dictionary<RequestHandle<PageRequest, Action>>;

    nextRequestId: number;
    random: RandomConfig;
    errors: ServerMessage[];
    waitingHash: string | null;
};

export type Action =
    | {
          type: "SERVER_MESSAGE";
          message: ServerMessage;
      }
    | {
          type: "SERVER_OPEN";
      }
    | {
          type: "HASHCHANGE";
          hash: string;
      }
    | {
          type: "REQUESTS_DISPATCHED";
          requests: string[];
      }
    | {
          type: "PAGE";
          action: any;
      }
    | {
          type: "VISIBILITY_CHANGE";
          value: string;
      }
    | {
          type: "HEARTBEAT";
      }
    | {
          type: "UNLOAD";
      }
    | {
          type: "CLOSE_ERROR";
          error: ServerMessage;
      };

function reducePage(
    state: State,
    process: (detail: State) => ReduceResult<RouterPageState, any>
): State {
    const reduced = process(state);

    let nextRequestId = state.nextRequestId;
    let pendingRequests = state.pendingRequests;
    let dispatchedRequests = state.dispatchedRequests;

    for (const request of reduced.requests) {
        if (request.type === "RESET_REQUESTS") {
            pendingRequests = {};
            dispatchedRequests = {};
        } else {
            pendingRequests = {
                ...pendingRequests,
                [nextRequestId]: request,
            };
            nextRequestId += 1;
        }
    }
    return {
        ...state,
        nextRequestId,
        pageState: reduced.state,
        pendingRequests,
        dispatchedRequests,
    };
}

function parseHash(hash: string) {
    const question = hash.indexOf("?");
    if (question === -1) {
        while (hash.endsWith("/")) {
            hash = hash.slice(0, hash.length - 1);
        }

        return {
            segments: hash.split("/").slice(1),
            parameters: {},
        };
    } else {
        const parameters = parseQueryString(hash.slice(question + 1));
        hash = hash.slice(0, question);
        while (hash.endsWith("/")) {
            hash = hash.slice(0, hash.length - 1);
        }
        const segments = hash.split("/").slice(1);
        return {
            segments,
            parameters,
        };
    }
}

export function innerReducer(
    state: State,
    action: Action,
    context: AppContext
): State {
    switch (action.type) {
        case "PAGE":
            return reducePage(state, (state) => {
                return ROOT_PAGE.reduce(
                    state.pageState,
                    action.action,
                    context
                );
            });
        case "VISIBILITY_CHANGE":
            if (action.value === "visible") {
                return reducePage(state, (state) => {
                    return ROOT_PAGE.reduce(
                        state.pageState,
                        { type: "PAGE_ACTIVATED" },
                        context
                    );
                });
            } else {
                return state;
            }
        case "HEARTBEAT":
            return reducePage(state, (state) => {
                return ROOT_PAGE.reduce(
                    state.pageState,
                    { type: "HEARTBEAT" },
                    context
                );
            });

        case "UNLOAD":
            return reducePage(state, (state) => {
                return ROOT_PAGE.reduce(
                    state.pageState,
                    { type: "UNLOAD" },
                    context
                );
            });
        case "CLOSE_ERROR":
            return {
                ...state,
                errors: state.errors.filter((error) => error !== action.error),
            };

        case "SERVER_MESSAGE":
            const message = action.message;
            switch (message.type) {
                case "UPDATE_STATUS": {
                    return {
                        ...state,
                        status: message.status,
                    };
                }
                case "UPDATE_PROFILE":
                    return {
                        ...state,
                        profile_image_url: message.profile_image_url,
                        email: message.email,
                    };
                case "UPDATE_USER":
                    return {
                        ...state,
                        user: message.user,
                    };

                case "RESPONSE":
                    state = reducePage(state, (state) => {
                        const request = state.dispatchedRequests[message.id];
                        if ((action as any).message.response.type === "ERROR") {
                            console.log("Request: ", request);
                            console.log("Error: ", action.message);
                        }
                        if (request) {
                            return ROOT_PAGE.reduce(
                                state.pageState,
                                (request as any).decorator(message.response),
                                context
                            );
                        } else {
                            return {
                                state: state.pageState,
                                requests: [],
                            };
                        }
                    });

                    return {
                        ...state,
                        dispatchedRequests: pickBy(
                            state.dispatchedRequests,
                            (_, key) => key !== message.id
                        ),
                    };
                case "ERROR": {
                    const request = state.dispatchedRequests[message.id];
                    const detail = {
                        ...message,
                        request,
                    };
                    Sentry.captureEvent({
                        message: message.status,
                        extra: detail,
                    });
                    return {
                        ...state,
                        errors: [detail, ...state.errors],
                    };
                }

                default:
                    return state;
            }
        case "REQUESTS_DISPATCHED":
            return {
                ...state,
                pendingRequests: pickBy(
                    state.pendingRequests,
                    (_, key) => action.requests.indexOf(key) === -1
                ),
                dispatchedRequests: {
                    ...state.dispatchedRequests,
                    ...pickBy(
                        state.pendingRequests,
                        (_, key) => action.requests.indexOf(key) !== -1
                    ),
                },
            };
        case "HASHCHANGE":
            if (state.user === null) {
                return {
                    ...state,
                    waitingHash: action.hash,
                };
            } else {
                const parsed_hash = parseHash(action.hash);
                return reducePage(
                    {
                        ...state,
                        waitingHash: null,
                    },
                    (currentPage) =>
                        ROOT_PAGE.reduce(
                            currentPage.pageState,
                            {
                                type: "UPDATE_PARAMETERS",
                                segments: parsed_hash.segments,
                                parameters: parsed_hash.parameters,
                            },
                            context
                        )
                );
            }
        default:
            return state;
    }
}

type AppContext = {
    random: Random;
    currentTime: Date;
    currentUserId: Link<User>;
};

export function createInitialState(): State {
    return {
        profile_image_url: null,
        email: null,
        status: {
            currentToken: "",
            connected: false,
            pendingCount: 0,
            cache: null,
        },
        user: null,
        nextRequestId: 0,
        pageState: {
            currentPage: "",
            currentPageState: {},
        },
        random: {
            useCount: 0,
            seed: (Random as any).generateEntropyArray(),
        },
        pendingRequests: {},
        dispatchedRequests: {},
        errors: [],
        waitingHash: null,
    };
}

export const StateContext = React.createContext(createInitialState());

export function useUser(): UserPermissions {
    const state = React.useContext(StateContext);
    if (!state.user) {
        throw new Error("invariant violation");
    }
    return state.user;
}

function reviveRandom(config: RandomConfig) {
    const random = Random.engines.mt19937();
    random.seedWithArray(config.seed);
    random.discard(config.useCount);
    return random;
}

export function encodeState(state: State): string {
    const encodedState = ROOT_PAGE.encodeState(state.pageState);
    return `${["#", ...encodedState.segments].join(
        "/"
    )}/?${stringifyQueryString(encodedState.parameters)}`;
}

export function reducer(state: State, action: Action): State {
    const random = reviveRandom(state.random);
    const context: AppContext = {
        random: new Random(random),
        currentTime: new Date(),
        currentUserId: state.user !== null ? state.user.id : "",
    };
    state = innerReducer(state, action, context);

    if (state.user !== null && state.waitingHash !== null) {
        context.currentUserId = state.user.id;
        state = innerReducer(
            state,
            {
                type: "HASHCHANGE",
                hash: state.waitingHash,
            },
            context
        );
    }

    return {
        ...state,
        random: {
            ...state.random,
            useCount: random.getUseCount(),
        },
    };
}
