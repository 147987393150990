import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { LinkWidget } from "../../../clay/widgets/link-widget";
import { AiAuthorWidget } from "./widget";

export const AiAuthorPage = AdminCollectionPage({
    urlPrefix: "#/ai/authors",
    labelColumn: "name",
    meta: AiAuthorWidget,
    title: "Authors",
});

export const AiAuthorLinkWidget = LinkWidget({
    table: "AiAuthor",
    nameColumn: "name",
});
