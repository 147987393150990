import { doQuery } from "../../../clay/api";
import { GridWithEditor } from "../../../clay/dataGrid/gridWithEditor";
import { LinkWidget } from "../../../clay/widgets/link-widget";
import { clearCache, clearUrlCache } from "../cache";
import { articlePath } from "./table";
import { AiArticleWidget } from "./widget";

export const AiArticlePage = GridWithEditor({
    prefix: "#/ai/articles",
    meta: AiArticleWidget,
    makeContext: (context) => context,
    fallbackSorts: ["title"],
    title: (record) => {
        return `Feature: ${record.title}`;
    },
    locked: (record) => {
        return record.status === "PUBLISHED";
    },
    applyName: (record, name) => ({
        ...record,
        name,
    }),
    postSave: (record) => {
        clearCache("school-ranking-data");

        doQuery({
            tableName: "AiCategory",
            columns: ["slug"],
            filters: [
                {
                    column: "id",
                    filter: {
                        equal: record.category,
                    },
                },
            ],
        }).then((result) => {
            const category = result.rows[0] && result.rows[0][0];
            clearUrlCache(
                articlePath(record, category && ({ slug: category } as any))
            );
            clearCache(
                "article-" + record.slug + "-" + record.kind + "-" + category
            );
        });
        return [];
    },
});

export const AiArticleLinkWidget = LinkWidget({
    table: "AiArticle",
    nameColumn: "title",
});
