import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "glamor";
import * as React from "react";
import { Button } from "react-bootstrap";
import { Dictionary } from "../../clay/common";
import { resolveType } from "../../clay/dataGrid/DataGrid";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { Optional } from "../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { useListItemContext } from "../../clay/widgets/ListWidget";
import { TextWidget } from "../../clay/widgets/TextWidget";
import { ColumnPickerWidget } from "./column-picker-widget";
import { FilterWidget } from "./filter-widget";
import { Column, COLUMN_META } from "./table";
import { WidthWidget } from "./width-widget";

//!RecordWidget
export type ColumnWidgetData = Column;

type ColumnWidgetExtraActions = {
    type: "SELECT_COLUMN";
    key: string;
    label: string;
};

function columnWidgetReduce(
    state: ColumnWidgetState,
    data: Column,
    action: ColumnWidgetAction,
    context: ColumnWidgetContext
) {
    switch (action.type) {
        case "SELECT_COLUMN":
            return {
                state,
                data: {
                    ...data,
                    column: action.key,
                    name: action.label,
                },
            };
        default:
            return baseColumnWidgetReduce(state, data, action, context);
    }
}

export type ColumnWidgetExtraProps = {
    table: string;
};

export const ColumnWidgetFields = {
    column: ColumnPickerWidget,
    name: TextWidget,
    width: WidthWidget,
    filter: Optional(FilterWidget),
};

const COLUMN_BOX = css({});

function ColumnWidgetComponent(
    widgets: ColumnWidgetWidgets,
    props: ColumnWidgetProps
) {
    const listItemContext = useListItemContext();
    return (
        <tr {...listItemContext.draggableProps} {...COLUMN_BOX}>
            <td>{listItemContext.dragHandle}</td>
            <td>
                <widgets.column
                    table={props.table}
                    onSelect={(key, label) =>
                        props.dispatch({
                            type: "SELECT_COLUMN",
                            key,
                            label,
                        })
                    }
                />
            </td>
            <td>
                <widgets.name />
            </td>
            <td>
                <widgets.width name={props.data.name} />
            </td>
            <td>
                {props.data.column && (
                    <widgets.filter
                        meta={resolveType(props.table, props.data.column)}
                    />
                )}
            </td>
            <td>
                {listItemContext.remove && (
                    <Button variant="danger" onClick={listItemContext.remove}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                )}
            </td>
        </tr>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type ColumnWidgetContext = WidgetContext<typeof ColumnWidgetFields.column> &
    WidgetContext<typeof ColumnWidgetFields.name> &
    WidgetContext<typeof ColumnWidgetFields.width> &
    WidgetContext<typeof ColumnWidgetFields.filter>;
type ColumnWidgetBaseState = {
    column: WidgetState<typeof ColumnWidgetFields.column>;
    name: WidgetState<typeof ColumnWidgetFields.name>;
    width: WidgetState<typeof ColumnWidgetFields.width>;
    filter: WidgetState<typeof ColumnWidgetFields.filter>;
};
export type ColumnWidgetState = ColumnWidgetBaseState;

type BaseColumnWidgetAction =
    | { type: "COLUMN"; action: WidgetAction<typeof ColumnWidgetFields.column> }
    | { type: "NAME"; action: WidgetAction<typeof ColumnWidgetFields.name> }
    | { type: "WIDTH"; action: WidgetAction<typeof ColumnWidgetFields.width> }
    | {
          type: "FILTER";
          action: WidgetAction<typeof ColumnWidgetFields.filter>;
      };

export type ColumnWidgetAction =
    | ColumnWidgetExtraActions
    | BaseColumnWidgetAction;

export type ColumnWidgetProps = WidgetProps<
    ColumnWidgetState,
    ColumnWidgetData,
    ColumnWidgetAction,
    ColumnWidgetExtraProps
> &
    ColumnWidgetExtraProps;

function baseValidateColumnWidget(
    data: ColumnWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        ColumnWidgetFields.column,
        data.column,
        cache,
        "column",
        errors
    );
    subvalidate(ColumnWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(ColumnWidgetFields.width, data.width, cache, "width", errors);
    subvalidate(
        ColumnWidgetFields.filter,
        data.filter,
        cache,
        "filter",
        errors
    );
    return errors;
}
function baseColumnWidgetReduce(
    state: ColumnWidgetState,
    data: ColumnWidgetData,
    action: BaseColumnWidgetAction,
    context: ColumnWidgetContext
): WidgetResult<ColumnWidgetState, ColumnWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "COLUMN": {
            const inner = ColumnWidgetFields.column.reduce(
                state.column,
                data.column,
                action.action,
                subcontext
            );
            return {
                state: { ...state, column: inner.state },
                data: { ...data, column: inner.data },
            };
        }
        case "NAME": {
            const inner = ColumnWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "WIDTH": {
            const inner = ColumnWidgetFields.width.reduce(
                state.width,
                data.width,
                action.action,
                subcontext
            );
            return {
                state: { ...state, width: inner.state },
                data: { ...data, width: inner.data },
            };
        }
        case "FILTER": {
            const inner = ColumnWidgetFields.filter.reduce(
                state.filter,
                data.filter,
                action.action,
                subcontext
            );
            return {
                state: { ...state, filter: inner.state },
                data: { ...data, filter: inner.data },
            };
        }
    }
}
export type ColumnWidgetReactContextType = {
    state: ColumnWidgetState;
    data: ColumnWidgetData;
    dispatch: (action: ColumnWidgetAction) => void;
    status: WidgetStatus;
};
export const ColumnWidgetReactContext = React.createContext<
    ColumnWidgetReactContextType | undefined
>(undefined);
export const ColumnWidgetWidgets = {
    column: function (
        props: WidgetExtraProps<typeof ColumnWidgetFields.column> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ColumnWidgetReactContext
        ) as ColumnWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ColumnWidgetFields.column>) =>
                context.dispatch({ type: "COLUMN", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "column", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ColumnWidgetFields.column.component
                state={context.state.column}
                data={context.data.column}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Column"}
            />
        );
    },
    name: function (
        props: WidgetExtraProps<typeof ColumnWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ColumnWidgetReactContext
        ) as ColumnWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ColumnWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ColumnWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    width: function (
        props: WidgetExtraProps<typeof ColumnWidgetFields.width> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ColumnWidgetReactContext
        ) as ColumnWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ColumnWidgetFields.width>) =>
                context.dispatch({ type: "WIDTH", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "width", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ColumnWidgetFields.width.component
                state={context.state.width}
                data={context.data.width}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Width"}
            />
        );
    },
    filter: function (
        props: WidgetExtraProps<typeof ColumnWidgetFields.filter> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            ColumnWidgetReactContext
        ) as ColumnWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof ColumnWidgetFields.filter>) =>
                context.dispatch({ type: "FILTER", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "filter", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <ColumnWidgetFields.filter.component
                state={context.state.filter}
                data={context.data.filter}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Filter"}
            />
        );
    },
};
export const ColumnWidget: RecordWidget<
    ColumnWidgetState,
    ColumnWidgetData,
    ColumnWidgetContext,
    ColumnWidgetAction,
    ColumnWidgetExtraProps
> = {
    reactContext: ColumnWidgetReactContext,
    fieldWidgets: ColumnWidgetWidgets,
    dataMeta: COLUMN_META,
    initialize(
        data: ColumnWidgetData,
        context: ColumnWidgetContext,
        parameters?: string[]
    ): WidgetResult<ColumnWidgetState, ColumnWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerColumn = ColumnWidgetFields.column.initialize(
            data.column,
            subcontext,
            subparameters.column
        );
        const innerName = ColumnWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerWidth = ColumnWidgetFields.width.initialize(
            data.width,
            subcontext,
            subparameters.width
        );
        const innerFilter = ColumnWidgetFields.filter.initialize(
            data.filter,
            subcontext,
            subparameters.filter
        );
        let state = {
            column: innerColumn.state,
            name: innerName.state,
            width: innerWidth.state,
            filter: innerFilter.state,
        };
        return {
            state,
            data: {
                ...data,
                column: innerColumn.data,
                name: innerName.data,
                width: innerWidth.data,
                filter: innerFilter.data,
            },
        };
    },
    validate: baseValidateColumnWidget,
    component: React.memo((props: ColumnWidgetProps) => {
        return (
            <ColumnWidgetReactContext.Provider value={props}>
                {ColumnWidgetComponent(ColumnWidgetWidgets, props)}
            </ColumnWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: columnWidgetReduce,
};

type ColumnWidgetWidgets = {
    column: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ColumnWidgetFields.column
        >
    >;
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ColumnWidgetFields.name
        >
    >;
    width: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ColumnWidgetFields.width
        >
    >;
    filter: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof ColumnWidgetFields.filter
        >
    >;
};
// END MAGIC -- DO NOT EDIT
