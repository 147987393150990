import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveButton } from "../../../clay/save-button";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TabsWidget } from "../../../clay/widgets/TabsWidget";
import { AiSiteMenuWidget } from "./menu-widget";
import { AiMenus, AI_MENUS_META } from "./table";

//!RecordWidget
export type AiMenusWidgetData = AiMenus;

export const AiMenusWidgetFields = {
    menus: TabsWidget(AiSiteMenuWidget, {}),
};

function AiMenusWidgetComponent(
    widgets: AiMenusWidgetWidgets,
    props: AiMenusWidgetProps
) {
    const actions = React.useCallback(
        (item: {}, index: number) => {
            return [
                {
                    label: "Remove",
                    action: () => {
                        props.dispatch({
                            type: "MENUS",
                            action: {
                                type: "REMOVE",
                                index,
                            },
                        });
                    },
                },
            ];
        },
        [props.dispatch]
    );
    return (
        <>
            <widgets.menus
                actions={actions}
                labelForItem={(item) => item.name}
                newLabel="New Menu"
            />
            <SaveButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiMenusWidgetContext = WidgetContext<typeof AiMenusWidgetFields.menus>;
type AiMenusWidgetExtraProps = {};
type AiMenusWidgetBaseState = {
    menus: WidgetState<typeof AiMenusWidgetFields.menus>;
};
export type AiMenusWidgetState = AiMenusWidgetBaseState;

type BaseAiMenusWidgetAction = {
    type: "MENUS";
    action: WidgetAction<typeof AiMenusWidgetFields.menus>;
};

export type AiMenusWidgetAction = BaseAiMenusWidgetAction;

export type AiMenusWidgetProps = WidgetProps<
    AiMenusWidgetState,
    AiMenusWidgetData,
    AiMenusWidgetAction,
    AiMenusWidgetExtraProps
>;

function baseValidateAiMenusWidget(
    data: AiMenusWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiMenusWidgetFields.menus, data.menus, cache, "menus", errors);
    return errors;
}
function baseAiMenusWidgetReduce(
    state: AiMenusWidgetState,
    data: AiMenusWidgetData,
    action: BaseAiMenusWidgetAction,
    context: AiMenusWidgetContext
): WidgetResult<AiMenusWidgetState, AiMenusWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "MENUS": {
            const inner = AiMenusWidgetFields.menus.reduce(
                state.menus,
                data.menus,
                action.action,
                subcontext
            );
            return {
                state: { ...state, menus: inner.state },
                data: { ...data, menus: inner.data },
            };
        }
    }
}
export type AiMenusWidgetReactContextType = {
    state: AiMenusWidgetState;
    data: AiMenusWidgetData;
    dispatch: (action: AiMenusWidgetAction) => void;
    status: WidgetStatus;
};
export const AiMenusWidgetReactContext = React.createContext<
    AiMenusWidgetReactContextType | undefined
>(undefined);
export const AiMenusWidgetWidgets = {
    menus: function (
        props: WidgetExtraProps<typeof AiMenusWidgetFields.menus> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiMenusWidgetReactContext
        ) as AiMenusWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiMenusWidgetFields.menus>) =>
                context.dispatch({ type: "MENUS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "menus", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiMenusWidgetFields.menus.component
                state={context.state.menus}
                data={context.data.menus}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Menus"}
            />
        );
    },
};
export const AiMenusWidget: RecordWidget<
    AiMenusWidgetState,
    AiMenusWidgetData,
    AiMenusWidgetContext,
    AiMenusWidgetAction,
    AiMenusWidgetExtraProps
> = {
    reactContext: AiMenusWidgetReactContext,
    fieldWidgets: AiMenusWidgetWidgets,
    dataMeta: AI_MENUS_META,
    initialize(
        data: AiMenusWidgetData,
        context: AiMenusWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiMenusWidgetState, AiMenusWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerMenus = AiMenusWidgetFields.menus.initialize(
            data.menus,
            subcontext,
            subparameters.menus
        );
        let state = {
            menus: innerMenus.state,
        };
        return {
            state,
            data: {
                ...data,
                menus: innerMenus.data,
            },
        };
    },
    validate: baseValidateAiMenusWidget,
    component: React.memo((props: AiMenusWidgetProps) => {
        return (
            <AiMenusWidgetReactContext.Provider value={props}>
                {AiMenusWidgetComponent(AiMenusWidgetWidgets, props)}
            </AiMenusWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiMenusWidgetReduce,
};

type AiMenusWidgetWidgets = {
    menus: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiMenusWidgetFields.menus
        >
    >;
};
// END MAGIC -- DO NOT EDIT
