import dateParse from "date-fns/parseISO";
import { Decimal } from "decimal.js";
import { Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type AiSchoolDegreeCategory = {
    id: UUID;
    recordVersion: Version;
    name: string;
};

//!Data
export type AiSchoolDegreeLevel = {
    id: UUID;
    recordVersion: Version;
    name: string;
    number: Quantity;
};

//!Data
export type AiSchoolDegree = {
    id: UUID;
    fullyOnline: boolean;
    category: Link<AiSchoolDegreeCategory>;
    legacyDegreeId: string;
    level: Link<AiSchoolDegreeLevel>;
    discipline: string;
    degreeHubPage: string;
    completion: "Yes" | "No" | "None Reported";
    degreeType: string;
    concentrations: string[];
    creditsNeeded: string;
    completionTime: string;
    programLink: string;
};

//!Data
export type AiSchool = {
    id: UUID;
    recordVersion: Version;

    active: boolean;
    slug: string;
    name: string;

    description: string;
    short_description: string;
    extra_content: string;
    meta_description: string;
    wikidata_id: string;
    school_colors: string[];

    disciplines_text: string;
    influential_alumni_text: string;

    logo_url: string;
    logo_source_url: string;
    admissions_website: string;
    logo_accent_color: string;

    admissions_email: string;

    degrees: AiSchoolDegree[];
    modified_date_time: Date | null;
};

// BEGIN MAGIC -- DO NOT EDIT
export type AiSchoolDegreeCategoryJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
};

export function JSONToAiSchoolDegreeCategory(
    json: AiSchoolDegreeCategoryJSON
): AiSchoolDegreeCategory {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
    };
}
export type AiSchoolDegreeCategoryBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
};

export function newAiSchoolDegreeCategory(): AiSchoolDegreeCategory {
    return JSONToAiSchoolDegreeCategory(
        repairAiSchoolDegreeCategoryJSON(undefined)
    );
}
export function repairAiSchoolDegreeCategoryJSON(
    json: AiSchoolDegreeCategoryBrokenJSON | undefined
): AiSchoolDegreeCategoryJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
        };
    }
}

export function AiSchoolDegreeCategoryToJSON(
    value: AiSchoolDegreeCategory
): AiSchoolDegreeCategoryJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
    };
}

export const AI_SCHOOL_DEGREE_CATEGORY_META: RecordMeta<
    AiSchoolDegreeCategory,
    AiSchoolDegreeCategoryJSON,
    AiSchoolDegreeCategoryBrokenJSON
> = {
    name: "AiSchoolDegreeCategory",
    type: "record",
    repair: repairAiSchoolDegreeCategoryJSON,
    toJSON: AiSchoolDegreeCategoryToJSON,
    fromJSON: JSONToAiSchoolDegreeCategory,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiSchoolDegreeLevelJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    number: string;
};

export function JSONToAiSchoolDegreeLevel(
    json: AiSchoolDegreeLevelJSON
): AiSchoolDegreeLevel {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        number: new Decimal(json.number),
    };
}
export type AiSchoolDegreeLevelBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    number?: string;
};

export function newAiSchoolDegreeLevel(): AiSchoolDegreeLevel {
    return JSONToAiSchoolDegreeLevel(repairAiSchoolDegreeLevelJSON(undefined));
}
export function repairAiSchoolDegreeLevelJSON(
    json: AiSchoolDegreeLevelBrokenJSON | undefined
): AiSchoolDegreeLevelJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            number: json.number || "0",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            number: undefined || "0",
        };
    }
}

export function AiSchoolDegreeLevelToJSON(
    value: AiSchoolDegreeLevel
): AiSchoolDegreeLevelJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        number: value.number.toString(),
    };
}

export const AI_SCHOOL_DEGREE_LEVEL_META: RecordMeta<
    AiSchoolDegreeLevel,
    AiSchoolDegreeLevelJSON,
    AiSchoolDegreeLevelBrokenJSON
> = {
    name: "AiSchoolDegreeLevel",
    type: "record",
    repair: repairAiSchoolDegreeLevelJSON,
    toJSON: AiSchoolDegreeLevelToJSON,
    fromJSON: JSONToAiSchoolDegreeLevel,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        number: { type: "quantity" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiSchoolDegreeJSON = {
    id: string;
    fullyOnline: boolean;
    category: string | null;
    legacyDegreeId: string;
    level: string | null;
    discipline: string;
    degreeHubPage: string;
    completion: string;
    degreeType: string;
    concentrations: string[];
    creditsNeeded: string;
    completionTime: string;
    programLink: string;
};

export function JSONToAiSchoolDegree(json: AiSchoolDegreeJSON): AiSchoolDegree {
    return {
        id: { uuid: json.id },
        fullyOnline: json.fullyOnline,
        category: json.category,
        legacyDegreeId: json.legacyDegreeId,
        level: json.level,
        discipline: json.discipline,
        degreeHubPage: json.degreeHubPage,
        completion: json.completion as any,
        degreeType: json.degreeType,
        concentrations: json.concentrations.map((inner) => inner),
        creditsNeeded: json.creditsNeeded,
        completionTime: json.completionTime,
        programLink: json.programLink,
    };
}
export type AiSchoolDegreeBrokenJSON = {
    id?: string;
    fullyOnline?: boolean;
    category?: string | null;
    legacyDegreeId?: string;
    level?: string | null;
    discipline?: string;
    degreeHubPage?: string;
    completion?: string;
    degreeType?: string;
    concentrations?: string[];
    creditsNeeded?: string;
    completionTime?: string;
    programLink?: string;
};

export function newAiSchoolDegree(): AiSchoolDegree {
    return JSONToAiSchoolDegree(repairAiSchoolDegreeJSON(undefined));
}
export function repairAiSchoolDegreeJSON(
    json: AiSchoolDegreeBrokenJSON | undefined
): AiSchoolDegreeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            fullyOnline: json.fullyOnline || false,
            category: json.category || null,
            legacyDegreeId: json.legacyDegreeId || "",
            level: json.level || null,
            discipline: json.discipline || "",
            degreeHubPage: json.degreeHubPage || "",
            completion: json.completion || "Yes",
            degreeType: json.degreeType || "",
            concentrations: (json.concentrations || []).map(
                (inner) => inner || ""
            ),
            creditsNeeded: json.creditsNeeded || "",
            completionTime: json.completionTime || "",
            programLink: json.programLink || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            fullyOnline: undefined || false,
            category: undefined || null,
            legacyDegreeId: undefined || "",
            level: undefined || null,
            discipline: undefined || "",
            degreeHubPage: undefined || "",
            completion: undefined || "Yes",
            degreeType: undefined || "",
            concentrations: (undefined || []).map((inner) => inner || ""),
            creditsNeeded: undefined || "",
            completionTime: undefined || "",
            programLink: undefined || "",
        };
    }
}

export function AiSchoolDegreeToJSON(
    value: AiSchoolDegree
): AiSchoolDegreeJSON {
    return {
        id: value.id.uuid,
        fullyOnline: value.fullyOnline,
        category: value.category,
        legacyDegreeId: value.legacyDegreeId,
        level: value.level,
        discipline: value.discipline,
        degreeHubPage: value.degreeHubPage,
        completion: value.completion,
        degreeType: value.degreeType,
        concentrations: value.concentrations.map((inner) => inner),
        creditsNeeded: value.creditsNeeded,
        completionTime: value.completionTime,
        programLink: value.programLink,
    };
}

export const AI_SCHOOL_DEGREE_META: RecordMeta<
    AiSchoolDegree,
    AiSchoolDegreeJSON,
    AiSchoolDegreeBrokenJSON
> = {
    name: "AiSchoolDegree",
    type: "record",
    repair: repairAiSchoolDegreeJSON,
    toJSON: AiSchoolDegreeToJSON,
    fromJSON: JSONToAiSchoolDegree,
    fields: {
        id: { type: "uuid" },
        fullyOnline: { type: "boolean" },
        category: { type: "uuid", linkTo: "AiSchoolDegreeCategory" },
        legacyDegreeId: { type: "string" },
        level: { type: "uuid", linkTo: "AiSchoolDegreeLevel" },
        discipline: { type: "string" },
        degreeHubPage: { type: "string" },
        completion: {
            type: "enum",
            values: ["Yes", "No", "None Reported"],
        },
        degreeType: { type: "string" },
        concentrations: { type: "array", items: { type: "string" } },
        creditsNeeded: { type: "string" },
        completionTime: { type: "string" },
        programLink: { type: "string" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiSchoolJSON = {
    id: string;
    recordVersion: number | null;
    active: boolean;
    slug: string;
    name: string;
    description: string;
    short_description: string;
    extra_content: string;
    meta_description: string;
    wikidata_id: string;
    school_colors: string[];
    disciplines_text: string;
    influential_alumni_text: string;
    logo_url: string;
    logo_source_url: string;
    admissions_website: string;
    logo_accent_color: string;
    admissions_email: string;
    degrees: AiSchoolDegreeJSON[];
    modified_date_time: string | null;
};

export function JSONToAiSchool(json: AiSchoolJSON): AiSchool {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        active: json.active,
        slug: json.slug,
        name: json.name,
        description: json.description,
        short_description: json.short_description,
        extra_content: json.extra_content,
        meta_description: json.meta_description,
        wikidata_id: json.wikidata_id,
        school_colors: json.school_colors.map((inner) => inner),
        disciplines_text: json.disciplines_text,
        influential_alumni_text: json.influential_alumni_text,
        logo_url: json.logo_url,
        logo_source_url: json.logo_source_url,
        admissions_website: json.admissions_website,
        logo_accent_color: json.logo_accent_color,
        admissions_email: json.admissions_email,
        degrees: json.degrees.map((inner) => JSONToAiSchoolDegree(inner)),
        modified_date_time:
            json.modified_date_time !== null
                ? dateParse(json.modified_date_time)
                : null,
    };
}
export type AiSchoolBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    active?: boolean;
    slug?: string;
    name?: string;
    description?: string;
    short_description?: string;
    extra_content?: string;
    meta_description?: string;
    wikidata_id?: string;
    school_colors?: string[];
    disciplines_text?: string;
    influential_alumni_text?: string;
    logo_url?: string;
    logo_source_url?: string;
    admissions_website?: string;
    logo_accent_color?: string;
    admissions_email?: string;
    degrees?: AiSchoolDegreeJSON[];
    modified_date_time?: string | null;
};

export function newAiSchool(): AiSchool {
    return JSONToAiSchool(repairAiSchoolJSON(undefined));
}
export function repairAiSchoolJSON(
    json: AiSchoolBrokenJSON | undefined
): AiSchoolJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            active: json.active || false,
            slug: json.slug || "",
            name: json.name || "",
            description: json.description || "",
            short_description: json.short_description || "",
            extra_content: json.extra_content || "",
            meta_description: json.meta_description || "",
            wikidata_id: json.wikidata_id || "",
            school_colors: (json.school_colors || []).map(
                (inner) => inner || ""
            ),
            disciplines_text: json.disciplines_text || "",
            influential_alumni_text: json.influential_alumni_text || "",
            logo_url: json.logo_url || "",
            logo_source_url: json.logo_source_url || "",
            admissions_website: json.admissions_website || "",
            logo_accent_color: json.logo_accent_color || "",
            admissions_email: json.admissions_email || "",
            degrees: (json.degrees || []).map((inner) =>
                repairAiSchoolDegreeJSON(inner)
            ),
            modified_date_time: json.modified_date_time || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            active: undefined || false,
            slug: undefined || "",
            name: undefined || "",
            description: undefined || "",
            short_description: undefined || "",
            extra_content: undefined || "",
            meta_description: undefined || "",
            wikidata_id: undefined || "",
            school_colors: (undefined || []).map((inner) => inner || ""),
            disciplines_text: undefined || "",
            influential_alumni_text: undefined || "",
            logo_url: undefined || "",
            logo_source_url: undefined || "",
            admissions_website: undefined || "",
            logo_accent_color: undefined || "",
            admissions_email: undefined || "",
            degrees: (undefined || []).map((inner) =>
                repairAiSchoolDegreeJSON(inner)
            ),
            modified_date_time: undefined || null,
        };
    }
}

export function AiSchoolToJSON(value: AiSchool): AiSchoolJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        active: value.active,
        slug: value.slug,
        name: value.name,
        description: value.description,
        short_description: value.short_description,
        extra_content: value.extra_content,
        meta_description: value.meta_description,
        wikidata_id: value.wikidata_id,
        school_colors: value.school_colors.map((inner) => inner),
        disciplines_text: value.disciplines_text,
        influential_alumni_text: value.influential_alumni_text,
        logo_url: value.logo_url,
        logo_source_url: value.logo_source_url,
        admissions_website: value.admissions_website,
        logo_accent_color: value.logo_accent_color,
        admissions_email: value.admissions_email,
        degrees: value.degrees.map((inner) => AiSchoolDegreeToJSON(inner)),
        modified_date_time:
            value.modified_date_time !== null
                ? value.modified_date_time.toISOString()
                : null,
    };
}

export const AI_SCHOOL_META: RecordMeta<
    AiSchool,
    AiSchoolJSON,
    AiSchoolBrokenJSON
> = {
    name: "AiSchool",
    type: "record",
    repair: repairAiSchoolJSON,
    toJSON: AiSchoolToJSON,
    fromJSON: JSONToAiSchool,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        active: { type: "boolean" },
        slug: { type: "string" },
        name: { type: "string" },
        description: { type: "string" },
        short_description: { type: "string" },
        extra_content: { type: "string" },
        meta_description: { type: "string" },
        wikidata_id: { type: "string" },
        school_colors: { type: "array", items: { type: "string" } },
        disciplines_text: { type: "string" },
        influential_alumni_text: { type: "string" },
        logo_url: { type: "string" },
        logo_source_url: { type: "string" },
        admissions_website: { type: "string" },
        logo_accent_color: { type: "string" },
        admissions_email: { type: "string" },
        degrees: { type: "array", items: AI_SCHOOL_DEGREE_META },
        modified_date_time: { type: "datetime" },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
