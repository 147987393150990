import * as React from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { CheckboxWidget } from "../../../clay/widgets/CheckboxWidget";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { CONTENT_AREA } from "../../styles";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { ImageUrlWidget } from "../../widgets/image-url-editor";
import { AiPage, AI_PAGE_META } from "./table";

//!RecordWidget
export type AiPageWidgetData = AiPage;

export const AiPageWidgetFields = {
    key: FormField(TextWidget),
    title: FormField(TextWidget),
    sitemap: FormField(CheckboxWidget),
    text: HtmlEditorWidget,
    description: OptionalFormField(TextAreaWidget),
    bannerImageUrl: OptionalFormField(ImageUrlWidget),
    bannerImageAltText: OptionalFormField(TextWidget),
    alternateSocialMediaImageUrl: OptionalFormField(ImageUrlWidget),
    alternateSocialMediaImageAltText: OptionalFormField(TextWidget),
};

function AiPageWidgetComponent(
    widgets: AiPageWidgetWidgets,
    props: AiPageWidgetProps
) {
    return (
        <>
            <div {...CONTENT_AREA}>
                <Tabs defaultActiveKey="main" id="page-tabs">
                    <Tab eventKey="main" title="Main">
                        <widgets.key />
                        <span style={{ fontSize: "12pt" }}>
                            https://academicinfluence.com/{props.data.key}
                        </span>
                        <Form.Group
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflowY: "auto",
                            }}
                        >
                            <widgets.text path={"/" + props.data.key} />
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="more" title="More">
                        <widgets.sitemap label="Include in Sitemap" />

                        <widgets.title />
                        <widgets.description />
                        <widgets.bannerImageUrl />
                        <widgets.bannerImageAltText />
                        <widgets.alternateSocialMediaImageUrl />
                        <widgets.alternateSocialMediaImageAltText />
                    </Tab>
                </Tabs>
            </div>
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiPageWidgetContext = WidgetContext<typeof AiPageWidgetFields.key> &
    WidgetContext<typeof AiPageWidgetFields.title> &
    WidgetContext<typeof AiPageWidgetFields.sitemap> &
    WidgetContext<typeof AiPageWidgetFields.text> &
    WidgetContext<typeof AiPageWidgetFields.description> &
    WidgetContext<typeof AiPageWidgetFields.bannerImageUrl> &
    WidgetContext<typeof AiPageWidgetFields.bannerImageAltText> &
    WidgetContext<typeof AiPageWidgetFields.alternateSocialMediaImageUrl> &
    WidgetContext<typeof AiPageWidgetFields.alternateSocialMediaImageAltText>;
type AiPageWidgetExtraProps = {};
type AiPageWidgetBaseState = {
    key: WidgetState<typeof AiPageWidgetFields.key>;
    title: WidgetState<typeof AiPageWidgetFields.title>;
    sitemap: WidgetState<typeof AiPageWidgetFields.sitemap>;
    text: WidgetState<typeof AiPageWidgetFields.text>;
    description: WidgetState<typeof AiPageWidgetFields.description>;
    bannerImageUrl: WidgetState<typeof AiPageWidgetFields.bannerImageUrl>;
    bannerImageAltText: WidgetState<
        typeof AiPageWidgetFields.bannerImageAltText
    >;
    alternateSocialMediaImageUrl: WidgetState<
        typeof AiPageWidgetFields.alternateSocialMediaImageUrl
    >;
    alternateSocialMediaImageAltText: WidgetState<
        typeof AiPageWidgetFields.alternateSocialMediaImageAltText
    >;
};
export type AiPageWidgetState = AiPageWidgetBaseState;

type BaseAiPageWidgetAction =
    | { type: "KEY"; action: WidgetAction<typeof AiPageWidgetFields.key> }
    | { type: "TITLE"; action: WidgetAction<typeof AiPageWidgetFields.title> }
    | {
          type: "SITEMAP";
          action: WidgetAction<typeof AiPageWidgetFields.sitemap>;
      }
    | { type: "TEXT"; action: WidgetAction<typeof AiPageWidgetFields.text> }
    | {
          type: "DESCRIPTION";
          action: WidgetAction<typeof AiPageWidgetFields.description>;
      }
    | {
          type: "BANNER_IMAGE_URL";
          action: WidgetAction<typeof AiPageWidgetFields.bannerImageUrl>;
      }
    | {
          type: "BANNER_IMAGE_ALT_TEXT";
          action: WidgetAction<typeof AiPageWidgetFields.bannerImageAltText>;
      }
    | {
          type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL";
          action: WidgetAction<
              typeof AiPageWidgetFields.alternateSocialMediaImageUrl
          >;
      }
    | {
          type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT";
          action: WidgetAction<
              typeof AiPageWidgetFields.alternateSocialMediaImageAltText
          >;
      };

export type AiPageWidgetAction = BaseAiPageWidgetAction;

export type AiPageWidgetProps = WidgetProps<
    AiPageWidgetState,
    AiPageWidgetData,
    AiPageWidgetAction,
    AiPageWidgetExtraProps
>;

function baseValidateAiPageWidget(
    data: AiPageWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AiPageWidgetFields.key, data.key, cache, "key", errors);
    subvalidate(AiPageWidgetFields.title, data.title, cache, "title", errors);
    subvalidate(
        AiPageWidgetFields.sitemap,
        data.sitemap,
        cache,
        "sitemap",
        errors
    );
    subvalidate(AiPageWidgetFields.text, data.text, cache, "text", errors);
    subvalidate(
        AiPageWidgetFields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(
        AiPageWidgetFields.bannerImageUrl,
        data.bannerImageUrl,
        cache,
        "bannerImageUrl",
        errors
    );
    subvalidate(
        AiPageWidgetFields.bannerImageAltText,
        data.bannerImageAltText,
        cache,
        "bannerImageAltText",
        errors
    );
    subvalidate(
        AiPageWidgetFields.alternateSocialMediaImageUrl,
        data.alternateSocialMediaImageUrl,
        cache,
        "alternateSocialMediaImageUrl",
        errors
    );
    subvalidate(
        AiPageWidgetFields.alternateSocialMediaImageAltText,
        data.alternateSocialMediaImageAltText,
        cache,
        "alternateSocialMediaImageAltText",
        errors
    );
    return errors;
}
function baseAiPageWidgetReduce(
    state: AiPageWidgetState,
    data: AiPageWidgetData,
    action: BaseAiPageWidgetAction,
    context: AiPageWidgetContext
): WidgetResult<AiPageWidgetState, AiPageWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "KEY": {
            const inner = AiPageWidgetFields.key.reduce(
                state.key,
                data.key,
                action.action,
                subcontext
            );
            return {
                state: { ...state, key: inner.state },
                data: { ...data, key: inner.data },
            };
        }
        case "TITLE": {
            const inner = AiPageWidgetFields.title.reduce(
                state.title,
                data.title,
                action.action,
                subcontext
            );
            return {
                state: { ...state, title: inner.state },
                data: { ...data, title: inner.data },
            };
        }
        case "SITEMAP": {
            const inner = AiPageWidgetFields.sitemap.reduce(
                state.sitemap,
                data.sitemap,
                action.action,
                subcontext
            );
            return {
                state: { ...state, sitemap: inner.state },
                data: { ...data, sitemap: inner.data },
            };
        }
        case "TEXT": {
            const inner = AiPageWidgetFields.text.reduce(
                state.text,
                data.text,
                action.action,
                subcontext
            );
            return {
                state: { ...state, text: inner.state },
                data: { ...data, text: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = AiPageWidgetFields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "BANNER_IMAGE_URL": {
            const inner = AiPageWidgetFields.bannerImageUrl.reduce(
                state.bannerImageUrl,
                data.bannerImageUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, bannerImageUrl: inner.state },
                data: { ...data, bannerImageUrl: inner.data },
            };
        }
        case "BANNER_IMAGE_ALT_TEXT": {
            const inner = AiPageWidgetFields.bannerImageAltText.reduce(
                state.bannerImageAltText,
                data.bannerImageAltText,
                action.action,
                subcontext
            );
            return {
                state: { ...state, bannerImageAltText: inner.state },
                data: { ...data, bannerImageAltText: inner.data },
            };
        }
        case "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL": {
            const inner = AiPageWidgetFields.alternateSocialMediaImageUrl.reduce(
                state.alternateSocialMediaImageUrl,
                data.alternateSocialMediaImageUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, alternateSocialMediaImageUrl: inner.state },
                data: { ...data, alternateSocialMediaImageUrl: inner.data },
            };
        }
        case "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT": {
            const inner = AiPageWidgetFields.alternateSocialMediaImageAltText.reduce(
                state.alternateSocialMediaImageAltText,
                data.alternateSocialMediaImageAltText,
                action.action,
                subcontext
            );
            return {
                state: {
                    ...state,
                    alternateSocialMediaImageAltText: inner.state,
                },
                data: { ...data, alternateSocialMediaImageAltText: inner.data },
            };
        }
    }
}
export type AiPageWidgetReactContextType = {
    state: AiPageWidgetState;
    data: AiPageWidgetData;
    dispatch: (action: AiPageWidgetAction) => void;
    status: WidgetStatus;
};
export const AiPageWidgetReactContext = React.createContext<
    AiPageWidgetReactContextType | undefined
>(undefined);
export const AiPageWidgetWidgets = {
    key: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.key> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.key>) =>
                context.dispatch({ type: "KEY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "key", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.key.component
                state={context.state.key}
                data={context.data.key}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Key"}
            />
        );
    },
    title: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.title> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.title>) =>
                context.dispatch({ type: "TITLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "title", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.title.component
                state={context.state.title}
                data={context.data.title}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Title"}
            />
        );
    },
    sitemap: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.sitemap> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.sitemap>) =>
                context.dispatch({ type: "SITEMAP", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "sitemap", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.sitemap.component
                state={context.state.sitemap}
                data={context.data.sitemap}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Sitemap"}
            />
        );
    },
    text: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.text> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.text>) =>
                context.dispatch({ type: "TEXT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "text", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.text.component
                state={context.state.text}
                data={context.data.text}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Text"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.description> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.description>) =>
                context.dispatch({ type: "DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.description.component
                state={context.state.description}
                data={context.data.description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Description"}
            />
        );
    },
    bannerImageUrl: function (
        props: WidgetExtraProps<typeof AiPageWidgetFields.bannerImageUrl> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiPageWidgetFields.bannerImageUrl>) =>
                context.dispatch({ type: "BANNER_IMAGE_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "bannerImageUrl", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.bannerImageUrl.component
                state={context.state.bannerImageUrl}
                data={context.data.bannerImageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Banner Image Url"}
            />
        );
    },
    bannerImageAltText: function (
        props: WidgetExtraProps<
            typeof AiPageWidgetFields.bannerImageAltText
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPageWidgetFields.bannerImageAltText
                >
            ) => context.dispatch({ type: "BANNER_IMAGE_ALT_TEXT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "bannerImageAltText",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.bannerImageAltText.component
                state={context.state.bannerImageAltText}
                data={context.data.bannerImageAltText}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Banner Image Alt Text"}
            />
        );
    },
    alternateSocialMediaImageUrl: function (
        props: WidgetExtraProps<
            typeof AiPageWidgetFields.alternateSocialMediaImageUrl
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPageWidgetFields.alternateSocialMediaImageUrl
                >
            ) =>
                context.dispatch({
                    type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL",
                    action,
                }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "alternateSocialMediaImageUrl",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.alternateSocialMediaImageUrl.component
                state={context.state.alternateSocialMediaImageUrl}
                data={context.data.alternateSocialMediaImageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alternate Social Media Image Url"}
            />
        );
    },
    alternateSocialMediaImageAltText: function (
        props: WidgetExtraProps<
            typeof AiPageWidgetFields.alternateSocialMediaImageAltText
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiPageWidgetReactContext
        ) as AiPageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiPageWidgetFields.alternateSocialMediaImageAltText
                >
            ) =>
                context.dispatch({
                    type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT",
                    action,
                }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "alternateSocialMediaImageAltText",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiPageWidgetFields.alternateSocialMediaImageAltText.component
                state={context.state.alternateSocialMediaImageAltText}
                data={context.data.alternateSocialMediaImageAltText}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alternate Social Media Image Alt Text"}
            />
        );
    },
};
export const AiPageWidget: RecordWidget<
    AiPageWidgetState,
    AiPageWidgetData,
    AiPageWidgetContext,
    AiPageWidgetAction,
    AiPageWidgetExtraProps
> = {
    reactContext: AiPageWidgetReactContext,
    fieldWidgets: AiPageWidgetWidgets,
    dataMeta: AI_PAGE_META,
    initialize(
        data: AiPageWidgetData,
        context: AiPageWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiPageWidgetState, AiPageWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerKey = AiPageWidgetFields.key.initialize(
            data.key,
            subcontext,
            subparameters.key
        );
        const innerTitle = AiPageWidgetFields.title.initialize(
            data.title,
            subcontext,
            subparameters.title
        );
        const innerSitemap = AiPageWidgetFields.sitemap.initialize(
            data.sitemap,
            subcontext,
            subparameters.sitemap
        );
        const innerText = AiPageWidgetFields.text.initialize(
            data.text,
            subcontext,
            subparameters.text
        );
        const innerDescription = AiPageWidgetFields.description.initialize(
            data.description,
            subcontext,
            subparameters.description
        );
        const innerBannerImageUrl = AiPageWidgetFields.bannerImageUrl.initialize(
            data.bannerImageUrl,
            subcontext,
            subparameters.bannerImageUrl
        );
        const innerBannerImageAltText = AiPageWidgetFields.bannerImageAltText.initialize(
            data.bannerImageAltText,
            subcontext,
            subparameters.bannerImageAltText
        );
        const innerAlternateSocialMediaImageUrl = AiPageWidgetFields.alternateSocialMediaImageUrl.initialize(
            data.alternateSocialMediaImageUrl,
            subcontext,
            subparameters.alternateSocialMediaImageUrl
        );
        const innerAlternateSocialMediaImageAltText = AiPageWidgetFields.alternateSocialMediaImageAltText.initialize(
            data.alternateSocialMediaImageAltText,
            subcontext,
            subparameters.alternateSocialMediaImageAltText
        );
        let state = {
            key: innerKey.state,
            title: innerTitle.state,
            sitemap: innerSitemap.state,
            text: innerText.state,
            description: innerDescription.state,
            bannerImageUrl: innerBannerImageUrl.state,
            bannerImageAltText: innerBannerImageAltText.state,
            alternateSocialMediaImageUrl:
                innerAlternateSocialMediaImageUrl.state,
            alternateSocialMediaImageAltText:
                innerAlternateSocialMediaImageAltText.state,
        };
        return {
            state,
            data: {
                ...data,
                key: innerKey.data,
                title: innerTitle.data,
                sitemap: innerSitemap.data,
                text: innerText.data,
                description: innerDescription.data,
                bannerImageUrl: innerBannerImageUrl.data,
                bannerImageAltText: innerBannerImageAltText.data,
                alternateSocialMediaImageUrl:
                    innerAlternateSocialMediaImageUrl.data,
                alternateSocialMediaImageAltText:
                    innerAlternateSocialMediaImageAltText.data,
            },
        };
    },
    validate: baseValidateAiPageWidget,
    component: React.memo((props: AiPageWidgetProps) => {
        return (
            <AiPageWidgetReactContext.Provider value={props}>
                {AiPageWidgetComponent(AiPageWidgetWidgets, props)}
            </AiPageWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiPageWidgetReduce,
};

type AiPageWidgetWidgets = {
    key: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.key
        >
    >;
    title: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.title
        >
    >;
    sitemap: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.sitemap
        >
    >;
    text: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.text
        >
    >;
    description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.description
        >
    >;
    bannerImageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.bannerImageUrl
        >
    >;
    bannerImageAltText: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.bannerImageAltText
        >
    >;
    alternateSocialMediaImageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.alternateSocialMediaImageUrl
        >
    >;
    alternateSocialMediaImageAltText: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiPageWidgetFields.alternateSocialMediaImageAltText
        >
    >;
};
// END MAGIC -- DO NOT EDIT
