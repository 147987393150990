import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { DeleteButton } from "../../../clay/delete-button";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { DropdownLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import {
    FormField,
    Optional,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { TagsWidget } from "../../tags-widget";
import {
    AiSchoolDegree,
    AI_SCHOOL_DEGREE_CATEGORY_META,
    AI_SCHOOL_DEGREE_LEVEL_META,
    AI_SCHOOL_DEGREE_META,
} from "./table";

//!RecordWidget
export type SchoolDegreeWidgetData = AiSchoolDegree;

export const SchoolDegreeWidgetFields = {
    fullyOnline: FormField(SwitchWidget),
    category: FormField(
        DropdownLinkWidget({
            meta: AI_SCHOOL_DEGREE_CATEGORY_META,
            label: (category) => category.name,
        })
    ),
    level: FormField(
        DropdownLinkWidget({
            meta: AI_SCHOOL_DEGREE_LEVEL_META,
            label: (level) => level.name,
            compare: (a, b) => a.number.comparedTo(b.number),
        })
    ),
    degreeType: FormField(TextWidget),
    discipline: FormField(TextWidget),
    degreeHubPage: FormField(Optional(TextWidget)),
    completion: FormField(
        SelectWidget<AiSchoolDegree["completion"]>([
            {
                value: "Yes",
                label: "Yes",
            },
            {
                value: "No",
                label: "No",
            },
            {
                value: "None Reported",
                label: "None Reported",
            },
        ])
    ),
    concentrations: OptionalFormField(TagsWidget),
    creditsNeeded: FormField(Optional(TextWidget)),
    completionTime: FormField(Optional(TextWidget)),
};

function SchoolDegreeWidgetComponent(
    widgets: SchoolDegreeWidgetWidgets,
    props: SchoolDegreeWidgetProps
) {
    const context = useListItemContext();
    return (
        <div
            {...context.draggableProps}
            style={{ marginBottom: "1em", borderBottom: "solid 1px black" }}
        >
            <div>
                {context.dragHandle}
                <DeleteButton />
            </div>
            <FieldRow>
                <widgets.level />
                <widgets.degreeType />
            </FieldRow>
            <FieldRow>
                <widgets.category />
                <widgets.discipline />
            </FieldRow>
            <FieldRow>
                <widgets.completion />
                <widgets.completionTime />
                <widgets.creditsNeeded />
            </FieldRow>
            <FieldRow>
                <widgets.fullyOnline />
                <widgets.degreeHubPage />
            </FieldRow>
        </div>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type SchoolDegreeWidgetContext = WidgetContext<
    typeof SchoolDegreeWidgetFields.fullyOnline
> &
    WidgetContext<typeof SchoolDegreeWidgetFields.category> &
    WidgetContext<typeof SchoolDegreeWidgetFields.level> &
    WidgetContext<typeof SchoolDegreeWidgetFields.degreeType> &
    WidgetContext<typeof SchoolDegreeWidgetFields.discipline> &
    WidgetContext<typeof SchoolDegreeWidgetFields.degreeHubPage> &
    WidgetContext<typeof SchoolDegreeWidgetFields.completion> &
    WidgetContext<typeof SchoolDegreeWidgetFields.concentrations> &
    WidgetContext<typeof SchoolDegreeWidgetFields.creditsNeeded> &
    WidgetContext<typeof SchoolDegreeWidgetFields.completionTime>;
type SchoolDegreeWidgetExtraProps = {};
type SchoolDegreeWidgetBaseState = {
    fullyOnline: WidgetState<typeof SchoolDegreeWidgetFields.fullyOnline>;
    category: WidgetState<typeof SchoolDegreeWidgetFields.category>;
    level: WidgetState<typeof SchoolDegreeWidgetFields.level>;
    degreeType: WidgetState<typeof SchoolDegreeWidgetFields.degreeType>;
    discipline: WidgetState<typeof SchoolDegreeWidgetFields.discipline>;
    degreeHubPage: WidgetState<typeof SchoolDegreeWidgetFields.degreeHubPage>;
    completion: WidgetState<typeof SchoolDegreeWidgetFields.completion>;
    concentrations: WidgetState<typeof SchoolDegreeWidgetFields.concentrations>;
    creditsNeeded: WidgetState<typeof SchoolDegreeWidgetFields.creditsNeeded>;
    completionTime: WidgetState<typeof SchoolDegreeWidgetFields.completionTime>;
};
export type SchoolDegreeWidgetState = SchoolDegreeWidgetBaseState;

type BaseSchoolDegreeWidgetAction =
    | {
          type: "FULLY_ONLINE";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.fullyOnline>;
      }
    | {
          type: "CATEGORY";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.category>;
      }
    | {
          type: "LEVEL";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.level>;
      }
    | {
          type: "DEGREE_TYPE";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.degreeType>;
      }
    | {
          type: "DISCIPLINE";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.discipline>;
      }
    | {
          type: "DEGREE_HUB_PAGE";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.degreeHubPage>;
      }
    | {
          type: "COMPLETION";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.completion>;
      }
    | {
          type: "CONCENTRATIONS";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.concentrations>;
      }
    | {
          type: "CREDITS_NEEDED";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.creditsNeeded>;
      }
    | {
          type: "COMPLETION_TIME";
          action: WidgetAction<typeof SchoolDegreeWidgetFields.completionTime>;
      };

export type SchoolDegreeWidgetAction = BaseSchoolDegreeWidgetAction;

export type SchoolDegreeWidgetProps = WidgetProps<
    SchoolDegreeWidgetState,
    SchoolDegreeWidgetData,
    SchoolDegreeWidgetAction,
    SchoolDegreeWidgetExtraProps
>;

function baseValidateSchoolDegreeWidget(
    data: SchoolDegreeWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        SchoolDegreeWidgetFields.fullyOnline,
        data.fullyOnline,
        cache,
        "fullyOnline",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.category,
        data.category,
        cache,
        "category",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.level,
        data.level,
        cache,
        "level",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.degreeType,
        data.degreeType,
        cache,
        "degreeType",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.discipline,
        data.discipline,
        cache,
        "discipline",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.degreeHubPage,
        data.degreeHubPage,
        cache,
        "degreeHubPage",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.completion,
        data.completion,
        cache,
        "completion",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.concentrations,
        data.concentrations,
        cache,
        "concentrations",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.creditsNeeded,
        data.creditsNeeded,
        cache,
        "creditsNeeded",
        errors
    );
    subvalidate(
        SchoolDegreeWidgetFields.completionTime,
        data.completionTime,
        cache,
        "completionTime",
        errors
    );
    return errors;
}
function baseSchoolDegreeWidgetReduce(
    state: SchoolDegreeWidgetState,
    data: SchoolDegreeWidgetData,
    action: BaseSchoolDegreeWidgetAction,
    context: SchoolDegreeWidgetContext
): WidgetResult<SchoolDegreeWidgetState, SchoolDegreeWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "FULLY_ONLINE": {
            const inner = SchoolDegreeWidgetFields.fullyOnline.reduce(
                state.fullyOnline,
                data.fullyOnline,
                action.action,
                subcontext
            );
            return {
                state: { ...state, fullyOnline: inner.state },
                data: { ...data, fullyOnline: inner.data },
            };
        }
        case "CATEGORY": {
            const inner = SchoolDegreeWidgetFields.category.reduce(
                state.category,
                data.category,
                action.action,
                subcontext
            );
            return {
                state: { ...state, category: inner.state },
                data: { ...data, category: inner.data },
            };
        }
        case "LEVEL": {
            const inner = SchoolDegreeWidgetFields.level.reduce(
                state.level,
                data.level,
                action.action,
                subcontext
            );
            return {
                state: { ...state, level: inner.state },
                data: { ...data, level: inner.data },
            };
        }
        case "DEGREE_TYPE": {
            const inner = SchoolDegreeWidgetFields.degreeType.reduce(
                state.degreeType,
                data.degreeType,
                action.action,
                subcontext
            );
            return {
                state: { ...state, degreeType: inner.state },
                data: { ...data, degreeType: inner.data },
            };
        }
        case "DISCIPLINE": {
            const inner = SchoolDegreeWidgetFields.discipline.reduce(
                state.discipline,
                data.discipline,
                action.action,
                subcontext
            );
            return {
                state: { ...state, discipline: inner.state },
                data: { ...data, discipline: inner.data },
            };
        }
        case "DEGREE_HUB_PAGE": {
            const inner = SchoolDegreeWidgetFields.degreeHubPage.reduce(
                state.degreeHubPage,
                data.degreeHubPage,
                action.action,
                subcontext
            );
            return {
                state: { ...state, degreeHubPage: inner.state },
                data: { ...data, degreeHubPage: inner.data },
            };
        }
        case "COMPLETION": {
            const inner = SchoolDegreeWidgetFields.completion.reduce(
                state.completion,
                data.completion,
                action.action,
                subcontext
            );
            return {
                state: { ...state, completion: inner.state },
                data: { ...data, completion: inner.data },
            };
        }
        case "CONCENTRATIONS": {
            const inner = SchoolDegreeWidgetFields.concentrations.reduce(
                state.concentrations,
                data.concentrations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, concentrations: inner.state },
                data: { ...data, concentrations: inner.data },
            };
        }
        case "CREDITS_NEEDED": {
            const inner = SchoolDegreeWidgetFields.creditsNeeded.reduce(
                state.creditsNeeded,
                data.creditsNeeded,
                action.action,
                subcontext
            );
            return {
                state: { ...state, creditsNeeded: inner.state },
                data: { ...data, creditsNeeded: inner.data },
            };
        }
        case "COMPLETION_TIME": {
            const inner = SchoolDegreeWidgetFields.completionTime.reduce(
                state.completionTime,
                data.completionTime,
                action.action,
                subcontext
            );
            return {
                state: { ...state, completionTime: inner.state },
                data: { ...data, completionTime: inner.data },
            };
        }
    }
}
export type SchoolDegreeWidgetReactContextType = {
    state: SchoolDegreeWidgetState;
    data: SchoolDegreeWidgetData;
    dispatch: (action: SchoolDegreeWidgetAction) => void;
    status: WidgetStatus;
};
export const SchoolDegreeWidgetReactContext = React.createContext<
    SchoolDegreeWidgetReactContextType | undefined
>(undefined);
export const SchoolDegreeWidgetWidgets = {
    fullyOnline: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.fullyOnline> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof SchoolDegreeWidgetFields.fullyOnline
                >
            ) => context.dispatch({ type: "FULLY_ONLINE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "fullyOnline", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.fullyOnline.component
                state={context.state.fullyOnline}
                data={context.data.fullyOnline}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Fully Online"}
            />
        );
    },
    category: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.category> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof SchoolDegreeWidgetFields.category>) =>
                context.dispatch({ type: "CATEGORY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "category", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.category.component
                state={context.state.category}
                data={context.data.category}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Category"}
            />
        );
    },
    level: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.level> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof SchoolDegreeWidgetFields.level>) =>
                context.dispatch({ type: "LEVEL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "level", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.level.component
                state={context.state.level}
                data={context.data.level}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Level"}
            />
        );
    },
    degreeType: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.degreeType> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof SchoolDegreeWidgetFields.degreeType>
            ) => context.dispatch({ type: "DEGREE_TYPE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "degreeType", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.degreeType.component
                state={context.state.degreeType}
                data={context.data.degreeType}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Degree Type"}
            />
        );
    },
    discipline: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.discipline> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof SchoolDegreeWidgetFields.discipline>
            ) => context.dispatch({ type: "DISCIPLINE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "discipline", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.discipline.component
                state={context.state.discipline}
                data={context.data.discipline}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Discipline"}
            />
        );
    },
    degreeHubPage: function (
        props: WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.degreeHubPage
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof SchoolDegreeWidgetFields.degreeHubPage
                >
            ) => context.dispatch({ type: "DEGREE_HUB_PAGE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "degreeHubPage", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.degreeHubPage.component
                state={context.state.degreeHubPage}
                data={context.data.degreeHubPage}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Degree Hub Page"}
            />
        );
    },
    completion: function (
        props: WidgetExtraProps<typeof SchoolDegreeWidgetFields.completion> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof SchoolDegreeWidgetFields.completion>
            ) => context.dispatch({ type: "COMPLETION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "completion", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.completion.component
                state={context.state.completion}
                data={context.data.completion}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Completion"}
            />
        );
    },
    concentrations: function (
        props: WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.concentrations
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof SchoolDegreeWidgetFields.concentrations
                >
            ) => context.dispatch({ type: "CONCENTRATIONS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "concentrations", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.concentrations.component
                state={context.state.concentrations}
                data={context.data.concentrations}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Concentrations"}
            />
        );
    },
    creditsNeeded: function (
        props: WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.creditsNeeded
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof SchoolDegreeWidgetFields.creditsNeeded
                >
            ) => context.dispatch({ type: "CREDITS_NEEDED", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "creditsNeeded", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.creditsNeeded.component
                state={context.state.creditsNeeded}
                data={context.data.creditsNeeded}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Credits Needed"}
            />
        );
    },
    completionTime: function (
        props: WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.completionTime
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            SchoolDegreeWidgetReactContext
        ) as SchoolDegreeWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof SchoolDegreeWidgetFields.completionTime
                >
            ) => context.dispatch({ type: "COMPLETION_TIME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "completionTime", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <SchoolDegreeWidgetFields.completionTime.component
                state={context.state.completionTime}
                data={context.data.completionTime}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Completion Time"}
            />
        );
    },
};
export const SchoolDegreeWidget: RecordWidget<
    SchoolDegreeWidgetState,
    SchoolDegreeWidgetData,
    SchoolDegreeWidgetContext,
    SchoolDegreeWidgetAction,
    SchoolDegreeWidgetExtraProps
> = {
    reactContext: SchoolDegreeWidgetReactContext,
    fieldWidgets: SchoolDegreeWidgetWidgets,
    dataMeta: AI_SCHOOL_DEGREE_META,
    initialize(
        data: SchoolDegreeWidgetData,
        context: SchoolDegreeWidgetContext,
        parameters?: string[]
    ): WidgetResult<SchoolDegreeWidgetState, SchoolDegreeWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerFullyOnline = SchoolDegreeWidgetFields.fullyOnline.initialize(
            data.fullyOnline,
            subcontext,
            subparameters.fullyOnline
        );
        const innerCategory = SchoolDegreeWidgetFields.category.initialize(
            data.category,
            subcontext,
            subparameters.category
        );
        const innerLevel = SchoolDegreeWidgetFields.level.initialize(
            data.level,
            subcontext,
            subparameters.level
        );
        const innerDegreeType = SchoolDegreeWidgetFields.degreeType.initialize(
            data.degreeType,
            subcontext,
            subparameters.degreeType
        );
        const innerDiscipline = SchoolDegreeWidgetFields.discipline.initialize(
            data.discipline,
            subcontext,
            subparameters.discipline
        );
        const innerDegreeHubPage = SchoolDegreeWidgetFields.degreeHubPage.initialize(
            data.degreeHubPage,
            subcontext,
            subparameters.degreeHubPage
        );
        const innerCompletion = SchoolDegreeWidgetFields.completion.initialize(
            data.completion,
            subcontext,
            subparameters.completion
        );
        const innerConcentrations = SchoolDegreeWidgetFields.concentrations.initialize(
            data.concentrations,
            subcontext,
            subparameters.concentrations
        );
        const innerCreditsNeeded = SchoolDegreeWidgetFields.creditsNeeded.initialize(
            data.creditsNeeded,
            subcontext,
            subparameters.creditsNeeded
        );
        const innerCompletionTime = SchoolDegreeWidgetFields.completionTime.initialize(
            data.completionTime,
            subcontext,
            subparameters.completionTime
        );
        let state = {
            fullyOnline: innerFullyOnline.state,
            category: innerCategory.state,
            level: innerLevel.state,
            degreeType: innerDegreeType.state,
            discipline: innerDiscipline.state,
            degreeHubPage: innerDegreeHubPage.state,
            completion: innerCompletion.state,
            concentrations: innerConcentrations.state,
            creditsNeeded: innerCreditsNeeded.state,
            completionTime: innerCompletionTime.state,
        };
        return {
            state,
            data: {
                ...data,
                fullyOnline: innerFullyOnline.data,
                category: innerCategory.data,
                level: innerLevel.data,
                degreeType: innerDegreeType.data,
                discipline: innerDiscipline.data,
                degreeHubPage: innerDegreeHubPage.data,
                completion: innerCompletion.data,
                concentrations: innerConcentrations.data,
                creditsNeeded: innerCreditsNeeded.data,
                completionTime: innerCompletionTime.data,
            },
        };
    },
    validate: baseValidateSchoolDegreeWidget,
    component: React.memo((props: SchoolDegreeWidgetProps) => {
        return (
            <SchoolDegreeWidgetReactContext.Provider value={props}>
                {SchoolDegreeWidgetComponent(SchoolDegreeWidgetWidgets, props)}
            </SchoolDegreeWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseSchoolDegreeWidgetReduce,
};

type SchoolDegreeWidgetWidgets = {
    fullyOnline: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.fullyOnline
        >
    >;
    category: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.category
        >
    >;
    level: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.level
        >
    >;
    degreeType: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.degreeType
        >
    >;
    discipline: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.discipline
        >
    >;
    degreeHubPage: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.degreeHubPage
        >
    >;
    completion: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.completion
        >
    >;
    concentrations: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.concentrations
        >
    >;
    creditsNeeded: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.creditsNeeded
        >
    >;
    completionTime: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof SchoolDegreeWidgetFields.completionTime
        >
    >;
};
// END MAGIC -- DO NOT EDIT
