import { css } from "glamor";
import { find } from "lodash-es";
import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { COLUMNS, keyToLabel } from "../../clay/dataGrid/columns";
import { statusToState, WidgetStatus } from "../../clay/widgets";

interface Props {
    table: string;
    selectColumn: (key: string, label: string) => void;
    value: string | null;
    status: WidgetStatus;
}

type ColumnDetail = {
    label: string;
    key: string;
};
function makeColumns(table: string) {
    const columns: ColumnDetail[] = [];
    if (table in COLUMNS) {
        for (const [key, value] of Object.entries(COLUMNS[table])) {
            if (key != "") {
                switch (value.subkeyType) {
                    case null:
                        columns.push({
                            key,
                            label: keyToLabel(key),
                        });
                        break;
                }
            }
        }
    }

    return columns;
}

const WIDGET_STYLE = css({
    "html & input.form-control": {
        border: "none",
        height: "auto",
        padding: "0px",
    },
});

export default function ColumnPicker({
    table,
    selectColumn,
    value,
    status,
}: Props) {
    const handleChange = React.useCallback(
        (selected: any[]) => {
            console.log("TRY handle change", selected);
            if (selected.length > 0) {
                selectColumn(selected[0].key, selected[0].label);
            }
        },
        [selectColumn]
    );

    const [text, setText] = React.useState("");

    const onInputChange = React.useCallback((text: string) => {
        setText(text);
    }, []);

    const columns = React.useMemo(() => makeColumns(table), [table]);

    const selected = find(columns, (column) => column.key === value);
    const id = React.useId();
    console.log("RESCAN", id, selected);
    return (
        <div
            className={
                "f-column-picker form-control " +
                statusToState(status.validation, value === null)
            }
            {...WIDGET_STYLE}
        >
            <Typeahead
                id={id}
                onInputChange={onInputChange}
                labelKey="label"
                selected={selected ? [selected] : []}
                options={makeColumns(table)}
                onChange={handleChange}
            />
        </div>
    );
}
