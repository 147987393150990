import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cell, Column, Table } from "fixed-data-table-2";
import React from "react";
import { FormControl, Tab, Table as SimpleTable, Tabs } from "react-bootstrap";
import { SizeMe, SizeMeProps } from "react-sizeme";
import { useQuery, useSearchAnalytics, useUrlInspection } from "../../clay/api";
import { escapePattern } from "../../clay/dataGrid/patterns";
import { FormWrapper } from "../../clay/widgets/FormField";
import { QuantityStatic } from "../../clay/widgets/number-widget";
import { AiArticle } from "../ai/articles/table";
import {
    AiArticleWidgetReactContext,
    AiArticleWidgetWidgets,
} from "../ai/articles/widget";

function occurrences(
    string: string,
    subString: string,
    allowOverlapping: boolean
) {
    string += "";
    subString += "";
    if (subString.length <= 0) return string.length + 1;

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length;

    while (true) {
        pos = string.indexOf(subString, pos);
        if (pos >= 0) {
            ++n;
            pos += step;
        } else break;
    }
    return n;
}

function OutgoingLinks(props: { text: string }) {
    const links = React.useMemo(() => {
        const div = document.createElement("div");
        div.innerHTML = props.text;
        const links: string[] = [];
        div.querySelectorAll("a").forEach((link) => {
            links.push(link.href);
        });
        return links;
    }, [props.text]);

    return (
        <SizeMe monitorHeight={true}>
            {({ size }: SizeMeProps) => (
                <div style={{}}>
                    {" "}
                    <Table
                        width={size.width || 10}
                        height={size.height || 10}
                        rowsCount={links.length}
                        rowHeight={40}
                        headerHeight={40}
                        isColumnResizing={false}
                        isColumnReordering={false}
                    >
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"link"}
                            isResizable={true}
                            isReorderable={false}
                            key={"link"}
                            header={<Cell onClick={(event) => {}}>Link</Cell>}
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {links[cellProps.rowIndex]}
                                </Cell>
                            )}
                            width={Math.max(1000)}
                        />
                    </Table>
                </div>
            )}
        </SizeMe>
    );
}

function IncomingLinks(props: { path?: string }) {
    const articles = useQuery(
        {
            tableName: "AiArticle",
            columns: ["title", "liveUrl"],
            filters: [
                {
                    or: [
                        {
                            column: "content",
                            filter: {
                                like:
                                    '%"' +
                                    escapePattern(props.path || "") +
                                    '"%',
                            },
                        },
                        {
                            column: "content",
                            filter: {
                                like:
                                    '%"https://academicinfluence.com' +
                                    escapePattern(props.path || "") +
                                    '"%',
                            },
                        },
                    ],
                },
            ],
        },
        [props.path],
        !!props.path
    );

    const pages = useQuery(
        {
            tableName: "AiPage",
            columns: ["key"],
            filters: [
                {
                    or: [
                        {
                            column: "text",
                            filter: {
                                like:
                                    '%"' +
                                    escapePattern(props.path || "") +
                                    '"%',
                            },
                        },
                        {
                            column: "text",
                            filter: {
                                like:
                                    '%"https://academicinfluence.com' +
                                    escapePattern(props.path || "") +
                                    '"%',
                            },
                        },
                    ],
                },
            ],
        },
        [props.path],
        !!props.path
    );

    if (!articles || !pages) {
        return <FontAwesomeIcon icon={faSpinner} spin />;
    }

    const links = [
        ...articles.map(([title, key]) => (key as string) || (title as string)),
        ...pages.map(
            ([key]) => `https://academicinfluence.com/${key as string}`
        ),
    ];

    return (
        <SizeMe monitorHeight={true}>
            {({ size }: SizeMeProps) => (
                <div style={{}}>
                    {" "}
                    <Table
                        width={size.width || 10}
                        height={size.height || 10}
                        rowsCount={links.length}
                        rowHeight={40}
                        headerHeight={40}
                        isColumnResizing={false}
                        isColumnReordering={false}
                    >
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"link"}
                            isResizable={true}
                            isReorderable={false}
                            key={"link"}
                            header={<Cell onClick={(event) => {}}>Link</Cell>}
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {links[cellProps.rowIndex]}
                                </Cell>
                            )}
                            width={Math.max(1000)}
                        />
                    </Table>
                </div>
            )}
        </SizeMe>
    );
}

export function UrlInspection(props: { path?: string }) {
    const inspection = useUrlInspection(
        {
            inspectionUrl: "https://academicinfluence.com" + props.path || "",
            siteUrl: "sc-domain:academicinfluence.com",
        },
        [props.path]
    );

    if (!inspection) {
        return <FontAwesomeIcon icon={faSpinner} spin />;
    }

    return (
        <>
            <FormWrapper label="Verdict">
                <FormControl
                    type="text"
                    value={
                        inspection.inspectionResult.indexStatusResult.verdict
                    }
                    disabled
                />
            </FormWrapper>
            <FormWrapper label="Last Crawl Time">
                <FormControl
                    type="text"
                    value={
                        inspection.inspectionResult.indexStatusResult
                            .lastCrawlTime
                    }
                    disabled
                />
            </FormWrapper>
            <FormWrapper label="Page Fetch State">
                <FormControl
                    type="text"
                    value={
                        inspection.inspectionResult.indexStatusResult
                            .pageFetchState
                    }
                    disabled
                />
            </FormWrapper>
            <FormWrapper label="Referring Urls">
                <ul>
                    {(
                        inspection.inspectionResult.indexStatusResult
                            .referringUrls || []
                    ).map((url: string) => (
                        <li key={url}>{url}</li>
                    ))}
                </ul>
            </FormWrapper>
        </>
    );
}

function SurferSeo(props: { data: AiArticle }) {
    return (
        <>
            <AiArticleWidgetWidgets.focusKeywords />
            {props.data.surferSeoContentScore && (
                <FormWrapper label="Content Score">
                    <QuantityStatic value={props.data.surferSeoContentScore} />
                </FormWrapper>
            )}
            <SimpleTable>
                <tbody>
                    {props.data.surferSeoCompetitors.map((row) => (
                        <tr>
                            <th>{row.url}</th>
                            <th>{row.contentScore.toString()}</th>
                        </tr>
                    ))}
                </tbody>
            </SimpleTable>
        </>
    );
}

function SearchAnalytics(props: { text: string; path?: string }) {
    const processedText = React.useMemo(() => {
        const noTags = props.text.replace(
            /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
            ""
        );
        const noDashes = noTags.replace(/-/g, " ");
        const noPunc = noDashes.replace(/"',.;/g, "");
        return noPunc.toLowerCase();
    }, [props.text]);

    const analytics = useSearchAnalytics(
        {
            siteUrl: "sc-domain:academicinfluence.com",
            requestBody: {
                type: "web",
                startDate: "2023-01-01",
                endDate: "2024-01-01",
                dimensions: ["query"],
                dimensionFilterGroups: [
                    {
                        groupType: "and",
                        filters: [
                            {
                                dimension: "page",
                                operator: "equals",
                                expression:
                                    "https://academicinfluence.com" +
                                        props.path || "",
                            },
                        ],
                    },
                ],
            },
        },
        [props.path]
    );

    if (!analytics) {
        return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    console.log(analytics);
    return (
        <SizeMe monitorHeight={true}>
            {({ size }: SizeMeProps) => (
                <div style={{}}>
                    {" "}
                    <Table
                        width={size.width || 10}
                        height={size.height || 10}
                        rowsCount={analytics.rows?.length || 0}
                        rowHeight={40}
                        headerHeight={40}
                        isColumnResizing={false}
                        isColumnReordering={false}
                    >
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"query"}
                            isResizable={true}
                            isReorderable={false}
                            key={"query"}
                            header={<Cell onClick={(event) => {}}>Query</Cell>}
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {analytics.rows[cellProps.rowIndex].keys[0]}
                                </Cell>
                            )}
                            width={Math.max(500)}
                        />
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"query"}
                            isResizable={true}
                            isReorderable={false}
                            key={"query"}
                            header={
                                <Cell onClick={(event) => {}}>Mentions</Cell>
                            }
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {occurrences(
                                        processedText,
                                        analytics.rows[cellProps.rowIndex]
                                            .keys[0],
                                        false
                                    )}
                                </Cell>
                            )}
                            width={Math.max(500)}
                        />
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"clicks"}
                            isResizable={true}
                            isReorderable={false}
                            key={"clicks"}
                            header={<Cell onClick={(event) => {}}>Clicks</Cell>}
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {analytics.rows[cellProps.rowIndex].clicks}
                                </Cell>
                            )}
                            width={Math.max(75)}
                        />
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"impressions"}
                            isResizable={true}
                            isReorderable={false}
                            key={"impressions"}
                            header={
                                <Cell onClick={(event) => {}}>Impressions</Cell>
                            }
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {
                                        analytics.rows[cellProps.rowIndex]
                                            .impressions
                                    }
                                </Cell>
                            )}
                            width={Math.max(75)}
                        />
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"ctr"}
                            isResizable={true}
                            isReorderable={false}
                            key={"ctr"}
                            header={<Cell onClick={(event) => {}}>CTR</Cell>}
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {analytics.rows[cellProps.rowIndex].ctr}
                                </Cell>
                            )}
                            width={Math.max(75)}
                        />
                        <Column
                            allowCellsRecycling={true}
                            columnKey={"position"}
                            isResizable={true}
                            isReorderable={false}
                            key={"position"}
                            header={
                                <Cell onClick={(event) => {}}>Position</Cell>
                            }
                            cell={(cellProps) => (
                                <Cell {...cellProps}>
                                    {
                                        analytics.rows[cellProps.rowIndex]
                                            .position
                                    }
                                </Cell>
                            )}
                            width={Math.max(75)}
                        />
                    </Table>
                </div>
            )}
        </SizeMe>
    );
    return <></>;
}

export function LinkReport(props: { text: string; path?: string }) {
    const articleContext = React.useContext(AiArticleWidgetReactContext);
    return (
        <div className="link-report-tabs">
            <Tabs defaultActiveKey="outgoing-links" id="link-report-tabs">
                <Tab eventKey="outgoing-links" title="Outgoing Links">
                    <OutgoingLinks text={props.text} />
                </Tab>
                <Tab eventKey="incoming-links" title="Incoming Links">
                    <IncomingLinks path={props.path} />
                </Tab>
                <Tab eventKey="url-inspection" title="URL Inspection">
                    <UrlInspection path={props.path} />
                </Tab>
                <Tab eventKey="search-analytics" title="Search Analytics">
                    <SearchAnalytics path={props.path} text={props.text} />
                </Tab>
                {articleContext?.data && (
                    <Tab eventKey="surfer-seo" title="SurferSeo">
                        <SurferSeo data={articleContext.data} />
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}
