import * as React from "react";
import { Widget, WidgetStatus } from "../../clay/widgets/index";
import { SimpleAtomic } from "../../clay/widgets/simple-atomic";
import ColumnPicker from "./ColumnPicker";

export type ColumnPickerWidgetAction = {
    type: "SET";
    value: string;
};

type ColumnPickerWidgetExtraProps = {
    table: string;
    style?: React.CSSProperties;
    hideStatus?: boolean;
    onSelect?: (key: string, label: string) => void;
};

type ColumnPickerWidgetProps = {
    state: null;
    data: string;
    dispatch: (action: ColumnPickerWidgetAction) => void;
    status: WidgetStatus;
} & ColumnPickerWidgetExtraProps;

export type ColumnPickerWidgetType = Widget<
    null,
    string,
    {},
    ColumnPickerWidgetAction,
    ColumnPickerWidgetExtraProps
>;

export const ColumnPickerWidget: ColumnPickerWidgetType = {
    ...SimpleAtomic,
    dataMeta: {
        type: "string",
    },
    initialize(data: string) {
        return {
            state: null,
            data,
        };
    },
    component(props: ColumnPickerWidgetProps) {
        return (
            <ColumnPicker
                value={props.data}
                table={props.table}
                selectColumn={(key, label) => {
                    if (props.onSelect) {
                        props.onSelect(key, label);
                    } else {
                        props.dispatch({
                            type: "SET",
                            value: key,
                        });
                    }
                }}
                status={props.status}
            />
        );
    },
    reduce(
        state: null,
        data: string,
        action: ColumnPickerWidgetAction,
        context: {}
    ) {
        switch (action.type) {
            case "SET":
                return {
                    state: null,
                    data: action.value,
                    requests: [],
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        }
    },
};
