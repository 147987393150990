import dateParse from "date-fns/parseISO";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import { User } from "../../user/table";

//!Data
export type Replacement = {
    id: UUID;
    recordVersion: Version;
    original: string;
    replacement: string;
    details: string;
    addedBy: Link<User>;
    addedDateTime: Date | null;
    articles: boolean;
    profiles: boolean;
    pages: boolean;
    caseSensitive: boolean;
};

// BEGIN MAGIC -- DO NOT EDIT
export type ReplacementJSON = {
    id: string;
    recordVersion: number | null;
    original: string;
    replacement: string;
    details: string;
    addedBy: string | null;
    addedDateTime: string | null;
    articles: boolean;
    profiles: boolean;
    pages: boolean;
    caseSensitive: boolean;
};

export function JSONToReplacement(json: ReplacementJSON): Replacement {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        original: json.original,
        replacement: json.replacement,
        details: json.details,
        addedBy: json.addedBy,
        addedDateTime:
            json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
        articles: json.articles,
        profiles: json.profiles,
        pages: json.pages,
        caseSensitive: json.caseSensitive,
    };
}
export type ReplacementBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    original?: string;
    replacement?: string;
    details?: string;
    addedBy?: string | null;
    addedDateTime?: string | null;
    articles?: boolean;
    profiles?: boolean;
    pages?: boolean;
    caseSensitive?: boolean;
};

export function newReplacement(): Replacement {
    return JSONToReplacement(repairReplacementJSON(undefined));
}
export function repairReplacementJSON(
    json: ReplacementBrokenJSON | undefined
): ReplacementJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            original: json.original || "",
            replacement: json.replacement || "",
            details: json.details || "",
            addedBy: json.addedBy || null,
            addedDateTime: json.addedDateTime || null,
            articles: json.articles || false,
            profiles: json.profiles || false,
            pages: json.pages || false,
            caseSensitive: json.caseSensitive || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            original: undefined || "",
            replacement: undefined || "",
            details: undefined || "",
            addedBy: undefined || null,
            addedDateTime: undefined || null,
            articles: undefined || false,
            profiles: undefined || false,
            pages: undefined || false,
            caseSensitive: undefined || false,
        };
    }
}

export function ReplacementToJSON(value: Replacement): ReplacementJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        original: value.original,
        replacement: value.replacement,
        details: value.details,
        addedBy: value.addedBy,
        addedDateTime:
            value.addedDateTime !== null
                ? value.addedDateTime.toISOString()
                : null,
        articles: value.articles,
        profiles: value.profiles,
        pages: value.pages,
        caseSensitive: value.caseSensitive,
    };
}

export const REPLACEMENT_META: RecordMeta<
    Replacement,
    ReplacementJSON,
    ReplacementBrokenJSON
> = {
    name: "Replacement",
    type: "record",
    repair: repairReplacementJSON,
    toJSON: ReplacementToJSON,
    fromJSON: JSONToReplacement,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        original: { type: "string" },
        replacement: { type: "string" },
        details: { type: "string" },
        addedBy: { type: "uuid", linkTo: "User" },
        addedDateTime: { type: "datetime" },
        articles: { type: "boolean" },
        profiles: { type: "boolean" },
        pages: { type: "boolean" },
        caseSensitive: { type: "boolean" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
