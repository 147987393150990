import { title } from "change-case";
import Decimal from "decimal.js";
import { find } from "lodash-es";
import * as React from "react";
import {
    Alert,
    Button,
    ListGroup,
    ListGroupItem,
    Modal,
} from "react-bootstrap";
import ReactSwitch from "react-switch";
import { storeRecord, useQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { ShowEditors } from "../../../clay/editors";
import { LocalDate } from "../../../clay/LocalDate";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { QueryRequest } from "../../../clay/requests";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { genUUID } from "../../../clay/uuid";
import { CheckboxWidget } from "../../../clay/widgets/CheckboxWidget";
import { StaticDateTimeWidget } from "../../../clay/widgets/DateTimeWidget";
import { DateWidget } from "../../../clay/widgets/DateWidget";
import { DecimalDefaultWidget } from "../../../clay/widgets/DecimalDefaultWidget";
import {
    FormField,
    FormWrapper,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetRequest,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import { SelectNumberWidget } from "../../../clay/widgets/SelectNumberWidget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { US_STATES } from "../../../states";
import { ED_CATEGORIES, ED_DEGREELEVEL, ED_SUBCATEGORIES } from "../../ed";
import { slugCheckValidate } from "../../slugCheck";
import { CONTENT_AREA } from "../../styles";
import { TagsWidget } from "../../tags-widget";
import { UserLinkWidget } from "../../user";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { ImageUrlWidget } from "../../widgets/image-url-editor";
import { AI_AUTHOR_META } from "../authors/table";
import { AiCategoryLinkWidget } from "../categories";
import { AI_DISCIPLINE_META } from "../disciplines/table";
import {
    AiArticle,
    AiArticleToJSON,
    AI_ARTICLE_META,
    articleLiveUrl,
    articlePath,
} from "./table";

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
//!RecordWidget
export type AiArticleWidgetData = AiArticle;

export const AiArticleWidgetFields = {
    title: FormField(TextWidget),
    shortTitle: OptionalFormField(TextWidget),
    focusKeywords: OptionalFormField(TextWidget),
    content: HtmlEditorWidget,
    modifiedDateTime: OptionalFormField(StaticDateTimeWidget),
    modifiedDate: OptionalFormField(DateWidget),
    publishedDate: OptionalFormField(DateWidget),
    displayDates: FormField(SwitchWidget),
    addedBy: OptionalFormField(UserLinkWidget),
    category: OptionalFormField(AiCategoryLinkWidget),
    slug: FormField(TextWidget),
    tags: OptionalFormField(TagsWidget),
    excerpt: OptionalFormField(TextAreaWidget),
    metaTitle: FormField(TextWidget),
    metaDescription: FormField(TextAreaWidget),
    trending: FormField(CheckboxWidget),
    heroImageBannerUrl: OptionalFormField(ImageUrlWidget),
    heroImageBannerAltText: OptionalFormField(TextWidget),
    alternateSocialMediaImageUrl: OptionalFormField(ImageUrlWidget),
    alternateSocialMediaImageAltText: OptionalFormField(TextWidget),
    importance: OptionalFormField(DecimalDefaultWidget),
    disciplines: OptionalFormField(
        LinkSetWidget({
            meta: AI_DISCIPLINE_META,
            name: (x) => x.name,
        })
    ),
    status: FormField(
        SelectWidget<AiArticle["status"]>([
            { value: "DRAFT", label: "Draft" },
            { value: "SEO", label: "SEO" },
            { value: "READY", label: "Ready" },
            { value: "SUBSCRIBER", label: "Subscriber Only" },
            { value: "PUBLISHED", label: "Published" },
        ])
    ),
    kind: OptionalFormField(
        SelectWidget<AiArticle["kind"]>([
            { value: "article", label: "Rankings" },
            { value: "resource", label: "Resource" },
            { value: "inflection", label: "Inflection" },
            { value: "interview", label: "Interview" },
        ])
    ),
    priority: OptionalFormField(
        SelectNumberWidget([
            {
                label: "Ordinary",
                value: new Decimal(0),
            },
            {
                label: "First",
                value: new Decimal(100000),
            },
            {
                label: "Second",
                value: new Decimal(10000),
            },
            {
                label: "Third",
                value: new Decimal(1000),
            },
            {
                label: "Fourth",
                value: new Decimal(100),
            },
            {
                label: "Fifth",
                value: new Decimal(10),
            },
            {
                label: "Sixth",
                value: new Decimal(1),
            },
        ])
    ),
    edDegreeLevel: OptionalFormField(
        SelectWidget(
            ED_DEGREELEVEL.map((code) => ({
                value: code,
                label: title(code),
            }))
        )
    ),
    edCategory: OptionalFormField(
        SelectWidget(
            ED_CATEGORIES.map((code) => ({
                value: code,
                label: title(code),
            }))
        )
    ),
    edSubcategory: OptionalFormField(
        SelectWidget(
            ED_SUBCATEGORIES.map((code) => ({
                value: code,
                label: title(code),
            }))
        )
    ),
    edHeader: OptionalFormField(TextWidget),
    edCta: OptionalFormField(TextWidget),
    authors: FormField(
        LinkSetWidget({
            meta: AI_AUTHOR_META,
            name: (author) => author.name,
        })
    ),
    reviewers: OptionalFormField(
        LinkSetWidget({
            meta: AI_AUTHOR_META,
            name: (author) => author.name,
        })
    ),
    hideAdWidget: FormField(SwitchWidget),
    hideSubscribePopup: FormField(SwitchWidget),
    hideExcerpt: FormField(SwitchWidget),
};

function aiArticleWidgetReduce(
    state: AiArticleWidgetState,
    data: AiArticleWidgetData,
    action: BaseAiArticleWidgetAction,
    context: AiArticleWidgetContext
): WidgetResult<AiArticleWidgetState, AiArticleWidgetData> {
    if (
        action.type == "STATUS" &&
        action.action.type == "SET" &&
        action.action.value == "PUBLISHED"
    ) {
        return {
            state,
            data: {
                ...data,
                status: "PUBLISHED",
                publishedDate: data.publishedDate || new LocalDate(new Date()),
            },
        };
    } else {
        return baseAiArticleWidgetReduce(state, data, action, context);
    }
}

function AiArticleWidgetRequests(
    state: AiArticleWidgetState,
    data: AiArticle,
    context: AiArticleWidgetContext
) {
    return {
        categories: WidgetRequest<QueryRequest>("QUERY", {
            tableName: "AiCategory",
            columns: ["slug", "id"],
        }),
        slugCheck: WidgetRequest<QueryRequest>("QUERY", {
            tableName: "AiArticle",
            columns: ["id"],
            filters: [
                {
                    column: "id",
                    filter: {
                        not_equal: data.id.uuid,
                    },
                },
                {
                    column: "slug",
                    filter: {
                        equal: data.slug,
                    },
                },
                {
                    column: "category",
                    filter: {
                        equal: data.category,
                    },
                },
            ],
        }),
    };
}

function validateAiArticleWidget(data: AiArticle, cache: QuickCacheApi) {
    const validation = baseValidateAiArticleWidget(data, cache);

    slugCheckValidate("AiArticle", data, cache, validation);

    const readyState = data.status === "READY" || data.status === "PUBLISHED";
    return validation.filter((item) => {
        if (item.invalid) {
            return true;
        }
        switch (item.field) {
            case "excerpt":
            case "category":
            case "metaTitle":
            case "metaDescription":
            case "authors":
                return readyState;

            default:
                return true;
        }
    });
}

function MakingSeries(
    props: AiArticleWidgetProps & { setMakingSeries: (a: boolean) => void }
) {
    const [stateSlug, articleSlug] = props.data.slug.split("/");

    const activeState = find(US_STATES, (state) => state.slug == stateSlug);

    const articles =
        useQuery(
            {
                tableName: "AiArticle",
                columns: ["id", "slug", "recordVersion"],
                filters: [
                    {
                        column: "category",
                        filter: {
                            equal: props.data.category,
                        },
                    },
                    {
                        column: "kind",
                        filter: {
                            equal: props.data.kind,
                        },
                    },
                ],
            },
            [props.data.category, props.data.kind]
        ) || null;

    const [replace, setReplace] = React.useState(false);

    const doIt = React.useCallback(async () => {
        const slugRegex = new RegExp(
            "\\b" + escapeRegExp(activeState!.slug) + "\\b",
            "g"
        );
        const nameRegex = new RegExp(
            "\\b" + escapeRegExp(activeState!.name) + "\\b",
            "g"
        );
        const abbrRegex = new RegExp(
            "\\b" + escapeRegExp(activeState!.abbr) + "\\b",
            "g"
        );

        console.log(nameRegex);

        await Promise.all(
            US_STATES.map((state) => {
                if (state.slug == stateSlug) {
                    // don't do the sample state
                    return;
                }

                const existingState = find(
                    articles,
                    (article) => article[1] == state.slug + "/" + articleSlug
                );
                if (existingState && !replace) {
                    // don't overwrite states if not doing tha
                    return;
                }

                const rewrite = (text: string) => {
                    const before = text;
                    text = text.replace(slugRegex, state.slug);
                    text = text.replace(nameRegex, state.name);
                    text = text.replace(abbrRegex, state.abbr);
                    console.log(before, text);
                    return text;
                };

                const newArticle: AiArticle = {
                    id: {
                        uuid:
                            (existingState && (existingState[0] as string)) ||
                            genUUID(),
                    },
                    recordVersion: {
                        version:
                            (existingState && (existingState[2] as any)) ??
                            null,
                    },
                    cachedViews: new Decimal(0),
                    title: rewrite(props.data.title),
                    shortTitle: rewrite(props.data.shortTitle),
                    byline: props.data.byline,
                    addedBy: null,
                    addedDateTime: null,
                    modifiedBy: null,
                    modifiedDateTime: null,
                    modifiedDate: null,
                    publishedDate: null,
                    publishedDateTime: null,
                    content: rewrite(props.data.content),
                    displayDates: props.data.displayDates,
                    priority: props.data.priority,
                    trending: props.data.trending,
                    author: props.data.author,
                    tags: props.data.tags,
                    kind: props.data.kind,
                    category: props.data.category,
                    slug: state.slug + "/" + articleSlug,
                    excerpt: rewrite(props.data.excerpt),
                    metaTitle: rewrite(props.data.metaTitle),
                    metaDescription: rewrite(props.data.metaDescription),
                    metaKeywords: rewrite(props.data.metaKeywords),
                    focusKeywords: rewrite(props.data.focusKeywords),
                    surferSeoAuditId: "",
                    surferSeoAuditKeywords: "",
                    surferSeoCompetitors: [],
                    surferSeoContentScore: null,
                    status: "DRAFT",
                    heroImageBannerUrl: rewrite(props.data.heroImageBannerUrl),
                    heroImageBannerAltText: rewrite(
                        props.data.heroImageBannerAltText
                    ),
                    heroImageThumbnailAltText: rewrite(
                        props.data.heroImageThumbnailAltText
                    ),
                    heroImageThumbnailUrl: rewrite(
                        props.data.heroImageThumbnailUrl
                    ),
                    alternateSocialMediaImageAltText: rewrite(
                        props.data.alternateSocialMediaImageAltText
                    ),
                    alternateSocialMediaImageUrl: rewrite(
                        props.data.alternateSocialMediaImageUrl
                    ),
                    discipline: props.data.discipline,
                    disciplines: props.data.disciplines,
                    edDegreeLevel: props.data.edDegreeLevel,
                    edCategory: props.data.edCategory,
                    edSubcategory: props.data.edSubcategory,
                    edCta: "",
                    edHeader: "",
                    authors: props.data.authors,
                    reviewers: props.data.reviewers,
                    hideAdWidget: props.data.hideAdWidget,
                    hideSubscribePopup: props.data.hideSubscribePopup,
                    hideExcerpt: props.data.hideExcerpt,
                    relatedArticles: props.data.relatedArticles,
                    importance: props.data.importance,
                };

                console.log(
                    newArticle.recordVersion,
                    existingState && existingState[2]
                );

                return storeRecord(
                    AI_ARTICLE_META,
                    "create series",
                    newArticle
                );
            })
        );

        props.setMakingSeries(false);
    }, [props.data, articles, props.setMakingSeries, replace]);

    if (!activeState) {
        return (
            <Modal show={true} onHide={() => props.setMakingSeries(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Make By State Series</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">
                        Did not understand state: {stateSlug}
                    </Alert>
                </Modal.Body>
            </Modal>
        );
    }

    if (!articles) {
        return null;
    }

    const presentStates = US_STATES.filter(
        (state) =>
            state.slug != stateSlug &&
            find(
                articles,
                (article) => article[1] == state.slug + "/" + articleSlug
            )
    );

    return (
        articles && (
            <Modal show={true} onHide={() => props.setMakingSeries(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Make By State Series</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {presentStates.length > 0 && (
                        <>
                            <h5>
                                Articles For the Following States Already Exist
                            </h5>
                            <ListGroup>
                                {presentStates.map((state) => (
                                    <ListGroupItem>{state.name}</ListGroupItem>
                                ))}
                            </ListGroup>
                            <FormWrapper label="Replace Existing Pages">
                                <ReactSwitch
                                    checked={replace}
                                    onChange={setReplace}
                                />
                            </FormWrapper>
                        </>
                    )}
                    <Button onClick={doIt}>Generate</Button>
                </Modal.Body>
            </Modal>
        )
    );
}

function AiArticleWidgetComponent(
    widgets: AiArticleWidgetWidgets,
    props: AiArticleWidgetProps
) {
    const previewWindow = React.useRef<Window | null>();
    const openPreview = React.useCallback(() => {
        previewWindow.current = window.open(
            window.location.hostname === "localhost"
                ? "http://localhost:3000/_preview"
                : "https://academicinfluence.com/_preview"
        );
        function onMessage(message: MessageEvent) {
            if (message.source === previewWindow.current) {
                previewWindow.current?.postMessage(
                    AiArticleToJSON(props.data),
                    "*"
                );
            }
        }
        window.addEventListener("message", onMessage);
    }, [props.data]);
    previewWindow.current?.postMessage(AiArticleToJSON(props.data), "*");
    const categories = useQuery(
        {
            tableName: "AiCategory",
            columns: ["slug", "id"],
        },
        []
    );

    let category_name = "";
    if (categories) {
        let row = find(categories, (row) => row[1] == props.data.category);
        if (row) {
            category_name = row[0] as string;
        }
    }
    let category = { slug: category_name } as any;

    const [isMakingSeries, setMakingSeries] = React.useState(false);

    return (
        <div
            style={{
                display: "flex",
                flexGrow: 1,
                overflowY: "auto",
            }}
        >
            {isMakingSeries && (
                <MakingSeries {...props} setMakingSeries={setMakingSeries} />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                }}
            >
                <widgets.content path={articlePath(props.data, category)} />
            </div>
            <div
                style={{
                    paddingLeft: "25px",
                    overflowY: "auto",
                    width: "3in",
                    flexShrink: 0,
                    flexGrow: 0,
                }}
                {...CONTENT_AREA}
            >
                <SaveDeleteButton />
                <ShowEditors />
                <div {...CONTENT_AREA}>
                    <Button onClick={openPreview}>Preview</Button>
                    <widgets.title />
                    <widgets.authors />
                    <widgets.reviewers />
                    <widgets.shortTitle />
                    <widgets.status />
                    <widgets.tags />
                    <widgets.importance defaultData={new Decimal(5)} />
                    <widgets.publishedDate label="Published (visible)" />
                    <widgets.modifiedDate label="Modified (visible)" />
                    <widgets.modifiedDateTime label="Last Edited (hidden)" />
                    <widgets.displayDates />
                    <widgets.trending />
                    <widgets.priority label="Order Priority" />
                    <widgets.kind />
                    <widgets.category clearable />
                    <widgets.slug />
                    <widgets.disciplines
                        disableAdd={props.data.disciplines.length >= 3}
                    />
                    <widgets.edDegreeLevel label="Degree Level" />
                    <widgets.edCategory label="Category" />
                    <widgets.edSubcategory label="Subject" />
                    <widgets.edHeader label="Header" />
                    <widgets.edCta label="Call to Action" />
                    <a
                        target="_blank"
                        style={{ fontSize: "10pt" }}
                        href={articleLiveUrl(props.data, category)}
                    >
                        {articlePath(props.data, category)}
                    </a>
                    <widgets.excerpt />
                    <widgets.hideExcerpt />
                    <widgets.heroImageBannerUrl />
                    <widgets.heroImageBannerAltText />
                    <widgets.alternateSocialMediaImageUrl />
                    <widgets.alternateSocialMediaImageAltText />
                    <widgets.metaTitle />
                    <widgets.metaDescription />
                    <widgets.hideAdWidget />
                    <widgets.hideSubscribePopup />
                    <Button onClick={() => setMakingSeries(true)}>
                        Make State Series
                    </Button>
                </div>
            </div>
        </div>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiArticleWidgetContext = WidgetContext<
    typeof AiArticleWidgetFields.title
> &
    WidgetContext<typeof AiArticleWidgetFields.shortTitle> &
    WidgetContext<typeof AiArticleWidgetFields.focusKeywords> &
    WidgetContext<typeof AiArticleWidgetFields.content> &
    WidgetContext<typeof AiArticleWidgetFields.modifiedDateTime> &
    WidgetContext<typeof AiArticleWidgetFields.modifiedDate> &
    WidgetContext<typeof AiArticleWidgetFields.publishedDate> &
    WidgetContext<typeof AiArticleWidgetFields.displayDates> &
    WidgetContext<typeof AiArticleWidgetFields.addedBy> &
    WidgetContext<typeof AiArticleWidgetFields.category> &
    WidgetContext<typeof AiArticleWidgetFields.slug> &
    WidgetContext<typeof AiArticleWidgetFields.tags> &
    WidgetContext<typeof AiArticleWidgetFields.excerpt> &
    WidgetContext<typeof AiArticleWidgetFields.metaTitle> &
    WidgetContext<typeof AiArticleWidgetFields.metaDescription> &
    WidgetContext<typeof AiArticleWidgetFields.trending> &
    WidgetContext<typeof AiArticleWidgetFields.heroImageBannerUrl> &
    WidgetContext<typeof AiArticleWidgetFields.heroImageBannerAltText> &
    WidgetContext<typeof AiArticleWidgetFields.alternateSocialMediaImageUrl> &
    WidgetContext<
        typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
    > &
    WidgetContext<typeof AiArticleWidgetFields.importance> &
    WidgetContext<typeof AiArticleWidgetFields.disciplines> &
    WidgetContext<typeof AiArticleWidgetFields.status> &
    WidgetContext<typeof AiArticleWidgetFields.kind> &
    WidgetContext<typeof AiArticleWidgetFields.priority> &
    WidgetContext<typeof AiArticleWidgetFields.edDegreeLevel> &
    WidgetContext<typeof AiArticleWidgetFields.edCategory> &
    WidgetContext<typeof AiArticleWidgetFields.edSubcategory> &
    WidgetContext<typeof AiArticleWidgetFields.edHeader> &
    WidgetContext<typeof AiArticleWidgetFields.edCta> &
    WidgetContext<typeof AiArticleWidgetFields.authors> &
    WidgetContext<typeof AiArticleWidgetFields.reviewers> &
    WidgetContext<typeof AiArticleWidgetFields.hideAdWidget> &
    WidgetContext<typeof AiArticleWidgetFields.hideSubscribePopup> &
    WidgetContext<typeof AiArticleWidgetFields.hideExcerpt>;
type AiArticleWidgetExtraProps = {};
type AiArticleWidgetBaseState = {
    title: WidgetState<typeof AiArticleWidgetFields.title>;
    shortTitle: WidgetState<typeof AiArticleWidgetFields.shortTitle>;
    focusKeywords: WidgetState<typeof AiArticleWidgetFields.focusKeywords>;
    content: WidgetState<typeof AiArticleWidgetFields.content>;
    modifiedDateTime: WidgetState<
        typeof AiArticleWidgetFields.modifiedDateTime
    >;
    modifiedDate: WidgetState<typeof AiArticleWidgetFields.modifiedDate>;
    publishedDate: WidgetState<typeof AiArticleWidgetFields.publishedDate>;
    displayDates: WidgetState<typeof AiArticleWidgetFields.displayDates>;
    addedBy: WidgetState<typeof AiArticleWidgetFields.addedBy>;
    category: WidgetState<typeof AiArticleWidgetFields.category>;
    slug: WidgetState<typeof AiArticleWidgetFields.slug>;
    tags: WidgetState<typeof AiArticleWidgetFields.tags>;
    excerpt: WidgetState<typeof AiArticleWidgetFields.excerpt>;
    metaTitle: WidgetState<typeof AiArticleWidgetFields.metaTitle>;
    metaDescription: WidgetState<typeof AiArticleWidgetFields.metaDescription>;
    trending: WidgetState<typeof AiArticleWidgetFields.trending>;
    heroImageBannerUrl: WidgetState<
        typeof AiArticleWidgetFields.heroImageBannerUrl
    >;
    heroImageBannerAltText: WidgetState<
        typeof AiArticleWidgetFields.heroImageBannerAltText
    >;
    alternateSocialMediaImageUrl: WidgetState<
        typeof AiArticleWidgetFields.alternateSocialMediaImageUrl
    >;
    alternateSocialMediaImageAltText: WidgetState<
        typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
    >;
    importance: WidgetState<typeof AiArticleWidgetFields.importance>;
    disciplines: WidgetState<typeof AiArticleWidgetFields.disciplines>;
    status: WidgetState<typeof AiArticleWidgetFields.status>;
    kind: WidgetState<typeof AiArticleWidgetFields.kind>;
    priority: WidgetState<typeof AiArticleWidgetFields.priority>;
    edDegreeLevel: WidgetState<typeof AiArticleWidgetFields.edDegreeLevel>;
    edCategory: WidgetState<typeof AiArticleWidgetFields.edCategory>;
    edSubcategory: WidgetState<typeof AiArticleWidgetFields.edSubcategory>;
    edHeader: WidgetState<typeof AiArticleWidgetFields.edHeader>;
    edCta: WidgetState<typeof AiArticleWidgetFields.edCta>;
    authors: WidgetState<typeof AiArticleWidgetFields.authors>;
    reviewers: WidgetState<typeof AiArticleWidgetFields.reviewers>;
    hideAdWidget: WidgetState<typeof AiArticleWidgetFields.hideAdWidget>;
    hideSubscribePopup: WidgetState<
        typeof AiArticleWidgetFields.hideSubscribePopup
    >;
    hideExcerpt: WidgetState<typeof AiArticleWidgetFields.hideExcerpt>;
};
export type AiArticleWidgetState = AiArticleWidgetBaseState;

type BaseAiArticleWidgetAction =
    | {
          type: "TITLE";
          action: WidgetAction<typeof AiArticleWidgetFields.title>;
      }
    | {
          type: "SHORT_TITLE";
          action: WidgetAction<typeof AiArticleWidgetFields.shortTitle>;
      }
    | {
          type: "FOCUS_KEYWORDS";
          action: WidgetAction<typeof AiArticleWidgetFields.focusKeywords>;
      }
    | {
          type: "CONTENT";
          action: WidgetAction<typeof AiArticleWidgetFields.content>;
      }
    | {
          type: "MODIFIED_DATE_TIME";
          action: WidgetAction<typeof AiArticleWidgetFields.modifiedDateTime>;
      }
    | {
          type: "MODIFIED_DATE";
          action: WidgetAction<typeof AiArticleWidgetFields.modifiedDate>;
      }
    | {
          type: "PUBLISHED_DATE";
          action: WidgetAction<typeof AiArticleWidgetFields.publishedDate>;
      }
    | {
          type: "DISPLAY_DATES";
          action: WidgetAction<typeof AiArticleWidgetFields.displayDates>;
      }
    | {
          type: "ADDED_BY";
          action: WidgetAction<typeof AiArticleWidgetFields.addedBy>;
      }
    | {
          type: "CATEGORY";
          action: WidgetAction<typeof AiArticleWidgetFields.category>;
      }
    | { type: "SLUG"; action: WidgetAction<typeof AiArticleWidgetFields.slug> }
    | { type: "TAGS"; action: WidgetAction<typeof AiArticleWidgetFields.tags> }
    | {
          type: "EXCERPT";
          action: WidgetAction<typeof AiArticleWidgetFields.excerpt>;
      }
    | {
          type: "META_TITLE";
          action: WidgetAction<typeof AiArticleWidgetFields.metaTitle>;
      }
    | {
          type: "META_DESCRIPTION";
          action: WidgetAction<typeof AiArticleWidgetFields.metaDescription>;
      }
    | {
          type: "TRENDING";
          action: WidgetAction<typeof AiArticleWidgetFields.trending>;
      }
    | {
          type: "HERO_IMAGE_BANNER_URL";
          action: WidgetAction<typeof AiArticleWidgetFields.heroImageBannerUrl>;
      }
    | {
          type: "HERO_IMAGE_BANNER_ALT_TEXT";
          action: WidgetAction<
              typeof AiArticleWidgetFields.heroImageBannerAltText
          >;
      }
    | {
          type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL";
          action: WidgetAction<
              typeof AiArticleWidgetFields.alternateSocialMediaImageUrl
          >;
      }
    | {
          type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT";
          action: WidgetAction<
              typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
          >;
      }
    | {
          type: "IMPORTANCE";
          action: WidgetAction<typeof AiArticleWidgetFields.importance>;
      }
    | {
          type: "DISCIPLINES";
          action: WidgetAction<typeof AiArticleWidgetFields.disciplines>;
      }
    | {
          type: "STATUS";
          action: WidgetAction<typeof AiArticleWidgetFields.status>;
      }
    | { type: "KIND"; action: WidgetAction<typeof AiArticleWidgetFields.kind> }
    | {
          type: "PRIORITY";
          action: WidgetAction<typeof AiArticleWidgetFields.priority>;
      }
    | {
          type: "ED_DEGREE_LEVEL";
          action: WidgetAction<typeof AiArticleWidgetFields.edDegreeLevel>;
      }
    | {
          type: "ED_CATEGORY";
          action: WidgetAction<typeof AiArticleWidgetFields.edCategory>;
      }
    | {
          type: "ED_SUBCATEGORY";
          action: WidgetAction<typeof AiArticleWidgetFields.edSubcategory>;
      }
    | {
          type: "ED_HEADER";
          action: WidgetAction<typeof AiArticleWidgetFields.edHeader>;
      }
    | {
          type: "ED_CTA";
          action: WidgetAction<typeof AiArticleWidgetFields.edCta>;
      }
    | {
          type: "AUTHORS";
          action: WidgetAction<typeof AiArticleWidgetFields.authors>;
      }
    | {
          type: "REVIEWERS";
          action: WidgetAction<typeof AiArticleWidgetFields.reviewers>;
      }
    | {
          type: "HIDE_AD_WIDGET";
          action: WidgetAction<typeof AiArticleWidgetFields.hideAdWidget>;
      }
    | {
          type: "HIDE_SUBSCRIBE_POPUP";
          action: WidgetAction<typeof AiArticleWidgetFields.hideSubscribePopup>;
      }
    | {
          type: "HIDE_EXCERPT";
          action: WidgetAction<typeof AiArticleWidgetFields.hideExcerpt>;
      };

export type AiArticleWidgetAction = BaseAiArticleWidgetAction;

export type AiArticleWidgetProps = WidgetProps<
    AiArticleWidgetState,
    AiArticleWidgetData,
    AiArticleWidgetAction,
    AiArticleWidgetExtraProps
>;

function baseValidateAiArticleWidget(
    data: AiArticleWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiArticleWidgetFields.title,
        data.title,
        cache,
        "title",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.shortTitle,
        data.shortTitle,
        cache,
        "shortTitle",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.focusKeywords,
        data.focusKeywords,
        cache,
        "focusKeywords",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.content,
        data.content,
        cache,
        "content",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.modifiedDateTime,
        data.modifiedDateTime,
        cache,
        "modifiedDateTime",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.modifiedDate,
        data.modifiedDate,
        cache,
        "modifiedDate",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.publishedDate,
        data.publishedDate,
        cache,
        "publishedDate",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.displayDates,
        data.displayDates,
        cache,
        "displayDates",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.addedBy,
        data.addedBy,
        cache,
        "addedBy",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.category,
        data.category,
        cache,
        "category",
        errors
    );
    subvalidate(AiArticleWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(AiArticleWidgetFields.tags, data.tags, cache, "tags", errors);
    subvalidate(
        AiArticleWidgetFields.excerpt,
        data.excerpt,
        cache,
        "excerpt",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.metaTitle,
        data.metaTitle,
        cache,
        "metaTitle",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.metaDescription,
        data.metaDescription,
        cache,
        "metaDescription",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.trending,
        data.trending,
        cache,
        "trending",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.heroImageBannerUrl,
        data.heroImageBannerUrl,
        cache,
        "heroImageBannerUrl",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.heroImageBannerAltText,
        data.heroImageBannerAltText,
        cache,
        "heroImageBannerAltText",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.alternateSocialMediaImageUrl,
        data.alternateSocialMediaImageUrl,
        cache,
        "alternateSocialMediaImageUrl",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.alternateSocialMediaImageAltText,
        data.alternateSocialMediaImageAltText,
        cache,
        "alternateSocialMediaImageAltText",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.importance,
        data.importance,
        cache,
        "importance",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.disciplines,
        data.disciplines,
        cache,
        "disciplines",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.status,
        data.status,
        cache,
        "status",
        errors
    );
    subvalidate(AiArticleWidgetFields.kind, data.kind, cache, "kind", errors);
    subvalidate(
        AiArticleWidgetFields.priority,
        data.priority,
        cache,
        "priority",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.edDegreeLevel,
        data.edDegreeLevel,
        cache,
        "edDegreeLevel",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.edCategory,
        data.edCategory,
        cache,
        "edCategory",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.edSubcategory,
        data.edSubcategory,
        cache,
        "edSubcategory",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.edHeader,
        data.edHeader,
        cache,
        "edHeader",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.edCta,
        data.edCta,
        cache,
        "edCta",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.authors,
        data.authors,
        cache,
        "authors",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.reviewers,
        data.reviewers,
        cache,
        "reviewers",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.hideAdWidget,
        data.hideAdWidget,
        cache,
        "hideAdWidget",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.hideSubscribePopup,
        data.hideSubscribePopup,
        cache,
        "hideSubscribePopup",
        errors
    );
    subvalidate(
        AiArticleWidgetFields.hideExcerpt,
        data.hideExcerpt,
        cache,
        "hideExcerpt",
        errors
    );
    return errors;
}
function baseAiArticleWidgetReduce(
    state: AiArticleWidgetState,
    data: AiArticleWidgetData,
    action: BaseAiArticleWidgetAction,
    context: AiArticleWidgetContext
): WidgetResult<AiArticleWidgetState, AiArticleWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "TITLE": {
            const inner = AiArticleWidgetFields.title.reduce(
                state.title,
                data.title,
                action.action,
                subcontext
            );
            return {
                state: { ...state, title: inner.state },
                data: { ...data, title: inner.data },
            };
        }
        case "SHORT_TITLE": {
            const inner = AiArticleWidgetFields.shortTitle.reduce(
                state.shortTitle,
                data.shortTitle,
                action.action,
                subcontext
            );
            return {
                state: { ...state, shortTitle: inner.state },
                data: { ...data, shortTitle: inner.data },
            };
        }
        case "FOCUS_KEYWORDS": {
            const inner = AiArticleWidgetFields.focusKeywords.reduce(
                state.focusKeywords,
                data.focusKeywords,
                action.action,
                subcontext
            );
            return {
                state: { ...state, focusKeywords: inner.state },
                data: { ...data, focusKeywords: inner.data },
            };
        }
        case "CONTENT": {
            const inner = AiArticleWidgetFields.content.reduce(
                state.content,
                data.content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, content: inner.state },
                data: { ...data, content: inner.data },
            };
        }
        case "MODIFIED_DATE_TIME": {
            const inner = AiArticleWidgetFields.modifiedDateTime.reduce(
                state.modifiedDateTime,
                data.modifiedDateTime,
                action.action,
                subcontext
            );
            return {
                state: { ...state, modifiedDateTime: inner.state },
                data: { ...data, modifiedDateTime: inner.data },
            };
        }
        case "MODIFIED_DATE": {
            const inner = AiArticleWidgetFields.modifiedDate.reduce(
                state.modifiedDate,
                data.modifiedDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, modifiedDate: inner.state },
                data: { ...data, modifiedDate: inner.data },
            };
        }
        case "PUBLISHED_DATE": {
            const inner = AiArticleWidgetFields.publishedDate.reduce(
                state.publishedDate,
                data.publishedDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, publishedDate: inner.state },
                data: { ...data, publishedDate: inner.data },
            };
        }
        case "DISPLAY_DATES": {
            const inner = AiArticleWidgetFields.displayDates.reduce(
                state.displayDates,
                data.displayDates,
                action.action,
                subcontext
            );
            return {
                state: { ...state, displayDates: inner.state },
                data: { ...data, displayDates: inner.data },
            };
        }
        case "ADDED_BY": {
            const inner = AiArticleWidgetFields.addedBy.reduce(
                state.addedBy,
                data.addedBy,
                action.action,
                subcontext
            );
            return {
                state: { ...state, addedBy: inner.state },
                data: { ...data, addedBy: inner.data },
            };
        }
        case "CATEGORY": {
            const inner = AiArticleWidgetFields.category.reduce(
                state.category,
                data.category,
                action.action,
                subcontext
            );
            return {
                state: { ...state, category: inner.state },
                data: { ...data, category: inner.data },
            };
        }
        case "SLUG": {
            const inner = AiArticleWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "TAGS": {
            const inner = AiArticleWidgetFields.tags.reduce(
                state.tags,
                data.tags,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tags: inner.state },
                data: { ...data, tags: inner.data },
            };
        }
        case "EXCERPT": {
            const inner = AiArticleWidgetFields.excerpt.reduce(
                state.excerpt,
                data.excerpt,
                action.action,
                subcontext
            );
            return {
                state: { ...state, excerpt: inner.state },
                data: { ...data, excerpt: inner.data },
            };
        }
        case "META_TITLE": {
            const inner = AiArticleWidgetFields.metaTitle.reduce(
                state.metaTitle,
                data.metaTitle,
                action.action,
                subcontext
            );
            return {
                state: { ...state, metaTitle: inner.state },
                data: { ...data, metaTitle: inner.data },
            };
        }
        case "META_DESCRIPTION": {
            const inner = AiArticleWidgetFields.metaDescription.reduce(
                state.metaDescription,
                data.metaDescription,
                action.action,
                subcontext
            );
            return {
                state: { ...state, metaDescription: inner.state },
                data: { ...data, metaDescription: inner.data },
            };
        }
        case "TRENDING": {
            const inner = AiArticleWidgetFields.trending.reduce(
                state.trending,
                data.trending,
                action.action,
                subcontext
            );
            return {
                state: { ...state, trending: inner.state },
                data: { ...data, trending: inner.data },
            };
        }
        case "HERO_IMAGE_BANNER_URL": {
            const inner = AiArticleWidgetFields.heroImageBannerUrl.reduce(
                state.heroImageBannerUrl,
                data.heroImageBannerUrl,
                action.action,
                subcontext
            );
            return {
                state: { ...state, heroImageBannerUrl: inner.state },
                data: { ...data, heroImageBannerUrl: inner.data },
            };
        }
        case "HERO_IMAGE_BANNER_ALT_TEXT": {
            const inner = AiArticleWidgetFields.heroImageBannerAltText.reduce(
                state.heroImageBannerAltText,
                data.heroImageBannerAltText,
                action.action,
                subcontext
            );
            return {
                state: { ...state, heroImageBannerAltText: inner.state },
                data: { ...data, heroImageBannerAltText: inner.data },
            };
        }
        case "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL": {
            const inner =
                AiArticleWidgetFields.alternateSocialMediaImageUrl.reduce(
                    state.alternateSocialMediaImageUrl,
                    data.alternateSocialMediaImageUrl,
                    action.action,
                    subcontext
                );
            return {
                state: { ...state, alternateSocialMediaImageUrl: inner.state },
                data: { ...data, alternateSocialMediaImageUrl: inner.data },
            };
        }
        case "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT": {
            const inner =
                AiArticleWidgetFields.alternateSocialMediaImageAltText.reduce(
                    state.alternateSocialMediaImageAltText,
                    data.alternateSocialMediaImageAltText,
                    action.action,
                    subcontext
                );
            return {
                state: {
                    ...state,
                    alternateSocialMediaImageAltText: inner.state,
                },
                data: { ...data, alternateSocialMediaImageAltText: inner.data },
            };
        }
        case "IMPORTANCE": {
            const inner = AiArticleWidgetFields.importance.reduce(
                state.importance,
                data.importance,
                action.action,
                subcontext
            );
            return {
                state: { ...state, importance: inner.state },
                data: { ...data, importance: inner.data },
            };
        }
        case "DISCIPLINES": {
            const inner = AiArticleWidgetFields.disciplines.reduce(
                state.disciplines,
                data.disciplines,
                action.action,
                subcontext
            );
            return {
                state: { ...state, disciplines: inner.state },
                data: { ...data, disciplines: inner.data },
            };
        }
        case "STATUS": {
            const inner = AiArticleWidgetFields.status.reduce(
                state.status,
                data.status,
                action.action,
                subcontext
            );
            return {
                state: { ...state, status: inner.state },
                data: { ...data, status: inner.data },
            };
        }
        case "KIND": {
            const inner = AiArticleWidgetFields.kind.reduce(
                state.kind,
                data.kind,
                action.action,
                subcontext
            );
            return {
                state: { ...state, kind: inner.state },
                data: { ...data, kind: inner.data },
            };
        }
        case "PRIORITY": {
            const inner = AiArticleWidgetFields.priority.reduce(
                state.priority,
                data.priority,
                action.action,
                subcontext
            );
            return {
                state: { ...state, priority: inner.state },
                data: { ...data, priority: inner.data },
            };
        }
        case "ED_DEGREE_LEVEL": {
            const inner = AiArticleWidgetFields.edDegreeLevel.reduce(
                state.edDegreeLevel,
                data.edDegreeLevel,
                action.action,
                subcontext
            );
            return {
                state: { ...state, edDegreeLevel: inner.state },
                data: { ...data, edDegreeLevel: inner.data },
            };
        }
        case "ED_CATEGORY": {
            const inner = AiArticleWidgetFields.edCategory.reduce(
                state.edCategory,
                data.edCategory,
                action.action,
                subcontext
            );
            return {
                state: { ...state, edCategory: inner.state },
                data: { ...data, edCategory: inner.data },
            };
        }
        case "ED_SUBCATEGORY": {
            const inner = AiArticleWidgetFields.edSubcategory.reduce(
                state.edSubcategory,
                data.edSubcategory,
                action.action,
                subcontext
            );
            return {
                state: { ...state, edSubcategory: inner.state },
                data: { ...data, edSubcategory: inner.data },
            };
        }
        case "ED_HEADER": {
            const inner = AiArticleWidgetFields.edHeader.reduce(
                state.edHeader,
                data.edHeader,
                action.action,
                subcontext
            );
            return {
                state: { ...state, edHeader: inner.state },
                data: { ...data, edHeader: inner.data },
            };
        }
        case "ED_CTA": {
            const inner = AiArticleWidgetFields.edCta.reduce(
                state.edCta,
                data.edCta,
                action.action,
                subcontext
            );
            return {
                state: { ...state, edCta: inner.state },
                data: { ...data, edCta: inner.data },
            };
        }
        case "AUTHORS": {
            const inner = AiArticleWidgetFields.authors.reduce(
                state.authors,
                data.authors,
                action.action,
                subcontext
            );
            return {
                state: { ...state, authors: inner.state },
                data: { ...data, authors: inner.data },
            };
        }
        case "REVIEWERS": {
            const inner = AiArticleWidgetFields.reviewers.reduce(
                state.reviewers,
                data.reviewers,
                action.action,
                subcontext
            );
            return {
                state: { ...state, reviewers: inner.state },
                data: { ...data, reviewers: inner.data },
            };
        }
        case "HIDE_AD_WIDGET": {
            const inner = AiArticleWidgetFields.hideAdWidget.reduce(
                state.hideAdWidget,
                data.hideAdWidget,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hideAdWidget: inner.state },
                data: { ...data, hideAdWidget: inner.data },
            };
        }
        case "HIDE_SUBSCRIBE_POPUP": {
            const inner = AiArticleWidgetFields.hideSubscribePopup.reduce(
                state.hideSubscribePopup,
                data.hideSubscribePopup,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hideSubscribePopup: inner.state },
                data: { ...data, hideSubscribePopup: inner.data },
            };
        }
        case "HIDE_EXCERPT": {
            const inner = AiArticleWidgetFields.hideExcerpt.reduce(
                state.hideExcerpt,
                data.hideExcerpt,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hideExcerpt: inner.state },
                data: { ...data, hideExcerpt: inner.data },
            };
        }
    }
}
export type AiArticleWidgetReactContextType = {
    state: AiArticleWidgetState;
    data: AiArticleWidgetData;
    dispatch: (action: AiArticleWidgetAction) => void;
    status: WidgetStatus;
};
export const AiArticleWidgetReactContext = React.createContext<
    AiArticleWidgetReactContextType | undefined
>(undefined);
export const AiArticleWidgetWidgets = {
    title: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.title> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.title>) =>
                context.dispatch({ type: "TITLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "title", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.title.component
                state={context.state.title}
                data={context.data.title}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Title"}
            />
        );
    },
    shortTitle: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.shortTitle> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.shortTitle>) =>
                context.dispatch({ type: "SHORT_TITLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "shortTitle", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.shortTitle.component
                state={context.state.shortTitle}
                data={context.data.shortTitle}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Short Title"}
            />
        );
    },
    focusKeywords: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.focusKeywords> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiArticleWidgetFields.focusKeywords>
            ) => context.dispatch({ type: "FOCUS_KEYWORDS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "focusKeywords", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.focusKeywords.component
                state={context.state.focusKeywords}
                data={context.data.focusKeywords}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Focus Keywords"}
            />
        );
    },
    content: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.content> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.content>) =>
                context.dispatch({ type: "CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.content.component
                state={context.state.content}
                data={context.data.content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Content"}
            />
        );
    },
    modifiedDateTime: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.modifiedDateTime
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.modifiedDateTime
                >
            ) => context.dispatch({ type: "MODIFIED_DATE_TIME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "modifiedDateTime", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.modifiedDateTime.component
                state={context.state.modifiedDateTime}
                data={context.data.modifiedDateTime}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Modified Date Time"}
            />
        );
    },
    modifiedDate: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.modifiedDate> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.modifiedDate>) =>
                context.dispatch({ type: "MODIFIED_DATE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "modifiedDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.modifiedDate.component
                state={context.state.modifiedDate}
                data={context.data.modifiedDate}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Modified Date"}
            />
        );
    },
    publishedDate: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.publishedDate> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiArticleWidgetFields.publishedDate>
            ) => context.dispatch({ type: "PUBLISHED_DATE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "publishedDate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.publishedDate.component
                state={context.state.publishedDate}
                data={context.data.publishedDate}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Published Date"}
            />
        );
    },
    displayDates: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.displayDates> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.displayDates>) =>
                context.dispatch({ type: "DISPLAY_DATES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "displayDates", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.displayDates.component
                state={context.state.displayDates}
                data={context.data.displayDates}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Display Dates"}
            />
        );
    },
    addedBy: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.addedBy> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.addedBy>) =>
                context.dispatch({ type: "ADDED_BY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "addedBy", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.addedBy.component
                state={context.state.addedBy}
                data={context.data.addedBy}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Added By"}
            />
        );
    },
    category: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.category> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.category>) =>
                context.dispatch({ type: "CATEGORY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "category", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.category.component
                state={context.state.category}
                data={context.data.category}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Category"}
            />
        );
    },
    slug: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    tags: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.tags> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.tags>) =>
                context.dispatch({ type: "TAGS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "tags", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.tags.component
                state={context.state.tags}
                data={context.data.tags}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Tags"}
            />
        );
    },
    excerpt: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.excerpt> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.excerpt>) =>
                context.dispatch({ type: "EXCERPT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "excerpt", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.excerpt.component
                state={context.state.excerpt}
                data={context.data.excerpt}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Excerpt"}
            />
        );
    },
    metaTitle: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.metaTitle> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.metaTitle>) =>
                context.dispatch({ type: "META_TITLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "metaTitle", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.metaTitle.component
                state={context.state.metaTitle}
                data={context.data.metaTitle}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Meta Title"}
            />
        );
    },
    metaDescription: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.metaDescription
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.metaDescription
                >
            ) => context.dispatch({ type: "META_DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "metaDescription", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.metaDescription.component
                state={context.state.metaDescription}
                data={context.data.metaDescription}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Meta Description"}
            />
        );
    },
    trending: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.trending> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.trending>) =>
                context.dispatch({ type: "TRENDING", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "trending", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.trending.component
                state={context.state.trending}
                data={context.data.trending}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Trending"}
            />
        );
    },
    heroImageBannerUrl: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.heroImageBannerUrl
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.heroImageBannerUrl
                >
            ) => context.dispatch({ type: "HERO_IMAGE_BANNER_URL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "heroImageBannerUrl",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.heroImageBannerUrl.component
                state={context.state.heroImageBannerUrl}
                data={context.data.heroImageBannerUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hero Image Banner Url"}
            />
        );
    },
    heroImageBannerAltText: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.heroImageBannerAltText
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.heroImageBannerAltText
                >
            ) =>
                context.dispatch({
                    type: "HERO_IMAGE_BANNER_ALT_TEXT",
                    action,
                }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "heroImageBannerAltText",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.heroImageBannerAltText.component
                state={context.state.heroImageBannerAltText}
                data={context.data.heroImageBannerAltText}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hero Image Banner Alt Text"}
            />
        );
    },
    alternateSocialMediaImageUrl: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.alternateSocialMediaImageUrl
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.alternateSocialMediaImageUrl
                >
            ) =>
                context.dispatch({
                    type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_URL",
                    action,
                }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "alternateSocialMediaImageUrl",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.alternateSocialMediaImageUrl.component
                state={context.state.alternateSocialMediaImageUrl}
                data={context.data.alternateSocialMediaImageUrl}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alternate Social Media Image Url"}
            />
        );
    },
    alternateSocialMediaImageAltText: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
                >
            ) =>
                context.dispatch({
                    type: "ALTERNATE_SOCIAL_MEDIA_IMAGE_ALT_TEXT",
                    action,
                }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "alternateSocialMediaImageAltText",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.alternateSocialMediaImageAltText.component
                state={context.state.alternateSocialMediaImageAltText}
                data={context.data.alternateSocialMediaImageAltText}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alternate Social Media Image Alt Text"}
            />
        );
    },
    importance: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.importance> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.importance>) =>
                context.dispatch({ type: "IMPORTANCE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "importance", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.importance.component
                state={context.state.importance}
                data={context.data.importance}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Importance"}
            />
        );
    },
    disciplines: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.disciplines> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.disciplines>) =>
                context.dispatch({ type: "DISCIPLINES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "disciplines", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.disciplines.component
                state={context.state.disciplines}
                data={context.data.disciplines}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Disciplines"}
            />
        );
    },
    status: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.status> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.status>) =>
                context.dispatch({ type: "STATUS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "status", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.status.component
                state={context.state.status}
                data={context.data.status}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Status"}
            />
        );
    },
    kind: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.kind> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.kind>) =>
                context.dispatch({ type: "KIND", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "kind", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.kind.component
                state={context.state.kind}
                data={context.data.kind}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Kind"}
            />
        );
    },
    priority: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.priority> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.priority>) =>
                context.dispatch({ type: "PRIORITY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "priority", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.priority.component
                state={context.state.priority}
                data={context.data.priority}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Priority"}
            />
        );
    },
    edDegreeLevel: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.edDegreeLevel> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiArticleWidgetFields.edDegreeLevel>
            ) => context.dispatch({ type: "ED_DEGREE_LEVEL", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "edDegreeLevel", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.edDegreeLevel.component
                state={context.state.edDegreeLevel}
                data={context.data.edDegreeLevel}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ed Degree Level"}
            />
        );
    },
    edCategory: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.edCategory> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.edCategory>) =>
                context.dispatch({ type: "ED_CATEGORY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "edCategory", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.edCategory.component
                state={context.state.edCategory}
                data={context.data.edCategory}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ed Category"}
            />
        );
    },
    edSubcategory: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.edSubcategory> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiArticleWidgetFields.edSubcategory>
            ) => context.dispatch({ type: "ED_SUBCATEGORY", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "edSubcategory", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.edSubcategory.component
                state={context.state.edSubcategory}
                data={context.data.edSubcategory}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ed Subcategory"}
            />
        );
    },
    edHeader: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.edHeader> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.edHeader>) =>
                context.dispatch({ type: "ED_HEADER", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "edHeader", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.edHeader.component
                state={context.state.edHeader}
                data={context.data.edHeader}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ed Header"}
            />
        );
    },
    edCta: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.edCta> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.edCta>) =>
                context.dispatch({ type: "ED_CTA", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "edCta", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.edCta.component
                state={context.state.edCta}
                data={context.data.edCta}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ed Cta"}
            />
        );
    },
    authors: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.authors> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.authors>) =>
                context.dispatch({ type: "AUTHORS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "authors", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.authors.component
                state={context.state.authors}
                data={context.data.authors}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Authors"}
            />
        );
    },
    reviewers: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.reviewers> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.reviewers>) =>
                context.dispatch({ type: "REVIEWERS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "reviewers", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.reviewers.component
                state={context.state.reviewers}
                data={context.data.reviewers}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Reviewers"}
            />
        );
    },
    hideAdWidget: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.hideAdWidget> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.hideAdWidget>) =>
                context.dispatch({ type: "HIDE_AD_WIDGET", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "hideAdWidget", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.hideAdWidget.component
                state={context.state.hideAdWidget}
                data={context.data.hideAdWidget}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hide ad Widget"}
            />
        );
    },
    hideSubscribePopup: function (
        props: WidgetExtraProps<
            typeof AiArticleWidgetFields.hideSubscribePopup
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiArticleWidgetFields.hideSubscribePopup
                >
            ) => context.dispatch({ type: "HIDE_SUBSCRIBE_POPUP", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "hideSubscribePopup",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.hideSubscribePopup.component
                state={context.state.hideSubscribePopup}
                data={context.data.hideSubscribePopup}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hide Subscribe Popup"}
            />
        );
    },
    hideExcerpt: function (
        props: WidgetExtraProps<typeof AiArticleWidgetFields.hideExcerpt> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiArticleWidgetReactContext
        ) as AiArticleWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiArticleWidgetFields.hideExcerpt>) =>
                context.dispatch({ type: "HIDE_EXCERPT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "hideExcerpt", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiArticleWidgetFields.hideExcerpt.component
                state={context.state.hideExcerpt}
                data={context.data.hideExcerpt}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Hide Excerpt"}
            />
        );
    },
};
export const AiArticleWidget: RecordWidget<
    AiArticleWidgetState,
    AiArticleWidgetData,
    AiArticleWidgetContext,
    AiArticleWidgetAction,
    AiArticleWidgetExtraProps
> = {
    reactContext: AiArticleWidgetReactContext,
    fieldWidgets: AiArticleWidgetWidgets,
    dataMeta: AI_ARTICLE_META,
    initialize(
        data: AiArticleWidgetData,
        context: AiArticleWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiArticleWidgetState, AiArticleWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerTitle = AiArticleWidgetFields.title.initialize(
            data.title,
            subcontext,
            subparameters.title
        );
        const innerShortTitle = AiArticleWidgetFields.shortTitle.initialize(
            data.shortTitle,
            subcontext,
            subparameters.shortTitle
        );
        const innerFocusKeywords =
            AiArticleWidgetFields.focusKeywords.initialize(
                data.focusKeywords,
                subcontext,
                subparameters.focusKeywords
            );
        const innerContent = AiArticleWidgetFields.content.initialize(
            data.content,
            subcontext,
            subparameters.content
        );
        const innerModifiedDateTime =
            AiArticleWidgetFields.modifiedDateTime.initialize(
                data.modifiedDateTime,
                subcontext,
                subparameters.modifiedDateTime
            );
        const innerModifiedDate = AiArticleWidgetFields.modifiedDate.initialize(
            data.modifiedDate,
            subcontext,
            subparameters.modifiedDate
        );
        const innerPublishedDate =
            AiArticleWidgetFields.publishedDate.initialize(
                data.publishedDate,
                subcontext,
                subparameters.publishedDate
            );
        const innerDisplayDates = AiArticleWidgetFields.displayDates.initialize(
            data.displayDates,
            subcontext,
            subparameters.displayDates
        );
        const innerAddedBy = AiArticleWidgetFields.addedBy.initialize(
            data.addedBy,
            subcontext,
            subparameters.addedBy
        );
        const innerCategory = AiArticleWidgetFields.category.initialize(
            data.category,
            subcontext,
            subparameters.category
        );
        const innerSlug = AiArticleWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerTags = AiArticleWidgetFields.tags.initialize(
            data.tags,
            subcontext,
            subparameters.tags
        );
        const innerExcerpt = AiArticleWidgetFields.excerpt.initialize(
            data.excerpt,
            subcontext,
            subparameters.excerpt
        );
        const innerMetaTitle = AiArticleWidgetFields.metaTitle.initialize(
            data.metaTitle,
            subcontext,
            subparameters.metaTitle
        );
        const innerMetaDescription =
            AiArticleWidgetFields.metaDescription.initialize(
                data.metaDescription,
                subcontext,
                subparameters.metaDescription
            );
        const innerTrending = AiArticleWidgetFields.trending.initialize(
            data.trending,
            subcontext,
            subparameters.trending
        );
        const innerHeroImageBannerUrl =
            AiArticleWidgetFields.heroImageBannerUrl.initialize(
                data.heroImageBannerUrl,
                subcontext,
                subparameters.heroImageBannerUrl
            );
        const innerHeroImageBannerAltText =
            AiArticleWidgetFields.heroImageBannerAltText.initialize(
                data.heroImageBannerAltText,
                subcontext,
                subparameters.heroImageBannerAltText
            );
        const innerAlternateSocialMediaImageUrl =
            AiArticleWidgetFields.alternateSocialMediaImageUrl.initialize(
                data.alternateSocialMediaImageUrl,
                subcontext,
                subparameters.alternateSocialMediaImageUrl
            );
        const innerAlternateSocialMediaImageAltText =
            AiArticleWidgetFields.alternateSocialMediaImageAltText.initialize(
                data.alternateSocialMediaImageAltText,
                subcontext,
                subparameters.alternateSocialMediaImageAltText
            );
        const innerImportance = AiArticleWidgetFields.importance.initialize(
            data.importance,
            subcontext,
            subparameters.importance
        );
        const innerDisciplines = AiArticleWidgetFields.disciplines.initialize(
            data.disciplines,
            subcontext,
            subparameters.disciplines
        );
        const innerStatus = AiArticleWidgetFields.status.initialize(
            data.status,
            subcontext,
            subparameters.status
        );
        const innerKind = AiArticleWidgetFields.kind.initialize(
            data.kind,
            subcontext,
            subparameters.kind
        );
        const innerPriority = AiArticleWidgetFields.priority.initialize(
            data.priority,
            subcontext,
            subparameters.priority
        );
        const innerEdDegreeLevel =
            AiArticleWidgetFields.edDegreeLevel.initialize(
                data.edDegreeLevel,
                subcontext,
                subparameters.edDegreeLevel
            );
        const innerEdCategory = AiArticleWidgetFields.edCategory.initialize(
            data.edCategory,
            subcontext,
            subparameters.edCategory
        );
        const innerEdSubcategory =
            AiArticleWidgetFields.edSubcategory.initialize(
                data.edSubcategory,
                subcontext,
                subparameters.edSubcategory
            );
        const innerEdHeader = AiArticleWidgetFields.edHeader.initialize(
            data.edHeader,
            subcontext,
            subparameters.edHeader
        );
        const innerEdCta = AiArticleWidgetFields.edCta.initialize(
            data.edCta,
            subcontext,
            subparameters.edCta
        );
        const innerAuthors = AiArticleWidgetFields.authors.initialize(
            data.authors,
            subcontext,
            subparameters.authors
        );
        const innerReviewers = AiArticleWidgetFields.reviewers.initialize(
            data.reviewers,
            subcontext,
            subparameters.reviewers
        );
        const innerHideAdWidget = AiArticleWidgetFields.hideAdWidget.initialize(
            data.hideAdWidget,
            subcontext,
            subparameters.hideAdWidget
        );
        const innerHideSubscribePopup =
            AiArticleWidgetFields.hideSubscribePopup.initialize(
                data.hideSubscribePopup,
                subcontext,
                subparameters.hideSubscribePopup
            );
        const innerHideExcerpt = AiArticleWidgetFields.hideExcerpt.initialize(
            data.hideExcerpt,
            subcontext,
            subparameters.hideExcerpt
        );
        let state = {
            title: innerTitle.state,
            shortTitle: innerShortTitle.state,
            focusKeywords: innerFocusKeywords.state,
            content: innerContent.state,
            modifiedDateTime: innerModifiedDateTime.state,
            modifiedDate: innerModifiedDate.state,
            publishedDate: innerPublishedDate.state,
            displayDates: innerDisplayDates.state,
            addedBy: innerAddedBy.state,
            category: innerCategory.state,
            slug: innerSlug.state,
            tags: innerTags.state,
            excerpt: innerExcerpt.state,
            metaTitle: innerMetaTitle.state,
            metaDescription: innerMetaDescription.state,
            trending: innerTrending.state,
            heroImageBannerUrl: innerHeroImageBannerUrl.state,
            heroImageBannerAltText: innerHeroImageBannerAltText.state,
            alternateSocialMediaImageUrl:
                innerAlternateSocialMediaImageUrl.state,
            alternateSocialMediaImageAltText:
                innerAlternateSocialMediaImageAltText.state,
            importance: innerImportance.state,
            disciplines: innerDisciplines.state,
            status: innerStatus.state,
            kind: innerKind.state,
            priority: innerPriority.state,
            edDegreeLevel: innerEdDegreeLevel.state,
            edCategory: innerEdCategory.state,
            edSubcategory: innerEdSubcategory.state,
            edHeader: innerEdHeader.state,
            edCta: innerEdCta.state,
            authors: innerAuthors.state,
            reviewers: innerReviewers.state,
            hideAdWidget: innerHideAdWidget.state,
            hideSubscribePopup: innerHideSubscribePopup.state,
            hideExcerpt: innerHideExcerpt.state,
        };
        return {
            state,
            data: {
                ...data,
                title: innerTitle.data,
                shortTitle: innerShortTitle.data,
                focusKeywords: innerFocusKeywords.data,
                content: innerContent.data,
                modifiedDateTime: innerModifiedDateTime.data,
                modifiedDate: innerModifiedDate.data,
                publishedDate: innerPublishedDate.data,
                displayDates: innerDisplayDates.data,
                addedBy: innerAddedBy.data,
                category: innerCategory.data,
                slug: innerSlug.data,
                tags: innerTags.data,
                excerpt: innerExcerpt.data,
                metaTitle: innerMetaTitle.data,
                metaDescription: innerMetaDescription.data,
                trending: innerTrending.data,
                heroImageBannerUrl: innerHeroImageBannerUrl.data,
                heroImageBannerAltText: innerHeroImageBannerAltText.data,
                alternateSocialMediaImageUrl:
                    innerAlternateSocialMediaImageUrl.data,
                alternateSocialMediaImageAltText:
                    innerAlternateSocialMediaImageAltText.data,
                importance: innerImportance.data,
                disciplines: innerDisciplines.data,
                status: innerStatus.data,
                kind: innerKind.data,
                priority: innerPriority.data,
                edDegreeLevel: innerEdDegreeLevel.data,
                edCategory: innerEdCategory.data,
                edSubcategory: innerEdSubcategory.data,
                edHeader: innerEdHeader.data,
                edCta: innerEdCta.data,
                authors: innerAuthors.data,
                reviewers: innerReviewers.data,
                hideAdWidget: innerHideAdWidget.data,
                hideSubscribePopup: innerHideSubscribePopup.data,
                hideExcerpt: innerHideExcerpt.data,
            },
        };
    },
    validate: validateAiArticleWidget,
    component: React.memo((props: AiArticleWidgetProps) => {
        return (
            <AiArticleWidgetReactContext.Provider value={props}>
                {AiArticleWidgetComponent(AiArticleWidgetWidgets, props)}
            </AiArticleWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: aiArticleWidgetReduce,
};

type AiArticleWidgetWidgets = {
    title: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.title
        >
    >;
    shortTitle: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.shortTitle
        >
    >;
    focusKeywords: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.focusKeywords
        >
    >;
    content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.content
        >
    >;
    modifiedDateTime: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.modifiedDateTime
        >
    >;
    modifiedDate: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.modifiedDate
        >
    >;
    publishedDate: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.publishedDate
        >
    >;
    displayDates: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.displayDates
        >
    >;
    addedBy: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.addedBy
        >
    >;
    category: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.category
        >
    >;
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.slug
        >
    >;
    tags: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.tags
        >
    >;
    excerpt: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.excerpt
        >
    >;
    metaTitle: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.metaTitle
        >
    >;
    metaDescription: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.metaDescription
        >
    >;
    trending: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.trending
        >
    >;
    heroImageBannerUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.heroImageBannerUrl
        >
    >;
    heroImageBannerAltText: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.heroImageBannerAltText
        >
    >;
    alternateSocialMediaImageUrl: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.alternateSocialMediaImageUrl
        >
    >;
    alternateSocialMediaImageAltText: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.alternateSocialMediaImageAltText
        >
    >;
    importance: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.importance
        >
    >;
    disciplines: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.disciplines
        >
    >;
    status: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.status
        >
    >;
    kind: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.kind
        >
    >;
    priority: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.priority
        >
    >;
    edDegreeLevel: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.edDegreeLevel
        >
    >;
    edCategory: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.edCategory
        >
    >;
    edSubcategory: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.edSubcategory
        >
    >;
    edHeader: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.edHeader
        >
    >;
    edCta: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.edCta
        >
    >;
    authors: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.authors
        >
    >;
    reviewers: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.reviewers
        >
    >;
    hideAdWidget: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.hideAdWidget
        >
    >;
    hideSubscribePopup: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.hideSubscribePopup
        >
    >;
    hideExcerpt: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiArticleWidgetFields.hideExcerpt
        >
    >;
};
// END MAGIC -- DO NOT EDIT
