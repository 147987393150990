import { Decimal } from "decimal.js";
import { uniq } from "lodash-es";

export function sumMap<T>(items: T[], f: (t: T) => Decimal) {
    return items.map(f).reduce((x, y) => x.add(y), new Decimal(0));
}

export function joinMap<T>(items: T[], join: string, f: (t: T) => string) {
    return items.map(f).join(join);
}

export function filterMap<T, S>(
    items: T[],
    g: (t: T) => boolean,
    f: (t: T) => S
) {
    return items.filter(g).map(f);
}

export function firstMatch<T, S>(
    items: T[],
    match: (t: T) => boolean,
    f: (t: T) => S
) {
    for (const item of items) {
        if (match(item)) {
            return f(item);
        }
    }
    return null;
}

export function lastItem<T, S>(items: T[], f: (t: T) => S) {
    if (items.length == 0) {
        return null;
    } else {
        return f(items[items.length - 1]);
    }
}

export function isNotNull<T>(T: T | null) {
    return T !== null;
}

export function isEmpty<T>(items: T[]) {
    return items.length == 0;
}

export function uniqueMap<T, S>(items: T[], f: (s: T) => S) {
    return uniq(items.map(f));
}

export function resolve(column: string): any {
    throw new Error("Not Supported");
}
