import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import {
    FormField,
    Optional,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TabsWidget } from "../../../clay/widgets/TabsWidget";
import { TextArrayWidget } from "../../../clay/widgets/TextArrayWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { AiAdWidget } from "./ad-widget";
import { AiTimeDescriptionWidget } from "./description-widget";
import { AiDiscipline, AI_DISCIPLINE_META } from "./table";

//!RecordWidget
export type AiDisciplineWidgetData = AiDiscipline;

export const AiDisciplineWidgetFields = {
    name: FormField(TextWidget),
    active: FormField(SwitchWidget),
    occupation_labels: OptionalFormField(TextArrayWidget),
    wikidata_ids: OptionalFormField(TextArrayWidget),
    cipcodes: OptionalFormField(TextArrayWidget),
    black_hole: FormField(SwitchWidget),
    superdiscipline: FormField(
        SelectWidget(
            [
                "Humanities",
                "Social Sciences",
                "Natural Sciences",
                "Formal Sciences",
                "Applied Sciences",
            ].map((name) => ({
                value: name as any,
                label: name,
            }))
        )
    ),
    description: Optional(HtmlEditorWidget),
    factor: OptionalFormField(QuantityWidget),
    descriptions: Optional(
        TabsWidget(AiTimeDescriptionWidget, {
            adaptNewItem(item) {
                return item;
            },
        })
    ),
    ads: Optional(
        TabsWidget(AiAdWidget, {
            adaptNewItem(item) {
                return item;
            },
        })
    ),
};

function AiDisciplineWidgetComponent(
    widgets: AiDisciplineWidgetWidgets,
    props: AiDisciplineWidgetProps
) {
    return (
        <>
            <Tabs defaultActiveKey="first" id="discipline-tabs">
                <Tab eventKey="first" title="Main">
                    <widgets.name />
                    <widgets.active />
                    <widgets.superdiscipline />
                    <widgets.black_hole />
                    <widgets.factor />
                    <widgets.occupation_labels />
                    <widgets.wikidata_ids />
                    <widgets.cipcodes />
                </Tab>
                <Tab eventKey="second" title="Description">
                    <widgets.description />
                </Tab>
                <Tab eventKey="descriptions" title="Descriptions">
                    <widgets.descriptions
                        actions={(_data, index) => [
                            {
                                label: "Remove",
                                action: () => {
                                    props.dispatch({
                                        type: "DESCRIPTIONS",
                                        action: {
                                            type: "REMOVE",
                                            index,
                                        },
                                    });
                                },
                            },
                        ]}
                    />
                </Tab>
                <Tab eventKey="ads" title="Ads">
                    <widgets.ads
                        labelForItem={(_item, index) => `${index + 1}`}
                        actions={(_data, index) => [
                            {
                                label: "Remove",
                                action: () => {
                                    props.dispatch({
                                        type: "ADS",
                                        action: {
                                            type: "REMOVE",
                                            index,
                                        },
                                    });
                                },
                            },
                        ]}
                    />
                </Tab>
            </Tabs>

            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiDisciplineWidgetContext = WidgetContext<
    typeof AiDisciplineWidgetFields.name
> &
    WidgetContext<typeof AiDisciplineWidgetFields.active> &
    WidgetContext<typeof AiDisciplineWidgetFields.occupation_labels> &
    WidgetContext<typeof AiDisciplineWidgetFields.wikidata_ids> &
    WidgetContext<typeof AiDisciplineWidgetFields.cipcodes> &
    WidgetContext<typeof AiDisciplineWidgetFields.black_hole> &
    WidgetContext<typeof AiDisciplineWidgetFields.superdiscipline> &
    WidgetContext<typeof AiDisciplineWidgetFields.description> &
    WidgetContext<typeof AiDisciplineWidgetFields.factor> &
    WidgetContext<typeof AiDisciplineWidgetFields.descriptions> &
    WidgetContext<typeof AiDisciplineWidgetFields.ads>;
type AiDisciplineWidgetExtraProps = {};
type AiDisciplineWidgetBaseState = {
    name: WidgetState<typeof AiDisciplineWidgetFields.name>;
    active: WidgetState<typeof AiDisciplineWidgetFields.active>;
    occupation_labels: WidgetState<
        typeof AiDisciplineWidgetFields.occupation_labels
    >;
    wikidata_ids: WidgetState<typeof AiDisciplineWidgetFields.wikidata_ids>;
    cipcodes: WidgetState<typeof AiDisciplineWidgetFields.cipcodes>;
    black_hole: WidgetState<typeof AiDisciplineWidgetFields.black_hole>;
    superdiscipline: WidgetState<
        typeof AiDisciplineWidgetFields.superdiscipline
    >;
    description: WidgetState<typeof AiDisciplineWidgetFields.description>;
    factor: WidgetState<typeof AiDisciplineWidgetFields.factor>;
    descriptions: WidgetState<typeof AiDisciplineWidgetFields.descriptions>;
    ads: WidgetState<typeof AiDisciplineWidgetFields.ads>;
};
export type AiDisciplineWidgetState = AiDisciplineWidgetBaseState;

type BaseAiDisciplineWidgetAction =
    | {
          type: "NAME";
          action: WidgetAction<typeof AiDisciplineWidgetFields.name>;
      }
    | {
          type: "ACTIVE";
          action: WidgetAction<typeof AiDisciplineWidgetFields.active>;
      }
    | {
          type: "OCCUPATION_LABELS";
          action: WidgetAction<
              typeof AiDisciplineWidgetFields.occupation_labels
          >;
      }
    | {
          type: "WIKIDATA_IDS";
          action: WidgetAction<typeof AiDisciplineWidgetFields.wikidata_ids>;
      }
    | {
          type: "CIPCODES";
          action: WidgetAction<typeof AiDisciplineWidgetFields.cipcodes>;
      }
    | {
          type: "BLACK_HOLE";
          action: WidgetAction<typeof AiDisciplineWidgetFields.black_hole>;
      }
    | {
          type: "SUPERDISCIPLINE";
          action: WidgetAction<typeof AiDisciplineWidgetFields.superdiscipline>;
      }
    | {
          type: "DESCRIPTION";
          action: WidgetAction<typeof AiDisciplineWidgetFields.description>;
      }
    | {
          type: "FACTOR";
          action: WidgetAction<typeof AiDisciplineWidgetFields.factor>;
      }
    | {
          type: "DESCRIPTIONS";
          action: WidgetAction<typeof AiDisciplineWidgetFields.descriptions>;
      }
    | {
          type: "ADS";
          action: WidgetAction<typeof AiDisciplineWidgetFields.ads>;
      };

export type AiDisciplineWidgetAction = BaseAiDisciplineWidgetAction;

export type AiDisciplineWidgetProps = WidgetProps<
    AiDisciplineWidgetState,
    AiDisciplineWidgetData,
    AiDisciplineWidgetAction,
    AiDisciplineWidgetExtraProps
>;

function baseValidateAiDisciplineWidget(
    data: AiDisciplineWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiDisciplineWidgetFields.name,
        data.name,
        cache,
        "name",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.active,
        data.active,
        cache,
        "active",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.occupation_labels,
        data.occupation_labels,
        cache,
        "occupation_labels",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.wikidata_ids,
        data.wikidata_ids,
        cache,
        "wikidata_ids",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.cipcodes,
        data.cipcodes,
        cache,
        "cipcodes",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.black_hole,
        data.black_hole,
        cache,
        "black_hole",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.superdiscipline,
        data.superdiscipline,
        cache,
        "superdiscipline",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.factor,
        data.factor,
        cache,
        "factor",
        errors
    );
    subvalidate(
        AiDisciplineWidgetFields.descriptions,
        data.descriptions,
        cache,
        "descriptions",
        errors
    );
    subvalidate(AiDisciplineWidgetFields.ads, data.ads, cache, "ads", errors);
    return errors;
}
function baseAiDisciplineWidgetReduce(
    state: AiDisciplineWidgetState,
    data: AiDisciplineWidgetData,
    action: BaseAiDisciplineWidgetAction,
    context: AiDisciplineWidgetContext
): WidgetResult<AiDisciplineWidgetState, AiDisciplineWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AiDisciplineWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "ACTIVE": {
            const inner = AiDisciplineWidgetFields.active.reduce(
                state.active,
                data.active,
                action.action,
                subcontext
            );
            return {
                state: { ...state, active: inner.state },
                data: { ...data, active: inner.data },
            };
        }
        case "OCCUPATION_LABELS": {
            const inner = AiDisciplineWidgetFields.occupation_labels.reduce(
                state.occupation_labels,
                data.occupation_labels,
                action.action,
                subcontext
            );
            return {
                state: { ...state, occupation_labels: inner.state },
                data: { ...data, occupation_labels: inner.data },
            };
        }
        case "WIKIDATA_IDS": {
            const inner = AiDisciplineWidgetFields.wikidata_ids.reduce(
                state.wikidata_ids,
                data.wikidata_ids,
                action.action,
                subcontext
            );
            return {
                state: { ...state, wikidata_ids: inner.state },
                data: { ...data, wikidata_ids: inner.data },
            };
        }
        case "CIPCODES": {
            const inner = AiDisciplineWidgetFields.cipcodes.reduce(
                state.cipcodes,
                data.cipcodes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, cipcodes: inner.state },
                data: { ...data, cipcodes: inner.data },
            };
        }
        case "BLACK_HOLE": {
            const inner = AiDisciplineWidgetFields.black_hole.reduce(
                state.black_hole,
                data.black_hole,
                action.action,
                subcontext
            );
            return {
                state: { ...state, black_hole: inner.state },
                data: { ...data, black_hole: inner.data },
            };
        }
        case "SUPERDISCIPLINE": {
            const inner = AiDisciplineWidgetFields.superdiscipline.reduce(
                state.superdiscipline,
                data.superdiscipline,
                action.action,
                subcontext
            );
            return {
                state: { ...state, superdiscipline: inner.state },
                data: { ...data, superdiscipline: inner.data },
            };
        }
        case "DESCRIPTION": {
            const inner = AiDisciplineWidgetFields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "FACTOR": {
            const inner = AiDisciplineWidgetFields.factor.reduce(
                state.factor,
                data.factor,
                action.action,
                subcontext
            );
            return {
                state: { ...state, factor: inner.state },
                data: { ...data, factor: inner.data },
            };
        }
        case "DESCRIPTIONS": {
            const inner = AiDisciplineWidgetFields.descriptions.reduce(
                state.descriptions,
                data.descriptions,
                action.action,
                subcontext
            );
            return {
                state: { ...state, descriptions: inner.state },
                data: { ...data, descriptions: inner.data },
            };
        }
        case "ADS": {
            const inner = AiDisciplineWidgetFields.ads.reduce(
                state.ads,
                data.ads,
                action.action,
                subcontext
            );
            return {
                state: { ...state, ads: inner.state },
                data: { ...data, ads: inner.data },
            };
        }
    }
}
export type AiDisciplineWidgetReactContextType = {
    state: AiDisciplineWidgetState;
    data: AiDisciplineWidgetData;
    dispatch: (action: AiDisciplineWidgetAction) => void;
    status: WidgetStatus;
};
export const AiDisciplineWidgetReactContext = React.createContext<
    AiDisciplineWidgetReactContextType | undefined
>(undefined);
export const AiDisciplineWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiDisciplineWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    active: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.active> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiDisciplineWidgetFields.active>) =>
                context.dispatch({ type: "ACTIVE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "active", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.active.component
                state={context.state.active}
                data={context.data.active}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Active"}
            />
        );
    },
    occupation_labels: function (
        props: WidgetExtraProps<
            typeof AiDisciplineWidgetFields.occupation_labels
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiDisciplineWidgetFields.occupation_labels
                >
            ) => context.dispatch({ type: "OCCUPATION_LABELS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "occupation_labels",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.occupation_labels.component
                state={context.state.occupation_labels}
                data={context.data.occupation_labels}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Occupation Labels"}
            />
        );
    },
    wikidata_ids: function (
        props: WidgetExtraProps<
            typeof AiDisciplineWidgetFields.wikidata_ids
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiDisciplineWidgetFields.wikidata_ids
                >
            ) => context.dispatch({ type: "WIKIDATA_IDS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "wikidata_ids", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.wikidata_ids.component
                state={context.state.wikidata_ids}
                data={context.data.wikidata_ids}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Wikidata Ids"}
            />
        );
    },
    cipcodes: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.cipcodes> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiDisciplineWidgetFields.cipcodes>) =>
                context.dispatch({ type: "CIPCODES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "cipcodes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.cipcodes.component
                state={context.state.cipcodes}
                data={context.data.cipcodes}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Cipcodes"}
            />
        );
    },
    black_hole: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.black_hole> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiDisciplineWidgetFields.black_hole>
            ) => context.dispatch({ type: "BLACK_HOLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "black_hole", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.black_hole.component
                state={context.state.black_hole}
                data={context.data.black_hole}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Black Hole"}
            />
        );
    },
    superdiscipline: function (
        props: WidgetExtraProps<
            typeof AiDisciplineWidgetFields.superdiscipline
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiDisciplineWidgetFields.superdiscipline
                >
            ) => context.dispatch({ type: "SUPERDISCIPLINE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "superdiscipline", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.superdiscipline.component
                state={context.state.superdiscipline}
                data={context.data.superdiscipline}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Superdiscipline"}
            />
        );
    },
    description: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.description> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiDisciplineWidgetFields.description
                >
            ) => context.dispatch({ type: "DESCRIPTION", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.description.component
                state={context.state.description}
                data={context.data.description}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Description"}
            />
        );
    },
    factor: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.factor> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiDisciplineWidgetFields.factor>) =>
                context.dispatch({ type: "FACTOR", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "factor", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.factor.component
                state={context.state.factor}
                data={context.data.factor}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Factor"}
            />
        );
    },
    descriptions: function (
        props: WidgetExtraProps<
            typeof AiDisciplineWidgetFields.descriptions
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiDisciplineWidgetFields.descriptions
                >
            ) => context.dispatch({ type: "DESCRIPTIONS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "descriptions", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.descriptions.component
                state={context.state.descriptions}
                data={context.data.descriptions}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Descriptions"}
            />
        );
    },
    ads: function (
        props: WidgetExtraProps<typeof AiDisciplineWidgetFields.ads> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiDisciplineWidgetReactContext
        ) as AiDisciplineWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiDisciplineWidgetFields.ads>) =>
                context.dispatch({ type: "ADS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "ads", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiDisciplineWidgetFields.ads.component
                state={context.state.ads}
                data={context.data.ads}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Ads"}
            />
        );
    },
};
export const AiDisciplineWidget: RecordWidget<
    AiDisciplineWidgetState,
    AiDisciplineWidgetData,
    AiDisciplineWidgetContext,
    AiDisciplineWidgetAction,
    AiDisciplineWidgetExtraProps
> = {
    reactContext: AiDisciplineWidgetReactContext,
    fieldWidgets: AiDisciplineWidgetWidgets,
    dataMeta: AI_DISCIPLINE_META,
    initialize(
        data: AiDisciplineWidgetData,
        context: AiDisciplineWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiDisciplineWidgetState, AiDisciplineWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AiDisciplineWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerActive = AiDisciplineWidgetFields.active.initialize(
            data.active,
            subcontext,
            subparameters.active
        );
        const innerOccupationLabels = AiDisciplineWidgetFields.occupation_labels.initialize(
            data.occupation_labels,
            subcontext,
            subparameters.occupation_labels
        );
        const innerWikidataIds = AiDisciplineWidgetFields.wikidata_ids.initialize(
            data.wikidata_ids,
            subcontext,
            subparameters.wikidata_ids
        );
        const innerCipcodes = AiDisciplineWidgetFields.cipcodes.initialize(
            data.cipcodes,
            subcontext,
            subparameters.cipcodes
        );
        const innerBlackHole = AiDisciplineWidgetFields.black_hole.initialize(
            data.black_hole,
            subcontext,
            subparameters.black_hole
        );
        const innerSuperdiscipline = AiDisciplineWidgetFields.superdiscipline.initialize(
            data.superdiscipline,
            subcontext,
            subparameters.superdiscipline
        );
        const innerDescription = AiDisciplineWidgetFields.description.initialize(
            data.description,
            subcontext,
            subparameters.description
        );
        const innerFactor = AiDisciplineWidgetFields.factor.initialize(
            data.factor,
            subcontext,
            subparameters.factor
        );
        const innerDescriptions = AiDisciplineWidgetFields.descriptions.initialize(
            data.descriptions,
            subcontext,
            subparameters.descriptions
        );
        const innerAds = AiDisciplineWidgetFields.ads.initialize(
            data.ads,
            subcontext,
            subparameters.ads
        );
        let state = {
            name: innerName.state,
            active: innerActive.state,
            occupation_labels: innerOccupationLabels.state,
            wikidata_ids: innerWikidataIds.state,
            cipcodes: innerCipcodes.state,
            black_hole: innerBlackHole.state,
            superdiscipline: innerSuperdiscipline.state,
            description: innerDescription.state,
            factor: innerFactor.state,
            descriptions: innerDescriptions.state,
            ads: innerAds.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                active: innerActive.data,
                occupation_labels: innerOccupationLabels.data,
                wikidata_ids: innerWikidataIds.data,
                cipcodes: innerCipcodes.data,
                black_hole: innerBlackHole.data,
                superdiscipline: innerSuperdiscipline.data,
                description: innerDescription.data,
                factor: innerFactor.data,
                descriptions: innerDescriptions.data,
                ads: innerAds.data,
            },
        };
    },
    validate: baseValidateAiDisciplineWidget,
    component: React.memo((props: AiDisciplineWidgetProps) => {
        return (
            <AiDisciplineWidgetReactContext.Provider value={props}>
                {AiDisciplineWidgetComponent(AiDisciplineWidgetWidgets, props)}
            </AiDisciplineWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiDisciplineWidgetReduce,
};

type AiDisciplineWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.name
        >
    >;
    active: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.active
        >
    >;
    occupation_labels: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.occupation_labels
        >
    >;
    wikidata_ids: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.wikidata_ids
        >
    >;
    cipcodes: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.cipcodes
        >
    >;
    black_hole: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.black_hole
        >
    >;
    superdiscipline: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.superdiscipline
        >
    >;
    description: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.description
        >
    >;
    factor: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.factor
        >
    >;
    descriptions: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.descriptions
        >
    >;
    ads: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiDisciplineWidgetFields.ads
        >
    >;
};
// END MAGIC -- DO NOT EDIT
