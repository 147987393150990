import { TableWidgetPage } from "../../../clay/TableWidgetPage";
import { clearCache, clearUrlCache } from "../cache";
import { AiHomePageWidget } from "./widget";

export const AiHomePagePage = TableWidgetPage({
    meta: AiHomePageWidget,
    makeContext: (context) => context,
    title: () => "AI Home Page",
    postSave: (record) => {
        clearCache("home");
        clearCache("basicContext");
        clearUrlCache("/");
        return [];
    },
});
