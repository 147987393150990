import Random from "random-js";
export type UUID = {
    uuid: string;
};
const random = new Random();

export function newUUID() {
    return {
        uuid: random.uuid4(),
    };
}

export function genUUID() {
    return random.uuid4();
}
