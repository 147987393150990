import { Link } from "../../clay/link";
import { RecordMeta } from "../../clay/meta";
import { genUUID, UUID } from "../../clay/uuid";
import { Role } from "../roles/table";
import { Version } from "../version";

//!Data
export type User = {
    id: UUID;
    name: string;
    recordVersion: Version;
    accountEmail: string;
    active: boolean;
    roles: Link<Role>[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type UserJSON = {
    id: string;
    name: string;
    recordVersion: number | null;
    accountEmail: string;
    active: boolean;
    roles: (string | null)[];
};

export function JSONToUser(json: UserJSON): User {
    return {
        id: { uuid: json.id },
        name: json.name,
        recordVersion: { version: json.recordVersion },
        accountEmail: json.accountEmail,
        active: json.active,
        roles: json.roles.map((inner) => inner),
    };
}
export type UserBrokenJSON = {
    id?: string;
    name?: string;
    recordVersion?: number | null;
    accountEmail?: string;
    active?: boolean;
    roles?: (string | null)[];
};

export function newUser(): User {
    return JSONToUser(repairUserJSON(undefined));
}
export function repairUserJSON(json: UserBrokenJSON | undefined): UserJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            name: json.name || "",
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            accountEmail: json.accountEmail || "",
            active: json.active || false,
            roles: (json.roles || []).map((inner) => inner || null),
        };
    } else {
        return {
            id: undefined || genUUID(),
            name: undefined || "",
            recordVersion: null,
            accountEmail: undefined || "",
            active: undefined || false,
            roles: (undefined || []).map((inner) => inner || null),
        };
    }
}

export function UserToJSON(value: User): UserJSON {
    return {
        id: value.id.uuid,
        name: value.name,
        recordVersion: value.recordVersion.version,
        accountEmail: value.accountEmail,
        active: value.active,
        roles: value.roles.map((inner) => inner),
    };
}

export const USER_META: RecordMeta<User, UserJSON, UserBrokenJSON> = {
    name: "User",
    type: "record",
    repair: repairUserJSON,
    toJSON: UserToJSON,
    fromJSON: JSONToUser,
    fields: {
        id: { type: "uuid" },
        name: { type: "string" },
        recordVersion: { type: "version" },
        accountEmail: { type: "string" },
        active: { type: "boolean" },
        roles: { type: "array", items: { type: "uuid", linkTo: "Role" } },
    },
    userFacingKey: "name",
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
