import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { Ad, AD_META } from "./table";

//!RecordWidget
export type AdWidgetData = Ad;

export const AdWidgetFields = {
    name: TextWidget,
    image: TextWidget,
    link: TextWidget,
    alt: TextWidget,
    width: QuantityWidget,
    height: QuantityWidget,
};

function AdWidgetComponent(widget: AdWidgetWidgets, props: AdWidgetProps) {
    throw new Error("unreachable");
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type AdWidgetContext = WidgetContext<typeof AdWidgetFields.name> &
    WidgetContext<typeof AdWidgetFields.image> &
    WidgetContext<typeof AdWidgetFields.link> &
    WidgetContext<typeof AdWidgetFields.alt> &
    WidgetContext<typeof AdWidgetFields.width> &
    WidgetContext<typeof AdWidgetFields.height>;
type AdWidgetExtraProps = {};
type AdWidgetBaseState = {
    name: WidgetState<typeof AdWidgetFields.name>;
    image: WidgetState<typeof AdWidgetFields.image>;
    link: WidgetState<typeof AdWidgetFields.link>;
    alt: WidgetState<typeof AdWidgetFields.alt>;
    width: WidgetState<typeof AdWidgetFields.width>;
    height: WidgetState<typeof AdWidgetFields.height>;
};
export type AdWidgetState = AdWidgetBaseState;

type BaseAdWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AdWidgetFields.name> }
    | { type: "IMAGE"; action: WidgetAction<typeof AdWidgetFields.image> }
    | { type: "LINK"; action: WidgetAction<typeof AdWidgetFields.link> }
    | { type: "ALT"; action: WidgetAction<typeof AdWidgetFields.alt> }
    | { type: "WIDTH"; action: WidgetAction<typeof AdWidgetFields.width> }
    | { type: "HEIGHT"; action: WidgetAction<typeof AdWidgetFields.height> };

export type AdWidgetAction = BaseAdWidgetAction;

export type AdWidgetProps = WidgetProps<
    AdWidgetState,
    AdWidgetData,
    AdWidgetAction,
    AdWidgetExtraProps
>;

function baseValidateAdWidget(data: AdWidgetData, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(AdWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(AdWidgetFields.image, data.image, cache, "image", errors);
    subvalidate(AdWidgetFields.link, data.link, cache, "link", errors);
    subvalidate(AdWidgetFields.alt, data.alt, cache, "alt", errors);
    subvalidate(AdWidgetFields.width, data.width, cache, "width", errors);
    subvalidate(AdWidgetFields.height, data.height, cache, "height", errors);
    return errors;
}
function baseAdWidgetReduce(
    state: AdWidgetState,
    data: AdWidgetData,
    action: BaseAdWidgetAction,
    context: AdWidgetContext
): WidgetResult<AdWidgetState, AdWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AdWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "IMAGE": {
            const inner = AdWidgetFields.image.reduce(
                state.image,
                data.image,
                action.action,
                subcontext
            );
            return {
                state: { ...state, image: inner.state },
                data: { ...data, image: inner.data },
            };
        }
        case "LINK": {
            const inner = AdWidgetFields.link.reduce(
                state.link,
                data.link,
                action.action,
                subcontext
            );
            return {
                state: { ...state, link: inner.state },
                data: { ...data, link: inner.data },
            };
        }
        case "ALT": {
            const inner = AdWidgetFields.alt.reduce(
                state.alt,
                data.alt,
                action.action,
                subcontext
            );
            return {
                state: { ...state, alt: inner.state },
                data: { ...data, alt: inner.data },
            };
        }
        case "WIDTH": {
            const inner = AdWidgetFields.width.reduce(
                state.width,
                data.width,
                action.action,
                subcontext
            );
            return {
                state: { ...state, width: inner.state },
                data: { ...data, width: inner.data },
            };
        }
        case "HEIGHT": {
            const inner = AdWidgetFields.height.reduce(
                state.height,
                data.height,
                action.action,
                subcontext
            );
            return {
                state: { ...state, height: inner.state },
                data: { ...data, height: inner.data },
            };
        }
    }
}
export type AdWidgetReactContextType = {
    state: AdWidgetState;
    data: AdWidgetData;
    dispatch: (action: AdWidgetAction) => void;
    status: WidgetStatus;
};
export const AdWidgetReactContext = React.createContext<
    AdWidgetReactContextType | undefined
>(undefined);
export const AdWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AdWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    image: function (
        props: WidgetExtraProps<typeof AdWidgetFields.image> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.image>) =>
                context.dispatch({ type: "IMAGE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "image", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.image.component
                state={context.state.image}
                data={context.data.image}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Image"}
            />
        );
    },
    link: function (
        props: WidgetExtraProps<typeof AdWidgetFields.link> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.link>) =>
                context.dispatch({ type: "LINK", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "link", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.link.component
                state={context.state.link}
                data={context.data.link}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Link"}
            />
        );
    },
    alt: function (
        props: WidgetExtraProps<typeof AdWidgetFields.alt> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.alt>) =>
                context.dispatch({ type: "ALT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "alt", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.alt.component
                state={context.state.alt}
                data={context.data.alt}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Alt"}
            />
        );
    },
    width: function (
        props: WidgetExtraProps<typeof AdWidgetFields.width> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.width>) =>
                context.dispatch({ type: "WIDTH", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "width", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.width.component
                state={context.state.width}
                data={context.data.width}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Width"}
            />
        );
    },
    height: function (
        props: WidgetExtraProps<typeof AdWidgetFields.height> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdWidgetReactContext
        ) as AdWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdWidgetFields.height>) =>
                context.dispatch({ type: "HEIGHT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "height", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdWidgetFields.height.component
                state={context.state.height}
                data={context.data.height}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Height"}
            />
        );
    },
};
export const AdWidget: RecordWidget<
    AdWidgetState,
    AdWidgetData,
    AdWidgetContext,
    AdWidgetAction,
    AdWidgetExtraProps
> = {
    reactContext: AdWidgetReactContext,
    fieldWidgets: AdWidgetWidgets,
    dataMeta: AD_META,
    initialize(
        data: AdWidgetData,
        context: AdWidgetContext,
        parameters?: string[]
    ): WidgetResult<AdWidgetState, AdWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AdWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerImage = AdWidgetFields.image.initialize(
            data.image,
            subcontext,
            subparameters.image
        );
        const innerLink = AdWidgetFields.link.initialize(
            data.link,
            subcontext,
            subparameters.link
        );
        const innerAlt = AdWidgetFields.alt.initialize(
            data.alt,
            subcontext,
            subparameters.alt
        );
        const innerWidth = AdWidgetFields.width.initialize(
            data.width,
            subcontext,
            subparameters.width
        );
        const innerHeight = AdWidgetFields.height.initialize(
            data.height,
            subcontext,
            subparameters.height
        );
        let state = {
            name: innerName.state,
            image: innerImage.state,
            link: innerLink.state,
            alt: innerAlt.state,
            width: innerWidth.state,
            height: innerHeight.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                image: innerImage.data,
                link: innerLink.data,
                alt: innerAlt.data,
                width: innerWidth.data,
                height: innerHeight.data,
            },
        };
    },
    validate: baseValidateAdWidget,
    component: React.memo((props: AdWidgetProps) => {
        return (
            <AdWidgetReactContext.Provider value={props}>
                {AdWidgetComponent(AdWidgetWidgets, props)}
            </AdWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAdWidgetReduce,
};

type AdWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.name
        >
    >;
    image: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.image
        >
    >;
    link: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.link
        >
    >;
    alt: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.alt
        >
    >;
    width: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.width
        >
    >;
    height: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdWidgetFields.height
        >
    >;
};
// END MAGIC -- DO NOT EDIT
