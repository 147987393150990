import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { FormField, Optional } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { HtmlEditorWidget } from "../../widgets/html-editor";
import { AiTimeDescription, AI_TIME_DESCRIPTION_META } from "./table";

//!RecordWidget
export type AiTimeDescriptionWidgetData = AiTimeDescription;

export const AiTimeDescriptionWidgetFields = {
    start: FormField(QuantityWidget),
    end: FormField(QuantityWidget),
    content: Optional(HtmlEditorWidget),
};

function AiTimeDescriptionWidgetComponent(
    widgets: AiTimeDescriptionWidgetWidgets,
    props: AiTimeDescriptionWidgetProps
) {
    return (
        <>
            <widgets.start suppressCommas />
            <widgets.end suppressCommas />
            <widgets.content />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiTimeDescriptionWidgetContext = WidgetContext<
    typeof AiTimeDescriptionWidgetFields.start
> &
    WidgetContext<typeof AiTimeDescriptionWidgetFields.end> &
    WidgetContext<typeof AiTimeDescriptionWidgetFields.content>;
type AiTimeDescriptionWidgetExtraProps = {};
type AiTimeDescriptionWidgetBaseState = {
    start: WidgetState<typeof AiTimeDescriptionWidgetFields.start>;
    end: WidgetState<typeof AiTimeDescriptionWidgetFields.end>;
    content: WidgetState<typeof AiTimeDescriptionWidgetFields.content>;
};
export type AiTimeDescriptionWidgetState = AiTimeDescriptionWidgetBaseState;

type BaseAiTimeDescriptionWidgetAction =
    | {
          type: "START";
          action: WidgetAction<typeof AiTimeDescriptionWidgetFields.start>;
      }
    | {
          type: "END";
          action: WidgetAction<typeof AiTimeDescriptionWidgetFields.end>;
      }
    | {
          type: "CONTENT";
          action: WidgetAction<typeof AiTimeDescriptionWidgetFields.content>;
      };

export type AiTimeDescriptionWidgetAction = BaseAiTimeDescriptionWidgetAction;

export type AiTimeDescriptionWidgetProps = WidgetProps<
    AiTimeDescriptionWidgetState,
    AiTimeDescriptionWidgetData,
    AiTimeDescriptionWidgetAction,
    AiTimeDescriptionWidgetExtraProps
>;

function baseValidateAiTimeDescriptionWidget(
    data: AiTimeDescriptionWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiTimeDescriptionWidgetFields.start,
        data.start,
        cache,
        "start",
        errors
    );
    subvalidate(
        AiTimeDescriptionWidgetFields.end,
        data.end,
        cache,
        "end",
        errors
    );
    subvalidate(
        AiTimeDescriptionWidgetFields.content,
        data.content,
        cache,
        "content",
        errors
    );
    return errors;
}
function baseAiTimeDescriptionWidgetReduce(
    state: AiTimeDescriptionWidgetState,
    data: AiTimeDescriptionWidgetData,
    action: BaseAiTimeDescriptionWidgetAction,
    context: AiTimeDescriptionWidgetContext
): WidgetResult<AiTimeDescriptionWidgetState, AiTimeDescriptionWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "START": {
            const inner = AiTimeDescriptionWidgetFields.start.reduce(
                state.start,
                data.start,
                action.action,
                subcontext
            );
            return {
                state: { ...state, start: inner.state },
                data: { ...data, start: inner.data },
            };
        }
        case "END": {
            const inner = AiTimeDescriptionWidgetFields.end.reduce(
                state.end,
                data.end,
                action.action,
                subcontext
            );
            return {
                state: { ...state, end: inner.state },
                data: { ...data, end: inner.data },
            };
        }
        case "CONTENT": {
            const inner = AiTimeDescriptionWidgetFields.content.reduce(
                state.content,
                data.content,
                action.action,
                subcontext
            );
            return {
                state: { ...state, content: inner.state },
                data: { ...data, content: inner.data },
            };
        }
    }
}
export type AiTimeDescriptionWidgetReactContextType = {
    state: AiTimeDescriptionWidgetState;
    data: AiTimeDescriptionWidgetData;
    dispatch: (action: AiTimeDescriptionWidgetAction) => void;
    status: WidgetStatus;
};
export const AiTimeDescriptionWidgetReactContext = React.createContext<
    AiTimeDescriptionWidgetReactContextType | undefined
>(undefined);
export const AiTimeDescriptionWidgetWidgets = {
    start: function (
        props: WidgetExtraProps<typeof AiTimeDescriptionWidgetFields.start> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiTimeDescriptionWidgetReactContext
        ) as AiTimeDescriptionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<typeof AiTimeDescriptionWidgetFields.start>
            ) => context.dispatch({ type: "START", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "start", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiTimeDescriptionWidgetFields.start.component
                state={context.state.start}
                data={context.data.start}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Start"}
            />
        );
    },
    end: function (
        props: WidgetExtraProps<typeof AiTimeDescriptionWidgetFields.end> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AiTimeDescriptionWidgetReactContext
        ) as AiTimeDescriptionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AiTimeDescriptionWidgetFields.end>) =>
                context.dispatch({ type: "END", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "end", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiTimeDescriptionWidgetFields.end.component
                state={context.state.end}
                data={context.data.end}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "End"}
            />
        );
    },
    content: function (
        props: WidgetExtraProps<
            typeof AiTimeDescriptionWidgetFields.content
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiTimeDescriptionWidgetReactContext
        ) as AiTimeDescriptionWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiTimeDescriptionWidgetFields.content
                >
            ) => context.dispatch({ type: "CONTENT", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "content", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiTimeDescriptionWidgetFields.content.component
                state={context.state.content}
                data={context.data.content}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Content"}
            />
        );
    },
};
export const AiTimeDescriptionWidget: RecordWidget<
    AiTimeDescriptionWidgetState,
    AiTimeDescriptionWidgetData,
    AiTimeDescriptionWidgetContext,
    AiTimeDescriptionWidgetAction,
    AiTimeDescriptionWidgetExtraProps
> = {
    reactContext: AiTimeDescriptionWidgetReactContext,
    fieldWidgets: AiTimeDescriptionWidgetWidgets,
    dataMeta: AI_TIME_DESCRIPTION_META,
    initialize(
        data: AiTimeDescriptionWidgetData,
        context: AiTimeDescriptionWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiTimeDescriptionWidgetState, AiTimeDescriptionWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerStart = AiTimeDescriptionWidgetFields.start.initialize(
            data.start,
            subcontext,
            subparameters.start
        );
        const innerEnd = AiTimeDescriptionWidgetFields.end.initialize(
            data.end,
            subcontext,
            subparameters.end
        );
        const innerContent = AiTimeDescriptionWidgetFields.content.initialize(
            data.content,
            subcontext,
            subparameters.content
        );
        let state = {
            start: innerStart.state,
            end: innerEnd.state,
            content: innerContent.state,
        };
        return {
            state,
            data: {
                ...data,
                start: innerStart.data,
                end: innerEnd.data,
                content: innerContent.data,
            },
        };
    },
    validate: baseValidateAiTimeDescriptionWidget,
    component: React.memo((props: AiTimeDescriptionWidgetProps) => {
        return (
            <AiTimeDescriptionWidgetReactContext.Provider value={props}>
                {AiTimeDescriptionWidgetComponent(
                    AiTimeDescriptionWidgetWidgets,
                    props
                )}
            </AiTimeDescriptionWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiTimeDescriptionWidgetReduce,
};

type AiTimeDescriptionWidgetWidgets = {
    start: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiTimeDescriptionWidgetFields.start
        >
    >;
    end: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiTimeDescriptionWidgetFields.end
        >
    >;
    content: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiTimeDescriptionWidgetFields.content
        >
    >;
};
// END MAGIC -- DO NOT EDIT
