export type ED_DATA = [number, string][];

export const ED_CATEGORIES: string[] = [
    "art-and-design",
    "business-and-management",
    "computers-and-technology",
    "criminal-justice-and-legal",
    "education-and-teaching",
    "general",
    "liberal-arts-and-humanities",
    "nursing-and-healthcare",
    "psychology-and-counseling",
    "science-and-engineering",
    "trades-and-careers",
];

export const ED_SUBCATEGORIES: string[] = [
    "accounting",
    "addictions-recovery",
    "administrative-assistant",
    "adult-education-learning",
    "animation",
    "anthropology",
    "art-art-history",
    "automotive",
    "behavioral-psychology",
    "biology",
    "biotechnology",
    "business-administration",
    "business-intelligence",
    "business-law",
    "carpentry",
    "child-and-adolescent-psychology",
    "child-development",
    "clinical-research",
    "communications",
    "computer-engineering",
    "computer-forensics",
    "computer-programming",
    "computer-science",
    "construction-management",
    "contract-management",
    "corrections",
    "counseling",
    "creative-design",
    "crime-scene-investigation",
    "criminal-justice",
    "criminology",
    "culinary",
    "curriculum-instruction",
    "cyber-crime",
    "data-science",
    "database-management",
    "dental-assistance",
    "drafting",
    "early-childhood-education",
    "economics",
    "education",
    "educational-administration",
    "educational-counseling",
    "educational-leadership",
    "educational-psychology",
    "educational-technology",
    "electrical-engineering",
    "electrical-technician",
    "elementary-education",
    "emergency-management",
    "engineering",
    "engineering-management",
    "english",
    "english-language-learning",
    "entertainment-management",
    "entrepreneurship",
    "environmental-management",
    "environmental-science",
    "family-counseling",
    "fashion-design",
    "film",
    "finance",
    "fire-science",
    "fitness-personal-training",
    "forensic-accounting",
    "forensic-psychology",
    "forensic-science",
    "game-design",
    "general",
    "general-studies",
    "geography",
    "gerontology",
    "gifted-talented-education",
    "graphic-design",
    "health-education",
    "health-informatics",
    "health-science",
    "health-services",
    "healthcare-administration",
    "healthcare-management",
    "heating-cooling",
    "higher-education",
    "history",
    "homeland-security",
    "hospitality-management",
    "hotel-restaurant-management",
    "human-family-development",
    "human-resources",
    "human-services",
    "industrial-engineering",
    "information-systems-security",
    "information-technology",
    "interior-design",
    "international-business",
    "internet-marketing",
    "jewelry-design",
    "journalism",
    "k-12-education",
    "lab-science",
    "landscape-architecture",
    "law-enforcement",
    "legal-studies",
    "liberal-arts",
    "library-science",
    "logistics",
    "management",
    "management-information-systems",
    "marketing",
    "math-education",
    "media-communications",
    "medical-assisting",
    "medical-coding",
    "medical-office-administration",
    "mental-health-counseling",
    "military-studies",
    "mobile-development",
    "multimedia-design",
    "music",
    "negotiation-conflict-management",
    "network-administration",
    "network-security",
    "non-profit-management",
    "nurse-practitioner",
    "nursing",
    "nursing-education-rn-required",
    "nutritional-sciences",
    "occupational-therapy",
    "online-teaching",
    "organizational-leadership",
    "organizational-psychology",
    "paralegal",
    "pharmacy",
    "philosophy",
    "photography",
    "political-science",
    "project-management",
    "psychology",
    "public-administration",
    "public-health",
    "public-policy",
    "public-relations",
    "public-safety-administration",
    "reading-literacy",
    "real-estate",
    "respiratory-therapy",
    "retail-sales-management",
    "risk-management",
    "rn-to-bsn",
    "secondary-education",
    "security",
    "small-business",
    "social-studies",
    "social-work",
    "sociology",
    "software-engineering",
    "special-education",
    "speech-therapy",
    "sports-management",
    "sports-medicine",
    "sports-medicine-physical-therapy",
    "supply-chain-logistics",
    "sustainability",
    "taxation",
    "teacher-licensure",
    "technology",
    "telecommunications",
    "theology",
    "trades",
    "training-development",
    "veterinary",
    "web-design",
    "web-development",
    "writing",
];

export const ED_DEGREELEVEL: string[] = [
    "associates",
    "bachelors",
    "certificate",
    "doctorate",
    "general",
    "grad-certificates",
    "masters",
];
