import { AdminCollectionPage } from "../../../clay/admin-collection-page";
import { clearCache, clearUrlCache } from "../cache";
import { AiPageWidget } from "./widget";

export const AiPagesPage = AdminCollectionPage({
    meta: AiPageWidget,
    labelColumn: "key",
    urlPrefix: "#/ai/pages",
    postSave: (record) => {
        clearCache("page-" + record.key);
        clearUrlCache("/" + record.key);
        if (record.key === "home") {
            clearUrlCache("/");
        }
        if (record.key == "footer") {
            clearCache("basic-context");
        }
    },
});
