import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TabsWidget } from "../../../clay/widgets/TabsWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { AdGroupItemWidget } from "./item-widget";
import { AdGroup, AD_GROUP_META } from "./table";

//!RecordWidget
export type AdGroupWidgetData = AdGroup;

export const AdGroupWidgetFields = {
    name: FormField(TextWidget),
    items: TabsWidget(AdGroupItemWidget, {}),
};

function AdGroupWidgetComponent(
    widgets: AdGroupWidgetWidgets,
    props: AdGroupWidgetProps
) {
    const actions = React.useCallback(
        (item: {}, index: number) => {
            return [
                {
                    label: "Remove",
                    action: () => {
                        props.dispatch({
                            type: "ITEMS",
                            action: {
                                type: "REMOVE",
                                index,
                            },
                        });
                    },
                },
            ];
        },
        [props.dispatch]
    );
    return (
        <>
            <widgets.name />
            <widgets.items
                actions={actions}
                labelForItem={(item) => item.name}
                newLabel="New Ad Group"
            />
            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AdGroupWidgetContext = WidgetContext<typeof AdGroupWidgetFields.name> &
    WidgetContext<typeof AdGroupWidgetFields.items>;
type AdGroupWidgetExtraProps = {};
type AdGroupWidgetBaseState = {
    name: WidgetState<typeof AdGroupWidgetFields.name>;
    items: WidgetState<typeof AdGroupWidgetFields.items>;
};
export type AdGroupWidgetState = AdGroupWidgetBaseState;

type BaseAdGroupWidgetAction =
    | { type: "NAME"; action: WidgetAction<typeof AdGroupWidgetFields.name> }
    | { type: "ITEMS"; action: WidgetAction<typeof AdGroupWidgetFields.items> };

export type AdGroupWidgetAction = BaseAdGroupWidgetAction;

export type AdGroupWidgetProps = WidgetProps<
    AdGroupWidgetState,
    AdGroupWidgetData,
    AdGroupWidgetAction,
    AdGroupWidgetExtraProps
>;

function baseValidateAdGroupWidget(
    data: AdGroupWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(AdGroupWidgetFields.name, data.name, cache, "name", errors);
    subvalidate(AdGroupWidgetFields.items, data.items, cache, "items", errors);
    return errors;
}
function baseAdGroupWidgetReduce(
    state: AdGroupWidgetState,
    data: AdGroupWidgetData,
    action: BaseAdGroupWidgetAction,
    context: AdGroupWidgetContext
): WidgetResult<AdGroupWidgetState, AdGroupWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = AdGroupWidgetFields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "ITEMS": {
            const inner = AdGroupWidgetFields.items.reduce(
                state.items,
                data.items,
                action.action,
                subcontext
            );
            return {
                state: { ...state, items: inner.state },
                data: { ...data, items: inner.data },
            };
        }
    }
}
export type AdGroupWidgetReactContextType = {
    state: AdGroupWidgetState;
    data: AdGroupWidgetData;
    dispatch: (action: AdGroupWidgetAction) => void;
    status: WidgetStatus;
};
export const AdGroupWidgetReactContext = React.createContext<
    AdGroupWidgetReactContextType | undefined
>(undefined);
export const AdGroupWidgetWidgets = {
    name: function (
        props: WidgetExtraProps<typeof AdGroupWidgetFields.name> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupWidgetReactContext
        ) as AdGroupWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupWidgetFields.name>) =>
                context.dispatch({ type: "NAME", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupWidgetFields.name.component
                state={context.state.name}
                data={context.data.name}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Name"}
            />
        );
    },
    items: function (
        props: WidgetExtraProps<typeof AdGroupWidgetFields.items> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            AdGroupWidgetReactContext
        ) as AdGroupWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof AdGroupWidgetFields.items>) =>
                context.dispatch({ type: "ITEMS", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "items", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AdGroupWidgetFields.items.component
                state={context.state.items}
                data={context.data.items}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Items"}
            />
        );
    },
};
export const AdGroupWidget: RecordWidget<
    AdGroupWidgetState,
    AdGroupWidgetData,
    AdGroupWidgetContext,
    AdGroupWidgetAction,
    AdGroupWidgetExtraProps
> = {
    reactContext: AdGroupWidgetReactContext,
    fieldWidgets: AdGroupWidgetWidgets,
    dataMeta: AD_GROUP_META,
    initialize(
        data: AdGroupWidgetData,
        context: AdGroupWidgetContext,
        parameters?: string[]
    ): WidgetResult<AdGroupWidgetState, AdGroupWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerName = AdGroupWidgetFields.name.initialize(
            data.name,
            subcontext,
            subparameters.name
        );
        const innerItems = AdGroupWidgetFields.items.initialize(
            data.items,
            subcontext,
            subparameters.items
        );
        let state = {
            name: innerName.state,
            items: innerItems.state,
        };
        return {
            state,
            data: {
                ...data,
                name: innerName.data,
                items: innerItems.data,
            },
        };
    },
    validate: baseValidateAdGroupWidget,
    component: React.memo((props: AdGroupWidgetProps) => {
        return (
            <AdGroupWidgetReactContext.Provider value={props}>
                {AdGroupWidgetComponent(AdGroupWidgetWidgets, props)}
            </AdGroupWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAdGroupWidgetReduce,
};

type AdGroupWidgetWidgets = {
    name: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupWidgetFields.name
        >
    >;
    items: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AdGroupWidgetFields.items
        >
    >;
};
// END MAGIC -- DO NOT EDIT
