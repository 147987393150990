import { QuickCacheApi } from "../clay/quick-cache";
import { FilterDetail } from "../clay/server/api";
import { UUID } from "../clay/uuid";
import { ValidationError } from "../clay/widgets";

type WithSlug = {
    id: UUID;
    slug: string;
    active?: boolean;
    category?: string | null;
    kind?: string;
};

export function slugCheckValidate(
    tableName: string,
    data: WithSlug,
    cache: QuickCacheApi,
    errors: ValidationError[]
) {
    if (data.active !== false) {
        const key = `slug-check-${data.id.uuid}-${data.slug}-${data.active}-${data.kind}-${data.category}-${tableName}`;
        const filters: FilterDetail[] = [
            {
                column: "id",
                filter: {
                    not_equal: data.id.uuid,
                },
            },
            {
                column: "slug",
                filter: {
                    equal: data.slug,
                },
            },
        ];
        if (data.category !== undefined) {
            filters.push({
                column: "category",
                filter: {
                    equal: data.category,
                },
            });
        }
        if (data.kind !== undefined) {
            filters.push({
                column: "kind",
                filter: {
                    equal: data.kind,
                },
            });
        }
        const slugAvailable =
            cache.exists(key, {
                tableName,
                columns: ["id"],
                filters,
            }) === false;

        if (!slugAvailable) {
            errors.push({
                field: "slug",
                empty: false,
                invalid: true,
            });
        }
    }
}
