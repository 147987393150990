import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveButton } from "../../../clay/save-button";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { ArticleLinkWidget } from "./article-widget";
import { PersonLinkWidget } from "./person-widget";
import { AiHomePage, AI_HOME_PAGE_META } from "./table";

//!RecordWidget
export type AiHomePageWidgetData = AiHomePage;

export const AiHomePageWidgetFields = {
    featuredPeople: ListWidget(PersonLinkWidget),
    promotionalArticles: ListWidget(ArticleLinkWidget),
    footerArticles: ListWidget(ArticleLinkWidget),
};

function AiHomePageWidgetComponent(
    widgets: AiHomePageWidgetWidgets,
    props: AiHomePageWidgetProps
) {
    return (
        <>
            <Tabs defaultActiveKey="promo" id="page-tabs">
                <Tab eventKey="promo" title="Promotional Articles">
                    <table style={{ width: "100%" }}>
                        <widgets.promotionalArticles
                            containerClass="tbody"
                            extraItemForAdd
                        />
                    </table>
                </Tab>
                <Tab eventKey="footer" title="Footer Articles">
                    <table style={{ width: "100%" }}>
                        <widgets.footerArticles
                            containerClass="tbody"
                            extraItemForAdd
                        />
                    </table>
                </Tab>
            </Tabs>
            <SaveButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type AiHomePageWidgetContext = WidgetContext<
    typeof AiHomePageWidgetFields.featuredPeople
> &
    WidgetContext<typeof AiHomePageWidgetFields.promotionalArticles> &
    WidgetContext<typeof AiHomePageWidgetFields.footerArticles>;
type AiHomePageWidgetExtraProps = {};
type AiHomePageWidgetBaseState = {
    featuredPeople: WidgetState<typeof AiHomePageWidgetFields.featuredPeople>;
    promotionalArticles: WidgetState<
        typeof AiHomePageWidgetFields.promotionalArticles
    >;
    footerArticles: WidgetState<typeof AiHomePageWidgetFields.footerArticles>;
};
export type AiHomePageWidgetState = AiHomePageWidgetBaseState;

type BaseAiHomePageWidgetAction =
    | {
          type: "FEATURED_PEOPLE";
          action: WidgetAction<typeof AiHomePageWidgetFields.featuredPeople>;
      }
    | {
          type: "PROMOTIONAL_ARTICLES";
          action: WidgetAction<
              typeof AiHomePageWidgetFields.promotionalArticles
          >;
      }
    | {
          type: "FOOTER_ARTICLES";
          action: WidgetAction<typeof AiHomePageWidgetFields.footerArticles>;
      };

export type AiHomePageWidgetAction = BaseAiHomePageWidgetAction;

export type AiHomePageWidgetProps = WidgetProps<
    AiHomePageWidgetState,
    AiHomePageWidgetData,
    AiHomePageWidgetAction,
    AiHomePageWidgetExtraProps
>;

function baseValidateAiHomePageWidget(
    data: AiHomePageWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(
        AiHomePageWidgetFields.featuredPeople,
        data.featuredPeople,
        cache,
        "featuredPeople",
        errors
    );
    subvalidate(
        AiHomePageWidgetFields.promotionalArticles,
        data.promotionalArticles,
        cache,
        "promotionalArticles",
        errors
    );
    subvalidate(
        AiHomePageWidgetFields.footerArticles,
        data.footerArticles,
        cache,
        "footerArticles",
        errors
    );
    return errors;
}
function baseAiHomePageWidgetReduce(
    state: AiHomePageWidgetState,
    data: AiHomePageWidgetData,
    action: BaseAiHomePageWidgetAction,
    context: AiHomePageWidgetContext
): WidgetResult<AiHomePageWidgetState, AiHomePageWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "FEATURED_PEOPLE": {
            const inner = AiHomePageWidgetFields.featuredPeople.reduce(
                state.featuredPeople,
                data.featuredPeople,
                action.action,
                subcontext
            );
            return {
                state: { ...state, featuredPeople: inner.state },
                data: { ...data, featuredPeople: inner.data },
            };
        }
        case "PROMOTIONAL_ARTICLES": {
            const inner = AiHomePageWidgetFields.promotionalArticles.reduce(
                state.promotionalArticles,
                data.promotionalArticles,
                action.action,
                subcontext
            );
            return {
                state: { ...state, promotionalArticles: inner.state },
                data: { ...data, promotionalArticles: inner.data },
            };
        }
        case "FOOTER_ARTICLES": {
            const inner = AiHomePageWidgetFields.footerArticles.reduce(
                state.footerArticles,
                data.footerArticles,
                action.action,
                subcontext
            );
            return {
                state: { ...state, footerArticles: inner.state },
                data: { ...data, footerArticles: inner.data },
            };
        }
    }
}
export type AiHomePageWidgetReactContextType = {
    state: AiHomePageWidgetState;
    data: AiHomePageWidgetData;
    dispatch: (action: AiHomePageWidgetAction) => void;
    status: WidgetStatus;
};
export const AiHomePageWidgetReactContext = React.createContext<
    AiHomePageWidgetReactContextType | undefined
>(undefined);
export const AiHomePageWidgetWidgets = {
    featuredPeople: function (
        props: WidgetExtraProps<
            typeof AiHomePageWidgetFields.featuredPeople
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiHomePageWidgetReactContext
        ) as AiHomePageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiHomePageWidgetFields.featuredPeople
                >
            ) => context.dispatch({ type: "FEATURED_PEOPLE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "featuredPeople", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiHomePageWidgetFields.featuredPeople.component
                state={context.state.featuredPeople}
                data={context.data.featuredPeople}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Featured People"}
            />
        );
    },
    promotionalArticles: function (
        props: WidgetExtraProps<
            typeof AiHomePageWidgetFields.promotionalArticles
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiHomePageWidgetReactContext
        ) as AiHomePageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiHomePageWidgetFields.promotionalArticles
                >
            ) => context.dispatch({ type: "PROMOTIONAL_ARTICLES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "promotionalArticles",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <AiHomePageWidgetFields.promotionalArticles.component
                state={context.state.promotionalArticles}
                data={context.data.promotionalArticles}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Promotional Articles"}
            />
        );
    },
    footerArticles: function (
        props: WidgetExtraProps<
            typeof AiHomePageWidgetFields.footerArticles
        > & { label?: string; readOnly?: boolean }
    ) {
        const context = React.useContext(
            AiHomePageWidgetReactContext
        ) as AiHomePageWidgetReactContextType;
        const subdispatch = React.useCallback(
            (
                action: WidgetAction<
                    typeof AiHomePageWidgetFields.footerArticles
                >
            ) => context.dispatch({ type: "FOOTER_ARTICLES", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "footerArticles", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <AiHomePageWidgetFields.footerArticles.component
                state={context.state.footerArticles}
                data={context.data.footerArticles}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Footer Articles"}
            />
        );
    },
};
export const AiHomePageWidget: RecordWidget<
    AiHomePageWidgetState,
    AiHomePageWidgetData,
    AiHomePageWidgetContext,
    AiHomePageWidgetAction,
    AiHomePageWidgetExtraProps
> = {
    reactContext: AiHomePageWidgetReactContext,
    fieldWidgets: AiHomePageWidgetWidgets,
    dataMeta: AI_HOME_PAGE_META,
    initialize(
        data: AiHomePageWidgetData,
        context: AiHomePageWidgetContext,
        parameters?: string[]
    ): WidgetResult<AiHomePageWidgetState, AiHomePageWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerFeaturedPeople = AiHomePageWidgetFields.featuredPeople.initialize(
            data.featuredPeople,
            subcontext,
            subparameters.featuredPeople
        );
        const innerPromotionalArticles = AiHomePageWidgetFields.promotionalArticles.initialize(
            data.promotionalArticles,
            subcontext,
            subparameters.promotionalArticles
        );
        const innerFooterArticles = AiHomePageWidgetFields.footerArticles.initialize(
            data.footerArticles,
            subcontext,
            subparameters.footerArticles
        );
        let state = {
            featuredPeople: innerFeaturedPeople.state,
            promotionalArticles: innerPromotionalArticles.state,
            footerArticles: innerFooterArticles.state,
        };
        return {
            state,
            data: {
                ...data,
                featuredPeople: innerFeaturedPeople.data,
                promotionalArticles: innerPromotionalArticles.data,
                footerArticles: innerFooterArticles.data,
            },
        };
    },
    validate: baseValidateAiHomePageWidget,
    component: React.memo((props: AiHomePageWidgetProps) => {
        return (
            <AiHomePageWidgetReactContext.Provider value={props}>
                {AiHomePageWidgetComponent(AiHomePageWidgetWidgets, props)}
            </AiHomePageWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseAiHomePageWidgetReduce,
};

type AiHomePageWidgetWidgets = {
    featuredPeople: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiHomePageWidgetFields.featuredPeople
        >
    >;
    promotionalArticles: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiHomePageWidgetFields.promotionalArticles
        >
    >;
    footerArticles: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof AiHomePageWidgetFields.footerArticles
        >
    >;
};
// END MAGIC -- DO NOT EDIT
